import React, { useEffect, useState } from "react"
import EditIcon from "assets/icons/EditIcon"
import DeleteIcon from "assets/icons/DeleteIcon"
import CategoryIcon from "assets/icons/CategoryIcon"
import AgGridTable from "components/Common/AgGridTable"
import PageContainer from "components/Common/PageContainer"
import { Badge, Button, Spinner } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons"
import { fetchDispositions } from "store/actions"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import DeleteModal from "components/Common/DeleteModal"
import { axiosApi } from "helpers/api_helper"
import { POST_DISPOSITION } from "helpers/url_helper"
import { truncateString } from "helpers/helperFns"
// import { getDepartments } from "store/actions"

const moduleName = "dispositions"

const Dispositions = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  //permissions
  const PermissionsSelector = createSelector(
    state => state.Permissions,
    perm => ({
      permissions: perm.permissions,
    })
  )
  const { permissions } = useSelector(PermissionsSelector)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [currentId, setCurrentId] = useState()
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [deleteError, setDeleteError] = useState()

  const [rows, setRows] = useState([])

  useEffect(() => {
    dispatch(fetchDispositions())
  }, [])

  const DispositionProperties = createSelector(
    state => state.Disposition,
    dis => ({
      loading: dis.loading,
      dispositions: dis.dispositions,
    })
  )

  const { loading, dispositions } = useSelector(DispositionProperties)

  const generateType = (isIb, isOb, isInternal) => {
    const typeArr = []
    if (isIb) typeArr.push("IB")
    if (isOb) typeArr.push("OB")
    if (isInternal) typeArr.push("Internal")
    return typeArr.join("/")
  }

  useEffect(() => {
    setRows(
      dispositions.map(item => ({
        id: item.disposition_id,
        name: item.name,
        description: item.description,
        type: generateType(item.is_ib, item.is_ob, item.is_internal),
        opportunity: item.is_opportunity,
        appointment: item.is_appointment,
        serviceAdvisor: item.is_sarequired,
        status: item.is_active ? "Active" : "Inactive",
        is_ob: item.is_ob,
        is_connected: item.is_connected,
      }))
    )
  }, [dispositions])

  const columns = [
    { field: "name", filter: true, headerName: "Disposition" },
    { field: "type", filter: true, headerName: "Type" },
    {
      field: "description",
      filter: true,
      headerName: "Description",
      cellRenderer: params => {
        const description = params.data.description
        const isLong = !!description && description.length > 30

        const updatedDescription = isLong
          ? `${truncateString(description, 30)}...`
          : description
        return (
          <div className="d-flex align-items-center h-100">
            {description &&
              (isLong ? (
                <div
                  data-tooltip-id="viewInfo"
                  data-tooltip-content={description}
                >
                  <p>{updatedDescription}</p>
                </div>
              ) : (
                <div>{updatedDescription}</div>
              ))}
          </div>
        )
      },
    },

    {
      field: "opportunity",
      filter: true,
      headerName: "OTDB",
      cellRenderer: params => {
        return (
          params.data.opportunity && (
            <FontAwesomeIcon color="green" icon={faCheck} />
          )
        )
      },
    },
    {
      field: "appointment",
      filter: true,
      headerName: "Appt",
      cellRenderer: params => {
        return (
          params.data.appointment && (
            <FontAwesomeIcon color="green" icon={faCheck} />
          )
        )
      },
    },
    {
      field: "serviceAdvisor",
      filter: true,
      headerName: "SA",
      cellRenderer: params => {
        return (
          params.data.serviceAdvisor && (
            <FontAwesomeIcon color="green" icon={faCheck} />
          )
        )
      },
    },
    {
      field: "isConnected",
      filter: true,
      headerName: "Connected",
      cellRenderer: params => {
        const { is_ob, is_connected } = params.data || {}

        return is_ob && is_connected ? (
          <FontAwesomeIcon color="green" icon={faCheck} />
        ) : is_ob && !is_connected ? (
          <FontAwesomeIcon color="red" icon={faClose} />
        ) : (
          <div>-</div>
        )
      },
    },
    {
      field: "status",
      filter: true,
      headerName: "Status",
      cellRenderer: params => {
        return (
          <Badge
            color={
              params.data.status === "Active"
                ? "success"
                : params.data.status === "Inactive"
                ? "danger"
                : "secondary"
            }
          >
            {params.data.status}
          </Badge>
        )
      },
    },
    {
      headerName: "",
      sortable: false,
      cellRenderer: params => {
        console.log(params.data)
        return (
          <div className="d-flex align-items-center h-100 gap-2">
            {actions(params.data.id)}
          </div>
        )
      },
    },
  ]

  const actions = id => {
    return (
      <>
        {/* <CategoryIcon /> */}
        {permissions?.[moduleName]?.EDIT && (
          <div
            onClick={() =>
              navigate("/dispositions/" + id, {
                state: dispositions?.find(item => item.disposition_id === id),
              })
            }
          >
            <EditIcon />
          </div>
        )}
        {permissions?.[moduleName]?.DELETE && (
          <div
            onClick={() => {
              setShowDeleteModal(true)
              setCurrentId(id)
            }}
          >
            <DeleteIcon />
          </div>
        )}
      </>
    )
  }

  const handleDelete = async () => {
    setDeleteLoading(true)
    setDeleteError(null)
    try {
      await axiosApi.delete(POST_DISPOSITION + "/" + currentId)
      dispatch(fetchDispositions())
      setShowDeleteModal(false)
    } catch (err) {
      setDeleteError(err.response.data?.message)
    } finally {
      setDeleteLoading(false)
    }
  }

  return (
    <PageContainer>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setShowDeleteModal(false)
          setDeleteError(null)
        }}
        errorMsg={deleteError}
        loading={deleteLoading}
      />
      <div className="d-flex justify-content-between mb-4">
        <h1 className="page-title">Dispositions</h1>
        {permissions?.[moduleName]?.ADD && (
          <Button
            color="primary"
            style={{ height: "36px" }}
            onClick={() => navigate("new")}
          >
            Add Disposition
          </Button>
        )}
      </div>
      {loading ? (
        <div className="w-full d-flex justify-content-center align-items-center pt-4">
          <Spinner />
        </div>
      ) : (
        <>
          {permissions?.[moduleName]?.VIEW && (
            <AgGridTable
              rowState={[rows, setRows]}
              columns={columns}
              qiuckFilter={true}
            />
          )}
        </>
      )}
    </PageContainer>
  )
}

export default Dispositions
