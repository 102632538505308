import PageContainer from "components/Common/PageContainer"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { Alert, Button, Form, Spinner } from "reactstrap"
import * as Yup from "yup"
import InputField from "components/Form/InputField"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import SelectField from "components/Form/SelectField"
import { axiosApi } from "helpers/api_helper"
import {
  addServiceAdvisor,
  addUser,
  fetchDepartments,
  fetchStores,
  updateServiceAdvisor,
} from "store/actions"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

const AddEditServiceAdvisor = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { id } = useParams()
  const { state: serviceAdvisorData } = useLocation()

  const [showConfirm, setShowConfirm] = useState(false)

  useEffect(() => {
    dispatch(fetchDepartments())
    dispatch(fetchStores())
  }, [])

  const DepartmentProperties = createSelector(
    state => state.Department,
    dep => ({
      departments: dep.departments,
    })
  )

  const { departments } = useSelector(DepartmentProperties)

  const StoreProperties = createSelector(
    state => state.Store,
    store => ({
      stores: store.stores,
    })
  )

  const { stores } = useSelector(StoreProperties)

  const ServiceAdvisor = createSelector(
    state => state.ServiceAdvisor,
    serviceAdvisor => ({
      loading: serviceAdvisor.loading,
      errorMsg: serviceAdvisor.errorMsg,
      successMsg: serviceAdvisor.successMsg,
    })
  )

  const initialStoreValue = stores?.find(
    ({ name }) => name === serviceAdvisorData?.scopes?.[0]?.name
  )
  const initialStoreOption = initialStoreValue
    ? {
        value: initialStoreValue?.store_id,
        label: initialStoreValue?.name,
      }
    : null

  const { loading, errorMsg, successMsg } = useSelector(ServiceAdvisor)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: serviceAdvisorData
      ? {
          firstName: serviceAdvisorData.firstName,
          lastName: serviceAdvisorData.lastName,
          email: serviceAdvisorData.email,
          department:
            serviceAdvisorData.departments?.length > 0
              ? {
                  value: serviceAdvisorData.departments?.[0]?.department_id,
                  label: serviceAdvisorData.departments?.[0]?.type_name,
                }
              : null,
          status: {
            value: serviceAdvisorData.status?.toLowerCase(),
            label:
              serviceAdvisorData.status?.[0] +
              serviceAdvisorData.status?.substr(1)?.toLowerCase(),
          },
          store: initialStoreOption,
        }
      : {
          firstName: "",
          lastName: "",
          email: "",
          department: null,
          status: null,
          store: null,
        },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      // email: Yup.string().email().required("Required"),
      department: Yup.object().required("Required"),
      status: Yup.object().required("Required"),
      store: Yup.object().required("Required"),
    }),
    onSubmit: values => {
      window.scrollTo({ top: 0, behavior: "smooth" })
      const body = {
        firstName: values.firstName,
        lastName: values.lastName,
        departmentId: values.department.value,
        status: values.status?.value?.toUpperCase(),
        scopes_ids: [values.store?.value],
      }
      if (values.email) {
        body.email = values.email
      }
      if (id) dispatch(updateServiceAdvisor(id, body, navigate))
      else dispatch(addServiceAdvisor(body, navigate))
    },
  })

  const handleCancel = () => {
    if (validation.dirty) {
      window.scrollTo({ top: 0, behavior: "smooth" })
      setShowConfirm(true)
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    if (successMsg) {
      toast.success(successMsg)
    }
  }, [successMsg])

  useEffect(() => {
    if (errorMsg) {
      toast.error(errorMsg)
    }
  }, [errorMsg])

  return (
    <PageContainer>
      <h1 className="page-title mb-4">{id ? "Edit" : "Add"} Service Advisor</h1>
      <div className="content-container">
        {showConfirm && (
          <Alert color="danger" style={{ marginTop: "13px" }}>
            <div>
              <p>Are you sure you want to cancel? Your inputs will be lost.</p>
              <div className="d-flex">
                <Button
                  type="button"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() => navigate(-1)}
                >
                  Yes
                </Button>
                <Button type="button" onClick={() => setShowConfirm(false)}>
                  No
                </Button>
              </div>
            </div>
          </Alert>
        )}

        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="row">
                  <div className="col-md-6 col-12  mb-4">
                    <InputField
                      name="firstName"
                      validation={validation}
                      placeholder=""
                      label={"First Name"}
                      required
                    />
                  </div>
                  <div className="col-md-6 col-12  mb-4">
                    <InputField
                      name="lastName"
                      validation={validation}
                      placeholder=""
                      label={"Last Name"}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                {/* <div className="row"> */}
                {/* <div className="col-md-6 col-12  mb-4"> */}
                <InputField
                  name="email"
                  validation={validation}
                  placeholder=""
                  label={"Email"}
                />
                {/* </div> */}
                {/* <div className="col-md-6 col-12  mb-4">
                    <InputField
                      name="mobileNumber"
                      validation={validation}
                      placeholder=""
                      label={"Mobile Number"}
                      required
                    />
                  </div> */}
                {/* </div> */}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12  mb-4">
                <SelectField
                  label={"Department"}
                  required
                  name="department"
                  validation={validation}
                  options={departments.map(item => ({
                    value: item.department_id,
                    label: item.type_name,
                  }))}
                />
              </div>
              <div className="col-md-6 col-12  mb-4">
                <SelectField
                  label={"Store"}
                  required
                  name="store"
                  validation={validation}
                  options={stores.map(item => ({
                    value: item.store_id,
                    label: item.name,
                  }))}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12  mb-4">
                <SelectField
                  label={"Status"}
                  required
                  name="status"
                  validation={validation}
                  options={[
                    // { value: "pending", label: "Pending" },
                    { value: "active", label: "Active" },
                    { value: "inactive", label: "Inactive" },
                  ]}
                />
              </div>
            </div>
          </div>

          <div className="mt-5 d-flex justify-content-center justify-content-lg-start">
            <Button
              color="primary"
              style={{
                marginRight: "20px",
                width: "110px",
              }}
              type="submit"
            >
              {loading ? <Spinner size="sm" /> : "Save"}
            </Button>
            <Button type="button" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </PageContainer>
  )
}

export default AddEditServiceAdvisor
