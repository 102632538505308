import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import PermissionsSaga from "./auth/permissions/saga"

import LayoutSaga from "./layout/saga"
import DepartmentSaga from "./department/saga"
import CompanySaga from "./company/saga"
import StoreSaga from "./store/saga"
import GroupSaga from "./group/saga"
import RoleSaga from "./role/saga"
import UserSaga from "./user/saga"
import DispositionSaga from "./disposition/saga"
import CallSaga from "./call/saga"
import ServiceAdvisor from "./serviceAdvisor/saga"
import Agent from "./agent/saga"
import Target from "./target/saga"
import GoalSaga from "./goal/saga"
import GoalEntrySaga from "./goalEntry/saga"
import KpiSaga from "./kpi/saga"
import KpiEntrySaga from "./kpiEntry/saga"
import EntityReportSaga from "./entityReport/saga"
import ServiceAdvisorReportSaga from "./serviceAdvisorReport/saga"
import RollupReportSaga from "./rollupReport/saga"
import stackRankingReportSaga from "./stackRanking/saga"
import msTargetReportSaga from "./msTargetReport/saga"
import CalendarSaga from "./calendar/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(DepartmentSaga),
    fork(CompanySaga),
    fork(StoreSaga),
    fork(GroupSaga),
    fork(RoleSaga),
    fork(UserSaga),
    fork(DispositionSaga),
    fork(CallSaga),
    fork(ServiceAdvisor),
    fork(Agent),
    fork(Target),
    fork(PermissionsSaga),
    fork(GoalSaga),
    fork(GoalEntrySaga),
    fork(KpiSaga),
    fork(KpiEntrySaga),
    fork(EntityReportSaga),
    fork(ServiceAdvisorReportSaga),
    fork(RollupReportSaga),
    fork(stackRankingReportSaga),
    fork(msTargetReportSaga),
    fork(CalendarSaga),
  ])
}
