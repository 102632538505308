import React, { useEffect, useState } from "react"
import PageContainer from "components/Common/PageContainer"
import { useNavigate, useParams } from "react-router-dom"
import { getCall } from "helpers/fakebackend_helper"
import NewCallForm from "./NewCallForm"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"

const EditCall = () => {
  const { callId } = useParams()
  const [call, setCall] = useState()
  const navigate = useNavigate()

  const CallProperties = createSelector(
    state => state.Call,
    call => ({
      calls: call.calls,
    })
  )

  const { calls } = useSelector(CallProperties)

  useEffect(() => {
    if (callId && calls?.length > 0) {
      setCall(calls.find(item => item.call_id == callId))
    } else {
      navigate("/calls")
    }
  }, [callId, calls])

  return (
    <PageContainer>
      <h1 className="page-title">Edit Call Record</h1>
      <NewCallForm call={call} />
    </PageContainer>
  )
}

export default EditCall
