import React, { useEffect, useState } from "react"
import EditIcon from "assets/icons/EditIcon"
import DeleteIcon from "assets/icons/DeleteIcon"
import AgGridTable from "components/Common/AgGridTable"
import PageContainer from "components/Common/PageContainer"
import { Button, Spinner } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { fetchGroups, fetchTargets } from "store/actions"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { format } from "date-fns"

const Targets = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [rows, setRows] = useState([])

  useEffect(() => {
    dispatch(fetchTargets())
  }, [])

  const TargetProperties = createSelector(
    state => state.Target,
    target => ({
      loading: target.loading,
      targets: target.targets,
    })
  )

  const { loading, targets } = useSelector(TargetProperties)

  useEffect(() => {
    console.log("targets", targets)
    setRows(
      targets.map(target => ({
        id: target.target_id,
        month: new Date(target.month),
        store: target.store?.name,
        department: target.department?.type_name,
        callsGoal: target.dailyCallsTarget,
        apptsGoal: target.dailyAppointmentsTarget,
      }))
    )
  }, [targets])

  const columns = [
    {
      field: "month",
      filter: true,
      headerName: "Month",
      valueFormatter: params => format(params.data.month, "MMMM yyyy"),
    },
    { field: "store", filter: true, headerName: "Store" },
    { field: "department", filter: true, headerName: "Department" },
    { field: "callsGoal", filter: true, headerName: "Daily Calls Goal" },
    { field: "apptsGoal", filter: true, headerName: "Daily Appts Goal" },
    {
      headerName: "",
      sortable: false,
      cellRenderer: params => {
        return (
          <div className="d-flex align-items-center h-100 gap-2">
            {actions(params.data.make)}
          </div>
        )
      },
    },
  ]

  const actions = id => {
    return (
      <>
        <EditIcon />
        <DeleteIcon />
      </>
    )
  }

  return (
    <PageContainer>
      <div className="d-flex justify-content-between mb-4">
        <h1 className="page-title">Targets</h1>
        <Button
          color="primary"
          style={{ height: "36px" }}
          onClick={() => navigate("new")}
        >
          Set New Target
        </Button>
      </div>
      {loading ? (
        <div className="w-full d-flex justify-content-center align-items-center pt-4">
          <Spinner />
        </div>
      ) : (
        <AgGridTable
          rowState={[rows, setRows]}
          columns={columns}
          qiuckFilter={true}
        />
      )}
    </PageContainer>
  )
}

export default Targets
