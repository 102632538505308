import {
  ADD_KPI,
  FETCH_KPIS,
  KPI_ERROR,
  KPI_SUCCESS,
  SET_KPIS,
  UPDATE_KPI,
} from "./actionTypes"

export const addKpi = (kpi, history) => {
  return {
    type: ADD_KPI,
    payload: { kpi, history },
  }
}

export const updateKpi = (id, kpi, history) => {
  return {
    type: UPDATE_KPI,
    payload: { id, kpi, history },
  }
}

export const fetchKpis = () => {
  return {
    type: FETCH_KPIS,
  }
}

export const kpiSuccess = msg => {
  return {
    type: KPI_SUCCESS,
    payload: { msg },
  }
}

export const kpiError = error => {
  return {
    type: KPI_ERROR,
    payload: error,
  }
}

export const setKpis = kpis => {
  return {
    type: SET_KPIS,
    payload: { kpis },
  }
}
