import PageContainer from "components/Common/PageContainer"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { Alert, Button, Form, Spinner } from "reactstrap"
import * as Yup from "yup"
import InputField from "components/Form/InputField"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import SelectField from "components/Form/SelectField"
import {
  addGoal,
  addKpi,
  fetchGoals,
  updateGoal,
  updateKpi,
} from "store/actions"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import SwitchField from "components/Form/SwitchField"

const AddEditKpi = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { state: kpiData } = useLocation()
  const [showConfirm, setShowConfirm] = useState(false)
  const dispatch = useDispatch()

  console.log("kpi details", kpiData)

  useEffect(() => {
    dispatch(fetchGoals())
  }, [])

  const GoalsProperties = createSelector(
    state => state.Goal,
    goal => ({
      goals: goal.goals,
    })
  )

  const { goals } = useSelector(GoalsProperties)

  const KpiProperties = createSelector(
    state => state.Kpi,
    kpi => ({
      loading: kpi.loading,
      errorMsg: kpi.errorMsg,
      successMsg: kpi.successMsg,
    })
  )

  const { loading, errorMsg, successMsg } = useSelector(KpiProperties)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: kpiData
      ? {
          // name: kpiData.name,
          forStore: kpiData.is_perstore,
          forAgent: kpiData.is_peragent,
          goal: { value: kpiData.goal?.goal_id, label: kpiData.goal?.name },
          unit: { value: kpiData.unit, label: kpiData.unit },
        }
      : {
          // name: "",
          forStore: false,
          forAgent: false,
          goal: null,
          unit: null,
        },
    validationSchema: Yup.object({
      // name: Yup.string().required("Please enter goal name"),
      goal: Yup.object().required("Please choose goal"),
      unit: Yup.object().required("Please choose value"),
    }),
    onSubmit: values => {
      window.scrollTo({ top: 0, behavior: "smooth" })
      const body = {
        // name: values.name,
        is_perstore: values.forStore,
        is_peragent: values.forAgent,
        goal_id: values.goal?.value,
        unit: values.unit?.value,
      }
      if (id) dispatch(updateKpi(id, body, navigate))
      else dispatch(addKpi(body, navigate))
    },
  })

  const handleCancel = () => {
    if (validation.dirty) {
      window.scrollTo({ top: 0, behavior: "smooth" })
      setShowConfirm(true)
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    if (successMsg) {
      toast.success(successMsg)
    }
  }, [successMsg])

  useEffect(() => {
    if (errorMsg) {
      toast.error(errorMsg)
    }
  }, [errorMsg])

  return (
    <PageContainer>
      <h1 className="page-title mb-4">{id ? "Edit" : "Add"} Manual KPI</h1>
      <div className="content-container">
        {showConfirm && (
          <Alert color="danger" style={{ marginTop: "13px" }}>
            <div>
              <p>Are you sure you want to cancel? Your inputs will be lost.</p>
              <div className="d-flex">
                <Button
                  type="button"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() => navigate(-1)}
                >
                  Yes
                </Button>
                <Button type="button" onClick={() => setShowConfirm(false)}>
                  No
                </Button>
              </div>
            </div>
          </Alert>
        )}

        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="container">
            <div className="row">
              {/* <div className="col-lg-6 col-12 mb-4">
                <InputField
                  name="name"
                  validation={validation}
                  placeholder=""
                  label={"Manual KPI Name"}
                  required
                />
              </div> */}

              <div className="col-lg-6 col-12 mb-4">
                <SelectField
                  label={"Goal"}
                  required
                  name="goal"
                  validation={validation}
                  options={goals?.map(item => ({
                    value: item.goal_id,
                    label: item.name,
                  }))}
                />
              </div>
              <div className="col-lg-6 col-12 mb-4">
                <SelectField
                  label={"Unit"}
                  required
                  name="unit"
                  validation={validation}
                  options={[
                    { value: "Number", label: "Number" },
                    { value: "Percentage", label: "Percentage" },
                    { value: "Minutes", label: "Minutes" },
                  ]}
                />
              </div>
              <div className="col-lg-6 col-12 mb-4">
                <div className="row">
                  <div className="col-lg-6 col-6">
                    <SwitchField
                      name="forStore"
                      validation={validation}
                      label={"Per Store"}
                      required
                    />
                  </div>
                  <div className="col-lg-6 col-6">
                    <SwitchField
                      name="forAgent"
                      validation={validation}
                      label={"Per Agent"}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 d-flex justify-content-center justify-content-lg-start">
            <Button
              color="primary"
              style={{
                marginRight: "20px",
                width: "110px",
              }}
              type="submit"
            >
              {loading ? <Spinner size="sm" /> : "Save"}
            </Button>
            <Button type="button" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </PageContainer>
  )
}

export default AddEditKpi
