import {
  faCircleCheck,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getPercentage, roundAndAddSignToPercentage } from "helpers/helperFns"
import React, { useEffect, useState } from "react"
import { Tooltip } from "react-tooltip"
import { Alert, Progress } from "reactstrap"
import target from "store/target/reducer"

const NewCallCountCard = ({
  count,
  title,
  icon,
  target,
  multiProgress,
  isLoading,
  minStandard,
}) => {
  // const [isLoading, setIsLoading] = useState(true)

  // useEffect(() => {
  //   // Simulating loading delay for demonstration purpose
  //   const timer = setTimeout(() => {
  //     setIsLoading(false)
  //   }, 500) // Adjust the delay time as needed

  //   // Cleanup function to clear the timeout
  //   return () => clearTimeout(timer)
  // }, [])
  let status = "danger"
  if (target > 0 && minStandard) {
    status =
      count / target > 0.9
        ? "success"
        : count > minStandard
        ? "warning"
        : "danger"
  }
  return (
    <div>
      <div className="bg-white p-3 px-4 w-full m-0">
        <div className="d-flex justify-content-between align-items-start">
          <p
            style={{
              fontWeight: 800,
              fontSize: "40px",
              color: "black",
              marginBottom: 0,
            }}
          >
            {count}
          </p>
          {/* <FontAwesomeIcon fontSize={"50px"} color="#D9D9D9" icon={icon} /> */}
          {!isLoading && (
            <div className="d-flex flex-column">
              <div className="d-flex gap-2 align-items-center">
                <FontAwesomeIcon
                  fontSize={"20px"}
                  color="#AF0001"
                  icon={icon.icon}
                />
                <span
                  style={{
                    color: "#AF0001",
                    fontWeight: 600,
                    fontSize: "15px",
                  }}
                >
                  {icon.value}
                </span>
              </div>
              <span style={{ fontSize: "12px", color: "grey" }}>
                {icon.label}
              </span>
            </div>
          )}
        </div>
        <p className="mb-1">{title}</p>
        <div
          className="d-flex flex-column gap-1 align-items-end"
          style={{ width: "60%" }}
        >
          <Progress
            data-tooltip-id="progress"
            style={{
              backgroundColor: "#d9d9d9",
            }}
            data-tooltip-content={getPercentage(count, target)}
            className="w-100"
            value={isLoading ? 0 : (count / target) * 100}
            color={status}
          />
          <Tooltip id="progress" />
          <span>{target || 0}</span>
        </div>

        {/* <Alert
            // color={color}
            style={{
              backgroundColor:
                color === "success"
                  ? "#DCFFF3"
                  : color === "warning"
                  ? "#FFEFD3"
                  : "#FFDDDD",
              width: "fit-content",
              margin: "auto",
              marginTop: "30px",
              position: "relative",
              color:
                color === "success"
                  ? "#34C38F"
                  : color === "warning"
                  ? "#F1B44C"
                  : "#C34040",
              border: 0,
            }}
          >
            <FontAwesomeIcon
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                fontSize: "30px",
                transform: "translate(-50%,-50%)",
                color:
                  color === "success"
                    ? "rgb(52, 195, 143)"
                    : color === "warning"
                    ? "#F1B44C"
                    : "#C34040",
              }}
              icon={color === "success" ? faCircleCheck : faCircleExclamation}
            />
            {info}
          </Alert> */}
      </div>
      <Progress multi style={{ height: "5px", bottom: "-5px" }}>
        <Progress
          bar
          value={isLoading ? 0 : multiProgress[0]?.percent}
          style={{ backgroundColor: "#F1426D" }}
          data-tooltip-id="progress"
          data-tooltip-content={`${
            isLoading
              ? 0
              : "IB: " +
                multiProgress[0]?.value +
                "-" +
                roundAndAddSignToPercentage(multiProgress[0]?.percent)
          }`}
        />
        <Progress
          bar
          value={isLoading ? 0 : multiProgress[1]?.percent}
          style={{ backgroundColor: "#7239EA" }}
          data-tooltip-id="progress"
          data-tooltip-content={`${
            isLoading
              ? 0
              : "OB: " +
                multiProgress[1]?.value +
                "-" +
                roundAndAddSignToPercentage(multiProgress[1]?.percent)
          }`}
        />
      </Progress>
    </div>
  )
}

export default NewCallCountCard
