import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Button, Col, Container, Row, Spinner } from "reactstrap"

//i18n
import PageContainer from "components/Common/PageContainer"
import DashboardCountCard from "components/DashboardCountCard"
import {
  faCalendar,
  faCalendarCheck,
  faCaretLeft,
  faCaretRight,
  faChalkboardUser,
  faHandshake,
  faLink,
  faPhone,
  faRightFromBracket,
  faUsers,
  faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons"
import DashboardPercentCard from "components/DashboardPercentCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AgGridTable from "components/Common/AgGridTable"
import SelectField from "components/Form/SelectField"
import { useFormik } from "formik"
import ScopeSelector from "components/Common/ScopeSelector"
import {
  customDateSelectFieldOptions,
  datesOptions,
  datesSelectFieldOptions,
  isCustomDateOption,
  mtdSubText,
  tzMoment,
} from "constants/datesOptions"
import useManagerDashboard from "./hooks/useManagerDashboard"
import { roundAndAddSignToPercentage } from "helpers/helperFns"
import LiveIcon from "./components/LiveIcon"
import useAuth from "common/hooks/useAuth"
import CustomRangePicker from "components/Form/CustomRangePicker"
import * as Yup from "yup"

const ManagerDashboard = props => {
  const { user } = useAuth()
  const [rangePickerKey, setRangePickerKey] = useState(0)

  //meta title
  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      scope: user?.scopes?.map(({ id }) => `${id}`),
      selectedDateOption: {
        value: datesOptions.today.value,
        label: datesOptions.today.title,
        customAbbreviation: datesOptions.today.secondaryTitle,
        afterLabelMarkup: <LiveIcon />,
      },
      startDate: "",
      endDate: "",
    },
    validationSchema: Yup.object({
      startDate: Yup.string().when(
        "selectedDateOption",
        ([selectedDateOption], schema) =>
          selectedDateOption.value === datesOptions.custom.value
            ? schema.required("Please add start date")
            : schema.optional()
      ),
      endDate: Yup.string().when(
        "selectedDateOption",
        ([selectedDateOption], schema) =>
          selectedDateOption.value === datesOptions.custom.value
            ? schema.required("Please add end date")
            : schema.optional()
      ),
      scope: Yup.array().min(1, "Scope Is Required"),

      selectedDateOption: Yup.object().required("Please select a date option"),
    }),

    onSubmit: values => {
      console.log("scope, date", values)
    },
  })

  const {
    loading,
    totalCallsData,
    totalApptsData,
    ibClosingRatioData,
    obClosingRatioData,
    ibOtdbCallsData,
    obConnectedCallsData,
    transferredOutData,
    activeAgentsData,
    avgDailyCallsPerAgentData,
    rows,
    setRows,
    agentTableMode,
    // refreshTable,
    columns,
    totalShowsPercentData,
    totalShowsData,
    handleAgentTableMode,
  } = useManagerDashboard({
    validation,
    // selectedDateOptionValue: validation.values.selectedDateOption?.value,
    // scopeIds: validation.values.scope,
    setFieldValue: validation.setFieldValue,
  })

  const isOneDayMode = [
    datesOptions.today.value,
    datesOptions.yesterday.value,
  ].includes(validation.values.selectedDateOption?.value)
  // const textAfterDashboard = isOneDayMode
  //   ? `| MTD | ${tzMoment().format("MMMM YYYY")}`
  //   : `| Today | ${tzMoment().format("MMMM DD, YYYY")}`

  function refreshDatePicker() {
    setRangePickerKey(oldVal => oldVal + 1)
  }
  const isCustomDate = isCustomDateOption(
    validation.values.selectedDateOption?.value
  )
  function handleCustomRangeChange(dateStrings) {
    if (dateStrings.some(el => !el)) {
      return
    }

    const [startStr, endStr] = dateStrings || []
    validation.setFieldValue("startDate", startStr)
    validation.setFieldValue("endDate", endStr)

    const startStrTitle = startStr
      ? tzMoment(startStr).format("MM/DD/YYYY")
      : ""
    const endStrTitle = endStr ? tzMoment(endStr).format("MM/DD/YYYY") : ""
    const customAbbr =
      startStrTitle && endStrTitle ? `${startStrTitle} - ${endStrTitle}` : ""
    validation.setFieldValue("selectedDateOption", {
      ...validation.values.selectedDateOption,
      customAbbreviation: customAbbr,
    })
  }
  return (
    <PageContainer>
      <div className="d-flex align-items-start justify-content-between mb-4 flex-wrap">
        <h1 className="page-title">Manager Dashboard</h1>
        <div className="flex-grow-1 row  gap-2 flex-nowrap justify-content-end">
          <div className="col-5">
            <ScopeSelector
              minWidth={"100%"}
              validation={validation}
              onChange={ids => {
                validation.setFieldValue("scope", ids)
              }}
              selectedScopes={validation.values.scope}
              hideLabel
              hasInitialValue={true}
            />
          </div>
          <div className="col-6">
            <SelectField
              withLabel={false}
              name="selectedDateOption"
              validation={validation}
              required
              options={customDateSelectFieldOptions
                .filter(({ value }) => value !== datesOptions.yearToDate.value)
                .map(option => {
                  if (option.value === datesOptions.today.value) {
                    return {
                      ...option,
                      afterLabelMarkup: <LiveIcon />,
                    }
                  } else {
                    return option
                  }
                })}
              onChange={option => {
                validation.setFieldValue("selectedDateOption", option)
                if (isCustomDateOption(option.value)) {
                  validation.setFieldValue("startDate", "")
                  validation.setFieldValue("endDate", "")
                  refreshDatePicker()
                }
              }}
            />
            {isCustomDate && !validation.values.startDate && (
              <div key={rangePickerKey} className="">
                <CustomRangePicker onChangeHandler={handleCustomRangeChange} />
              </div>
            )}
          </div>
        </div>
      </div>

      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "50vh" }}
        >
          <Spinner />
        </div>
      ) : (
        <>
          {isOneDayMode && (
            <>
              <div className="agent-dashboard-row">
                <DashboardCountCard
                  count={totalCallsData.count}
                  title="Total Calls"
                  iconComponent={
                    <div className="d-flex flex-column align-items-center gap-1">
                      <FontAwesomeIcon
                        fontSize={"50px"}
                        color="#D9D9D9"
                        icon={faPhone}
                      />
                      <span>AVG {totalCallsData.avg}</span>
                      <span style={{ textAlign: "center", fontSize: "11px" }}>
                        Per Agent
                      </span>
                    </div>
                  }
                  multiProgress={totalCallsData.multiProgress}
                  target={totalCallsData.target}
                  isLoading={loading}
                  minStandard={totalCallsData.minStandard}
                  hideAlert
                />

                <DashboardCountCard
                  count={totalApptsData.count}
                  title="Total Appts"
                  iconComponent={
                    <div className="d-flex flex-column align-items-center gap-1">
                      <FontAwesomeIcon
                        fontSize={"50px"}
                        color="#D9D9D9"
                        icon={faCalendarCheck}
                      />
                      <span>AVG {totalApptsData.avg}</span>
                      <span style={{ textAlign: "center", fontSize: "11px" }}>
                        Per Agent
                      </span>
                    </div>
                  }
                  multiProgress={totalApptsData.multiProgress}
                  target={totalApptsData.target}
                  isLoading={loading}
                  minStandard={totalApptsData.minStandard}
                  hideAlert
                />
                <DashboardPercentCard
                  percent={ibOtdbCallsData.percent}
                  title="IB OTDB Calls"
                  icon={
                    <FontAwesomeIcon
                      fontSize={"50px"}
                      color="#D9D9D9"
                      icon={faWandMagicSparkles}
                    />
                  }
                  progress={"100%"}
                  percentColor={"#000"}
                  isLoading={loading}
                  hideMinStandard
                  hideAlert
                  hideProgress
                />
                <DashboardPercentCard
                  percent={ibClosingRatioData.percent}
                  title="IB Closing Ratio"
                  icon={
                    <div className="d-flex align-items-start">
                      <FontAwesomeIcon
                        fontSize={"50px"}
                        color="#D9D9D9"
                        icon={faPhone}
                      />
                      <FontAwesomeIcon
                        icon={faCaretLeft}
                        color="#D9D9D9"
                        fontSize={"30px"}
                      />
                    </div>
                  }
                  percentColor={"#F1426D"}
                  isLoading={loading}
                  target={ibClosingRatioData.target}
                  minStandard={ibClosingRatioData.minStandard}
                  hideMinStandard
                  hideAlert
                />
              </div>
              <div className="agent-dashboard-row mt-3">
                <DashboardPercentCard
                  percent={obConnectedCallsData.percent}
                  title="OB Connected Calls"
                  icon={
                    <FontAwesomeIcon
                      fontSize={"50px"}
                      color="#D9D9D9"
                      icon={faLink}
                    />
                  }
                  progress={"100%"}
                  percentColor={"#000"}
                  isLoading={loading}
                  target={obConnectedCallsData.target}
                  hideMinStandard
                  hideAlert
                  hideProgress
                />
                <DashboardPercentCard
                  percent={obClosingRatioData.percent}
                  title="OB Closing Ratio"
                  icon={
                    <div className="d-flex align-items-start">
                      <FontAwesomeIcon
                        fontSize={"50px"}
                        color="#D9D9D9"
                        icon={faPhone}
                      />
                      <FontAwesomeIcon
                        icon={faCaretRight}
                        color="#D9D9D9"
                        fontSize={"30px"}
                      />
                    </div>
                  }
                  percentColor={"#7239EA"}
                  isLoading={loading}
                  target={obClosingRatioData.target}
                  minStandard={obClosingRatioData.minStandard}
                  hideMinStandard
                  hideAlert
                />
                <DashboardPercentCard
                  percent={transferredOutData.percent}
                  title="Transferred Out"
                  icon={
                    <FontAwesomeIcon
                      fontSize={"50px"}
                      color="#D9D9D9"
                      icon={faRightFromBracket}
                    />
                  }
                  percentColor={"#000"}
                  isLoading={loading}
                  target={100}
                  hideTarget
                  hideMinStandard
                  hideAlert
                  barColor={transferredOutData.barColor}
                />
                <DashboardCountCard
                  count={activeAgentsData.count}
                  title="Active Agents"
                  icon={faUsers}
                  target={activeAgentsData.target}
                  isLoading={loading}
                  hideAlert
                />
              </div>
              {(!!totalShowsData.count || !!totalShowsPercentData.percent) && (
                <div className="agent-dashboard-row mt-3">
                  <DashboardCountCard
                    count={totalShowsData.count}
                    title="Shows"
                    // icon={faUsers}
                    isLoading={loading}
                    hideAlert
                  />

                  <DashboardPercentCard
                    percent={totalShowsPercentData.percent}
                    title="Shows %"
                    // icon={
                    //   <FontAwesomeIcon
                    //     fontSize={"50px"}
                    //     color="#D9D9D9"
                    //     icon={faRightFromBracket}
                    //   />
                    // }
                    percentColor={"#000"}
                    isLoading={loading}
                    target={100}
                    hideTarget
                    hideMinStandard
                    hideAlert
                    progress={"100%"}
                    hideProgress
                  />
                </div>
              )}
            </>
          )}

          {!isOneDayMode && (
            <>
              <div className="agent-dashboard-row">
                <DashboardCountCard
                  count={totalCallsData.count}
                  title="Total Calls"
                  icon={faPhone}
                  multiProgress={totalCallsData.multiProgress}
                  target={totalCallsData.target}
                  isLoading={loading}
                  minStandard={totalCallsData.minStandard}
                  hideAlert
                />

                <DashboardCountCard
                  count={avgDailyCallsPerAgentData.count}
                  title="AVG Daily Calls per Agent"
                  icon={faChalkboardUser}
                  multiProgress={avgDailyCallsPerAgentData.multiProgress}
                  target={avgDailyCallsPerAgentData.target}
                  isLoading={loading}
                  minStandard={avgDailyCallsPerAgentData.minStandard}
                  hideAlert
                />

                <DashboardCountCard
                  count={totalApptsData.count}
                  title="Total Appts"
                  icon={faCalendarCheck}
                  multiProgress={totalApptsData.multiProgress}
                  target={totalApptsData.target}
                  isLoading={loading}
                  minStandard={totalApptsData.minStandard}
                  hideAlert
                />

                <DashboardPercentCard
                  percent={transferredOutData.percent}
                  title="Transferred Out"
                  icon={
                    <FontAwesomeIcon
                      fontSize={"50px"}
                      color="#D9D9D9"
                      icon={faRightFromBracket}
                    />
                  }
                  percentColor={"#000"}
                  isLoading={loading}
                  target={100}
                  hideTarget
                  hideMinStandard
                  hideAlert
                  barColor={transferredOutData.barColor}
                />
              </div>
              <div className="agent-dashboard-row mt-3">
                <DashboardPercentCard
                  percent={ibClosingRatioData.percent}
                  title="IB Closing Ratio"
                  icon={
                    <div
                      className="d-flex flex-column gap-2"
                      style={{ position: "absolute", right: 0 }}
                    >
                      <div className="d-flex align-items-start">
                        <FontAwesomeIcon
                          fontSize={"50px"}
                          color="#D9D9D9"
                          icon={faPhone}
                        />
                        <FontAwesomeIcon
                          icon={faCaretLeft}
                          color="#D9D9D9"
                          fontSize={"50px"}
                        />
                      </div>
                      <span className="mt-1">
                        {roundAndAddSignToPercentage(
                          ibClosingRatioData.dailyAvg
                        )}
                      </span>
                      <span>Daily AVG</span>
                    </div>
                  }
                  percentColor={"#F1426D"}
                  isLoading={loading}
                  target={ibClosingRatioData.target}
                  minStandard={ibClosingRatioData.minStandard}
                  hideMinStandard
                  hideAlert
                />
                <DashboardPercentCard
                  percent={obClosingRatioData.percent}
                  title="OB Closing Ratio"
                  icon={
                    <div
                      className="d-flex flex-column gap-2"
                      style={{ position: "absolute", right: 0 }}
                    >
                      <div className="d-flex align-items-start">
                        <FontAwesomeIcon
                          fontSize={"50px"}
                          color="#D9D9D9"
                          icon={faPhone}
                        />
                        <FontAwesomeIcon
                          icon={faCaretRight}
                          color="#D9D9D9"
                          fontSize={"30px"}
                        />
                      </div>
                      <span className="mt-1">
                        {roundAndAddSignToPercentage(
                          obClosingRatioData.dailyAvg
                        )}
                      </span>
                      <span>Daily AVG</span>
                    </div>
                  }
                  percentColor={"#7239EA"}
                  isLoading={loading}
                  target={obClosingRatioData.target}
                  minStandard={obClosingRatioData.minStandard}
                  hideMinStandard
                  hideAlert
                />
                <DashboardPercentCard
                  percent={ibOtdbCallsData.percent}
                  title="IB OTDB Calls"
                  icon={
                    <FontAwesomeIcon
                      fontSize={"50px"}
                      color="#D9D9D9"
                      icon={faWandMagicSparkles}
                    />
                  }
                  progress={"100%"}
                  percentColor={"#000"}
                  isLoading={loading}
                  hideMinStandard
                  hideAlert
                  hideProgress
                />
                <DashboardPercentCard
                  percent={obConnectedCallsData.percent}
                  title="OB Connected Calls"
                  icon={
                    <FontAwesomeIcon
                      fontSize={"50px"}
                      color="#D9D9D9"
                      icon={faLink}
                    />
                  }
                  progress={"100%"}
                  percentColor={"#000"}
                  isLoading={loading}
                  target={obConnectedCallsData.target}
                  hideMinStandard
                  hideAlert
                  hideProgress
                />
              </div>
              {/* <div className="agent-dashboard-row mt-3">
            <DashboardCountCard
              count={totalShowsData.count}
              title="Shows"
              icon={faUsers}
              isLoading={loading}
              hideAlert
            />

            <DashboardPercentCard
              percent={totalShowsPercentData.percent}
              title="Shows %"
              icon={
                <FontAwesomeIcon
                  fontSize={"50px"}
                  color="#D9D9D9"
                  icon={faRightFromBracket}
                />
              }
              percentColor={"#000"}
              isLoading={loading}
              target={100}
              hideTarget
              hideMinStandard
              hideAlert
              progress={"100%"}
              hideProgress
            />
          </div> */}
            </>
          )}

          <div className="mt-4 bg-white p-3 rounded-2">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h2
                style={{
                  color: "black",
                  fontSize: "16px",
                  fontWeight: 500,
                  margin: 0,
                }}
              >
                Agent {!isOneDayMode ? "Performance" : "Today"}
              </h2>
              <div className="d-flex align-items-center gap-2">
                <Button
                  color={agentTableMode === "top" ? "primary" : "transparent"}
                  onClick={() => {
                    handleAgentTableMode("top")
                  }}
                >
                  Top
                </Button>
                <Button
                  color={
                    agentTableMode === "bottom" ? "primary" : "transparent"
                  }
                  onClick={() => {
                    handleAgentTableMode("bottom")
                  }}
                >
                  Bottom
                </Button>
                {isOneDayMode && (
                  <Button
                    color={
                      agentTableMode === "absent" ? "primary" : "transparent"
                    }
                    onClick={() => handleAgentTableMode("absent")}
                  >
                    Absent
                  </Button>
                )}
              </div>
            </div>

            <AgGridTable
              rowState={[rows, setRows]}
              columns={columns}
              // refresh={refreshTable}
              field
            />
          </div>
        </>
      )}
    </PageContainer>
  )
}

export default ManagerDashboard

const CustomDateOption = props => {
  const { data } = props
  const { value, label, customAbbreviation } = data || {}
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: "15px",
      }}
    >
      <div>{label}</div>
      <div
        style={{
          color: isSelectedInValue
            ? "#ccc"
            : isSelectedInMenu
            ? "#000"
            : "#ccc",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {customAbbreviation}
      </div>
    </div>
  )
}
