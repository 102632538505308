import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { ADD_KPI, FETCH_KPIS, UPDATE_KPI } from "./actionTypes"
import { kpiSuccess, kpiError, setKpis } from "./actions"

//Include Both Helper File with needed methods
import { getKpis, postKpi, patchKpi } from "../../helpers/fakebackend_helper"

import { isArray } from "lodash"
function* addKpi({ payload: { kpi, history } }) {
  try {
    yield call(postKpi, kpi)
    yield put(kpiSuccess("Created Successfully"))
    setTimeout(() => {
      history("/manual-kpis")
    }, 1500)
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(kpiError(errMsg))
  }
}

function* updateKpi({ payload: { id, kpi, history } }) {
  try {
    yield call(patchKpi, id, kpi)
    yield put(kpiSuccess("Updated Successfully"))
    history("/manual-kpis")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(kpiError(errMsg))
  }
}

function* fetchKpis() {
  try {
    console.log("saga get kpis")
    const response = yield call(getKpis)
    yield put(setKpis(response))
  } catch (error) {
    console.log("error", error)
  }
}

function* kpiSaga() {
  yield takeEvery(ADD_KPI, addKpi)
  yield takeEvery(FETCH_KPIS, fetchKpis)
  yield takeEvery(UPDATE_KPI, updateKpi)
}

export default kpiSaga
