import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { Button, Col, Container, Row, Spinner } from "reactstrap"

//i18n
import PageContainer from "components/Common/PageContainer"
import TVDashboardCard from "components/TVDashboardCard"
import {
  faCalendar,
  faCalendarCheck,
  faCaretLeft,
  faCaretRight,
  faChalkboardUser,
  faHandshake,
  faLink,
  faPhone,
  faRightFromBracket,
  faUsers,
  faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons"
import DashboardPercentCard from "components/DashboardPercentCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AgGridTable from "components/Common/AgGridTable"
import SelectField from "components/Form/SelectField"
import { useFormik } from "formik"
import ScopeSelector from "components/Common/ScopeSelector"
import {
  customDateSelectFieldOptions,
  datesOptions,
  datesSelectFieldOptions,
  isCustomDateOption,
  mtdSubText,
  tzMoment,
} from "constants/datesOptions"
import useTVDashboard from "./hooks/useTVDashboard"
import {
  getScopeCompanies,
  roundAndAddSignToPercentage,
} from "helpers/helperFns"
import LiveIcon from "./components/LiveIcon"
import useAuth from "common/hooks/useAuth"
import { fetchPermissions } from "store/actions"
import { useDispatch } from "react-redux"
import SideSection from "./components/SideSection"
import classes from "./styles.module.css"
import CompaniesScopeSelector from "./components/CompaniesScopeSelector"
import { axiosApi } from "helpers/api_helper"
import CustomRangePicker from "components/Form/CustomRangePicker"
import * as Yup from "yup"

const TVDashboard = props => {
  const dispatch = useDispatch()
  const { user } = useAuth()

  const [rangePickerKey, setRangePickerKey] = useState(0)

  //meta title
  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      scope: user?.scopes?.map(({ id }) => `${id}`),
      selectedDateOption: {
        value: datesOptions.today.value,
        label: datesOptions.today.title,
        customAbbreviation: datesOptions.today.secondaryTitle,
        afterLabelMarkup: <LiveIcon />,
      },
      startDate: "",
      endDate: "",
    },
    validationSchema: Yup.object({
      startDate: Yup.string().when(
        "selectedDateOption",
        ([selectedDateOption], schema) =>
          selectedDateOption.value === datesOptions.custom.value
            ? schema.required("Please add start date")
            : schema.optional()
      ),
      endDate: Yup.string().when(
        "selectedDateOption",
        ([selectedDateOption], schema) =>
          selectedDateOption.value === datesOptions.custom.value
            ? schema.required("Please add end date")
            : schema.optional()
      ),
      scope: Yup.array().min(1, "Scope Is Required"),

      selectedDateOption: Yup.object().required("Please select a date option"),
    }),
    onSubmit: values => {
      console.log("scope, date", values)
    },
  })

  const {
    loading,
    totalCallsData,
    totalApptsData,
    ibClosingRatioData,
    obClosingRatioData,
    ibOtdbCallsData,
    transferredOutData,
    activeAgentsData,

    totalShowsPercentData,
    totalShowsData,
    ibCallsData,
    obOtdbCallsData,
    obCallsData,
    ibApptsData,
    obApptsData,
    totalAvgHandlingTimeData,
    callPerformanceScoreData,
  } = useTVDashboard({
    validation,
  })

  const isOneDayMode = [
    datesOptions.today.value,
    datesOptions.yesterday.value,
  ].includes(validation.values.selectedDateOption?.value)

  function refreshDatePicker() {
    setRangePickerKey(oldVal => oldVal + 1)
  }
  const isCustomDate = isCustomDateOption(
    validation.values.selectedDateOption?.value
  )
  function handleCustomRangeChange(dateStrings) {
    if (dateStrings.some(el => !el)) {
      return
    }

    const [startStr, endStr] = dateStrings || []
    validation.setFieldValue("startDate", startStr)
    validation.setFieldValue("endDate", endStr)

    const startStrTitle = startStr
      ? tzMoment(startStr).format("MM/DD/YYYY")
      : ""
    const endStrTitle = endStr ? tzMoment(endStr).format("MM/DD/YYYY") : ""
    const customAbbr =
      startStrTitle && endStrTitle ? `${startStrTitle} - ${endStrTitle}` : ""
    validation.setFieldValue("selectedDateOption", {
      ...validation.values.selectedDateOption,
      customAbbreviation: customAbbr,
    })
  }
  return (
    <PageContainer bgColor="#f0f0f0">
      <div className={`${classes.mainPageContainer}`}>
        <div className="d-flex align-items-start justify-content-between mb-4 flex-wrap">
          <h1 className="page-title">Dashboard</h1>
          <div className="flex-grow-1 row align-items-center gap-2 flex-nowrap justify-content-end">
            <div className="col-5">
              {/* <CompaniesScopeSelector
                validation={validation}
                scopesOptions={scopesOptions}
              /> */}
              <ScopeSelector
                minWidth={"100%"}
                validation={validation}
                onChange={ids => {
                  validation.setFieldValue("scope", ids)
                }}
                selectedScopes={validation.values.scope}
                hideLabel
                hasInitialValue={true}
              />
            </div>
            <div className="col-6">
              <SelectField
                withLabel={false}
                name="selectedDateOption"
                validation={validation}
                required
                options={customDateSelectFieldOptions
                  .filter(
                    ({ value }) => value !== datesOptions.yearToDate.value
                  )
                  .map(option => {
                    if (option.value === datesOptions.today.value) {
                      return {
                        ...option,
                        afterLabelMarkup: <LiveIcon />,
                      }
                    } else {
                      return option
                    }
                  })}
                onChange={option => {
                  validation.setFieldValue("selectedDateOption", option)
                  if (isCustomDateOption(option.value)) {
                    validation.setFieldValue("startDate", "")
                    validation.setFieldValue("endDate", "")
                    refreshDatePicker()
                  }
                }}
              />
              {isCustomDate && !validation.values.startDate && (
                <div key={rangePickerKey} className="">
                  <CustomRangePicker
                    onChangeHandler={handleCustomRangeChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "50vh" }}
          >
            <Spinner />
          </div>
        ) : (
          <div className="tv-dashboard-main-grid h-100">
            <div className="h-full">
              <div className="tv-dashboard-cards-grid h-full">
                <TVDashboardCard
                  type={ibCallsData.type}
                  count={ibCallsData.count}
                  title="IB Calls"
                  isLoading={loading}
                  additionalValueData={{
                    title: "IB OTDB Calls",
                    count: ibOtdbCallsData.count,
                  }}
                  target={ibCallsData.target}
                  minStandard={ibCallsData.minStandard}
                />

                <TVDashboardCard
                  type={ibApptsData.type}
                  count={ibApptsData.count}
                  title="IB Appts Created"
                  isLoading={loading}
                  target={ibApptsData.target}
                  minStandard={ibApptsData.minStandard}
                />
                <TVDashboardCard
                  type={ibClosingRatioData.type}
                  count={ibClosingRatioData.percent}
                  title="IB Closing Ratio"
                  isLoading={loading}
                  target={ibClosingRatioData.target}
                  minStandard={ibClosingRatioData.minStandard}
                />
                <TVDashboardCard
                  type={obCallsData.type}
                  count={obCallsData.count}
                  title="OB Calls"
                  isLoading={loading}
                  additionalValueData={{
                    title: "OB OTDB Calls",
                    count: obOtdbCallsData.count,
                    target: obOtdbCallsData.target,
                    minStandard: obOtdbCallsData.minStandard,
                  }}
                  target={obCallsData.target}
                  minStandard={obCallsData.minStandard}
                />
                <TVDashboardCard
                  type={obApptsData.type}
                  count={obApptsData.count}
                  title="OB Appts Created"
                  isLoading={loading}
                  target={obApptsData.target}
                  minStandard={obApptsData.minStandard}
                />
                <TVDashboardCard
                  type={obClosingRatioData.type}
                  count={obClosingRatioData.percent}
                  title="OB Closing Ratio"
                  isLoading={loading}
                  target={obClosingRatioData.target}
                  minStandard={obClosingRatioData.minStandard}
                />
                <TVDashboardCard
                  type={totalCallsData.type}
                  count={totalCallsData.count}
                  title="Total Calls"
                  isLoading={loading}
                  target={totalCallsData.target}
                  minStandard={totalCallsData.minStandard}
                />
                <TVDashboardCard
                  type={totalApptsData.type}
                  count={totalApptsData.count}
                  title="Total Appts"
                  isLoading={loading}
                  target={totalApptsData.target}
                  minStandard={totalApptsData.minStandard}
                />
                <TVDashboardCard
                  type={transferredOutData.type}
                  count={transferredOutData.percent}
                  title="Transfer Ratio"
                  isLoading={loading}
                  target={transferredOutData.target}
                  minStandard={transferredOutData.minStandard}
                />
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <SideSection
                activeAgentsData={activeAgentsData}
                totalShowsData={totalShowsData}
                totalShowsPercentData={totalShowsPercentData}
                totalAvgHandlingTimeData={totalAvgHandlingTimeData}
                callPerformanceScoreData={callPerformanceScoreData}
              />
            </div>
          </div>
        )}
      </div>
    </PageContainer>
  )
}

export default TVDashboard
