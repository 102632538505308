import {
  ADD_TARGET,
  FETCH_TARGETS,
  TARGET_ERROR,
  TARGET_SUCCESS,
  SET_TARGETS,
} from "./actionTypes"

const initialState = {
  errorMsg: "",
  successMsg: "",
  loading: false,
  targets: [],
}

const target = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TARGET:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case FETCH_TARGETS:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        targets: [],
        loading: true,
      }
      break

    case SET_TARGETS:
      state = {
        ...state,
        loading: false,
        targets: action.payload.targets,
      }
      break
    case TARGET_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload.msg,
        loading: false,
        targets: [...state.targets, action.payload.target],
      }
      break
    case TARGET_ERROR:
      state = { ...state, errorMsg: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default target
