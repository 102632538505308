import {
  ADD_GROUP,
  FETCH_GROUPS,
  GROUP_ERROR,
  GROUP_SUCCESS,
  SET_GROUPS,
  UPDATE_GROUP,
} from "./actionTypes"

export const addGroup = (group, history) => {
  return {
    type: ADD_GROUP,
    payload: { group, history },
  }
}

export const updateGroup = (id, group, history) => {
  return {
    type: UPDATE_GROUP,
    payload: { id, group, history },
  }
}

export const fetchGroups = () => {
  return {
    type: FETCH_GROUPS,
  }
}

export const groupSuccess = msg => {
  return {
    type: GROUP_SUCCESS,
    payload: { msg },
  }
}

export const groupError = error => {
  return {
    type: GROUP_ERROR,
    payload: error,
  }
}

export const setGroups = groups => {
  return {
    type: SET_GROUPS,
    payload: { groups },
  }
}
