import PageContainer from "components/Common/PageContainer"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { Alert, Button, Form, Spinner } from "reactstrap"
import * as Yup from "yup"
import InputField from "components/Form/InputField"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import SelectField from "components/Form/SelectField"
import { addKpiEntry, fetchKpis, updateKpiEntry } from "store/actions"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import DateTimeField from "components/Form/DateTimeField"
import { format } from "date-fns"
import { axiosApi } from "helpers/api_helper"
import { isDate } from "lodash"
import EntityFormTable from "./components/EntityFormTable"
import moment from "moment"
import { generateID } from "helpers/helperFns"

const AddEditKpiEntry = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { state: kpiEntryData } = useLocation()
  const [showConfirm, setShowConfirm] = useState(false)
  const [entities, setEntities] = useState([])
  const [lastEntityType, setLastEntityType] = useState(
    kpiEntryData?.entity_type || null
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchKpis())
  }, [])

  const KpiProperties = createSelector(
    state => state.Kpi,
    kpi => ({
      kpis: kpi.kpis,
    })
  )

  const { kpis } = useSelector(KpiProperties)

  const KpiEntryProperties = createSelector(
    state => state.KpiEntry,
    kpiEntry => ({
      loading: kpiEntry.loading,
      errorMsg: kpiEntry.errorMsg,
      successMsg: kpiEntry.successMsg,
    })
  )

  const { loading, errorMsg, successMsg } = useSelector(KpiEntryProperties)

  const [unit, setUnit] = useState("")
  const [entityTypesOptions, setEntityTypesOptions] = useState([])

  const kpiValueValidationSchema =
    unit === "Number"
      ? Yup.string()
          .nullable()
          .matches(/^[0-9]*$/, "Must be number")
      : unit === "Percentage"
      ? Yup.string()
          .nullable()
          .matches(/^100%$|^[1-9]?[0-9]%$/, "Must be percentage")
      : unit === "Minutes"
      ? Yup.string()
          .nullable()
          .matches(/^\d+:\d{2}$/, "Must be time with format MM:SS")
      : Yup.string().nullable()

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      date: moment().subtract(1, "day").format("YYYY-MM-DD"),
      kpi: null,
      entityType: null,
      entitiesValues: [],
    },
    validationSchema: Yup.object({
      date: Yup.string().required("Required"),
      kpi: Yup.object().required("Please choose manual KPI"),
      entityType: Yup.object().required("Please choose entity type"),

      entitiesValues: Yup.array().of(
        Yup.object().shape({
          specificId: Yup.string().required(),
          // specificValue:Yup.string().required()

          entityId: Yup.string().required(),
          storeId: Yup.number().nullable(), // optional means might provide a number of the id or null
          entityValue: kpiValueValidationSchema,
        })
      ),
    }),
    onSubmit: values => {
      let isValid = true
      // let temp = {}
      // for (const valuesObj of values.entitiesValues) {
      //   const currEntityId = valuesObj.entityId
      //   const currStoreId = valuesObj.storeId
      //   if (
      //     (Object.hasOwn(temp, currEntityId) && temp[currEntityId] === null) ||
      //     (Object.hasOwn(temp, currEntityId) && currStoreId === null)
      //   ) {
      //     isValid = false
      //     break
      //   }
      //   temp[currEntityId] = currStoreId
      // }

      for (let i = 0; i < values.entitiesValues.length; i++) {
        const firstValObj = values.entitiesValues[i]
        const firstEntityId = firstValObj.entityId
        const firstStoreId = firstValObj.storeId
        for (let j = i + 1; j < values.entitiesValues.length; j++) {
          const secValObj = values.entitiesValues[j]
          const secEntityId = secValObj.entityId
          const secStoreId = secValObj.storeId
          if (
            (firstEntityId === secEntityId && firstStoreId === secStoreId) ||
            (firstEntityId === secEntityId &&
              (firstStoreId === null || secStoreId === null))
          ) {
            isValid = false
          }
        }
      }
      if (!isValid) {
        toast.error("Make sure entries are valid")
        return
      }
      window.scrollTo({ top: 0, behavior: "smooth" })

      let body

      const valuesArr = []
      const entitiesIdsArr = []
      const storesIdsArr = []
      values.entitiesValues
        // .filter(
        //   ({ entityValue }) => entityValue !== undefined && entityValue !== null
        // )
        .forEach(({ entityId, entityValue, storeId }) => {
          valuesArr.push(entityValue || 0)
          entitiesIdsArr.push(entityId)
          storesIdsArr.push(storeId)
        })
      // if (entities.length !== valuesArr.length) {
      //   toast.error("You must fill all entities values")
      //   return
      // }

      body = {
        date: values.date,
        entity_type: values.entityType?.value,
        manualkpi_id: values.kpi?.value,
        values: valuesArr,
        entity_ids: entitiesIdsArr,
        store_ids: storesIdsArr,
      }
      console.log({ body })
      dispatch(addKpiEntry(body, navigate))
    },
  })
  const handleCancel = () => {
    if (validation.dirty) {
      window.scrollTo({ top: 0, behavior: "smooth" })
      setShowConfirm(true)
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    if (successMsg) {
      toast.success(successMsg)
    }
  }, [successMsg])

  useEffect(() => {
    if (errorMsg) {
      toast.error(errorMsg)
    }
  }, [errorMsg])

  useEffect(() => {
    const currentKpiId = validation.values.kpi?.value
    if (currentKpiId) {
      const currentKpi = kpis.find(item => item.manualkpi_id == currentKpiId)
      setUnit(currentKpi?.unit)
      const newOptions = [
        currentKpi?.is_perstore && { value: "store", label: "Store" },
        currentKpi?.is_peragent && { value: "agent", label: "Agent" },
      ].filter(item => item)
      setEntityTypesOptions(newOptions)
      if (!newOptions?.find(item => item.value === lastEntityType))
        validation.setFieldValue("entityType", null)
    }
  }, [validation.values.kpi])

  useEffect(() => {
    validation.validateForm()
  }, [unit])

  useEffect(() => {
    const type = validation.values.entityType?.value
    const kpiId = validation.values.kpi?.value
    const formattedDate = validation.values.date

    if (type && kpiId && formattedDate) {
      const endpoint = `/api/v1/manual-kpi-entry/allentities?type=${type}&date=${formattedDate}&manualkpi_id=${kpiId}`
      axiosApi.get(endpoint).then(res => {
        // if (type === "store") {
        //   setEntities(
        //     res.data?.map(item => ({
        //       value: item.id,
        //       type: item.type,
        //       label: item.store?.name,
        //     }))
        //   )
        // } else {
        //   setEntities(
        //     res.data?.map(item => ({
        //       value: item.id,
        //       type: item.type,
        //       label: item.user?.name,
        //     }))
        //   )
        // }

        let parsedEntitiesValues = []
        let parsedEntities = []

        const entities =
          res.data?.filter(
            entity =>
              entity.type !== "agent" ||
              (entity.type === "agent" &&
                !!entity.user?.roles?.find(({ name }) =>
                  ["Agent", "Call Center Manager"].includes(name)
                ))
          ) || []
        for (let i = 0; i < entities.length; i++) {
          const entity = entities[i]
          const hasStoreValue = entity.stores?.some(store => !!store.value)
          // general value (not store specific)
          const specificId = generateID()
          entity.value &&
            !hasStoreValue &&
            parsedEntitiesValues.push({
              specificId: specificId,
              entityId: entity.id,
              storeId: null,
              entityValue: entity.value,
            })
          !hasStoreValue &&
            parsedEntities.push({
              ...entity,
              specificData: {
                specificId: specificId,
                specificStoreId: null,
                specificValue: entity.value,
              },
            })

          // then we add each value and store combination to our final arr only if there's a value to that store
          for (const store of entity.stores || []) {
            if (store.value) {
              const specificId = generateID()
              parsedEntitiesValues.push({
                specificId: specificId,
                entityId: entity.id,
                storeId: store.store_id,
                entityValue: store.value,
              })
              parsedEntities.push({
                ...entity,
                specificData: {
                  specificId: specificId,
                  specificStoreId: store.store_id,
                  specificValue: store.value,
                },
              })
            }
          }
        }
        setEntities(parsedEntities)
        validation.setFieldValue("entitiesValues", parsedEntitiesValues)
      })
    }
    if (type !== lastEntityType) {
      validation.setFieldValue("entity", kpiEntryData ? null : [])
      setLastEntityType(type)
    }
  }, [
    validation.values.entityType,
    kpiEntryData,
    validation.values.kpi,
    validation.values.date,
  ])

  return (
    <PageContainer>
      <h1 className="page-title mb-4">Add/Edit Manual KPI Entry</h1>
      <div className="content-container">
        {showConfirm && (
          <Alert color="danger" style={{ marginTop: "13px" }}>
            <div>
              <p>Are you sure you want to cancel? Your inputs will be lost.</p>
              <div className="d-flex">
                <Button
                  type="button"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() => navigate(-1)}
                >
                  Yes
                </Button>
                <Button type="button" onClick={() => setShowConfirm(false)}>
                  No
                </Button>
              </div>
            </div>
          </Alert>
        )}

        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12 mb-4">
                {/* <DateTimeField
                  label="Date *"
                  name="date"
                  validation={validation}
                /> */}
                <InputField
                  type="date"
                  name="date"
                  validation={validation}
                  placeholder=""
                  label={"Date"}
                  required
                />
              </div>

              <div className="col-lg-6 col-12 mb-4">
                <SelectField
                  label={"Manual KPI"}
                  required
                  name="kpi"
                  validation={validation}
                  options={kpis?.map(item => ({
                    value: item.manualkpi_id,
                    label: item.name,
                  }))}
                />
              </div>
              <div className="col-lg-6 col-12 mb-4">
                <SelectField
                  label={"Entity Type"}
                  required
                  name="entityType"
                  validation={validation}
                  options={entityTypesOptions}
                />
              </div>

              <div>
                <EntityFormTable
                  validation={validation}
                  entities={entities}
                  setEntities={setEntities}
                />
              </div>
            </div>
          </div>

          <div className="mt-5 d-flex justify-content-center justify-content-lg-start">
            <Button
              color="primary"
              style={{
                marginRight: "20px",
                width: "110px",
              }}
              type="submit"
            >
              {loading ? <Spinner size="sm" /> : "Save"}
            </Button>
            <Button type="button" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </PageContainer>
  )
}

export default AddEditKpiEntry
