import PageContainer from "components/Common/PageContainer"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { Alert, Button, Form, Spinner } from "reactstrap"
import * as Yup from "yup"
import InputField from "components/Form/InputField"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import SelectField from "components/Form/SelectField"
import { axiosApi } from "helpers/api_helper"
import {
  addUser,
  fetchDepartments,
  fetchRoles,
  updateUser,
} from "store/actions"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import ScopeSelector from "components/Common/ScopeSelector"
import { isServiceAdvisorRole } from "helpers/helperFns"

const AddEditUser = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { id } = useParams()
  const { state: userData } = useLocation()

  const [showConfirm, setShowConfirm] = useState(false)

  useEffect(() => {
    dispatch(fetchDepartments())
    dispatch(fetchRoles())
  }, [])

  const DepartmentProperties = createSelector(
    state => state.Department,
    dep => ({
      departments: dep.departments,
    })
  )

  const { departments } = useSelector(DepartmentProperties)

  const RolesProperties = createSelector(
    state => state.Role,
    role => ({
      roles: role.roles,
    })
  )

  const { roles } = useSelector(RolesProperties)
  const updatedRoles = roles.filter(role => !isServiceAdvisorRole(role.name))

  const UserProperties = createSelector(
    state => state.User,
    user => ({
      loading: user.loading,
      errorMsg: user.errorMsg,
      successMsg: user.successMsg,
    })
  )

  const { loading, errorMsg, successMsg } = useSelector(UserProperties)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: userData
      ? {
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          password: userData.password,
          mobileNumber: userData.phone || "",
          department:
            userData.departments?.length > 0
              ? {
                  value: userData.departments?.[0]?.department_id,
                  label: userData.departments?.[0]?.type_name,
                }
              : null,
          scope: userData.scopes?.map(item => item.id),
          role:
            userData.roles?.length > 0
              ? {
                  value: userData.roles?.[0]?.role_id,
                  label: userData.roles?.[0]?.name,
                }
              : null,
          status: {
            value: userData.status?.toLowerCase(),
            label:
              userData.status?.[0] + userData.status?.substr(1)?.toLowerCase(),
          },
        }
      : {
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          mobileNumber: "",
          department: null,
          scope: [],
          role: null,
          // status: "",
        },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      email: Yup.string().email().required("Required"),
      password: id
        ? Yup.string().optional()
        : Yup.string().required("Required"),
      mobileNumber: Yup.string().test("Invalid mobile number", v => {
        // makes it optional
        if (!v || v === "(___) ___-____") return true

        console.log("test", v?.match(/\d/g)?.length === 10)
        return v?.match(/\d/g)?.length === 10
      }),
      department: Yup.object().required("Required"),
      scope: Yup.array().min(1, "Scope Is Required"),
      role: Yup.object().required("Required"),
      // status: Yup.string().required("Required"),
    }),
    onSubmit: values => {
      window.scrollTo({ top: 0, behavior: "smooth" })
      const body = {
        email: values.email,
        password: values.password,
        phone: values.mobileNumber || null,
        firstName: values.firstName,
        lastName: values.lastName,
        departmentId: values.department.value,
        scopes_ids: values.scope,
        role_id: values.role.value,
      }
      if (id)
        dispatch(
          updateUser(
            id,
            { ...body, status: values.status?.value?.toUpperCase() },
            navigate
          )
        )
      else dispatch(addUser(body, navigate))
    },
  })

  const handleCancel = () => {
    if (validation.dirty) {
      window.scrollTo({ top: 0, behavior: "smooth" })
      setShowConfirm(true)
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    if (successMsg) {
      toast.success(successMsg)
    }
  }, [successMsg])

  useEffect(() => {
    if (errorMsg) {
      toast.error(errorMsg)
    }
  }, [errorMsg])

  return (
    <PageContainer>
      <h1 className="page-title mb-4">{id ? "Edit" : "Invite"} User </h1>
      <div className="content-container">
        {showConfirm && (
          <Alert color="danger" style={{ marginTop: "13px" }}>
            <div>
              <p>Are you sure you want to cancel? Your inputs will be lost.</p>
              <div className="d-flex">
                <Button
                  type="button"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() => navigate(-1)}
                >
                  Yes
                </Button>
                <Button type="button" onClick={() => setShowConfirm(false)}>
                  No
                </Button>
              </div>
            </div>
          </Alert>
        )}

        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="row">
                  <div className="col-md-6 col-12  mb-4">
                    <InputField
                      name="firstName"
                      validation={validation}
                      placeholder=""
                      label={"First Name"}
                      required
                    />
                  </div>
                  <div className="col-md-6 col-12  mb-4">
                    <InputField
                      name="lastName"
                      validation={validation}
                      placeholder=""
                      label={"Last Name"}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="row">
                  <div className="col-md-6 col-12  mb-4">
                    <InputField
                      name="email"
                      validation={validation}
                      placeholder=""
                      label={"Email"}
                      required
                    />
                  </div>
                  <div className="col-md-6 col-12  mb-4">
                    <InputField
                      name="mobileNumber"
                      validation={validation}
                      label={"Mobile Number"}
                      mask={"(999) 999-9999"}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12  mb-4">
                <SelectField
                  label={"Department"}
                  required
                  name="department"
                  validation={validation}
                  options={departments.map(item => ({
                    value: item.department_id,
                    label: item.type_name,
                  }))}
                />
              </div>
              <div className="col-md-6 col-12  mb-4">
                <ScopeSelector
                  validation={validation}
                  onChange={ids => {
                    validation.setFieldValue("scope", ids)
                  }}
                  selectedScopes={validation.values.scope}
                  hasInitialValue={true}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12  mb-4">
                <SelectField
                  label={"Role"}
                  required
                  name="role"
                  validation={validation}
                  options={updatedRoles.map(item => ({
                    value: item.role_id,
                    label: item.name,
                  }))}
                />
              </div>
              {id && (
                <div className="col-md-6 col-12  mb-4">
                  <SelectField
                    label={"Status"}
                    required
                    name="status"
                    validation={validation}
                    defaultValue="pending"
                    options={[
                      { value: "pending", label: "Pending" },
                      { value: "active", label: "Active" },
                      { value: "inactive", label: "Inactive" },
                    ]}
                  />
                </div>
              )}
              <div className="col-md-6 col-12  mb-4">
                <InputField
                  name="password"
                  validation={validation}
                  placeholder=""
                  label={"Password"}
                  required={id ? false : true}
                />
              </div>
            </div>
            <div className="row"></div>
          </div>

          <div className="mt-5 d-flex justify-content-center justify-content-lg-start">
            <Button
              color="primary"
              style={{
                marginRight: "20px",
                width: "110px",
              }}
              type="submit"
            >
              {loading ? <Spinner size="sm" /> : "Save"}
            </Button>
            <Button type="button" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </PageContainer>
  )
}

export default AddEditUser
