import React, { useEffect, useState } from "react"
import EditIcon from "assets/icons/EditIcon"
import DeleteIcon from "assets/icons/DeleteIcon"
import AgGridTable from "components/Common/AgGridTable"
import PageContainer from "components/Common/PageContainer"
import { Button, Spinner } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { fetchRoles } from "store/actions"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { editAgent, fetchAgents } from "store/agent/actions"
import { format } from "date-fns"

const Agents = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAgents())
  }, [])

  const AgentProperties = createSelector(
    state => state.Agent,
    agent => ({
      loading: agent.loading,
      agents: agent.agents,
    })
  )

  const { loading, agents } = useSelector(AgentProperties)

  console.log("agents", agents)

  const [rows, setRows] = useState([])

  const handleUpdate = data => {
    const newAgent = {
      callScore: data.callScore,
      avgCallTime: data.avgCallTime,
      scheduledAppts: data.scheduledAppts,
      shows: data.shows,
      aiAppts: data.aiAppts,
      AbandonCalls: data.abandonCalls,
    }

    dispatch(editAgent(data.id, newAgent, navigate))
  }

  useEffect(() => {
    setRows(
      agents.map(item => ({
        id: item.id,
        date: item.creationDay ? format(item.creationDay, "MM/dd/yyyy") : "",
        agent: item.agent?.name,
        store: item.store?.name,
        department: item.department?.type_name,
        callScore: item.callScore,
        avgCallTime: item.avgCallTime,
        scheduledAppts: item.scheduledAppts,
        shows: item.shows,
        aiAppts: item.aiAppts,
        abandonCalls: item.AbandonCalls,
      }))
    )
  }, [agents])

  const columns = [
    { field: "date", filter: true, headerName: "Date" },
    { field: "agent", filter: true, headerName: "Agent" },
    { field: "store", filter: true, headerName: "Store" },
    { field: "department", filter: true, headerName: "Department" },
    {
      field: "callScore",
      filter: true,
      headerName: "Call Score",
      editable: true,
      onCellValueChanged: function (params) {
        handleUpdate(params.data)
      },
    },
    {
      field: "avgCallTime",
      filter: true,
      headerName: "AVG Call Time",
      editable: true,
      onCellValueChanged: function (params) {
        handleUpdate(params.data)
      },
    },
    {
      field: "scheduledAppts",
      filter: true,
      headerName: "Scheduled Appts",
      editable: true,
      onCellValueChanged: function (params) {
        handleUpdate(params.data)
      },
    },
    {
      field: "shows",
      filter: true,
      width: "120px",
      headerName: "Shows",
      editable: true,
      onCellValueChanged: function (params) {
        handleUpdate(params.data)
      },
    },
    {
      field: "aiAppts",
      width: "130px",
      filter: true,
      headerName: "AI Appts",
      editable: true,
      onCellValueChanged: function (params) {
        handleUpdate(params.data)
      },
    },
    {
      field: "abandonCalls",
      filter: true,
      headerName: "Abandon Calls",
      editable: true,
      onCellValueChanged: function (params) {
        handleUpdate(params.data)
      },
    },
  ]

  return (
    <PageContainer>
      <div className="d-flex justify-content-between mb-4">
        <h1 className="page-title">Agent Matrics</h1>
      </div>
      {loading ? (
        <div className="w-full d-flex justify-content-center align-items-center pt-4">
          <Spinner />
        </div>
      ) : (
        <AgGridTable
          rowState={[rows, setRows]}
          columns={columns}
          qiuckFilter={true}
        />
      )}
    </PageContainer>
  )
}

export default Agents
