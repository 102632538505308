import {
  EDIT_AGENT,
  FETCH_AGENTS,
  AGENT_ERROR,
  EDIT_AGENT_SUCCESS,
  SET_AGENTS,
} from "./actionTypes"

export const editAgent = (id, agent, history) => {
  return {
    type: EDIT_AGENT,
    payload: { id, agent, history },
  }
}

export const fetchAgents = () => {
  return {
    type: FETCH_AGENTS,
  }
}

export const editAgentSuccess = (agent, msg) => {
  return {
    type: EDIT_AGENT_SUCCESS,
    payload: { agent, msg },
  }
}

export const agentError = error => {
  return {
    type: AGENT_ERROR,
    payload: error,
  }
}

export const setAgents = agents => {
  return {
    type: SET_AGENTS,
    payload: { agents },
  }
}
