import CustomTable from "components/Common/CustomTable"
import InputField from "components/Form/InputField"
import React, { useMemo } from "react"
import { Input } from "reactstrap"
import CustomSelect from "./CustomSelect"
import { generateID } from "helpers/helperFns"
import RemoveIcon from "assets/icons/RemoveIcon"
import AddIcon from "assets/icons/AddIcon"

const Table = ({
  entities,
  entityType,
  onEntityValueChange,
  entitiesValues,
  onAddNewRow,
  onDeleteRow,
}) => {
  const isAgentEntity = useMemo(() => {
    return entityType?.value === "agent"
  }, [entityType?.value || ""])

  function handleValueChange({ specificId, entityId, value }) {
    onEntityValueChange({ specificId, entityId, name: "entityValue", value })
  }
  function handleStoreChange({ specificId, entityId, value }) {
    onEntityValueChange({ specificId, entityId, name: "storeId", value })
  }

  return (
    <div style={{ overflow: "auto" }}>
      <CustomTable>
        <thead>
          <tr>
            <th>Entity</th>
            <th>Value</th>
            {isAgentEntity && <th>Store</th>}
            {isAgentEntity && <th></th>}
          </tr>
        </thead>
        <tbody>
          {entities.map((entity, i) => {
            const {
              id: entityId,
              store,
              user,
              value,
              stores,
              specificData,
            } = entity

            const entityValueObj = entitiesValues.find(
              ({ specificId }) => specificId === specificData?.specificId
            )

            const label = isAgentEntity ? user?.name : store?.name
            const storesOptions = stores?.map(store => {
              return {
                label: store.name,
                value: store.store_id,
              }
            })
            return (
              <tr key={specificData.specificId}>
                <td>{label}</td>
                <td>
                  <div style={{ width: "200px" }}>
                    <Input
                      name={"value"}
                      placeholder={""}
                      type={"text"}
                      value={entityValueObj?.entityValue}
                      onChange={e =>
                        handleValueChange({
                          specificId: specificData.specificId,
                          entityId,
                          value: e.target.value,
                        })
                      }
                      // onBlur={validation.handleBlur}
                      //   value={value || ""}
                      style={{ height: "40px" }}
                      // invalid={
                      //   validation.touched[name] && validation.errors[name]
                      //     ? true
                      //     : false
                      // }
                      width="200px"
                    />
                  </div>
                </td>
                {isAgentEntity && (
                  <td>
                    <CustomSelect
                      options={storesOptions}
                      value={mapStoreToOption(entitiesValues, entity)}
                      onChange={option => {
                        handleStoreChange({
                          entityId,
                          specificId: specificData.specificId,
                          value: option?.value || null,
                        })
                      }}
                      isClearable={true}
                      placeholder="All Stores"
                    />
                  </td>
                )}
                {isAgentEntity && (
                  <td>
                    <span style={{ cursor: "pointer" }}>
                      {specificData?.isNew ? (
                        <span onClick={() => onDeleteRow({ entity })}>
                          <RemoveIcon />
                        </span>
                      ) : (
                        <span
                          onClick={() => {
                            onAddNewRow({ entity })
                          }}
                        >
                          <AddIcon />
                        </span>
                      )}
                    </span>
                  </td>
                )}
              </tr>
            )
          })}
        </tbody>
      </CustomTable>
    </div>
  )
}

export default Table

function mapStoreToOption(entitiesValues, parsedEntity) {
  const entityValueObj = entitiesValues.find(
    entityValueObj =>
      parsedEntity.specificData.specificId === entityValueObj.specificId
  )
  const storeId = entityValueObj?.storeId
  if (!storeId) {
    return null
  }

  const storeObj = parsedEntity.stores?.find(
    ({ store_id }) => store_id === storeId
  )
  const option = storeObj
    ? {
        label: storeObj.name,
        value: storeObj.store_id,
      }
    : null

  return option
}
