import React, { useEffect, useRef, useState } from "react"
import ReactSelect from "react-select"
import { FormFeedback, Label } from "reactstrap"

const CustomSelect = ({
  withLabel = false,
  label,
  name,
  options,
  validation,
  required,
  placeholder = "Choose ...",
  isMulti = false,
  defaultValue,
  className = "",
  wrapperClassName = "",
  preSelected = false,
  disabled = false,
  setFocus,
  onChange,
  ...props
}) => {
  const [selectedOptions, setSelectedOptions] = useState(defaultValue || [])
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const selectRef = useRef()

  const handleOnChange = options => {
    // setSelectedOptions(options)
    onChange(options)
  }

  return (
    <div className={wrapperClassName}>
      {withLabel && (
        <Label className="form-label">{`${label} ${
          required ? "*" : ""
        }`}</Label>
      )}
      <ReactSelect
        ref={selectRef}
        options={options}
        isMulti={isMulti}
        className={`form-control  p-0 ${className} `}
        value={selectedOptions}
        onChange={handleOnChange}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        placeholder={placeholder}
        styles={{
          control: baseStyles => ({
            ...baseStyles,
            border: 1,
          }),
          option: (base, state, other) => {
            let backgroundColor, color

            if (state.isSelected) {
              backgroundColor = "#ced4da"
              color = "#000"
            }

            // state.data.customAbbreviation

            return {
              ...base,
              ...(backgroundColor && { backgroundColor }),
              ...(color && { color }),
            }
          },
        }}
        isDisabled={disabled}
        menuPortalTarget={document.body}
        formatOptionLabel={formatOptionLabel}
        {...props}
      />
    </div>
  )
}

const formatOptionLabel = (option, labelMeta) => {
  const { value, label, customAbbreviation, afterLabelMarkup } = option
  const { context, selectValue, inputValue } = labelMeta
  // console.log({ value, labelMeta, option })
  const isSelectedInValue =
    selectValue?.[0]?.value === value && context === "value"
  const isSelectedInMenu =
    selectValue?.[0]?.value === value && context === "menu"
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: "15px",
      }}
    >
      <div>
        {label}
        {afterLabelMarkup}
      </div>
      <div
        style={{
          color: isSelectedInValue
            ? "#ccc"
            : isSelectedInMenu
            ? "#000"
            : "#ccc",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {customAbbreviation}
      </div>
    </div>
  )
}

export default CustomSelect
