import {
  faCircleCheck,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useMemo, useState } from "react"
import { Alert, Progress } from "reactstrap"
import bullseye from "assets/images/bullseye.png"
import { Tooltip } from "react-tooltip"
import { approximate, roundAndAddSignToPercentage } from "helpers/helperFns"

const DashboardPercentCard = ({
  percent,
  type,
  percentColor,
  title,
  target,
  minStandard,
  isLoading,
  icon,
  hideMinStandard,
  hideAlert,
  hideProgress,
  hideTarget,
  barColor,
}) => {
  const status = useMemo(() => {
    if (barColor) {
      return barColor
    }
    let currentStatus = "danger"
    if (target > 0 && minStandard) {
      currentStatus =
        percent / target > 0.9
          ? "success"
          : percent > minStandard
          ? "warning"
          : "danger"
    } else if (target > 0 && !minStandard) {
      currentStatus =
        percent / target > 0.75
          ? "success"
          : percent / target >= 0.5
          ? "warning"
          : "danger"
    }
    return currentStatus
  }, [barColor, target, minStandard, percent])

  return (
    <div className="w-100" style={{ minWidth: "270px" }}>
      <div className="bg-white p-3 px-4 m-0">
        {/* <div className="col-8 p-0"> */}
        <div
          className="d-flex gap-3 justify-content-between w-100"
          style={{ position: "relative" }}
        >
          <div style={{ width: "80%" }}>
            <div className="d-flex justify-content-between align-items-start">
              <p
                style={{
                  fontWeight: 800,
                  fontSize: "40px",
                  color: "black",
                  marginBottom: 0,
                  color: percentColor,
                }}
              >
                {roundAndAddSignToPercentage(percent)}
              </p>
              {!isLoading && !hideMinStandard && icon}
            </div>
            <p className="mb-1">{title}</p>

            <div
              className="d-flex flex-column gap-1 align-items-end"
              style={{ width: "70%", minHeight: "30px" }}
            >
              {!hideProgress && (
                <>
                  <Progress
                    data-tooltip-id="progress"
                    data-tooltip-content={roundAndAddSignToPercentage(percent)}
                    className="w-100"
                    value={
                      isLoading ? 0 : approximate((percent * 100) / target)
                    }
                    style={{
                      backgroundColor: "#d9d9d9",
                    }}
                    //70
                    //60
                    // value={Math.ceil((60 * 100) / 70)}
                    // value={Math.ceil((60 / (70 + 60)) * 100)}
                    barClassName={`progress-bar-${status}`}
                    // style={{ width: "171.6px !important" }}
                  />
                  <Tooltip id="progress" />
                  {!hideTarget && (
                    <span>{roundAndAddSignToPercentage(target)}</span>
                  )}
                </>
              )}
            </div>

            {!isLoading && !hideAlert && (
              <div
                className="px-3 py-2 mt-1"
                style={{
                  position: "relative",
                  backgroundColor:
                    status === "success"
                      ? "#DCFFF3"
                      : status === "warning"
                      ? "#FFEFD3"
                      : "#FFDDDD",
                  color:
                    status === "success"
                      ? "#34C38F"
                      : status === "warning"
                      ? "#F1B44C"
                      : "#C34040",
                  fontSize: "12px",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon
                  style={{
                    position: "absolute",
                    left: "-12.5px",
                    top: "-12.5px",
                    width: "25px",
                    height: "25px",
                    color:
                      status === "success"
                        ? "#34C38F"
                        : status === "warning"
                        ? "#F1B44C"
                        : "#C34040",
                    borderRadius: "50%",
                  }}
                  icon={
                    status === "success" ? faCircleCheck : faExclamationCircle
                  }
                />

                <span style={{ fontWeight: 500 }}>
                  {status === "success"
                    ? "On target to meet daily goal"
                    : status === "warning"
                    ? "Behind daily goal"
                    : "Not on Minimum Standard"}
                </span>
              </div>
            )}
          </div>
          {!isLoading && hideMinStandard && icon}
          {!isLoading && !hideMinStandard && (
            <div className="p-0" style={{ width: "66px" }}>
              <div
                className="rounded px-2 text-center"
                style={{
                  position: "relative",
                  backgroundColor: "#C8C7C7",
                  width: "100%",
                  // height: "170px",
                  height: "90%",
                  marginTop: "20px",
                  paddingTop: "30px",
                }}
              >
                <img
                  src={bullseye}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                />
                <p style={{ fontSize: "20px", fontWeight: 600, margin: 0 }}>
                  {roundAndAddSignToPercentage(minStandard)}
                </p>
                <p style={{ fontSize: "11.5px", margin: 0 }}>
                  Minimum standard of {type === "inbound" ? "IB" : "OB"} closing
                  ratio
                </p>
              </div>
            </div>
          )}
        </div>

        {/* </div> */}
      </div>
    </div>
  )
}

export default DashboardPercentCard
