import { TextField } from "@mui/material"
import { DateTimePicker } from "@mui/x-date-pickers"
import { format } from "date-fns"
import React, { useEffect, useRef } from "react"
import { FormFeedback, Input, Label } from "reactstrap"

const DateTimeField = ({
  label,
  name,
  placeholder = "",
  type = "text",
  validation,
  required,
  wrapperClassName = "",
  mask, // New prop for mask
  ...props
}) => {
  const inputRef = useRef()

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.disabled = true
    }
  }, [inputRef])
  return (
    <div className={"d-flex flex-column mb-4" + wrapperClassName}>
      <Label className="form-label">{`${label} ${required ? "*" : ""}`}</Label>
      <DateTimePicker
        name={name}
        onChange={value =>
          validation.handleChange({
            target: { value, type: "change", id: name, name },
          })
        }
        value={validation.values[name] || null}
        onBlur={validation.handleBlur}
        disablePast
        inputRef={inputRef}
        {...props}
      />

      {validation.errors[name] ? (
        <p className="m-0 mt-1" style={{ color: "rgb(244, 106, 106)" }}>
          {validation.errors[name]}
        </p>
      ) : null}
    </div>
  )
}

export default DateTimeField
