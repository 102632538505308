import React, { useEffect, useState } from "react"
import PageContainer from "components/Common/PageContainer"
import useMSTargetReport from "./hooks/useMSTargetReport"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { useFormik } from "formik"
import { useDispatch } from "react-redux"
import { fetchMSTargetReport } from "store/actions"
import { Button, Form, Spinner } from "reactstrap"
import ScopeSelector from "components/Common/ScopeSelector"
import SelectField from "components/Form/SelectField"
import AgGridTable from "components/Common/AgGridTable"
import * as Yup from "yup"
import { datesOptions, datesSelectFieldOptions } from "constants/datesOptions"
import TableHeaderSection from "./TableHeaderSection"

const moduleName = "entityReport"

const MSTargetReport = () => {
  const dispatch = useDispatch()

  //permissions
  const PermissionsSelector = createSelector(
    state => state.Permissions,
    perm => ({
      permissions: perm.permissions,
    })
  )
  const { permissions } = useSelector(PermissionsSelector)
  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    // validateOnMount: true,
    // isInitialValid: false,
    initialValues: {
      scope: [],
      selectedDateOption: {
        value: datesOptions.monthToDate.value,
        label: datesOptions.monthToDate.title,
        customAbbreviation: datesOptions.monthToDate.secondaryTitle,
      },
    },
    validationSchema: Yup.object({
      scope: Yup.array().min(1, "Scope Is Required"),

      selectedDateOption: Yup.object().required("Please select a date option"),
    }),
    onSubmit: values => {
      const currentDateOption = Object.values(datesOptions).find(
        ({ value }) => value === values.selectedDateOption.value
      )

      // if scope has "1" which is the whole system scope, then send empty scope as its optional in the api and will return the report for the whole system
      const updatedScope = values.scope.includes("1") ? [] : values.scope

      const startDate = currentDateOption.startDate
      const endDate = currentDateOption.endDate

      // console.log(startDate, endDate)
      dispatch(
        fetchMSTargetReport({
          storesIds: updatedScope,
          startDate,
          endDate,
        })
      )
    },
  })
  const {
    loadingReport,
    columns,
    rows,
    setRows,
    pinnedBottomRows,
    pinnedTopRows,
    totalMS,
    totalTarget,
    totalActiveAgents,
  } = useMSTargetReport({
    selectedDateValue: validation.values.selectedDateOption.value,
  })
  return (
    <PageContainer>
      <div className="d-flex justify-content-between mb-4">
        <h1 className="page-title">MS Target Report</h1>
      </div>
      <div>
        {/* -15px margin to blend in the 2 sections */}
        <Form
          className="content-container"
          style={{ marginBottom: "-15px" }}
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div
            className="row pb-4"
            style={{ background: "#fff", rowGap: "20px" }}
          >
            <div className="col-lg-3 col-12">
              <ScopeSelector
                validation={validation}
                onChange={ids => {
                  validation.setFieldValue("scope", ids)
                }}
              />
            </div>
            <div className="col-lg-4 col-12">
              <SelectField
                label="Date"
                name="selectedDateOption"
                validation={validation}
                required
                options={datesSelectFieldOptions}
              />
            </div>
            <div className="col-lg-2 col-12" style={{ marginTop: "28px" }}>
              <Button color="primary" style={{ height: "36px" }} type="submit">
                Generate
              </Button>
            </div>
          </div>
        </Form>
        {loadingReport ? (
          <div className="w-full d-flex justify-content-center align-items-center pt-4">
            <Spinner />
          </div>
        ) : (
          <div>
            {permissions?.[moduleName]?.VIEW &&
            validation.isValid &&
            !validation.isValidating &&
            columns.length ? (
              <div>
                <div
                  className="content-container"
                  style={{ marginBottom: "-25px" }}
                >
                  <TableHeaderSection
                    totalMS={totalMS}
                    totalTarget={totalTarget}
                    totalActiveAgents={totalActiveAgents}
                  />
                </div>
                <AgGridTable
                  rowState={[rows, setRows]}
                  columns={columns}
                  pinnedTopRowData={pinnedTopRows}
                  pinnedBottomRowData={pinnedBottomRows}
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </PageContainer>
  )
}

export default MSTargetReport
