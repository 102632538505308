import {
  ADD_ROLE,
  FETCH_ADDABLE_ROLES,
  FETCH_ROLES,
  ROLE_ERROR,
  ROLE_SUCCESS,
  SET_ROLES,
  UPDATE_ROLE,
} from "./actionTypes"

export const addRole = (role, history) => {
  return {
    type: ADD_ROLE,
    payload: { role, history },
  }
}

export const updateRole = (id, role, history) => {
  return {
    type: UPDATE_ROLE,
    payload: { id, role, history },
  }
}

export const fetchRoles = () => {
  return {
    type: FETCH_ROLES,
  }
}

export const fetchAddableRoles = () => {
  return {
    type: FETCH_ADDABLE_ROLES,
  }
}

export const roleSuccess = msg => {
  return {
    type: ROLE_SUCCESS,
    payload: { msg },
  }
}

export const roleError = error => {
  return {
    type: ROLE_ERROR,
    payload: error,
  }
}

export const setRoles = roles => {
  return {
    type: SET_ROLES,
    payload: { roles },
  }
}
