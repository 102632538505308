import React from "react"

const RemoveIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#918F90" />
      <path d="M15 16H17L23.9999 16V18L17 18H15L8 18V16L15 16Z" fill="white" />
    </svg>
  )
}

export default RemoveIcon
