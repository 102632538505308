import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { ADD_GOAL, FETCH_GOALS, UPDATE_GOAL } from "./actionTypes"
import { goalSuccess, goalError, setGoals } from "./actions"

//Include Both Helper File with needed methods
import { getGoals, postGoal, putGoal } from "../../helpers/fakebackend_helper"

import { isArray } from "lodash"
function* addGoal({ payload: { goal, history } }) {
  try {
    yield call(postGoal, goal)
    yield put(goalSuccess("Created Successfully"))
    setTimeout(() => {
      history("/goals")
    }, 1500)
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(goalError(errMsg))
  }
}

function* updateGoal({ payload: { id, goal, history } }) {
  try {
    yield call(putGoal, id, goal)
    yield put(goalSuccess("Updated Successfully"))
    history("/goals")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(goalError(errMsg))
  }
}

function* fetchGoals() {
  try {
    console.log("saga get goals")
    const response = yield call(getGoals)
    yield put(setGoals(response))
  } catch (error) {
    console.log("error", error)
  }
}

function* goalSaga() {
  yield takeEvery(ADD_GOAL, addGoal)
  yield takeEvery(FETCH_GOALS, fetchGoals)
  yield takeEvery(UPDATE_GOAL, updateGoal)
}

export default goalSaga
