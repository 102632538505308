import React from "react"

const AddIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#AF0001" />
      <path
        d="M15 15L15 8.00002L17 8.00003L17 15L23.9999 15V17L17 17L17 24L15 24L15 17L8 17V15L15 15Z"
        fill="white"
      />
    </svg>
  )
}

export default AddIcon
