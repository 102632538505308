import {
  ADD_USER,
  FETCH_USERS,
  USER_ERROR,
  USER_SUCCESS,
  SET_USERS,
  UPDATE_USER,
} from "./actionTypes"

const initialState = {
  errorMsg: "",
  successMsg: "",
  loading: false,
  users: [],
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case UPDATE_USER:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case FETCH_USERS:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        users: [],
        loading: true,
      }
      break

    case SET_USERS:
      state = {
        ...state,
        loading: false,
        users: action.payload.users,
      }
      break
    case USER_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload.msg,
        loading: false,
      }
      break
    case USER_ERROR:
      state = { ...state, errorMsg: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default user
