//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_PASSWORD_FORGET = "/api/v1/auth/forget-password"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"
export const POST_LOGIN = "/api/v1/auth/login"
export const POST_LOGOUT = "/api/v1/auth/logout"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//COMPANY
export const POST_COMPANY = "/api/v1/companies"
export const GET_COMPANIES = "/api/v1/companies/all?orderBy=company.createdAt"

//DEPARTMENT
export const POST_DEPARTMENT = "/api/v1/departments"
export const GET_DEPARTMENTS =
  "/api/v1/departments/all?orderBy=department.createdAt"

//STORE
export const POST_STORE = "/api/v1/stores"
export const GET_STORES = "/api/v1/stores/all?orderBy=store.createdAt"

//GROUP
export const POST_GROUP = "/api/v1/groups"
export const GET_GROUPS = "/api/v1/groups/all?orderBy=group.createdAt"
export const GET_GROUPS_STORES = "/api/v1/groups/stores-scopes-ids"

//ROLES
export const POST_ROLE = "/api/v1/roles"
export const GET_ROLES = "/api/v1/roles?orderBy=role.createdAt"
export const GET_ADDABLE_ROLES = "/api/v1/roles/addable"

//USER
export const POST_USER = "/api/v1/auth/create"
export const GET_USERS = "/api/v1/user/all?orderBy=user.createdAt"
export const PUT_USER = "/api/v1/user/"
export const DELETE_USER = "/api/v1/user/"

//DISPOSITION
export const POST_DISPOSITION = "/api/v1/dispositions"
export const GET_DISPOSITIONS =
  "/api/v1/dispositions/all?orderBy=disposition.createdAt"

//CALL
export const POST_CALL = "/api/v1/calls"
export const GET_CALLS = "/api/v1/calls/all/filtered"
export const GET_CALL = "/api/v1/calls/"
export const UPDATE_CALL = "/api/v1/calls/"

//SERVICE ADVISOR
export const POST_SERRVICE_ADVISOR = "/api/v1/service-advisor"
export const GET_SERRVICE_ADVISORS =
  "/api/v1/service-advisor/all?orderBy=user.createdAt"

//Agents kms
export const PUT_AGENT = "/api/v1/agent-kms/"
export const GET_AGENTS =
  "/api/v1/agent-kms?orderBy=agentKms.createdAt&take=200"

//Agents kms
export const POST_TARGET = "/api/v1/targets/"
export const GET_TARGETS = "/api/v1/targets?orderBy=target.createdAt&take=200"

//GOAL
export const POST_GOAL = "/api/v1/goal"
export const GET_GOALS = "/api/v1/goal/all?orderBy=goal.createdAt"

//GOAL ENTIRY
export const POST_GOAL_ENTRY = "/api/v1/goal/entry"
export const GET_GOALS_ENTRIES = "/api/v1/goal/entry/all?orderBy=goalentry_id"

//KPI
export const POST_KPI = "/api/v1/manual-kpi"
export const GET_KPIS = "/api/v1/manual-kpi/all?orderBy=manual-kpi.createdAt"

//KPI ENTRY
export const POST_KPI_ENTRY = "/api/v1/manual-kpi-entry/upsert"
export const DELETE_KPI_ENTRY = "/api/v1/manual-kpi-entry"

export const GET_KPIS_ENTRIES =
  "/api/v1/manual-kpi-entry/all?orderBy=manual-kpi-entry.createdAt"

// ENTITY REPORT
export const GET_ENTITY_REPORTS = "/api/v1/reports/entity"
// SERVICE ADVISOR REPORT
export const GET_SERVICE_ADVISOR_REPORT = "/api/v1/reports/service-advisor"
// ROLLUP REPORT
export const GET_ROLLUP_REPORT = "/api/v1/reports/rollup"
// ROLLUP REPORT
export const GET_STACK_RANKING_REPORT = "/api/v1/reports/stack-ranking"

// ROLLUP REPORT
export const GET_AGENTS_MADE_CALLS_NUMBER =
  "/api/v1/reports/agents-number-per-day"
export const GET_AGENTS_NUMBER_PER_STORE =
  "/api/v1/reports/agents-number-per-store"

// get goals by scope
export const GET_GOALS_BY_SCOPE = "/api/v1/reports/goals-by-scope"

//upload link
export const UPLOAD_LINK = "/api/v1/companies/upload-link"

//Whoami
export const GET_WHOAMI = "/api/v1/auth/whoami"

export const GET_MANUAL_KPIS_PER_AGENT = "/api/v1/reports/manual-kpi-per-agent"
export const GET_MANUAL_KPIS_PER_STORE = "/api/v1/reports/manual-kpi-per-store"

//holidays
export const POST_HOLIDAY = "/api/v1/holiday"
export const GET_HOLIDAYS = "/api/v1/holiday/all"
export const GET_HOLIDAY = id => `/api/v1/holiday/${id}`
export const UPDATE_HOLIDAY = id => `/api/v1/holiday/${id}`
export const DELETE_HOLIDAY = id => `/api/v1/holiday/${id}`
//weekends
export const POST_WEEKEND = "/api/v1/weekends"
export const GET_WEEKENDS = "/api/v1/weekends/all"
export const GET_WEEKEND = id => `/api/v1/weekends/${id}`
export const UPDATE_WEEKEND = id => `/api/v1/weekends/${id}`
export const DELETE_WEEKEND = id => `/api/v1/weekends/${id}`
