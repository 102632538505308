import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { ADD_STORE, FETCH_STORES, UPDATE_STORE } from "./actionTypes"
import { storeSuccess, storeError, setStores } from "./actions"

//Include Both Helper File with needed methods
import {
  getStores,
  postStore,
  putStore,
} from "../../helpers/fakebackend_helper"

import { isArray } from "lodash"
function* addStore({ payload: { store, history } }) {
  try {
    console.log("new saga", store)
    const response = yield call(postStore, store)
    yield put(storeSuccess("Created Successfully"))
    history("/stores")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(storeError(errMsg))
  }
}

function* updateStore({ payload: { id, store, history } }) {
  try {
    const response = yield call(putStore, id, store)
    yield put(storeSuccess("Updated Successfully"))
    history("/stores")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(storeError(errMsg))
  }
}

function* fetchStores() {
  try {
    console.log("saga get stores")
    const response = yield call(getStores)
    yield put(setStores(response))
  } catch (error) {
    console.log("error", error)
  }
}

function* storeSaga() {
  yield takeEvery(ADD_STORE, addStore)
  yield takeEvery(FETCH_STORES, fetchStores)
  yield takeEvery(UPDATE_STORE, updateStore)
}

export default storeSaga
