import { axiosApi } from "helpers/api_helper"
import { kpiTypesInitialValues, kpiValueTypes } from "./constants"
import {
  calcAvg,
  convertDurationToSeconds,
  getDurationFromSeconds,
  getGenericAvg,
  getGenericSum,
  isDuration,
  isPercentageString,
} from "helpers/helperFns"

export async function getGoal({
  name,
  type,
  startDateParam,
  endDateParam,
  scopeQuery,
  factor,
  onFinalize,
}) {
  const goalResponse = await axiosApi.get(
    `/api/v1/reports/dashboard-goal/${encodeURI(
      name
    )}?${startDateParam}&${endDateParam}&${scopeQuery}`
  )

  const [{ data: storeGoals }, { data: agentGoals }] = await Promise.all([
    axiosApi.get(
      `/api/v1/reports/dashboard-goal/${encodeURI(
        name
      )}?${startDateParam}&${endDateParam}&${scopeQuery}`
    ),
    axiosApi.get(
      `/api/v1/reports/active-dashboard-goal/${encodeURI(
        name
      )}?${startDateParam}&${endDateParam}&${scopeQuery}`
    ),
  ])

  const combinedGoals = [...storeGoals, ...agentGoals]
  const storesGoal = {
    minStandard: undefined,
    target: undefined,
    count: 0,
  }
  const agentsGoal = {
    minStandard: undefined,
    target: undefined,
    count: 0,
  }

  for (const goal of combinedGoals) {
    // first we check whether to manipulate the agentsGoal or the storesGoal based on entity_type
    let mainGoal
    if (goal.entity_type === "agent") {
      mainGoal = agentsGoal
    } else {
      mainGoal = storesGoal
    }

    // we increase the count of the goal by 1 for each new entry to the mainGoal object
    mainGoal.count = mainGoal.count + 1

    // we add the current goal values to the value of the mainGoal
    mainGoal.target = getGenericSum([mainGoal.target, goal.target])
    mainGoal.minStandard = getGenericSum([
      mainGoal.minStandard,
      goal.minstandard,
    ])
  }

  // we check if we should use the average of the main goal's summed up values (if the name of the goal contains avg or average or if its a percentage)
  const isAvg =
    isPercentageString(storesGoal.target || agentsGoal.target) ||
    name.toLowerCase().includes("avg") ||
    name.toLowerCase().includes("average")

  // if its an average goal then we get the avg but dividing the summed up values by their count
  if (isAvg) {
    agentsGoal.target = agentsGoal.target
      ? calcAvg(agentsGoal.target, agentsGoal.count)
      : undefined
    agentsGoal.minStandard = agentsGoal.minStandard
      ? calcAvg(agentsGoal.minStandard, agentsGoal.count)
      : undefined

    storesGoal.target = storesGoal.target
      ? calcAvg(storesGoal.target, storesGoal.count)
      : undefined
    storesGoal.minStandard = storesGoal.minStandard
      ? calcAvg(storesGoal.minStandard, storesGoal.count)
      : undefined
  }

  // if we have both storesGoal and agentsGoal we get their avg, else we only use the existing goal of either of them
  let avgTarget
  let avgMinStandard
  if (!!storesGoal.target && !!agentsGoal.target) {
    avgTarget = getGenericAvg([storesGoal.target, agentsGoal.target])
    avgMinStandard = getGenericAvg([
      storesGoal.minStandard,
      agentsGoal.minStandard,
    ])
  } else if (!!storesGoal.target) {
    avgTarget = storesGoal.target
    avgMinStandard = storesGoal.minStandard
  } else {
    avgTarget = agentsGoal.target
    avgMinStandard = agentsGoal.minStandard
  }

  const avgGoal = {
    target: isAvg ? avgTarget : Math.floor(avgTarget * factor),
    minStandard: isAvg ? avgMinStandard : Math.floor(avgMinStandard * factor),
  }

  onFinalize(avgGoal)
}
