import { tzMoment } from "constants/datesOptions"

export function approximate(
  n,
  decimals = 1,
  options = { isFixedDecimal: false }
) {
  const { isFixedDecimal } = options || {}

  let factor = "1"
  for (let decimal = 1; decimal <= decimals; decimal++) {
    factor = factor + "0"
  }
  factor = parseInt(factor)

  let result = 0
  const rounded = Math.round(parseFloat(n || 0) * factor) / factor
  result = rounded
  if (isFixedDecimal) {
    result = result.toFixed(decimals)
  }

  return result
}
export function roundAndAddSignToPercentage(n) {
  n = parseFloat(n) || 0

  n = approximate(n, 1, { isFixedDecimal: true })

  return `${n}%`
}
// maps an array of strings to a single url query param string
// ex: ids=['1', '2', '3'] => "ids=1&ids=2&ids=3"
export function mapArrayToQueryParam(paramName = "", arr = []) {
  if (!arr?.length || !paramName) return ""

  const fullParamString = arr.map(str => `${paramName}=${str}`).join("&")
  return fullParamString
}

export function getPercentage(
  partialNum,
  wholeNum,
  withPercentageSign = true,
  withApproximation = true,
  shouldReturnNumber = false
) {
  let ratio = 0
  if (wholeNum) {
    ratio = (parseFloat(partialNum) || 0) / parseFloat(wholeNum)
  }
  let percent = parseFloat(ratio) * 100
  if (withApproximation) {
    percent = approximate(percent, 1, { isFixedDecimal: true })
  }
  let returnValue = `${percent}${withPercentageSign ? "%" : ""}`

  if (shouldReturnNumber) {
    returnValue = parseFloat(returnValue)
  }
  return returnValue
}

export function convertRatioToPercentage(
  ratio,
  withPercentageSign = true,
  withApproximation = true
) {
  // let percent = parseFloat(ratio) * 100
  // if (withApproximation) {
  //   percent = approximate(percent)
  // }
  // const str = `${percent}${withPercentageSign ? "%" : ""}`

  return getPercentage(ratio, 1, withPercentageSign, withApproximation)
}

export function combineArrayToString(arr = []) {
  if (!arr.length) return ""

  let combinedStr = ""
  arr.forEach((item, i) => {
    if (i === 0) {
      combinedStr = item
    } else {
      combinedStr += `,${item}`
    }
  })
  return combinedStr
}

export function getSum(numsArr = [], withApproximation = true) {
  if (!numsArr.length) return 0

  let sum = numsArr.reduce((sum, n) => sum + (parseFloat(n) || 0), 0)

  if (withApproximation) {
    sum = approximate(sum)
  }

  return sum
}

export function getAverage(numsArr = [], withApproximation = true) {
  if (!numsArr.length) return 0

  const arrLength = numsArr.length
  const sum = getSum(numsArr, withApproximation)

  let avg = sum / arrLength

  if (withApproximation) {
    avg = approximate(avg)
  }

  return avg
}

export function isAdminRole(roleName) {
  return roleName === "Admin"
}
export function isAgentRole(roleName) {
  return roleName === "Agent"
}
export function isServiceAdvisorRole(roleName) {
  return roleName === "Service Advisor"
}

export function isHhMm(str) {
  if (!str) return false

  const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(str)

  return isValid
}
export function isDuration(str) {
  if (!str) return false
  str = `${str}`

  const isValid = str.includes(":")

  return isValid
}
export function convertDurationToSeconds(duration) {
  if (!duration || typeof duration !== "string") return 0

  const split = duration.split(":")
  const mins = split[0]
  const secs = split[1]
  const durationSecs = parseFloat(mins) * 60 + parseFloat(secs)
  return durationSecs
}

export function getDurationFromSeconds(seconds) {
  if (!seconds) return "00:00"
  seconds = Math.floor(seconds)

  const mins = Math.floor(seconds / 60)
  const secs = seconds - mins * 60

  const minsStr = mins.toString().length < 2 ? `0${mins}` : `${mins}`
  const secsStr = secs.toString().length < 2 ? `0${secs}` : `${secs}`

  const duration = `${minsStr}:${secsStr}`
  return duration
}
export function getSumOfDurations(durationsArr = []) {
  if (!durationsArr?.length) return "00:00"
  durationsArr = durationsArr.map(el =>
    el === undefined || el === null ? "00:00" : el
  )

  const totalSeconds = getSum(
    durationsArr.map(duration => convertDurationToSeconds(duration))
  )

  const totalDuration = getDurationFromSeconds(totalSeconds)

  return totalDuration
}
export function getAvgOfDurations(durationsArr) {
  if (!durationsArr?.length) return "00:00"
  durationsArr = durationsArr.map(el =>
    el === undefined || el === null ? "00:00" : el
  )

  const avgSeconds = getAverage(
    durationsArr.map(duration => convertDurationToSeconds(duration))
  )

  const avgDuration = getDurationFromSeconds(avgSeconds)

  return avgDuration
}
export function getGenericSum(arr) {
  if (!arr?.length) return

  const firstEl = arr.find(el => el !== undefined && el !== null)

  const isTime = isDuration(firstEl)
  const isPercent = isPercentageString(firstEl)

  arr = arr.map(el => (el === undefined || el === null ? "00:00" : el))

  let sum

  if (isTime) {
    sum = getSumOfDurations(arr)
  } else if (isPercent) {
    sum = getSum(arr) + "%"
  } else {
    sum = getSum(arr)
  }

  return sum
}
export function getGenericAvg(arr) {
  if (!arr?.length) return

  const firstEl = arr.find(el => el !== undefined && el !== null)
  const isTime = isDuration(firstEl)
  const isPercent = isPercentageString(firstEl)

  arr = arr.map(el => (el === undefined || el === null ? "00:00" : el))

  let sum

  if (isTime) {
    sum = getAvgOfDurations(arr)
    sum = roundTimeFraction(sum)
  } else if (isPercent) {
    sum = getAverage(arr) + "%"
  } else {
    sum = getAverage(arr)
  }

  return sum
}

export function convertTimeToDate(timeStr) {
  const result = tzMoment(timeStr, "HH:mm").toDate()

  return result
}

export function getAverageOfDates(datesArr = [], format) {
  if (!datesArr.length) return 0

  const arrLength = datesArr.length

  const sum = datesArr.reduce(
    (a, b) => a + tzMoment(b).set({ seconds: 0, millisecond: 0 }).valueOf(),
    0
  )

  const avg = sum / arrLength

  let dateAvg = tzMoment(avg).toDate()

  if (format) {
    dateAvg = tzMoment(dateAvg).format(format)
  }

  return dateAvg
}

export function isPercentageString(str) {
  if (!str) return false
  str = `${str}`
  return str.endsWith("%")
}

export function truncateString(str, maxLength) {
  if (!str) return ""

  str = `${str}`

  let updatedStr = ""
  if (maxLength && str.length > maxLength) {
    updatedStr = str.substring(0, maxLength)
  } else {
    updatedStr = str
  }

  return updatedStr
}

export function roundTimeFraction(timeString) {
  // Split the time string into components
  let [time, fraction] = timeString.split(".")
  let [hours, minutes] = time.split(":").map(Number)

  // Round the fractional part
  fraction = parseFloat("0." + fraction)
  if (fraction >= 0.5) {
    minutes += 1
  }

  // Adjust the hours and minutes if needed
  if (minutes === 60) {
    minutes = 0
    hours += 1
  }

  // Format the result with leading zeros if needed
  hours = String(hours).padStart(2, "0")
  minutes = String(minutes).padStart(2, "0")

  return `${hours}:${minutes}`
}
const aggregateCompanies = scopes => {
  const result = scopes.reduce((r, { children, ...rest }) => {
    if (rest.type === "company") r.push(rest)
    if (children) r.push(...aggregateCompanies(children))
    return r
  }, [])

  return result
}
const mapCompaniesArray = scopes => {
  const aggregatedCompanies = aggregateCompanies(scopes)
  return aggregatedCompanies.map(item => ({
    value: item.id.toString(),
    title: item.name,
    children: [],
  }))
}
export function getScopeCompanies(scopes) {
  if (!scopes?.length) return []

  const companiesArr = mapCompaniesArray(scopes)
  return companiesArr
}

export function getPreviousPeriodFromRange(start, end) {
  if (!start || !end) return ["", ""]

  const days = tzMoment(end).diff(start, "days")
  const previousPeriodEnd = tzMoment(start).endOf("day").format()
  const previousPeriodStart = tzMoment(previousPeriodEnd)
    .startOf("day")
    .subtract(days, "days")
    .format()

  return [previousPeriodStart, previousPeriodEnd]
}
export function generateID() {
  return `${Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))}`
}
export function compareGenericValues(a, b) {
  if ((!a && a !== 0) || (!b && b !== 0)) return

  const isTime = isDuration(a)
  const isPercent = isPercentageString(a)

  let diff

  if (isTime) {
    const totalASeconds = convertDurationToSeconds(a)
    const totalBSeconds = convertDurationToSeconds(b)
    diff =
      totalASeconds - totalBSeconds > 0
        ? 1
        : totalASeconds - totalBSeconds === 0
        ? 0
        : -1
  } else {
    diff =
      parseFloat(a) - parseFloat(b) > 0
        ? 1
        : parseFloat(a) - parseFloat(b) === 0
        ? 0
        : -1
  }

  return diff
}

export function mainRedirect(history) {
  if (!history) return
  const authUser = localStorage.getItem("authUser")
  const parsedUser = authUser ? JSON.parse(authUser) : undefined
  if (!authUser) {
    return history("/login")
  }
  const permissions = parsedUser.roles?.[0]?.permissions || []
  console.log({ permissions })
  const hasUserDashboardRole = permissions.userDashboard?.VIEW
  const hasRoomDashboardRole = permissions.roomDashboard?.VIEW

  // const isAgent = isAgentRole(parsedUser?.roles?.[0]?.name)
  // const isManagerDashboardPath = location.pathname === "/manager-dashboard"
  // const isAgentDashboardPath = location.pathname === "/agent-dashboard"

  if (hasRoomDashboardRole) {
    return history("/dashboard")
  }
  if (hasUserDashboardRole) {
    return history("/manager-dashboard")
  }

  return history("/calls")
}

export function sortManualKpis(
  manualKpis,
  comparator = "manualKpiName",
  order = []
) {
  if (!manualKpis?.length) return []
  if (!order?.length) return manualKpis

  let kpisCopy = [...manualKpis]

  kpisCopy.sort((a, b) => {
    // Get the index of the manualKpiName in the order array
    const indexA = order.indexOf(a[comparator])
    const indexB = order.indexOf(b[comparator])

    // If the manualKpiName is found in the order array, use its index for sorting
    // If not found, treat it as if it should come after the specified order
    return (
      (indexA === -1 ? order.length : indexA) -
      (indexB === -1 ? order.length : indexB)
    )
  })

  return kpisCopy
}

export function isAvgBehaviourManualKpi(manualKpiName) {
  if (!manualKpiName) return false

  const avgBehaviourKpis = ["Call Performance Score", "AVG Handling Time"]

  const isAvg = avgBehaviourKpis.includes(manualKpiName)
  return isAvg
}

export function calcAvg(value, count) {
  const isTime = isDuration(value)
  const isPercent = isPercentageString(value)

  let average
  if (isTime) {
    const mins = convertDurationToSeconds(value)
    const avgMins = Math.floor(mins / count)
    const parsedDuration = getDurationFromSeconds(avgMins)
    average = parsedDuration
  } else {
    const avg = approximate(parseInt(value) / count)

    average = isPercent ? `${avg}%` : avg
  }
  return average
}
