import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { ADD_COMPANY, FETCH_COMPANIES, UPDATE_COMPANY } from "./actionTypes"
import { companySuccess, companyError, setCompanies } from "./actions"

//Include Both Helper File with needed methods
import {
  getCompanies,
  postCompany,
  putCompany,
} from "../../helpers/fakebackend_helper"

import { isArray } from "lodash"
function* addCompany({ payload: { company, history } }) {
  try {
    console.log("new saga", company)

    const response = yield call(postCompany, company)
    yield put(companySuccess("Created Successfully"))
    history("/companies")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(companyError(errMsg))
  }
}

function* updateCompany({ payload: { id, company, history } }) {
  try {
    const response = yield call(putCompany, id, company)
    yield put(companySuccess("Updated Successfully"))
    history("/companies")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(companyError(errMsg))
  }
}

function* fetchCompanies() {
  try {
    console.log("saga get companies")
    const response = yield call(getCompanies)
    yield put(setCompanies(response))
  } catch (error) {
    console.log("error", error)
  }
}

function* companySaga() {
  yield takeEvery(ADD_COMPANY, addCompany)
  yield takeEvery(FETCH_COMPANIES, fetchCompanies)
  yield takeEvery(UPDATE_COMPANY, updateCompany)
}

export default companySaga
