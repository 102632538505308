import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import AgGridTable from "components/Common/AgGridTable"
import PageContainer from "components/Common/PageContainer"
import { Button, Form, Spinner } from "reactstrap"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { useFormik } from "formik"
import { fetchRollupReport, resetRollupReport } from "store/actions"
import * as Yup from "yup"
import ScopeSelector from "components/Common/ScopeSelector"
import SelectField from "components/Form/SelectField"
import { dummyReports } from "./dummy"
import {
  calcAvg,
  convertRatioToPercentage,
  getAverage,
  getGenericAvg,
  getGenericSum,
  getPercentage,
  getSum,
  isAvgBehaviourManualKpi,
} from "helpers/helperFns"
import {
  customDateSelectFieldOptions,
  datesOptions,
  datesSelectFieldOptions,
  isCustomDateOption,
  tzMoment,
} from "constants/datesOptions"
import CsvIcon from "assets/icons/CsvIcon"
import CustomRangePicker from "components/Form/CustomRangePicker"

const SHOWS_KPI = "Shows"
const SCHEDULED_APPTS_KPI = "Scheduled Appts"
const ABANDONED_CALLS_KPI = "Abandoned Calls"
const CALL_PERFORMANCE_SCORE_KPI = "Call Performance Score"
const AVG_HANDLING_TIME_KPI = "AVG Handling Time"

const moduleName = "rollupReport"

function getIBandOBDispositionNames(dispositionName) {
  const obDispositionName = `OB ${dispositionName}`
  const ibDispositionName = `IB ${dispositionName}`
  return {
    obDispositionName,
    ibDispositionName,
  }
}

const RollupReport = () => {
  //

  //
  const dispatch = useDispatch()
  const gridRef = useRef()
  const [rangePickerKey, setRangePickerKey] = useState(0)

  const localStorageUser = localStorage.getItem("authUser")
  const user = localStorageUser
    ? JSON.parse(localStorage.getItem("authUser"))
    : undefined

  //permissions
  const PermissionsSelector = createSelector(
    state => state.Permissions,
    perm => ({
      permissions: perm.permissions,
    })
  )
  const { permissions } = useSelector(PermissionsSelector)

  const RollupReportProperties = createSelector(
    state => state.RollupReport,
    RollupReport => ({
      loading: RollupReport.loading,
      rollupReports: RollupReport.rollupReports,
    })
  )

  const { loading: loadingRollupReport, rollupReports } = useSelector(
    RollupReportProperties
  )
  const rollupReport = useMemo(
    () => rollupReports?.rollupReport,
    [rollupReports]
  )
  const manualKpisPerStore = useMemo(
    () => rollupReports?.manualKpisPerStore,
    [rollupReports]
  )
  const manualKpisPerAgent = useMemo(
    () =>
      rollupReports?.manualKpisPerAgent?.filter(({ storeName }) => !!storeName),
    [rollupReports]
  )
  const combinedManualKpis = useMemo(
    () => [
      ...(manualKpisPerAgent || []),
      ...(manualKpisPerStore?.stores || []),
    ],
    [manualKpisPerAgent, manualKpisPerStore]
  )

  // const rollupReport = dummyReports

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    // validateOnMount: true,
    // isInitialValid: false,
    initialValues: {
      scope: [],
      selectedDateOption: {
        value: datesOptions.monthToDate.value,
        label: datesOptions.monthToDate.title,
        customAbbreviation: datesOptions.monthToDate.secondaryTitle,
      },
      startDate: "",
      endDate: "",
    },
    validationSchema: Yup.object({
      startDate: Yup.string().when(
        "selectedDateOption",
        ([selectedDateOption], schema) =>
          selectedDateOption.value === datesOptions.custom.value
            ? schema.required("Please add start date")
            : schema.optional()
      ),
      endDate: Yup.string().when(
        "selectedDateOption",
        ([selectedDateOption], schema) =>
          selectedDateOption.value === datesOptions.custom.value
            ? schema.required("Please add start date")
            : schema.optional()
      ),
      scope: Yup.array().min(1, "Scope Is Required"),

      selectedDateOption: Yup.object().required("Please select a date option"),
    }),
    onSubmit: values => {
      const currentDateOption = Object.values(datesOptions).find(
        ({ value }) => value === values.selectedDateOption.value
      )

      // if scope has "1" which is the whole system scope, then send empty scope as its optional in the api and will return the report for the whole system
      const updatedScope = values.scope.includes("1") ? [] : values.scope

      let startDate, endDate
      if (currentDateOption.value === datesOptions.custom.value) {
        startDate = values.startDate
        endDate = values.endDate
      } else {
        startDate = currentDateOption.startDate
        endDate = currentDateOption.endDate
      }
      dispatch(
        fetchRollupReport({
          storesIds: updatedScope,
          startDate,
          endDate,
          withManuallyAddedKpis: true,
        })
      )
    },
  })

  function valueFormatter(params) {
    if (
      params.value === "" ||
      params.value === undefined ||
      params.value === null
    ) {
      return "0"
    }
  }

  const [rows, setRows] = useState([])
  const [pinnedTopRows, setPinnedTopRows] = useState([])
  const [columns, setColumns] = useState([])

  useEffect(() => {
    if (validation.values) {
      dispatch(resetRollupReport())
    }
  }, [validation.values])

  useEffect(() => {
    if (!rollupReport?.stores?.length) {
      setRows([])
      setPinnedTopRows([])
      setColumns([])
      return
    }

    let tableColumns = [
      {
        field: "storeName",
        headerName: "Store Name",
        filter: true,
        pinned: "left",
      },
      {
        field: "ibCalls",
        headerName: "IB Calls",
        filter: true,
        valueFormatter,
      },
      {
        field: "ib_OTDB",
        filter: true,
        headerName: "IB OTDB Calls",
        valueFormatter,
      },
      {
        field: "ibApptsCreated",
        headerName: "IB Appts Created",
        filter: true,
        valueFormatter,
      },
      {
        field: "ibClosingRatio",
        headerName: "IB Closing Ratio",
        filter: true,
        valueFormatter,
      },
      {
        field: "obCalls",
        headerName: "OB Calls",
        filter: true,
        valueFormatter,
      },
      {
        field: "obConnectedCalls",
        headerName: "OB Connected Calls",
        filter: true,
        valueFormatter,
      },
      {
        field: "ob_OTDB",
        filter: true,
        headerName: "OB OTDB Calls",
        valueFormatter,
      },
      {
        field: "obApptsCreated",
        headerName: "OB Appts Created",
        filter: true,
        valueFormatter,
      },
      {
        field: "obClosingRatio",
        headerName: "OB Closing Ratio",
        filter: true,
        valueFormatter,
      },
      {
        field: "totalCalls",
        filter: true,
        headerName: "Total Calls",
        valueFormatter,
      },
      {
        field: "totalApptsCreated",
        filter: true,
        headerName: "Total Appts Created",
        valueFormatter,
      },
      // {
      //   field: "totalClosingRatio",
      //   filter: true,
      //   headerName: "Total Closing Ratio",
      //   valueFormatter,
      // },
    ]

    for (let i = 0; i < combinedManualKpis.length || 0; i++) {
      const kpiObj = combinedManualKpis[i]
      const manualKpiName = kpiObj.manualKpiName

      if (!tableColumns.find(({ field }) => manualKpiName === field)) {
        tableColumns.push({
          field: manualKpiName,
          headerName: manualKpiName,
          filter: true,
          valueFormatter,
        })
      }
    }
    tableColumns.push(
      {
        field: "showsRatio",
        headerName: "Shows Ratio",
        filter: true,
        valueFormatter,
      },
      {
        field: "abandonedRatio",
        headerName: "Abandoned Ratio",
        filter: true,
        valueFormatter,
      }
    )
    for (let i = 0; i < rollupReport?.stores?.length; i++) {
      const dispositionObj = rollupReport.stores[i]
      const dispositionName = dispositionObj.dispositionName
      const { obDispositionName, ibDispositionName } =
        getIBandOBDispositionNames(dispositionName)
      if (
        !tableColumns.find(({ field }) =>
          [obDispositionName, ibDispositionName].includes(field)
        )
      ) {
        const hasOBValue = rollupReport.stores.some(reportObj => {
          const sameName = reportObj.dispositionName === dispositionName
          const hasObValue =
            getSum([
              reportObj.obConnectedCalls,
              reportObj.obNotConnectedCalls,
            ]) > 0
          return sameName && hasObValue
        })
        const hasIBValue = rollupReport.stores.some(reportObj => {
          const sameName = reportObj.dispositionName === dispositionName
          const hasIbValue = parseFloat(reportObj.ibCalls) > 0
          return sameName && hasIbValue
        })

        hasIBValue &&
          tableColumns.push({
            field: ibDispositionName,
            headerName: ibDispositionName,
            filter: true,
            valueFormatter,
          })
        hasOBValue &&
          tableColumns.push({
            field: obDispositionName,
            headerName: obDispositionName,
            filter: true,
            valueFormatter,
          })
      }
    }

    let tableRows = {}
    for (let i = 0; i < rollupReport?.stores?.length; i++) {
      const dispositionObj = rollupReport.stores[i]
      let {
        dispositionName,
        storeName,
        count,
        ibCalls,
        ib_OTDB,
        ob_OTDB,
        obNotConnectedCalls,
        obConnectedCalls,
        ibApptsCreated,
        obNotConnectedApptsCreated,
        obConnectedApptsCreated,
        connectedOtdb,
      } = dispositionObj
      const isObExcludedDisposition = dispositionName
        .toLowerCase()
        .includes("confirmation")
      const filteredObConnectedCalls = isObExcludedDisposition
        ? 0
        : obConnectedCalls
      const filteredObNotConnectedCalls = isObExcludedDisposition
        ? 0
        : obNotConnectedCalls

      const { obDispositionName, ibDispositionName } =
        getIBandOBDispositionNames(dispositionName)
      if (tableRows[storeName]) {
        const updatedIbOTDB =
          (parseFloat(tableRows[storeName].ib_OTDB) || 0) +
          (parseFloat(ib_OTDB) || 0)
        const updatedObOTDB =
          (parseFloat(tableRows[storeName].ob_OTDB) || 0) +
          (parseFloat(ob_OTDB) || 0)
        const updatedOBNotConnectedCalls =
          (parseFloat(tableRows[storeName].obNotConnectedCalls) || 0) +
          (parseFloat(filteredObNotConnectedCalls) || 0)
        const updatedOBConnectedCalls =
          (parseFloat(tableRows[storeName].obConnectedCalls) || 0) +
          (parseFloat(filteredObConnectedCalls) || 0)

        const updatedConnectedOtdb =
          (parseFloat(tableRows[storeName].connectedOtdb) || 0) +
          (parseFloat(connectedOtdb) || 0)
        const updatedIBCalls =
          (parseFloat(tableRows[storeName].ibCalls) || 0) +
          (parseFloat(ibCalls) || 0)
        const updatedOBCalls = getSum([
          updatedOBNotConnectedCalls,
          updatedOBConnectedCalls,
        ])
        const updatedOBNotConnectedApptsCreated =
          (parseFloat(tableRows[storeName].obNotConnectedApptsCreated) || 0) +
          (parseFloat(obNotConnectedApptsCreated) || 0)
        const updatedOBConnectedApptsCreated =
          (parseFloat(tableRows[storeName].obConnectedApptsCreated) || 0) +
          (parseFloat(obConnectedApptsCreated) || 0)
        const updatedIBApptsCreated =
          (parseFloat(tableRows[storeName].ibApptsCreated) || 0) +
          (parseFloat(ibApptsCreated) || 0)
        const updatedOBApptsCreated = getSum([
          updatedOBNotConnectedApptsCreated,
          updatedOBConnectedApptsCreated,
        ])

        const updatedTotalCalls =
          (parseFloat(tableRows[storeName].totalCalls) || 0) +
          getSum([
            ibCalls,
            filteredObNotConnectedCalls,
            filteredObConnectedCalls,
          ])
        const updatedTotalAppts =
          (parseFloat(tableRows[storeName].totalApptsCreated) || 0) +
          getSum([
            ibApptsCreated,
            obConnectedApptsCreated,
            obNotConnectedApptsCreated,
          ])
        const updatedTotalClosingRatio = getPercentage(
          getSum([updatedIBApptsCreated, updatedOBApptsCreated]),
          getSum([updatedIbOTDB, updatedConnectedOtdb])
        )
        tableRows[storeName] = {
          ...tableRows[storeName],
          [ibDispositionName]: parseFloat(ibCalls),
          [obDispositionName]: getSum([obNotConnectedCalls, obConnectedCalls]),
          ibCalls: updatedIBCalls,
          ib_OTDB: updatedIbOTDB,
          ob_OTDB: updatedObOTDB,
          obCalls: updatedOBCalls,
          connectedOtdb: updatedConnectedOtdb,
          obNotConnectedCalls: updatedOBNotConnectedCalls,
          obConnectedCalls: updatedOBConnectedCalls,
          ibApptsCreated: updatedIBApptsCreated,
          obApptsCreated: updatedOBApptsCreated,
          obNotConnectedApptsCreated: updatedOBNotConnectedApptsCreated,
          obConnectedApptsCreated: updatedOBConnectedApptsCreated,

          ibClosingRatio: getPercentage(updatedIBApptsCreated, updatedIbOTDB),
          obClosingRatio: getPercentage(
            updatedOBApptsCreated,
            updatedConnectedOtdb
          ),

          totalCalls: updatedTotalCalls,
          totalApptsCreated: updatedTotalAppts,
          totalClosingRatio: updatedTotalClosingRatio,
        }
      } else {
        const totalCalls = getSum([
          ibCalls,
          filteredObNotConnectedCalls,
          filteredObConnectedCalls,
        ])
        const totalApptsCreated = getSum([
          ibApptsCreated,
          obConnectedApptsCreated,
          obNotConnectedApptsCreated,
        ])
        const totalClosingRatio = getPercentage(
          getSum([
            ibApptsCreated,
            obConnectedApptsCreated,
            obNotConnectedApptsCreated,
          ]),
          getSum([ib_OTDB, connectedOtdb])
        )
        tableRows[storeName] = {
          storeName,
          [ibDispositionName]: parseFloat(ibCalls),
          [obDispositionName]: getSum([obNotConnectedCalls, obConnectedCalls]),
          ibCalls: parseFloat(ibCalls),
          ib_OTDB: parseFloat(ib_OTDB),
          ob_OTDB: parseFloat(ob_OTDB),
          obNotConnectedCalls: parseFloat(filteredObNotConnectedCalls),
          obConnectedCalls: parseFloat(filteredObConnectedCalls),
          obCalls: getSum([
            filteredObNotConnectedCalls,
            filteredObConnectedCalls,
          ]),
          connectedOtdb: parseFloat(connectedOtdb),
          ibApptsCreated: parseFloat(ibApptsCreated),
          obConnectedApptsCreated: parseFloat(obConnectedApptsCreated),
          obNotConnectedApptsCreated: parseFloat(obNotConnectedApptsCreated),
          obApptsCreated: getSum([
            obConnectedApptsCreated,
            obNotConnectedApptsCreated,
          ]),
          ibClosingRatio: getPercentage(
            parseFloat(ibApptsCreated),
            parseFloat(ib_OTDB)
          ),
          obClosingRatio: getPercentage(
            getSum([obConnectedApptsCreated, obNotConnectedApptsCreated]),
            parseFloat(connectedOtdb)
          ),
          totalCalls: totalCalls,
          totalApptsCreated: totalApptsCreated,
          totalClosingRatio: totalClosingRatio,
        }
      }
    }

    //

    function getKpiCountPropName(kpiName) {
      return `${kpiName} Count`
    }
    function getLastIndexOfKpi(storeName, kpiName) {
      for (let i = manualKpisPerAgent.length - 1; i >= 0; i--) {
        if (
          manualKpisPerAgent[i].storeName === storeName &&
          manualKpisPerAgent[i].manualKpiName === kpiName
        ) {
          return i
        }
      }
      return -1
    }
    for (let i = 0; i < combinedManualKpis.length; i++) {
      const kpiObj = combinedManualKpis[i]
      const { manualKpiName, value, storeName, user_id, unit, creationDate } =
        kpiObj
      const kpiCountPropName = getKpiCountPropName(manualKpiName)
      const currentCount = tableRows[storeName]?.[kpiCountPropName] || 0
      const updatedCount = currentCount + 1
      const isAvgBehaviour = isAvgBehaviourManualKpi(manualKpiName)
      const lastKpiOfSameNameIndex = getLastIndexOfKpi(storeName, manualKpiName)
      const isLastKpiOfSameName = lastKpiOfSameNameIndex === i

      const isNumber = unit === "Number"
      const isTime = unit === "Minutes"
      const isPercent = unit === "Percentage"
      const parsedValue = isTime
        ? value
        : isPercent
        ? `${value}%`
        : parseFloat(value)

      if (tableRows[storeName]) {
        let updatedValue
        if (!!tableRows[storeName][manualKpiName]) {
          updatedValue = getGenericSum([
            value,
            tableRows[storeName][manualKpiName],
          ])
        } else {
          updatedValue = parsedValue
        }

        if (isAvgBehaviour && isLastKpiOfSameName) {
          updatedValue = calcAvg(updatedValue, updatedCount)
        }

        tableRows[storeName] = {
          ...tableRows[storeName],
          [manualKpiName]: updatedValue,
          ...(isAvgBehaviour && {
            [kpiCountPropName]: updatedCount,
          }),
        }
      } else {
        tableRows[storeName] = {
          storeName,
          [manualKpiName]: parsedValue,
          ...(isAvgBehaviour && {
            [kpiCountPropName]: updatedCount,
          }),
        }
      }
    }

    tableRows = Object.values(tableRows).map(row => {
      const showsRatio = getPercentage(row[SHOWS_KPI], row[SCHEDULED_APPTS_KPI])
      const abandonedRatio = getPercentage(
        row[ABANDONED_CALLS_KPI],
        row.ibCalls
      )

      return {
        ...row,
        showsRatio,
        abandonedRatio,
      }
    })

    let avgRow = {
      storeName: "AVG/Day",
    }
    let totalRow = {
      storeName:
        validation.values.selectedDateOption.value ===
        datesOptions.monthToDate.value
          ? "MTD Total"
          : "Total",
    }
    for (const column of tableColumns) {
      if (column.field === "storeName") {
        continue
      }
      if (
        [
          "obClosingRatio",
          "ibClosingRatio",
          "totalClosingRatio",
          "showsRatio",
          "abandonedRatio",
        ].includes(column.field)
      ) {
        const avgRatio = getAverageOfRatioColumn({
          columnName: column.field,
          tableRows,
        })
        const totalRatio = getTotalOfRatioColumn({
          columnName: column.field,
          tableRows,
        })

        totalRow[column.field] = totalRatio
        avgRow[column.field] = avgRatio
      } else {
        const avg = getGenericAvg(
          tableRows.map(row => row[column.field]).filter(el => !!parseFloat(el))
        )
        avgRow[column.field] = avg

        const isAvgBehaviourKpi = isAvgBehaviourManualKpi(column.field)

        totalRow[column.field] = isAvgBehaviourKpi
          ? avg
          : getGenericSum(tableRows.map(row => row[column.field]))
      }
    }

    // disables the total for some columns
    for (const key of Object.keys(totalRow)) {
      if (
        [
          "obClosingRatio",
          "ibClosingRatio",
          "totalClosingRatio",
          "showsRatio",
          "abandonedRatio",
          CALL_PERFORMANCE_SCORE_KPI,
          AVG_HANDLING_TIME_KPI,
        ].includes(key)
      ) {
        totalRow[key] = "-"
      }
    }

    const pinnedRows = [
      ...(![datesOptions.today.value, datesOptions.yesterday.value].includes(
        validation.values.selectedDateOption.value
      )
        ? [avgRow]
        : []),
      totalRow,
    ]
    setPinnedTopRows(pinnedRows)
    setRows(tableRows)
    setColumns(tableColumns)
  }, [rollupReport])

  const onBtnExport = useCallback(() => {
    const csvParams = {
      fileName: "Rollup Report",
    }

    gridRef.current.api.exportDataAsCsv(csvParams)
  }, [gridRef])

  function refreshDatePicker() {
    setRangePickerKey(oldVal => oldVal + 1)
  }

  const isCustomDate = isCustomDateOption(
    validation.values.selectedDateOption?.value
  )
  function handleCustomRangeChange(dateStrings) {
    if (dateStrings.some(el => !el)) {
      return
    }

    const [startStr, endStr] = dateStrings || []
    validation.setFieldValue("startDate", startStr)
    validation.setFieldValue("endDate", endStr)

    const startStrTitle = startStr
      ? tzMoment(startStr).format("MM/DD/YYYY")
      : ""
    const endStrTitle = endStr ? tzMoment(endStr).format("MM/DD/YYYY") : ""
    const customAbbr =
      startStrTitle && endStrTitle ? `${startStrTitle} - ${endStrTitle}` : ""
    validation.setFieldValue("selectedDateOption", {
      ...validation.values.selectedDateOption,
      customAbbreviation: customAbbr,
    })
  }

  return (
    <PageContainer>
      <div className="d-flex justify-content-between mb-4">
        <h1 className="page-title">Rollup Report</h1>
      </div>
      <div>
        {/* -15px margin to blend in the 2 sections */}
        <Form
          className="content-container"
          style={{ marginBottom: "-15px" }}
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div
            className="row pb-4"
            style={{ background: "#fff", rowGap: "20px" }}
          >
            <div className="col-lg-3 col-12">
              <ScopeSelector
                validation={validation}
                onChange={ids => {
                  validation.setFieldValue("scope", ids)
                }}
              />
            </div>
            <div className="col-lg-4 col-12">
              <SelectField
                label="Date"
                name="selectedDateOption"
                validation={validation}
                required
                options={customDateSelectFieldOptions}
                onChange={option => {
                  validation.setFieldValue("selectedDateOption", option)
                  if (isCustomDateOption(option.value)) {
                    validation.setFieldValue("startDate", "")
                    validation.setFieldValue("endDate", "")
                    refreshDatePicker()
                  }
                }}
              />
            </div>
            {isCustomDate && !validation.values.startDate && (
              <div key={rangePickerKey} className="">
                <CustomRangePicker onChangeHandler={handleCustomRangeChange} />
              </div>
            )}
            {/* <div className="col-lg-2 col-12" style={{ marginTop: "28px" }}>
              <Button color="primary" style={{ height: "36px" }} type="submit">
                Generate
              </Button>
            </div> */}
            <div
              // className="col-lg-1 col-12 d-flex gap-2"
              className="col d-flex align-items-start justify-content-between gap-2"
              style={{ marginTop: "28px" }}
            >
              <Button
                color="primary"
                style={{ height: "36px", marginRight: 20 }}
                type="submit"
              >
                Generate
              </Button>
              {!!columns.length && (
                <>
                  <div>
                    <CsvIcon onClick={onBtnExport} />
                  </div>
                </>
              )}
            </div>
          </div>
        </Form>
        {loadingRollupReport ? (
          <div className="w-full d-flex justify-content-center align-items-center pt-4">
            <Spinner />
          </div>
        ) : (
          <>
            {permissions?.[moduleName]?.VIEW &&
            validation.isValid &&
            !validation.isValidating &&
            columns.length ? (
              <AgGridTable
                gridRef={gridRef}
                rowState={[rows, setRows]}
                columns={columns}
                pinnedTopRowData={pinnedTopRows}
              />
            ) : null}
          </>
        )}
      </div>
    </PageContainer>
  )
}

export default RollupReport

const ratiosMap = {
  obClosingRatio: {
    numerators: ["obConnectedApptsCreated", "obNotConnectedApptsCreated"],
    denominators: ["connectedOtdb"],
  },
  ibClosingRatio: {
    numerators: ["ibApptsCreated"],
    denominators: ["ib_OTDB"],
  },
  totalClosingRatio: {
    numerators: [
      "ibApptsCreated",
      "obConnectedApptsCreated",
      "obNotConnectedApptsCreated",
    ],
    denominators: ["ib_OTDB", "connectedOtdb"],
  },
  showsRatio: {
    numerators: [SHOWS_KPI],
    denominators: [SCHEDULED_APPTS_KPI],
  },
  abandonedRatio: {
    numerators: [ABANDONED_CALLS_KPI],
    denominators: ["ibCalls"],
  },
}

function getAverageOfRatioColumn({ columnName = "", tableRows = [] }) {
  const { numerators, denominators } = ratiosMap[columnName]
  if (!numerators || !denominators) return ""

  const avgNumerators = getSum(
    numerators.map(numerator =>
      getAverage(
        tableRows.map(row => parseFloat(row[numerator])).filter(el => !!el)
      )
    )
  )
  const avgDenominators = getSum(
    denominators.map(denominator =>
      getAverage(
        tableRows.map(row => parseFloat(row[denominator])).filter(el => !!el)
      )
    )
  )

  const ratio = getPercentage(avgNumerators, avgDenominators)
  return ratio
}
function getTotalOfRatioColumn({ columnName = "", tableRows = [] }) {
  const { numerators, denominators } = ratiosMap[columnName] || {}
  if (!numerators || !denominators) return ""

  const totalNumerators = getSum(
    numerators.map(numerator =>
      getSum(tableRows.map(row => parseFloat(row[numerator])))
    )
  )
  const totalDenominators = getSum(
    denominators.map(denominator =>
      getSum(tableRows.map(row => parseFloat(row[denominator])))
    )
  )

  const ratio = getPercentage(totalNumerators, totalDenominators)
  return ratio
}
