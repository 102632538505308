import { getWhoami } from "helpers/fakebackend_helper"
import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { permissionsError, setPermissions } from "./actions"
import { FETCH_PERMISSIONS } from "./actionTypes"

function* fetchPermissions() {
  try {
    const response = yield call(getWhoami)

    yield put(setPermissions(response))
  } catch (error) {
    console.log("error", error)
    yield put(permissionsError(error.response?.data?.message))
  }
}

function* PermissionsSaga() {
  yield takeEvery(FETCH_PERMISSIONS, fetchPermissions)
}

export default PermissionsSaga
