import {
  ADD_CALL,
  FETCH_CALLS,
  CALL_ERROR,
  CALL_SUCCESS,
  SET_CALLS,
  UPDATE_CALL,
} from "./actionTypes"

export const addCall = (call, history) => {
  return {
    type: ADD_CALL,
    payload: { call, history },
  }
}

export const fetchCalls = (
  queryParams = {
    storesIds: [],
    startDate: "",
    endDate: "",
  }
) => {
  return {
    type: FETCH_CALLS,
    payload: { queryParams },
  }
}

export const updateCall = (id, call, history) => {
  return {
    type: UPDATE_CALL,
    payload: { id, call, history },
  }
}

export const callSuccess = msg => {
  return {
    type: CALL_SUCCESS,
    payload: { msg },
  }
}

export const callError = error => {
  return {
    type: CALL_ERROR,
    payload: error,
  }
}

export const resetCalls = () => {
  return {
    type: SET_CALLS,
    payload: { calls: [] },
  }
}
export const setCalls = calls => {
  return {
    type: SET_CALLS,
    payload: { calls },
  }
}
