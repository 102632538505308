import {
  faBullseye,
  faCircleCheck,
  faCircleExclamation,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { Alert, Progress } from "reactstrap"
import bullseye from "assets/images/bullseye.png"
import { Tooltip } from "react-tooltip"
import { roundAndAddSignToPercentage } from "helpers/helperFns"

const NewCallPercentCard = ({
  percent,
  percentColor,
  title,
  type,
  target,
  minStandard,
  isLoading,
  icon,
}) => {
  let status = "danger"
  if (target > 0 && minStandard) {
    status =
      percent / target > 0.9
        ? "success"
        : percent > minStandard
        ? "warning"
        : "danger"
  }
  return (
    <div className="bg-white p-3 px-4 m-0">
      {/* <div className="col-8 p-0"> */}
      <div className="d-flex justify-content-between align-items-start">
        <p
          style={{
            fontWeight: 800,
            fontSize: "40px",
            color: "black",
            marginBottom: 0,
            color: percentColor,
          }}
        >
          {roundAndAddSignToPercentage(percent)}
        </p>
        {/* <FontAwesomeIcon fontSize={"50px"} color="#D9D9D9" icon={faHandshake} /> */}
        {icon}
      </div>
      <p className="mb-1">{title}</p>
      <div
        className="d-flex flex-column gap-1 align-items-end"
        style={{ width: "60%" }}
      >
        <Progress
          data-tooltip-id="progress"
          style={{
            backgroundColor: "#d9d9d9",
          }}
          data-tooltip-content={roundAndAddSignToPercentage(percent)}
          className="w-100"
          value={isLoading ? 0 : Math.ceil((percent * 100) / target)}
          color={status}
        />
        <Tooltip id="progress" />
        <span>{roundAndAddSignToPercentage(percent)}</span>
      </div>

      {/* <Alert
            // color={color}
            style={{
              backgroundColor:
                color === "success"
                  ? "#DCFFF3"
                  : color === "warning"
                  ? "#FFEFD3"
                  : "#FFDDDD",
              width: "fit-content",
              margin: "auto",
              marginTop: "30px",
              position: "relative",
              color:
                color === "success"
                  ? "#34C38F"
                  : color === "warning"
                  ? "#F1B44C"
                  : "#C34040",
              border: 0,
            }}
          >
            <FontAwesomeIcon
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                fontSize: "30px",
                transform: "translate(-50%,-50%)",
                color:
                  color === "success"
                    ? "rgb(52, 195, 143)"
                    : color === "warning"
                    ? "#F1B44C"
                    : "#C34040",
              }}
              icon={color === "success" ? faCircleCheck : faCircleExclamation}
            />
            {info}
          </Alert> */}
      {/* </div> */}
      {/* <div className="col-4 p-0">
          <div
            className="rounded px-2 text-center"
            style={{
              position: "relative",
              backgroundColor: "#C8C7C7",
              width: "100%",
              height: "170px",
              marginTop: "20px",
              paddingTop: "30px",
            }}
          >
            <img
              src={bullseye}
              style={{
                position: "absolute",
                top: 0,
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            />
            <p style={{ fontSize: "20px", fontWeight: 600, margin: 0 }}>
              {minPercent}%
            </p>
            <p>Is the Minimum Standard of the IB Closing Ratio</p>
          </div>
        </div> */}
    </div>
  )
}

export default NewCallPercentCard
