import React, { useEffect, useState } from "react"
import EditIcon from "assets/icons/EditIcon"
import DeleteIcon from "assets/icons/DeleteIcon"
import AgGridTable from "components/Common/AgGridTable"
import PageContainer from "components/Common/PageContainer"
import { Button, Spinner } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { axiosApi } from "helpers/api_helper"
import DeleteModal from "components/Common/DeleteModal"
import { POST_GOAL, POST_KPI } from "helpers/url_helper"
import { fetchGoals, fetchKpis } from "store/actions"

const moduleName = "manualKpis"

const Kpis = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  //permissions
  const PermissionsSelector = createSelector(
    state => state.Permissions,
    perm => ({
      permissions: perm.permissions,
    })
  )
  const { permissions } = useSelector(PermissionsSelector)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [currentId, setCurrentId] = useState()
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [deleteError, setDeleteError] = useState()

  const [rows, setRows] = useState([])

  useEffect(() => {
    dispatch(fetchKpis())
  }, [])

  const KpiProperties = createSelector(
    state => state.Kpi,
    kpi => ({
      loading: kpi.loading,
      kpis: kpi.kpis,
    })
  )

  const { loading, kpis } = useSelector(KpiProperties)

  useEffect(() => {
    setRows(
      kpis.map(item => ({
        id: item.manualkpi_id,
        // kpi: item.name,
        storeAgent:
          item.is_perstore && item.is_peragent
            ? "Store & Agent"
            : item.is_perstore
            ? "Store"
            : "Agent",
        unit: item.unit,
        goal: item.goal?.name,
      }))
    )
    console.log("kpis", kpis)
  }, [kpis])

  const columns = [
    // { field: "kpi", filter: true, headerName: "Manual KPI" },
    { field: "goal", filter: true, headerName: "Goal" },
    { field: "storeAgent", filter: true, headerName: "Store/Agent" },
    { field: "unit", filter: true, headerName: "Unit" },

    {
      headerName: "",
      sortable: false,
      cellRenderer: params => {
        return (
          <div className="d-flex align-items-center h-100 gap-2">
            {actions(params.data.id)}
          </div>
        )
      },
    },
  ]

  const actions = id => {
    return (
      <>
        {permissions?.[moduleName]?.EDIT && (
          <div
            onClick={() =>
              navigate("/manual-kpis/" + id, {
                state: kpis?.find(item => item.manualkpi_id === id),
              })
            }
          >
            <EditIcon />
          </div>
        )}
        {permissions?.[moduleName]?.DELETE && (
          <div
            onClick={() => {
              setShowDeleteModal(true)
              setCurrentId(id)
            }}
          >
            <DeleteIcon />
          </div>
        )}
      </>
    )
  }

  const handleDelete = async () => {
    setDeleteLoading(true)
    setDeleteError(null)
    try {
      await axiosApi.delete(POST_KPI + "/" + currentId)
      dispatch(fetchKpis())
      setShowDeleteModal(false)
    } catch (err) {
      setDeleteError(err.response.data?.message)
    } finally {
      setDeleteLoading(false)
    }
  }

  return (
    <PageContainer>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setShowDeleteModal(false)
          setDeleteError(null)
        }}
        errorMsg={deleteError}
        loading={deleteLoading}
      />
      <div className="d-flex justify-content-between mb-4">
        <h1 className="page-title">Manually Added KPIs</h1>
        {permissions?.[moduleName]?.ADD && (
          <Button
            color="primary"
            style={{ height: "36px" }}
            onClick={() => navigate("new")}
          >
            Add Manual KPI
          </Button>
        )}
      </div>
      {loading ? (
        <div className="w-full d-flex justify-content-center align-items-center pt-4">
          <Spinner />
        </div>
      ) : (
        <>
          {permissions?.[moduleName]?.VIEW && (
            <AgGridTable
              rowState={[rows, setRows]}
              columns={columns}
              qiuckFilter={true}
            />
          )}
        </>
      )}
    </PageContainer>
  )
}

export default Kpis
