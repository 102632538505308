import { approximate, getPercentage } from "helpers/helperFns"
import React from "react"

const GOALS_NUMBER = 8
const TableHeaderSection = ({ totalMS, totalTarget, totalActiveAgents }) => {
  let msScore = "0.0%"
  let targetScore = "0.0%"
  if (totalMS && totalActiveAgents) {
    msScore = getPercentage(
      totalMS,
      parseFloat(totalActiveAgents) * GOALS_NUMBER
    )
  }
  if (totalTarget && totalActiveAgents) {
    targetScore = getPercentage(
      totalTarget,
      parseFloat(totalActiveAgents) * GOALS_NUMBER
    )
  }

  return (
    <div className="d-flex gap-4 justify-content-between">
      <h4>MS Score: {msScore}</h4>
      <h4>-</h4>
      <h4>Target Score: {targetScore}</h4>
    </div>
  )
}

export default TableHeaderSection
