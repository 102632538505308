import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import Permissions from "./auth/permissions/reducer"

import Department from "./department/reducer"
import Company from "./company/reducer"
import Store from "./store/reducer"
import Group from "./group/reducer"
import Role from "./role/reducer"
import User from "./user/reducer"
import Disposition from "./disposition/reducer"
import Call from "./call/reducer"
import ServiceAdvisor from "./serviceAdvisor/reducer"
import Agent from "./agent/reducer"
import Target from "./target/reducer"
import Goal from "./goal/reducer"
import GoalEntry from "./goalEntry/reducer"
import Kpi from "./kpi/reducer"
import KpiEntry from "./kpiEntry/reducer"
import EntityReport from "./entityReport/reducer"
import ServiceAdvisorReport from "./serviceAdvisorReport/reducer"
import RollupReport from "./rollupReport/reducer"
import StackRankingReport from "./stackRanking/reducer"
import MSTargetReport from "./msTargetReport/reducer"
import Calendar from "./calendar/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Department,
  Company,
  Store,
  Group,
  Role,
  User,
  Disposition,
  Call,
  ServiceAdvisor,
  Agent,
  Target,
  Permissions,
  Goal,
  GoalEntry,
  Kpi,
  KpiEntry,
  EntityReport,
  ServiceAdvisorReport,
  RollupReport,
  StackRankingReport,
  MSTargetReport,
  Calendar,
})

export default rootReducer
