import PageContainer from "components/Common/PageContainer"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { Alert, Button, Form, Spinner } from "reactstrap"
import * as Yup from "yup"
import InputField from "components/Form/InputField"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import SelectField from "components/Form/SelectField"
import { axiosApi } from "helpers/api_helper"
import {
  addEvent,
  addUser,
  fetchDepartments,
  fetchRoles,
  updateEvent,
  updateUser,
} from "store/actions"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import ScopeSelector from "components/Common/ScopeSelector"
import { isServiceAdvisorRole } from "helpers/helperFns"
import moment from "moment"
import {
  deleteWeekend,
  patchHoliday,
  patchWeekend,
  postHoliday,
  postWeekend,
} from "helpers/fakebackend_helper"
import { weekDays } from "constants/weekDays"
import { isArray } from "lodash"

const holidayTypes = {
  HOLIDAY: "Holiday",
  WEEKEND: "Weekend",
}

const AddEditHoliday = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { id } = useParams()

  const { state: data } = useLocation()

  const [showConfirm, setShowConfirm] = useState(false)
  const [loading, setLoading] = useState(false)

  function isWeekend(type) {
    return type === holidayTypes.WEEKEND
  }

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: data
      ? {
          type: {
            value: !!data.day_name
              ? holidayTypes.WEEKEND
              : holidayTypes.HOLIDAY,
            label: !!data.day_name
              ? holidayTypes.WEEKEND
              : holidayTypes.HOLIDAY,
          },
          day_name: !!data.day_name
            ? {
                value: data.day_name,
                label: data.day_name,
              }
            : undefined,
          from: data.from,
          to: data.to,
          scope: data.scopes?.map(item => `${item.id}`),

          name: data.name || "",
          description: data.description || "",
          weekDays: [],
        }
      : {
          type: {
            value: holidayTypes.HOLIDAY,
            label: holidayTypes.HOLIDAY,
          },
          day_name: undefined,
          weekDays: [],
          from: "",
          to: "",
          scope: [],

          name: "",
          description: "",
        },
    validationSchema: Yup.object({
      type: Yup.object().required("Required"),
      from: Yup.string().required("Required"),
      to: Yup.string().required("Required"),
      scope: Yup.array().min(1, "Scope Is Required"),
      name: Yup.string().when("type", ([type], schema) =>
        !isWeekend(type?.value)
          ? schema.required("Required")
          : schema.optional()
      ),
      day_name: Yup.object().when("type", ([type], schema) =>
        isWeekend(type?.value) && data
          ? schema.required("Please add week day")
          : schema.optional()
      ),
      weekDays: Yup.array().when("type", ([type], schema) =>
        isWeekend(type?.value) && !data
          ? schema.min(1, "Please choose at least one week day")
          : schema.optional()
      ),
    }),
    onSubmit: async values => {
      try {
        setLoading(true)
        window.scrollTo({ top: 0, behavior: "smooth" })

        const from = moment(values.from).format("YYYY-MM-DD")
        const to = moment(values.to).format("YYYY-MM-DD")

        const dataObj = {
          ...(isWeekend(values.type?.value)
            ? {
                ...(data
                  ? {
                      day_name: values.day_name.value,
                    }
                  : {
                      days: values.weekDays.map(option => option.value),
                    }),
              }
            : {
                name: values.name,
                description: values.description,
              }),
          from,
          to,
          ids: values.scope,
        }
        if (data?.id) {
          if (isWeekend(values.type?.value)) {
            // update weekend
            await patchWeekend(data.id, dataObj)
          } else {
            await patchHoliday(data.id, dataObj)
          }
        } else {
          if (isWeekend(values.type?.value)) {
            // save new weekend
            await postWeekend(dataObj)
          } else {
            await postHoliday(dataObj)
          }
        }
        toast.success("Saved Successfully!")
        navigate("/holidays-management")
      } catch (err) {
        console.log(err)
        const errMsg = isArray(err.response?.data?.message)
          ? err.response?.data?.message?.join(", ")
          : err.response?.data?.message

        toast.error(errMsg)
      } finally {
        setLoading(false)
      }
    },
  })

  const isWeekEnd = isWeekend(validation.values.type?.value)

  const handleCancel = () => {
    if (validation.dirty) {
      window.scrollTo({ top: 0, behavior: "smooth" })
      setShowConfirm(true)
    } else {
      navigate(-1)
    }
  }

  return (
    <PageContainer>
      <h1 className="page-title mb-4">
        {data ? "Edit" : "Add"} Holiday/Weekend
      </h1>
      <div className="content-container">
        {showConfirm && (
          <Alert color="danger" style={{ marginTop: "13px" }}>
            <div>
              <p>Are you sure you want to cancel? Your inputs will be lost.</p>
              <div className="d-flex">
                <Button
                  type="button"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() => navigate(-1)}
                >
                  Yes
                </Button>
                <Button type="button" onClick={() => setShowConfirm(false)}>
                  No
                </Button>
              </div>
            </div>
          </Alert>
        )}

        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="container">
            <div className="row mb-4">
              <div className="col-lg-6 col-12">
                <SelectField
                  label={"Type"}
                  required
                  name="type"
                  validation={validation}
                  options={Object.values(holidayTypes).map(item => ({
                    value: item,
                    label: item,
                  }))}
                  disabled={!!data}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12  mb-4">
                {!isWeekEnd ? (
                  <InputField
                    name="name"
                    validation={validation}
                    placeholder="Name"
                    label={"Name"}
                    required
                  />
                ) : data ? (
                  <SelectField
                    label={"Week Day"}
                    required
                    name="day_name"
                    validation={validation}
                    options={Object.values(weekDays).map(item => ({
                      value: item.name,
                      label: item.name,
                    }))}
                  />
                ) : (
                  <SelectField
                    label={"Week Days"}
                    required
                    name="weekDays"
                    validation={validation}
                    isMulti
                    options={Object.values(weekDays).map(item => ({
                      value: item.name,
                      label: item.name,
                    }))}
                  />
                )}
              </div>
              <div className="col-lg-6 col-12">
                <div className="row"></div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="row">
                  <div className="col-lg-6 col-12 mb-4">
                    <InputField
                      name="from"
                      validation={validation}
                      placeholder=""
                      label={"From"}
                      type="date"
                      required
                    />
                  </div>
                  <div className="col-lg-6 col-12 mb-4">
                    <InputField
                      name="to"
                      validation={validation}
                      placeholder=""
                      label={"To"}
                      type="date"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-12 mb-4">
                <ScopeSelector
                  validation={validation}
                  onChange={ids => {
                    validation.setFieldValue("scope", ids)
                  }}
                  selectedScopes={validation.values.scope}
                  hasInitialValue={true}
                />
              </div>
            </div>
            {!isWeekEnd ? (
              <div className="row">
                <div className="col-lg-6 col-12 mb-4">
                  <InputField
                    name="description"
                    validation={validation}
                    placeholder="Description"
                    label={"Description"}
                    type="textarea"
                    style={{ height: "130px" }}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div className="mt-5 d-flex justify-content-center justify-content-lg-start">
            <Button
              color="primary"
              style={{
                marginRight: "20px",
                width: "110px",
              }}
              type="submit"
            >
              {loading ? <Spinner size="sm" /> : id ? "Save" : "Invite"}
            </Button>
            <Button type="button" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </PageContainer>
  )
}

export default AddEditHoliday
