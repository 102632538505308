import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_AGENT, FETCH_AGENTS } from "./actionTypes"
import { editAgentSuccess, agentError, setAgents } from "./actions"

//Include Both Helper File with needed methods
import { getAgents, putAgent } from "../../helpers/fakebackend_helper"

function* editAgent({ payload: { id, agent, history } }) {
  try {
    console.log("new saga", agent)
    const response = yield call(putAgent, id, agent)
    console.log("agent edit resp", response)
    yield put(editAgentSuccess(response, "Created Successfully"))
  } catch (error) {
    console.log("error", error)
    yield put(agentError(error.response?.data?.message))
  }
}

function* fetchAgents() {
  try {
    console.log("saga get agents")
    const response = yield call(getAgents)
    yield put(setAgents(response.data))
  } catch (error) {
    console.log("error", error)
  }
}

function* agentSaga() {
  yield takeEvery(EDIT_AGENT, editAgent)
  yield takeEvery(FETCH_AGENTS, fetchAgents)
}

export default agentSaga
