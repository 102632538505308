import { isAgentRole } from "helpers/helperFns"
import React from "react"
import { Navigate, useLocation, useParams } from "react-router-dom"

const Authmiddleware = props => {
  const location = useLocation()

  const authUser = localStorage.getItem("authUser")
  const parsedUser = authUser ? JSON.parse(authUser) : undefined
  const isAgent = isAgentRole(parsedUser?.roles?.[0]?.name)
  const isManagerDashboardPath = location.pathname === "/manager-dashboard"
  const isAgentDashboardPath = location.pathname === "/agent-dashboard"

  if (!authUser) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    )
  }

  if (isAgent && isManagerDashboardPath) {
    return (
      <Navigate
        to={{ pathname: "/agent-dashboard", state: { from: props.location } }}
      />
    )
  }
  if (!isAgent && isAgentDashboardPath) {
    return (
      <Navigate
        to={{ pathname: "/manager-dashboard", state: { from: props.location } }}
      />
    )
  }

  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
