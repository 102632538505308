import {
  ADD_SERVICE_ADVISOR,
  FETCH_SERVICE_ADVISORS,
  SERVICE_ADVISOR_ERROR,
  SERVICE_ADVISOR_SUCCESS,
  SET_SERVICE_ADVISORS,
  UPDATE_SERVICE_ADVISOR,
} from "./actionTypes"

export const addServiceAdvisor = (serviceAdvisor, history) => {
  return {
    type: ADD_SERVICE_ADVISOR,
    payload: { serviceAdvisor, history },
  }
}

export const updateServiceAdvisor = (id, serviceAdvisor, history) => {
  return {
    type: UPDATE_SERVICE_ADVISOR,
    payload: { id, serviceAdvisor, history },
  }
}

export const fetchServiceAdvisors = () => {
  return {
    type: FETCH_SERVICE_ADVISORS,
  }
}

export const serviceAdvisorSuccess = msg => {
  return {
    type: SERVICE_ADVISOR_SUCCESS,
    payload: { msg },
  }
}

export const serviceAdvisorError = error => {
  return {
    type: SERVICE_ADVISOR_ERROR,
    payload: error,
  }
}

export const setServiceAdvisors = serviceAdvisors => {
  return {
    type: SET_SERVICE_ADVISORS,
    payload: { serviceAdvisors },
  }
}
