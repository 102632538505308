import {
  ADD_ROLE,
  FETCH_ADDABLE_ROLES,
  FETCH_ROLES,
  ROLE_ERROR,
  ROLE_SUCCESS,
  SET_ROLES,
  UPDATE_ROLE,
} from "./actionTypes"

const initialState = {
  errorMsg: "",
  successMsg: "",
  loading: false,
  roles: [],
}

const role = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ROLE:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break

    case UPDATE_ROLE:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case FETCH_ROLES:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        roles: [],
        loading: true,
      }
      break
    case FETCH_ADDABLE_ROLES:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        roles: [],
        loading: true,
      }
      break

    case SET_ROLES:
      state = {
        ...state,
        loading: false,
        roles: action.payload.roles,
      }
      break
    case ROLE_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload.msg,
        loading: false,
      }
      break
    case ROLE_ERROR:
      state = { ...state, errorMsg: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default role
