import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeLogin,
  postJwtLogin,
  postLogin,
  postLogout,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper"
import { axiosApi } from "helpers/api_helper"
import { mainRedirect } from "helpers/helperFns"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, rememberMe, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postLogin, {
        email: user.email,
        password: user.password,
      })

      localStorage.setItem("authUser", JSON.stringify(response))
      axiosApi.get("/api/v1/auth/whoami").then(res => {
        const role =
          res.data.roles?.[0].permissions?.calls?.OWNINPUT === false
            ? "Admin"
            : "Agent"
        console.log("role", role)
        localStorage.setItem("role", role)
      })
      if (rememberMe) localStorage.setItem("rememberMe", "true")
      else localStorage.removeItem("rememberMe")
      yield put(loginSuccess(response))
    }

    mainRedirect(history)
  } catch (error) {
    yield put(apiError(error.response?.data?.message))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //   const response = yield call(fireBaseBackend.logout)
    //   yield put(logoutUserSuccess(response))
    // }
    // const response = yield call(postLogout)
    // yield put(logoutUserSuccess(response))
    history("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { type, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, type)
      if (response) {
        mainRedirect(history)
      } else {
        history("/login")
      }
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    if (response) mainRedirect(history)
  } catch (error) {
    console.log("error", error)
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
