import React, { useEffect, useState } from "react"
import PageContainer from "components/Common/PageContainer"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { Button, Form, Spinner } from "reactstrap"
import ScopeSelector from "components/Common/ScopeSelector"
import SelectField from "components/Form/SelectField"
import AgGridTable from "components/Common/AgGridTable"
import { datesOptions, datesSelectFieldOptions } from "constants/datesOptions"
import useChecklistReport from "./hooks/useChecklistReport"
import StoreTable from "./StoreTable"
import ServiceAdvisorTable from "./ServiceAdvisorTable"
import ManualKpiTable from "./ManualKpiTable"

const moduleName = "entityReport"

const ChecklistReport = () => {
  //permissions
  const PermissionsSelector = createSelector(
    state => state.Permissions,
    perm => ({
      permissions: perm.permissions,
    })
  )
  const { permissions } = useSelector(PermissionsSelector)

  const {
    validation,
    loadingReport,
    columns,
    rows,
    setRows,
    pinnedBottomRows,
    pinnedTopRows,
    totalMS,
    totalTarget,
    totalActiveAgents,

    checklistReport,
    loadingChecklistReport,
  } = useChecklistReport({})
  return (
    <PageContainer>
      <div className="d-flex justify-content-between mb-4">
        <h1 className="page-title">Daily Checklist Report</h1>
      </div>
      <div>
        {/* -15px margin to blend in the 2 sections */}
        <Form
          className="content-container"
          style={{ marginBottom: "-15px" }}
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div
            className="row pb-4"
            style={{ background: "#fff", rowGap: "20px" }}
          >
            <div className="col-lg-3 col-12">
              <ScopeSelector
                validation={validation}
                onChange={ids => {
                  validation.setFieldValue("scope", ids)
                }}
              />
            </div>
            <div className="col-lg-4 col-12">
              <SelectField
                label="Date"
                name="selectedDateOption"
                validation={validation}
                required
                options={datesSelectFieldOptions}
              />
            </div>
            <div className="col-lg-2 col-12" style={{ marginTop: "28px" }}>
              <Button color="primary" style={{ height: "36px" }} type="submit">
                Generate
              </Button>
            </div>
          </div>
        </Form>
        {loadingChecklistReport ? (
          <div className="w-full d-flex justify-content-center align-items-center pt-4">
            <Spinner />
          </div>
        ) : (
          <div>
            {permissions?.[moduleName]?.VIEW &&
            validation.isValid &&
            !validation.isValidating ? (
              <>
                {!!checklistReport?.rollupByStoreTableData && (
                  <div>
                    <StoreTable
                      rollupByStoreTableData={
                        checklistReport.rollupByStoreTableData
                      }
                    />
                  </div>
                )}
                <div className="d-flex gap-2">
                  {/* <div className="" style={{ flex: 1 }}>
                  {checklistReport?.rollupByStoreTableData?.map(storeRollup => {
                    const { tableColumns, tableRows, storeName } = storeRollup
                    return (
                      <StoreTable key={storeName} storeRollup={storeRollup} />
                    )
                  })}
                </div> */}
                  <div className="d-flex gap-1 flex-wrap" style={{ flex: 1 }}>
                    {checklistReport?.manualKpisTableData?.map((kpiData, i) => {
                      const { tableColumns, tableRows, manualKpiName } = kpiData
                      return (
                        <div key={manualKpiName} style={{ width: "48%" }}>
                          <ManualKpiTable kpiData={kpiData} />
                        </div>
                      )
                    })}
                    {checklistReport?.saByStoreTableData?.map(
                      (tableData, i) => {
                        const { tableColumns, tableRows, storeName } = tableData
                        return (
                          <div key={storeName} style={{ width: "48%" }}>
                            <ServiceAdvisorTable tableData={tableData} />
                          </div>
                        )
                      }
                    )}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        )}
      </div>
    </PageContainer>
  )
}

export default ChecklistReport
