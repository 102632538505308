import React from "react"
import { Container } from "reactstrap"

const PageContainer = ({ bgColor, children }) => {
  return (
    <div
      className="page-content mb-4"
      style={bgColor ? { backgroundColor: bgColor } : {}}
    >
      <Container fluid>{children}</Container>
    </div>
  )
}

export default PageContainer
