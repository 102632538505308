import {
  FETCH_PERMISSIONS,
  PERMISSIONS_ERROR,
  SET_PERMISSIONS,
} from "./actionTypes"

const initialState = {
  error: "",
  success: "",
  loading: true,
  permissions: {},
  user: undefined,
}

const permissions = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PERMISSIONS:
      state = {
        error: "",
        success: "",
        loading: true,
        permissions: {},
        user: undefined,
      }
      break
    case SET_PERMISSIONS:
      state = {
        error: "",
        success: "permissions loaded successfully",
        loading: false,
        permissions: action.payload.permissions,
        user: action.payload.user,
      }
      break

    case PERMISSIONS_ERROR:
      state = {
        error: action.payload.error,
        success: "",
        loading: false,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default permissions
