import axios, { Axios } from "axios"
import { del, get, patch, post, put } from "./api_helper"
import * as url from "./url_helper"
import {
  combineArrayToString,
  mapArrayToQueryParam,
  mapArrayToUrlParam,
} from "./helperFns"
import { tzMoment } from "constants/datesOptions"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// auth Method
const postLogin = data => post(url.POST_LOGIN, data)
const postLogout = data => post(url.POST_LOGOUT, data)

// postForgetPwd
const postForgetPwd = data => post(url.POST_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

//Company
export const postCompany = data => post(url.POST_COMPANY, data)
export const getCompanies = () => get(url.GET_COMPANIES)
export const putCompany = (id, data) => put(url.POST_COMPANY + "/" + id, data)

//Department
export const getDepartments = () => get(url.GET_DEPARTMENTS)
export const postDepartment = data => post(url.POST_DEPARTMENT, data)
export const putDepartment = (id, data) =>
  put(url.POST_DEPARTMENT + "/" + id, data)

//Store
export const postStore = data => post(url.POST_STORE, data)
export const getStores = () => get(url.GET_STORES)
export const putStore = (id, data) => put(url.POST_STORE + "/" + id, data)

//GROUP
export const postGroup = data => post(url.POST_GROUP, data)
export const getGroups = () => get(url.GET_GROUPS)
export const putGroup = (id, data) => put(url.POST_GROUP + "/" + id, data)

//ROLE
export const postRole = data => post(url.POST_ROLE, data)
export const getRoles = () => get(url.GET_ROLES)
export const getAddableRoles = () => get(url.GET_ADDABLE_ROLES)
export const putRole = (id, data) => put(url.POST_ROLE + "/" + id, data)

//USER
export const postUser = data => post(url.POST_USER, data)
export const getUsers = searchValue =>
  get(
    `${url.GET_USERS}${
      searchValue ? `&searchField=roles.name&searchValue=${searchValue}` : ""
    }`
  )
export const putUser = (id, data) => put(url.PUT_USER + id, data)

//DISPOSITION
export const postDisposition = data => post(url.POST_DISPOSITION, data)
export const getDispositions = () => get(url.GET_DISPOSITIONS)
export const patchDisposition = (id, data) =>
  patch(url.POST_DISPOSITION + "/" + id, data)

//CALL
export const postCall = data => post(url.POST_CALL, data)
export const getCalls = async (
  queryParams = {
    storesIds: [],
    startDate: "",
    endDate: "",
  }
) => {
  const { storesIds, startDate, endDate } = queryParams
  const idsParam = mapArrayToQueryParam("ids", storesIds)
  const startDateParam = startDate ? `startDate=${startDate}` : ""
  const endDateParam = endDate ? `endDate=${endDate}` : ""

  const fullEndpoint = `${url.GET_CALLS}?orderBy=call.createdAt&${idsParam}&${startDateParam}&${endDateParam}`

  const calls = await get(fullEndpoint)

  return calls
}
export const getCall = id => get(url.GET_CALL + id)
export const patchCall = (id, data) => patch(url.UPDATE_CALL + id, data)

//SERVICE ADVISOR
export const postServiceAdvisor = data => post(url.POST_SERRVICE_ADVISOR, data)
export const getServiceAdvisors = () => get(url.GET_SERRVICE_ADVISORS)
export const putServiceAdvisor = (id, data) =>
  put(url.POST_SERRVICE_ADVISOR + "/" + id, data)

//AGENTS KMS
export const putAgent = (id, data) => put(url.PUT_AGENT + id, data)
export const getAgents = () => get(url.GET_AGENTS)

//TARGET
export const postTarget = data => post(url.POST_TARGET, data)
export const getTargets = () => get(url.GET_TARGETS)

//WHOAMI
export const getWhoami = () => get(url.GET_WHOAMI)

//GOAL
export const postGoal = data => post(url.POST_GOAL, data)
export const getGoals = () => get(url.GET_GOALS)
export const putGoal = (id, data) => put(url.POST_GOAL + "/" + id, data)

//GOAL ENTRY
export const postGoalEntry = data => post(url.POST_GOAL_ENTRY, data)
export const getGoalsEntries = () => get(url.GET_GOALS_ENTRIES)
export const putGoalEntry = (id, data) =>
  put(url.POST_GOAL_ENTRY + "/" + id, data)

//KPI
export const postKpi = data => post(url.POST_KPI, data)
export const getKpis = () => get(url.GET_KPIS)
export const patchKpi = (id, data) => patch(url.POST_KPI + "/" + id, data)

//KPI ENTRY
export const postKpiEntry = data => post(url.POST_KPI_ENTRY, data)
export const getKpisEntries = () => get(url.GET_KPIS_ENTRIES)
export const patchKpiEntry = (id, data) =>
  patch(url.POST_KPI_ENTRY + "/" + id, data)

export const getManualKpisPerAgent = async (
  queryParams = { storesIds: [], startDate: "", endDate: "" }
) => {
  const { storesIds, startDate, endDate } = queryParams

  const formattedStartDate = startDate
    ? tzMoment(startDate).format("YYYY-MM-DD")
    : undefined
  const formattedEndDate = endDate
    ? tzMoment(endDate).format("YYYY-MM-DD")
    : undefined
  const idsParam = mapArrayToQueryParam("ids", storesIds)
  const startDateParam = formattedStartDate
    ? `startDate=${formattedStartDate}`
    : ""
  const endDateParam = formattedEndDate ? `endDate=${formattedEndDate}` : ""

  const fullManualKpisEndpoint = `${url.GET_MANUAL_KPIS_PER_AGENT}?${idsParam}&${startDateParam}&${endDateParam}`

  const kpis = await get(fullManualKpisEndpoint)

  return kpis
}
export const getManualKpisPerStore = async (
  queryParams = { storesIds: [], startDate: "", endDate: "" }
) => {
  const { storesIds, startDate, endDate } = queryParams

  const formattedStartDate = startDate
    ? tzMoment(startDate).format("YYYY-MM-DD")
    : undefined
  const formattedEndDate = endDate
    ? tzMoment(endDate).format("YYYY-MM-DD")
    : undefined

  const idsParam = mapArrayToQueryParam("ids", storesIds)
  const startDateParam = startDate ? `startDate=${formattedStartDate}` : ""
  const endDateParam = endDate ? `endDate=${formattedEndDate}` : ""

  const fullManualKpisEndpoint = `${url.GET_MANUAL_KPIS_PER_STORE}?${idsParam}&${startDateParam}&${endDateParam}`

  const kpis = await get(fullManualKpisEndpoint)

  return kpis
}

// -- Reports --
// Entity Reports
export const getEntityReports = async (
  queryParams = {
    ids: [],
    agentIds: [],
    startDate: "",
    endDate: "",
    withAgentsNumber: false,
    withManuallyAddedKpisPerAgent: false,
    withManuallyAddedKpisPerStore: false,

    previousPeriodReportData: {
      isEnabled: false,
      startDate: "",
      endDate: "",
    },
  }
) => {
  const {
    ids,
    agentIds,
    startDate,
    endDate,
    withAgentsNumber = false,
    withManuallyAddedKpisPerAgent = false,
    withManuallyAddedKpisPerStore = false,

    previousPeriodReportData = {
      isEnabled: false,
      startDate: "",
      endDate: "",
    },
  } = queryParams

  const idsParam = ids?.length ? mapArrayToQueryParam("ids", ids) : ""
  const agentsIdsParam = agentIds?.length
    ? mapArrayToQueryParam("agentIds", agentIds)
    : ""
  const startDateParam = startDate ? `startDate=${startDate}` : ""
  const endDateParam = endDate ? `endDate=${endDate}` : ""

  const fullEndpoint = `${url.GET_ENTITY_REPORTS}?${idsParam}&${agentsIdsParam}&${startDateParam}&${endDateParam}`

  const agentsNumberEndpoint = `${url.GET_AGENTS_MADE_CALLS_NUMBER}?${idsParam}&${startDateParam}&${endDateParam}`

  const prevStartDateParam = previousPeriodReportData.startDate
    ? `startDate=${previousPeriodReportData.startDate}`
    : ""
  const prevEndDateParam = previousPeriodReportData.endDate
    ? `endDate=${previousPeriodReportData.endDate}`
    : ""

  const prevPeriodFullEndpoint = `${url.GET_ENTITY_REPORTS}?${idsParam}&${agentsIdsParam}&${prevStartDateParam}&${prevEndDateParam}`

  const [
    entityReport,
    agentsNumber,
    previousPeriodReport,
    manualKpisPerAgent,
    manualKpisPerStore,
  ] = await Promise.all([
    get(fullEndpoint),
    withAgentsNumber ? get(agentsNumberEndpoint) : [],
    previousPeriodReportData.isEnabled
      ? get(prevPeriodFullEndpoint)
      : undefined,
    withManuallyAddedKpisPerAgent
      ? getManualKpisPerAgent({ storesIds: ids, startDate, endDate })
      : [],
    withManuallyAddedKpisPerStore
      ? getManualKpisPerStore({ storesIds: ids, startDate, endDate })
      : [],
  ])

  return {
    entityReport,
    agentsNumber,
    previousPeriodReport,
    manualKpisPerAgent,
    manualKpisPerStore,
  }
}

// Service advisors reports
export const getServiceAdvisorsReports = (
  queryParams = { storesIds: [], startDate: "", endDate: "" }
) => {
  const { storesIds, startDate, endDate } = queryParams
  const idsParam = mapArrayToQueryParam("ids", storesIds)
  const startDateParam = startDate ? `startDate=${startDate}` : ""
  const endDateParam = endDate ? `endDate=${endDate}` : ""

  const fullEndpoint = `${url.GET_SERVICE_ADVISOR_REPORT}?${idsParam}&${startDateParam}&${endDateParam}`

  return get(fullEndpoint)
}

// Rollup Report
export const getRollupReport = async (
  queryParams = {
    storesIds: [],
    startDate: "",
    endDate: "",
    withManuallyAddedKpis: false,
  }
) => {
  const { storesIds, startDate, endDate, withManuallyAddedKpis } = queryParams
  const idsParam = mapArrayToQueryParam("ids", storesIds)
  const startDateParam = startDate ? `startDate=${startDate}` : ""
  const endDateParam = endDate ? `endDate=${endDate}` : ""

  const fullRollupEndpoint = `${url.GET_ROLLUP_REPORT}?${idsParam}&${startDateParam}&${endDateParam}`

  const [rollupReport, manualKpisPerStore = [], manualKpisPerAgent = []] =
    await Promise.all([
      get(fullRollupEndpoint),
      withManuallyAddedKpis
        ? getManualKpisPerStore({ storesIds, startDate, endDate })
        : [],
      withManuallyAddedKpis
        ? getManualKpisPerAgent({ storesIds, startDate, endDate })
        : [],
    ])

  return {
    rollupReport,
    manualKpisPerStore,
    manualKpisPerAgent,
  }
}

export const getStackRankingReport = async (
  queryParams = {
    storesIds: [],
    startDate: "",
    endDate: "",
    withManuallyAddedKpis: false,
  }
) => {
  const { storesIds, startDate, endDate, withManuallyAddedKpis } = queryParams
  const idsParam = mapArrayToQueryParam("ids", storesIds)
  const startDateParam = startDate ? `startDate=${startDate}` : ""
  const endDateParam = endDate ? `endDate=${endDate}` : ""

  const fullStackRankingEndpoint = `${url.GET_STACK_RANKING_REPORT}?${idsParam}&${startDateParam}&${endDateParam}`

  const [stackRankingReport, manualKpisPerAgent = []] = await Promise.all([
    get(fullStackRankingEndpoint),
    withManuallyAddedKpis
      ? getManualKpisPerAgent({ storesIds, startDate, endDate })
      : [],
  ])

  return {
    stackRankingReport,
    manualKpisPerAgent,
  }
}
export const getGoalsByScope = (
  queryParams = { storesIds: [], startDate: "", endDate: "" }
) => {
  const { storesIds, startDate, endDate } = queryParams
  const idsParam = mapArrayToQueryParam("ids", storesIds)
  const startDateParam = startDate ? `startDate=${startDate}` : ""
  const endDateParam = endDate ? `endDate=${endDate}` : ""

  const fullEndpoint = `${url.GET_GOALS_BY_SCOPE}?${idsParam}&${startDateParam}&${endDateParam}`

  return get(fullEndpoint)
}

// Service advisors reports
export const getNumberOfAgentsPerStore = (
  queryParams = { storesIds: [], startDate: "", endDate: "" }
) => {
  const { storesIds, startDate, endDate } = queryParams
  const idsParam = mapArrayToQueryParam("ids", storesIds)
  const startDateParam = startDate ? `startDate=${startDate}` : ""
  const endDateParam = endDate ? `endDate=${endDate}` : ""

  const fullEndpoint = `${url.GET_AGENTS_NUMBER_PER_STORE}?${idsParam}&${startDateParam}&${endDateParam}`

  return get(fullEndpoint)
}

const getCurrentDateTimeInLATimezone = () => {
  const fullEndpoint = `https://worldtimeapi.org/api/timezone/America/Los_Angeles`

  return get(fullEndpoint)
}

export const isValidClientSideDate = async () => {
  try {
    const clientCurrentDate = tzMoment().format()
    const { datetime: currentDateInLA } =
      (await getCurrentDateTimeInLATimezone()) || {}

    if (!currentDateInLA) return true

    const diff = Math.abs(
      tzMoment(currentDateInLA).diff(clientCurrentDate, "minute")
    )

    const isSimilarTime = diff < 5

    return isSimilarTime
  } catch (err) {
    console.log(err)
    return true
  }
}

export const postHoliday = data => post(url.POST_HOLIDAY, data)
export const getHolidays = async (
  queryParams = {
    startDate: "",
    endDate: "",
  }
) => {
  const { startDate, endDate } = queryParams
  const startDateParam = startDate ? `startDate=${startDate}` : ""
  const endDateParam = endDate ? `endDate=${endDate}` : ""

  const fullEndpoint = `${url.GET_HOLIDAYS}?orderBy=holiday.createdAt&${startDateParam}&${endDateParam}`

  const holidays = await get(fullEndpoint)

  return holidays
}
export const getHoliday = id => get(url.GET_HOLIDAY(id))
export const patchHoliday = (id, data) => patch(url.UPDATE_HOLIDAY(id), data)
export const deleteHoliday = id => del(url.DELETE_HOLIDAY(id))

export const postWeekend = data => post(url.POST_WEEKEND, data)
export const getWeekends = async (
  queryParams = {
    startDate: "",
    endDate: "",
  }
) => {
  const { startDate, endDate } = queryParams
  const startDateParam = startDate ? `startDate=${startDate}` : ""
  const endDateParam = endDate ? `endDate=${endDate}` : ""

  const fullEndpoint = `${url.GET_WEEKENDS}?orderBy=weekend.createdAt&${startDateParam}&${endDateParam}`

  const weekends = await get(fullEndpoint)

  return weekends
}
export const getWeekend = id => get(url.GET_WEEKEND(id))
export const patchWeekend = (id, data) => patch(url.UPDATE_WEEKEND(id), data)
export const deleteWeekend = id => del(url.DELETE_WEEKEND(id))

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postLogin,
  postLogout,
  postFakeProfile,
  postForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
}
