import CsvIcon from "assets/icons/CsvIcon"
import AgGridTable from "components/Common/AgGridTable"
import React, { useCallback, useEffect, useRef, useState } from "react"

const StoreTable = ({ rollupByStoreTableData }) => {
  const [rows, setRows] = useState([])

  const gridRef = useRef()

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv()
  }, [gridRef])

  useEffect(() => {
    setRows(rollupByStoreTableData.tableRows)
  }, [rollupByStoreTableData])
  return (
    <div>
      <div className="content-container" style={{ marginBottom: "-25px" }}>
        <h4 className="text-center">
          <CsvIcon className="ms-4" onClick={onBtnExport} />
        </h4>
      </div>
      <div>
        <AgGridTable
          gridRef={gridRef}
          gridOptions={{
            autoSizeStrategy: {
              type: "fitGridWidth",
            },
          }}
          height="110px"
          rowState={[rows, setRows]}
          columns={rollupByStoreTableData.tableColumns}
          defaultColDef={{
            suppressMovable: true,
            resizable: false,
            wrapHeaderText: true,
            autoHeaderHeight: true,
          }}
        />
      </div>
    </div>
  )
}

export default StoreTable
