import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import {
  ADD_DEPARTMENT,
  GET_DEPARTMENTS,
  UPDATE_DEPARTMENT,
} from "./actionTypes"
import {
  addDepartmentSuccess,
  departmentError,
  getDepartmentsSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  postDepartment,
  getDepartments,
  putDepartment,
} from "../../helpers/fakebackend_helper"

import { isArray } from "lodash"
function* addDepartment({ payload: { department, history } }) {
  try {
    console.log("saga add dep")
    const response = yield call(postDepartment, department)
    console.log("dep resp", response)
    yield put(addDepartmentSuccess("Created Successfully"))
    history("/departments")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(departmentError(errMsg))
  }
}

function* updateDepartment({ payload: { id, department, history } }) {
  try {
    yield call(putDepartment, id, department)
    yield put(addDepartmentSuccess("Updated Successfully"))
    history("/departments")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(departmentError(errMsg))
  }
}

function* fetchDepartments() {
  try {
    const response = yield call(getDepartments)
    // if (response) {
    //   history("/departments")
    // }
    yield put(getDepartmentsSuccess(response))
  } catch (error) {
    console.log("error", error)
    yield put(departmentError(error.response?.data?.message))
  }
}

function* DepartmentSaga() {
  yield takeEvery(ADD_DEPARTMENT, addDepartment)
  yield takeEvery(GET_DEPARTMENTS, fetchDepartments)
  yield takeEvery(UPDATE_DEPARTMENT, updateDepartment)
}

export default DepartmentSaga
