import React, { useEffect, useMemo, useState } from "react"
import {
  approximate,
  calcAvg,
  getAverage,
  getGenericAvg,
  getGenericSum,
  getPercentage,
  getSum,
  isAvgBehaviourManualKpi,
  isHhMm,
  sortManualKpis,
} from "helpers/helperFns"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { datesOptions, tzMoment } from "constants/datesOptions"
import { format } from "date-fns"
import moment from "moment"

const SHOWS_KPI = "Shows"
const SCHEDULED_APPTS_KPI = "Scheduled Appts"
const ABANDONED_CALLS_KPI = "Abandoned Calls"
const CALL_PERFORMANCE_SCORE_KPI = "Call Performance Score"
const AVG_HANDLING_TIME_KPI = "AVG Handling Time"
// const OB_CONFIRMATION = "OB Confirmation"
// const OB_APPT_CONFIRMATION = "OB Appt Confirmation"

const LAST_COLUMN_FIELD_BEFORE_KPIS = "totalApptsCreated"

function getIBandOBDispositionNames(dispositionName) {
  const obDispositionName = `OB ${dispositionName}`
  const ibDispositionName = `IB ${dispositionName}`
  return {
    obDispositionName,
    ibDispositionName,
  }
}

const useEntityReports = ({
  validation,
  isTeamTotalReport,
  isIndividualSummary,
  isEntityReport,
}) => {
  const [rows, setRows] = useState([])
  const [pinnedTopRows, setPinnedTopRows] = useState([])
  const [columns, setColumns] = useState([])

  const EntityReportsProperties = createSelector(
    state => state.EntityReport,
    entityReport => ({
      loading: entityReport.loading,
      entityReports: entityReport.entityReports,
    })
  )

  const { loading: loadingEntityReports, entityReports } = useSelector(
    EntityReportsProperties
  )

  const entityReport = useMemo(
    () => entityReports?.entityReport || [],
    [entityReports]
  )
  const agentsNumber = useMemo(
    () => entityReports?.agentsNumber || [],
    [entityReports]
  )
  const previousPeriodReport = useMemo(
    () => entityReports?.previousPeriodReport || [],
    [entityReports]
  )

  const manualKpisPerAgent = useMemo(() => {
    const kpis = entityReports?.manualKpisPerAgent || []
    if (isIndividualSummary) {
      return (
        kpis.filter(
          ({ user_id }) => user_id === validation.values.user?.value
        ) || []
      )
    } else if (isTeamTotalReport || isEntityReport) {
      return (
        kpis.filter(({ manualKpiName }) =>
          [
            "Call Performance Score",
            "AVG Handling Time",
            "Abandoned Calls",
            "Scheduled Appts",
            "Shows",
            "AI Appts Created",
          ].includes(manualKpiName)
        ) || []
      )
    } else {
      return kpis
    }
  }, [
    entityReports,
    isIndividualSummary,
    isTeamTotalReport,
    validation.values?.user?.value,
  ])

  const manualKpisPerStore = useMemo(() => {
    const kpis = entityReports?.manualKpisPerStore?.stores || []
    if (isTeamTotalReport || isEntityReport) {
      return (
        kpis.filter(({ manualKpiName }) =>
          [
            "Call Performance Score",
            "AVG Handling Time",
            "Abandoned Calls",
            "Scheduled Appts",
            "Shows",
            "AI Appts Created",
          ].includes(manualKpiName)
        ) || []
      )
    } else {
      return kpis
    }
  }, [entityReports, isTeamTotalReport])

  function valueFormatter(params) {
    if (
      params.value === "" ||
      params.value === undefined ||
      params.value === null
    ) {
      return "0"
    }
  }

  function dateFormatter(params) {
    if (
      params.value === "" ||
      params.value === undefined ||
      params.value === null
    ) {
      return ""
    }

    if (["AVG/Day", "MTD Total", "Total"].includes(params.value)) {
      return params.value
    }

    return moment(params.value, "MM-DD-YYYY").format("dddd M/D")
  }

  useEffect(() => {
    if (!entityReports) {
      setRows([])
      setPinnedTopRows([])
      setColumns([])
      return
    }

    let tableColumns = [
      {
        field: "creationDate",
        filter: false,
        headerName: "Creation Date",
        pinned: "left",
        minWidth: 120,
        valueFormatter: dateFormatter,
      },
      ...(isTeamTotalReport
        ? [
            {
              field: "workingAgents",
              filter: false,
              headerName: "# Of SC Worked",
              valueFormatter,
            },
            {
              field: "avgCallsPerDay",
              filter: false,
              headerName: "AVG Calls p/Agent",
              valueFormatter,
            },
            {
              field: "avgIBCallsPerDay",
              filter: false,
              headerName: "AVG IB Calls p/Agent",
              valueFormatter,
            },
            {
              field: "avgOBCallsPerDay",
              filter: false,
              headerName: "AVG OB Calls p/Agent",
              valueFormatter,
            },
          ]
        : []),
      {
        field: "ibCalls",
        filter: true,
        headerName: "IB Calls",
        valueFormatter,
      },
      {
        field: "ib_OTDB",
        filter: true,
        headerName: "IB OTDB Calls",
        valueFormatter,
      },
      {
        field: "ibApptsCreated",
        filter: true,
        headerName: "IB Appts Created",
        valueFormatter,
      },
      {
        field: "ibClosingRatio",
        filter: true,
        headerName: "IB Closing Ratio",
        valueFormatter,
      },
      {
        field: "obCalls",
        filter: true,
        headerName: "OB Calls",
        valueFormatter,
      },
      {
        field: "obConnectedCalls",
        filter: true,
        headerName: "OB Connected Calls",
        valueFormatter,
      },
      {
        field: "ob_OTDB",
        filter: true,
        headerName: "OB OTDB Calls",
        valueFormatter,
      },
      {
        field: "obApptsCreated",
        filter: true,
        headerName: "OB Appts Created",
        valueFormatter,
      },
      {
        field: "obClosingRatio",
        filter: true,
        headerName: "OB Closing Ratio",
        valueFormatter,
      },

      {
        field: "totalCalls",
        filter: true,
        headerName: "Total Calls",
        valueFormatter,
      },
      {
        field: "totalApptsCreated",
        filter: true,
        headerName: "Total Appts Created",
        valueFormatter,
      },
      // {
      //   field: "totalClosingRatio",
      //   filter: true,
      //   headerName: "Total Closing Ratio",
      //   valueFormatter,
      // },
    ]

    const combinedManualKpis = [...manualKpisPerAgent, ...manualKpisPerStore]
    for (let i = 0; i < combinedManualKpis.length; i++) {
      const kpiObj = combinedManualKpis[i]
      const manualKpiName = kpiObj.manualKpiName

      if (!tableColumns.find(({ field }) => manualKpiName === field)) {
        tableColumns.push({
          field: manualKpiName,
          headerName: manualKpiName,
          filter: true,
          valueFormatter,
        })
      }
    }
    tableColumns.push({
      field: "showsRatio",
      headerName: "Shows Ratio",
      filter: true,
      valueFormatter,
    })
    if (!isIndividualSummary) {
      tableColumns.push({
        field: "abandonedRatio",
        headerName: "Abandoned Ratio",
        filter: true,
        valueFormatter,
      })
    }

    for (let i = 0; i < entityReport.length; i++) {
      const reportObj = entityReport[i]
      const dispositionName = reportObj.dispositionName
      const { obDispositionName, ibDispositionName } =
        getIBandOBDispositionNames(dispositionName)

      if (
        !tableColumns.find(({ field }) =>
          [obDispositionName, ibDispositionName].includes(field)
        )
      ) {
        const hasOBValue = entityReport.some(reportObj => {
          const sameName = reportObj.dispositionName === dispositionName
          const hasObValue =
            getSum([
              reportObj.obConnectedCalls,
              reportObj.obNotConnectedCalls,
            ]) > 0
          return sameName && hasObValue
        })
        const hasIBValue = entityReport.some(reportObj => {
          const sameName = reportObj.dispositionName === dispositionName
          const hasIbValue = parseFloat(reportObj.ibCalls) > 0
          return sameName && hasIbValue
        })

        hasIBValue &&
          tableColumns.push({
            field: ibDispositionName,
            headerName: ibDispositionName,
            filter: true,
            valueFormatter,
          })
        hasOBValue &&
          tableColumns.push({
            field: obDispositionName,
            headerName: obDispositionName,
            filter: true,
            valueFormatter,
          })
      }
    }

    //--------------------------------------------------------------------------------------
    //--------------------------------------------------------------------------------------
    //--------------------------------------------------------------------------------------

    const tableRows = generateReportRows(
      entityReport,
      agentsNumber,
      manualKpisPerAgent,
      manualKpisPerStore
    )

    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------

    let avgRow = {
      creationDate: "AVG/Day",
    }
    let totalRow = {
      creationDate:
        validation.values.selectedDateOption.value ===
        datesOptions.monthToDate.value
          ? "MTD Total"
          : "Total",
    }
    for (const column of tableColumns) {
      if (column.field === "creationDate") {
        continue
      }

      if (
        [
          "obClosingRatio",
          "ibClosingRatio",
          "totalClosingRatio",
          "showsRatio",
          "abandonedRatio",
        ].includes(column.field)
      ) {
        const avgRatio = getAverageOfRatioColumn({
          columnName: column.field,
          tableRows,
        })
        const totalRatio = getTotalOfRatioColumn({
          columnName: column.field,
          tableRows,
        })

        totalRow[column.field] = totalRatio
        avgRow[column.field] = avgRatio
      } else {
        // if (column.field === "AVG Handling Time") {
        //   console.log({
        //     x: getGenericAvg(tableRows.map(row => row[column.field])),
        //     y: getGenericSum(tableRows.map(row => row[column.field])),
        //     z: tableRows.map(row => row[column.field]),
        //   })
        // }
        const avg = getGenericAvg(
          tableRows.map(row => row[column.field]).filter(el => !!parseFloat(el))
        )
        avgRow[column.field] = avg

        const isAvgBehaviourKpi = isAvgBehaviourManualKpi(column.field)

        totalRow[column.field] = isAvgBehaviourKpi
          ? avg
          : getGenericSum(tableRows.map(row => row[column.field]))
      }
    }

    // disables the total for some columns
    for (const key of Object.keys(totalRow)) {
      if (
        [
          "obClosingRatio",
          "ibClosingRatio",
          "totalClosingRatio",
          "showsRatio",
          "abandonedRatio",

          //kpis
          CALL_PERFORMANCE_SCORE_KPI,
          AVG_HANDLING_TIME_KPI,

          // additional team total cols
          "avgCallsPerDay",
          "avgIBCallsPerDay",
          "avgOBCallsPerDay",
        ].includes(key)
      ) {
        totalRow[key] = "-"
      }
    }
    const pinnedRows = [
      ...(![datesOptions.today.value, datesOptions.yesterday.value].includes(
        validation.values.selectedDateOption.value
      )
        ? [avgRow]
        : []),
      totalRow,
    ]

    // const avgWorkingAgents = getAverage(
    //   agentsNumber?.map(({ count }) => parseFloat(count)) || []
    // )
    // const totalWorkingAgents = getSum(
    //   agentsNumber?.map(({ count }) => parseFloat(count)) || []
    // )

    const lastColFieldBeforeKpis = tableColumns.findIndex(
      ({ field }) => field === LAST_COLUMN_FIELD_BEFORE_KPIS
    )
    const callColumns = [...tableColumns].splice(0, lastColFieldBeforeKpis + 1)

    const kpiCols = [...tableColumns].splice(
      lastColFieldBeforeKpis + 1,
      tableColumns.length
    )

    const kpiColsOrder = ["Shows", "Abandoned Calls", "showsRatio"]

    const sortedKpiCols = sortManualKpis(kpiCols, "field", kpiColsOrder)

    const combinedCols = [...callColumns, ...sortedKpiCols]
    setColumns(combinedCols)

    setPinnedTopRows(pinnedRows)
    setRows(tableRows)
  }, [entityReports])

  const statisticsData = useMemo(() => {
    if (!isTeamTotalReport) {
      return {
        currentReportValues: {},
        previousReportValues: {},
      }
    }
    const prevPeriodRows = generateReportRows(previousPeriodReport)
    return {
      currentReportValues: {
        avgCalls: getAverageTotalCallsFromRows(rows),
        avgIBCalls: getAverageIBCallsFromRows(rows),
        avgOBCalls: getAverageOBCallsFromRows(rows),
      },
      previousReportValues: {
        avgCalls: getAverageTotalCallsFromRows(prevPeriodRows),
        avgIBCalls: getAverageIBCallsFromRows(prevPeriodRows),
        avgOBCalls: getAverageOBCallsFromRows(prevPeriodRows),
      },
    }
  }, [rows, entityReports, isTeamTotalReport])
  return {
    setRows,
    rows,
    pinnedTopRows,
    columns,
    loadingEntityReports,
    statisticsData,
  }
}

export default useEntityReports

function generateReportRows(
  entityReport,
  agentsNumber = [],
  manualKpisPerAgent = [],
  manualKpisPerStore = []
) {
  let tableRows = {}
  for (let i = 0; i < entityReport.length; i++) {
    const reportObj = entityReport[i]
    let {
      //
      creationDate,
      dispositionId,
      dispositionName,
      ibCalls,
      ib_OTDB,
      obConnectedCalls,
      obNotConnectedCalls,
      ob_OTDB,
      ibApptsCreated,
      obConnectedApptsCreated,
      obNotConnectedApptsCreated,
      count,
      connectedOtdb,
    } = reportObj

    const isObExcludedDisposition = dispositionName
      .toLowerCase()
      .includes("confirmation")
    const filteredObConnectedCalls = isObExcludedDisposition
      ? 0
      : obConnectedCalls
    const filteredObNotConnectedCalls = isObExcludedDisposition
      ? 0
      : obNotConnectedCalls

    const workingAgentsOnCurrentDate = agentsNumber?.find(
      workingAgentObj => workingAgentObj.creationDate === creationDate
    )
    const { obDispositionName, ibDispositionName } =
      getIBandOBDispositionNames(dispositionName)
    if (tableRows[creationDate]) {
      const updatedIbOTDB =
        (parseFloat(tableRows[creationDate].ib_OTDB) || 0) +
        (parseFloat(ib_OTDB) || 0)
      const updatedObOTDB =
        (parseFloat(tableRows[creationDate].ob_OTDB) || 0) +
        (parseFloat(ob_OTDB) || 0)
      const updatedIBCalls =
        (parseFloat(tableRows[creationDate].ibCalls) || 0) +
        (parseFloat(ibCalls) || 0)
      const updatedOBNotConnectedCalls =
        (parseFloat(tableRows[creationDate].obNotConnectedCalls) || 0) +
        (parseFloat(filteredObNotConnectedCalls) || 0)
      const updatedOBConnectedCalls =
        (parseFloat(tableRows[creationDate].obConnectedCalls) || 0) +
        (parseFloat(filteredObConnectedCalls) || 0)
      const updatedConnectedOtdb =
        (parseFloat(tableRows[creationDate].connectedOtdb) || 0) +
        (parseFloat(connectedOtdb) || 0)
      const updatedIBApptsCreated =
        (parseFloat(tableRows[creationDate].ibApptsCreated) || 0) +
        (parseFloat(ibApptsCreated) || 0)
      const updatedOBNotConnectedApptsCreated =
        (parseFloat(tableRows[creationDate].obNotConnectedApptsCreated) || 0) +
        (parseFloat(obNotConnectedApptsCreated) || 0)
      const updatedOBConnectedApptsCreated =
        (parseFloat(tableRows[creationDate].obConnectedApptsCreated) || 0) +
        (parseFloat(obConnectedApptsCreated) || 0)
      const updatedTotalCalls =
        (parseFloat(tableRows[creationDate].totalCalls) || 0) +
        getSum([ibCalls, filteredObConnectedCalls, filteredObNotConnectedCalls])
      const updatedTotalAppts =
        (parseFloat(tableRows[creationDate].totalApptsCreated) || 0) +
        getSum([
          ibApptsCreated,
          obConnectedApptsCreated,
          obNotConnectedApptsCreated,
        ])
      const updatedTotalClosingRatio = getPercentage(
        getSum([
          updatedIBApptsCreated,
          updatedOBConnectedApptsCreated,
          updatedOBNotConnectedApptsCreated,
        ]),
        getSum([updatedIbOTDB, updatedConnectedOtdb])
      )

      const avgCallsPerDay = workingAgentsOnCurrentDate
        ? approximate(
            updatedTotalCalls /
              parseFloat(workingAgentsOnCurrentDate?.count || 0)
          )
        : 0
      const avgIBCallsPerDay = workingAgentsOnCurrentDate
        ? approximate(
            updatedIBCalls / parseFloat(workingAgentsOnCurrentDate?.count || 0)
          )
        : 0
      const avgOBCallsPerDay = workingAgentsOnCurrentDate
        ? approximate(
            getSum([updatedOBNotConnectedCalls, updatedOBConnectedCalls]) /
              parseFloat(workingAgentsOnCurrentDate?.count || 0)
          )
        : 0
      tableRows[creationDate] = {
        ...tableRows[creationDate],
        [ibDispositionName]: parseFloat(ibCalls),
        [obDispositionName]: getSum([obNotConnectedCalls, obConnectedCalls]),

        ibCalls: updatedIBCalls,
        ib_OTDB: updatedIbOTDB,
        ob_OTDB: updatedObOTDB,
        obNotConnectedCalls: updatedOBNotConnectedCalls,
        obConnectedCalls: updatedOBConnectedCalls,
        obCalls: getSum([updatedOBNotConnectedCalls, updatedOBConnectedCalls]),
        connectedOtdb: updatedConnectedOtdb,
        ibApptsCreated: updatedIBApptsCreated,
        obNotConnectedApptsCreated: updatedOBNotConnectedApptsCreated,
        obConnectedApptsCreated: updatedOBConnectedApptsCreated,
        obApptsCreated: getSum([
          updatedOBNotConnectedApptsCreated,
          updatedOBConnectedApptsCreated,
        ]),

        ibClosingRatio: getPercentage(updatedIBApptsCreated, updatedIbOTDB),
        obClosingRatio: getPercentage(
          getSum([
            updatedOBConnectedApptsCreated,
            updatedOBNotConnectedApptsCreated,
          ]),
          updatedConnectedOtdb
        ),
        totalCalls: updatedTotalCalls,
        totalApptsCreated: updatedTotalAppts,
        totalClosingRatio: updatedTotalClosingRatio,
        avgCallsPerDay,
        avgIBCallsPerDay,
        avgOBCallsPerDay,
      }
    } else {
      const totalCalls = getSum([
        ibCalls,
        filteredObNotConnectedCalls,
        filteredObConnectedCalls,
      ])
      const totalApptsCreated = getSum([
        ibApptsCreated,
        obConnectedApptsCreated,
        obNotConnectedApptsCreated,
      ])
      const totalClosingRatio = getPercentage(
        getSum([
          ibApptsCreated,
          obConnectedApptsCreated,
          obNotConnectedApptsCreated,
        ]),
        getSum([ib_OTDB, connectedOtdb])
      )

      const avgCallsPerDay = workingAgentsOnCurrentDate
        ? approximate(
            totalCalls / parseFloat(workingAgentsOnCurrentDate?.count || 0)
          )
        : 0
      const avgIBCallsPerDay = workingAgentsOnCurrentDate
        ? approximate(
            parseFloat(ibCalls) /
              parseFloat(workingAgentsOnCurrentDate?.count || 0)
          )
        : 0
      const avgOBCallsPerDay = workingAgentsOnCurrentDate
        ? approximate(
            getSum([filteredObNotConnectedCalls, filteredObConnectedCalls]) /
              parseFloat(workingAgentsOnCurrentDate?.count || 0)
          )
        : 0
      tableRows[creationDate] = {
        creationDate,
        [ibDispositionName]: parseFloat(ibCalls),
        [obDispositionName]: getSum([obNotConnectedCalls, obConnectedCalls]),

        ibCalls: parseFloat(ibCalls),
        ib_OTDB: parseFloat(ib_OTDB),
        ob_OTDB: parseFloat(ob_OTDB),
        obNotConnectedCalls: parseFloat(filteredObNotConnectedCalls),
        obConnectedCalls: parseFloat(filteredObConnectedCalls),
        connectedOtdb: parseFloat(connectedOtdb),
        ibApptsCreated: parseFloat(ibApptsCreated),
        obCalls: getSum([
          filteredObConnectedCalls,
          filteredObNotConnectedCalls,
        ]),
        obConnectedApptsCreated: parseFloat(obConnectedApptsCreated),
        obNotConnectedApptsCreated: parseFloat(obNotConnectedApptsCreated),
        obApptsCreated: getSum([
          obConnectedApptsCreated,
          obNotConnectedApptsCreated,
        ]),

        ibClosingRatio: getPercentage(
          parseFloat(ibApptsCreated),
          parseFloat(ib_OTDB)
        ),
        obClosingRatio: getPercentage(
          getSum([obConnectedApptsCreated, obNotConnectedApptsCreated]),
          parseFloat(connectedOtdb)
        ),
        totalCalls: totalCalls,
        totalApptsCreated: totalApptsCreated,
        totalClosingRatio: totalClosingRatio,
        workingAgents: parseFloat(workingAgentsOnCurrentDate?.count),
        avgCallsPerDay,
        avgIBCallsPerDay,
        avgOBCallsPerDay,
      }
    }
  }

  //
  const combinedKpis = [
    ...(manualKpisPerAgent || []),
    ...(manualKpisPerStore || []),
  ]
  function getKpiCountPropName(kpiName) {
    return `${kpiName} Count`
  }
  function getLastIndexOfKpi(creationDate, kpiName) {
    for (let i = combinedKpis.length - 1; i >= 0; i--) {
      if (
        combinedKpis[i].creationDate === creationDate &&
        combinedKpis[i].manualKpiName === kpiName
      ) {
        return i
      }
    }
    return -1
  }

  for (let i = 0; i < combinedKpis.length; i++) {
    const kpiObj = combinedKpis[i]
    const { manualKpiName, value, agentName, user_id, unit, creationDate } =
      kpiObj
    const parsedDate = tzMoment(creationDate, "YYYY-MM-DD").format("MM-DD-YYYY")
    const kpiCountPropName = getKpiCountPropName(manualKpiName)
    const currentCount = tableRows[parsedDate]?.[kpiCountPropName] || 0
    const updatedCount = currentCount + 1
    const isAvgBehaviour = isAvgBehaviourManualKpi(manualKpiName)
    const lastKpiOfSameNameIndex = getLastIndexOfKpi(
      creationDate,
      manualKpiName
    )
    const isLastKpiOfSameName = lastKpiOfSameNameIndex === i

    const isNumber = unit === "Number"
    const isTime = unit === "Minutes"
    const isPercent = unit === "Percentage"
    const parsedValue = isTime
      ? value
      : isPercent
      ? `${value}%`
      : parseFloat(value)

    if (tableRows[parsedDate]) {
      let updatedValue
      if (!!tableRows[parsedDate][manualKpiName]) {
        updatedValue = getGenericSum([
          value,
          tableRows[parsedDate][manualKpiName],
        ])
      } else {
        updatedValue = parsedValue
      }

      if (isAvgBehaviour && isLastKpiOfSameName) {
        updatedValue = calcAvg(updatedValue, updatedCount)
      }
      tableRows[parsedDate] = {
        ...tableRows[parsedDate],
        [manualKpiName]: updatedValue,
        ...(isAvgBehaviour && {
          [kpiCountPropName]: updatedCount,
        }),
      }
    } else {
      tableRows[parsedDate] = {
        creationDate: parsedDate,
        [manualKpiName]: parsedValue,
        ...(isAvgBehaviour && {
          [kpiCountPropName]: updatedCount,
        }),
      }
    }
  }

  tableRows = Object.values(tableRows).map(row => {
    const showsRatio = getPercentage(row[SHOWS_KPI], row[SCHEDULED_APPTS_KPI])
    const abandonedRatio = getPercentage(row[ABANDONED_CALLS_KPI], row.ibCalls)
    return {
      ...row,
      showsRatio,
      abandonedRatio,
    }
  })

  return tableRows
}

function getAverageTotalCallsFromRows(rows = []) {
  const avg = getAverage(
    rows
      .map(row =>
        getSum([row.ibCalls, row.obConnectedCalls, row.obNotConnectedCalls])
      )
      .filter(el => !!el)
  )
  return avg
}
function getAverageIBCallsFromRows(rows = []) {
  const avg = getAverage(
    rows.map(row => parseFloat(row.ibCalls)).filter(el => !!el)
  )
  return avg
}
function getAverageOBCallsFromRows(rows = []) {
  const avg = getAverage(
    rows
      .map(row => getSum([row.obConnectedCalls, row.obNotConnectedCalls]))
      .filter(el => !!el)
  )
  return avg
}

const ratiosMap = {
  obClosingRatio: {
    numerators: ["obConnectedApptsCreated", "obNotConnectedApptsCreated"],
    denominators: ["connectedOtdb"],
  },
  ibClosingRatio: {
    numerators: ["ibApptsCreated"],
    denominators: ["ib_OTDB"],
  },
  totalClosingRatio: {
    numerators: [
      "ibApptsCreated",
      "obConnectedApptsCreated",
      "obNotConnectedApptsCreated",
    ],
    denominators: ["ib_OTDB", "connectedOtdb"],
  },
  showsRatio: {
    numerators: [SHOWS_KPI],
    denominators: [SCHEDULED_APPTS_KPI],
  },
  abandonedRatio: {
    numerators: [ABANDONED_CALLS_KPI],
    denominators: ["ibCalls"],
  },
}

function getAverageOfRatioColumn({ columnName = "", tableRows = [] }) {
  const { numerators, denominators } = ratiosMap[columnName]
  if (!numerators || !denominators) return ""

  const avgNumerators = getSum(
    numerators.map(numerator =>
      getAverage(
        tableRows.map(row => parseFloat(row[numerator])).filter(el => !!el)
      )
    )
  )
  const avgDenominators = getSum(
    denominators.map(denominator =>
      getAverage(
        tableRows.map(row => parseFloat(row[denominator])).filter(el => !!el)
      )
    )
  )

  const ratio = getPercentage(avgNumerators, avgDenominators)
  return ratio
}
function getTotalOfRatioColumn({ columnName = "", tableRows = [] }) {
  const { numerators, denominators } = ratiosMap[columnName] || {}
  if (!numerators || !denominators) return ""

  const totalNumerators = getSum(
    numerators.map(numerator =>
      getSum(tableRows.map(row => parseFloat(row[numerator])))
    )
  )
  const totalDenominators = getSum(
    denominators.map(denominator =>
      getSum(tableRows.map(row => parseFloat(row[denominator])))
    )
  )

  const ratio = getPercentage(totalNumerators, totalDenominators)
  return ratio
}
