import {
  ADD_KPI_ENTRY,
  FETCH_KPIS_ENTRIES,
  KPI_ENTRY_ERROR,
  KPI_ENTRY_SUCCESS,
  SET_KPIS_ENTRIES,
  UPDATE_KPI_ENTRY,
} from "./actionTypes"

export const addKpiEntry = (kpiEntry, history) => {
  return {
    type: ADD_KPI_ENTRY,
    payload: { kpiEntry, history },
  }
}

export const fetchKpisEntries = () => {
  return {
    type: FETCH_KPIS_ENTRIES,
  }
}

export const updateKpiEntry = (id, kpiEntry, history) => {
  return {
    type: UPDATE_KPI_ENTRY,
    payload: { id, kpiEntry, history },
  }
}

export const kpiEntrySuccess = msg => {
  return {
    type: KPI_ENTRY_SUCCESS,
    payload: { msg },
  }
}

export const kpiEntryError = error => {
  return {
    type: KPI_ENTRY_ERROR,
    payload: error,
  }
}

export const setKpisEntries = kpisEntries => {
  return {
    type: SET_KPIS_ENTRIES,
    payload: { kpisEntries },
  }
}
