import React from "react"

import { Button } from "reactstrap"
const CsvIcon = ({ ...props }) => {
  return (
    <Button color="primary" style={{ height: "36px" }} {...props}>
      <i className="fa fa-download me-1"></i> Download CSV
    </Button>
  )
}

export default CsvIcon
