import { takeEvery, put, call } from "redux-saga/effects"
import { FETCH_SERVICE_ADVISOR_REPORTS } from "./actionTypes"
import { setServiceAdvisorReports } from "./actions"
import { getServiceAdvisorsReports } from "../../helpers/fakebackend_helper"

function* fetchServiceAdvisorsReports({ payload: { queryParams } }) {
  try {
    const response = yield call(getServiceAdvisorsReports, queryParams)
    yield put(setServiceAdvisorReports(response))
  } catch (error) {
    console.log("error", error)
  }
}

function* serviceAdvisorsReportsSaga() {
  yield takeEvery(FETCH_SERVICE_ADVISOR_REPORTS, fetchServiceAdvisorsReports)
}

export default serviceAdvisorsReportsSaga
