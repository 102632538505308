import React from "react"

const Icon = () => {
  return (
    <div
      style={{
        backgroundColor: "rgba(200, 199, 199,0.25)",
        width: "29px",
        height: "28px",
      }}
      className="d-flex align-items-center rounded"
    >
      <svg
        width="30"
        height="28"
        viewBox="0 0 30 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.000976562"
          width="30"
          height="28"
          rx="4"
          fill="#C8C7C7"
          fillOpacity="0.25"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5203 13.1876L16.7542 12.4215L11.7093 17.4665V18.2325H12.4753L17.5203 13.1876ZM18.2861 12.4217L19.0521 11.6557L18.2861 10.8896L17.5201 11.6557L18.2861 12.4217ZM12.9241 19.3161H10.626V17.018L17.9033 9.74068C18.1148 9.52915 18.4578 9.52915 18.6693 9.74068L20.2014 11.2727C20.4129 11.4843 20.4129 11.8272 20.2014 12.0388L12.9241 19.3161Z"
          fill="#5A5758"
        />
      </svg>
    </div>
  )
}

export default Icon
