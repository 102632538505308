import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import {
  ADD_EVENT,
  DELETE_EVENT,
  FETCH_EVENTS,
  UPDATE_EVENT,
} from "./actionTypes"
import { eventSuccess, eventError, setEvents } from "./actions"

//Include Both Helper File with needed methods
import {
  deleteHoliday,
  getHolidays,
  patchHoliday,
  postHoliday,
} from "../../helpers/fakebackend_helper"

import { isArray } from "lodash"
function* addEvent({ payload }) {
  try {
    const response = yield call(postHoliday, payload.event)
    yield put(eventSuccess("The event record is saved successfully"))
    // if (payload.onSuccess) {
    //   yield call(payload.onSuccess)
    // }
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message

    yield put(eventError(errMsg))
  }
}

function* fetchEvents({ payload: { queryParams, onSuccess } }) {
  try {
    const response = yield call(getHolidays, queryParams)
    yield put(setEvents(response))

    // if (onSuccess) {
    //   yield call(onSuccess)
    // }
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message

    yield put(eventError(errMsg))
  }
}

function* updateEvent({ payload }) {
  try {
    yield call(patchHoliday, payload.id, payload.event)
    yield put(eventSuccess("Updated Successfully"))
    // if (payload.onSuccess) {
    //   yield call(payload.onSuccess)
    // }

    // setTimeout(() => {
    //   payload.history("/calls")
    // })
  } catch (error) {
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    console.log("error", error)
    yield put(eventError(errMsg))
  }
}

function* deleteEvent({ payload }) {
  try {
    yield call(deleteHoliday, payload.id)
    yield put(eventSuccess("Deleted Successfully"))

    // if (payload.onSuccess) {
    //   yield call(payload.onSuccess)
    // }
    // setTimeout(() => {
    //   payload.history("/calls")
    // })
  } catch (error) {
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    console.log("error", error)
    yield put(eventError(errMsg))
  }
}

function* eventSaga() {
  yield takeEvery(ADD_EVENT, addEvent)
  yield takeEvery(FETCH_EVENTS, fetchEvents)
  yield takeEvery(UPDATE_EVENT, updateEvent)
  yield takeEvery(DELETE_EVENT, deleteEvent)
}

export default eventSaga
