import {
  ADD_STORE,
  FETCH_STORES,
  STORE_ERROR,
  STORE_SUCCESS,
  SET_STORES,
  UPDATE_STORE,
} from "./actionTypes"

export const addStore = (store, history) => {
  return {
    type: ADD_STORE,
    payload: { store, history },
  }
}

export const updateStore = (id, store, history) => {
  return {
    type: UPDATE_STORE,
    payload: { id, store, history },
  }
}

export const fetchStores = () => {
  return {
    type: FETCH_STORES,
  }
}

export const storeSuccess = msg => {
  return {
    type: STORE_SUCCESS,
    payload: { msg },
  }
}

export const storeError = error => {
  return {
    type: STORE_ERROR,
    payload: error,
  }
}

export const setStores = stores => {
  return {
    type: SET_STORES,
    payload: { stores },
  }
}
