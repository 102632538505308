import {
  ADD_USER,
  FETCH_USERS,
  USER_ERROR,
  USER_SUCCESS,
  SET_USERS,
  UPDATE_USER,
} from "./actionTypes"

export const addUser = (user, history) => {
  return {
    type: ADD_USER,
    payload: { user, history },
  }
}

export const updateUser = (id, user, history) => {
  return {
    type: UPDATE_USER,
    payload: { id, user, history },
  }
}

export const fetchUsers = searchValue => {
  return {
    type: FETCH_USERS,
    payload: { searchValue },
  }
}

export const userSuccess = msg => {
  return {
    type: USER_SUCCESS,
    payload: { msg },
  }
}

export const userError = error => {
  return {
    type: USER_ERROR,
    payload: error,
  }
}

export const setUsers = users => {
  return {
    type: SET_USERS,
    payload: { users },
  }
}
