import React, { useEffect, useMemo, useState } from "react"
import { fetchMSTargetReport } from "store/actions"
import {
  goalsArr as goals,
  stackRankingArr as stackRankingReport,
} from "../dummy"
import {
  generateTableColumns,
  generateTableRows,
  getAverageOfGoals,
} from "../helpers"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { datesOptions, tzMoment } from "constants/datesOptions"

// const msTargetReport = {
//   goals,
//   stackRankingReport,
// }

const useMSTargetReport = ({ selectedDateValue }) => {
  const [rows, setRows] = useState([])
  const [pinnedTopRows, setPinnedTopRows] = useState([])
  const [columns, setColumns] = useState([])
  const [pinnedBottomRows, setPinnedBottomRows] = useState([])
  const [totalMS, setTotalMS] = useState(0)
  const [totalTarget, setTotalTarget] = useState(0)
  const [totalActiveAgents, setTotalActiveAgents] = useState(0)

  const MSTargetReportProperties = createSelector(
    state => state.MSTargetReport,
    MSTargetReport => ({
      loading: MSTargetReport.loading,
      msTargetReport: MSTargetReport.msTargetReport,
    })
  )

  const { loading: loadingReport, msTargetReport } = useSelector(
    MSTargetReportProperties
  )

  const avgGoals = useMemo(() => {
    return getAverageOfGoals(msTargetReport?.goals)
  }, [msTargetReport])

  useEffect(() => {
    if (!msTargetReport?.stackRankingReport?.length) {
      setRows([])
      setPinnedTopRows([])
      setPinnedBottomRows([])
      setColumns([])
      return
    }

    const dateOption = Object.values(datesOptions).find(
      ({ value }) => value === selectedDateValue
    )
    const formattedDate = tzMoment(dateOption.startDate).format("MMMM YYYY")
    const tableColumns = generateTableColumns(avgGoals, formattedDate)
    const {
      pinnedBottomRows: bottomRows,
      pinnedTopRows: topRows,
      tableRows,
      totalAgentsNumber,
      totalMS,
      totalTarget,
    } = generateTableRows(
      msTargetReport.stackRankingReport,
      avgGoals,
      tableColumns
    )
    console.log({
      tableColumns,
      pinnedBottomRows,
      pinnedTopRows,
      tableRows,
      avgGoals,
      goals: msTargetReport.goals,
      totalAgentsNumber,
      totalMS,
      totalTarget,
    })
    setTotalMS(totalMS)
    setTotalTarget(totalTarget)
    setTotalActiveAgents(totalAgentsNumber)
    setPinnedTopRows(topRows)
    setPinnedBottomRows(bottomRows)

    setRows(tableRows)
    setColumns(tableColumns)
  }, [msTargetReport, avgGoals])
  return {
    loadingReport,
    columns,
    rows,
    setRows,
    pinnedBottomRows,
    pinnedTopRows,
    totalMS,
    totalTarget,
    totalActiveAgents,
  }
}

export default useMSTargetReport
