import {
  ADD_EVENT,
  FETCH_EVENTS,
  UPDATE_EVENT,
  EVENT_SUCCESS,
  EVENT_ERROR,
  SET_EVENTS,
  DELETE_EVENT,
} from "./actionTypes"

const INIT_STATE = {
  events: [],
  loading: false,
  errorMsg: "",
  successMsg: "",
}

const Calendar = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_EVENT:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case FETCH_EVENTS:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        events: [],
        loading: true,
      }
      break

    case UPDATE_EVENT:
      state = {
        ...state,
        successMsg: "",
        errorMsg: "",
        loading: true,
      }
      break
    case DELETE_EVENT:
      state = {
        ...state,
        successMsg: "",
        errorMsg: "",
        loading: true,
      }
      break

    case SET_EVENTS:
      state = {
        ...state,
        loading: false,
        events: action.payload.events,
      }
      break
    case EVENT_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload.msg,
        loading: false,
      }
      break
    case EVENT_ERROR:
      state = { ...state, errorMsg: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Calendar
