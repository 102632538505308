import React, { useEffect, useState } from "react"
import CategoryIcon from "assets/icons/CategoryIcon"
import EditIcon from "assets/icons/EditIcon"
import DeleteIcon from "assets/icons/DeleteIcon"
import AgGridTable from "components/Common/AgGridTable"
import PageContainer from "components/Common/PageContainer"
import { Badge, Button, Spinner } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { fetchCompanies } from "store/actions"
import { POST_COMPANY } from "helpers/url_helper"
import DeleteModal from "components/Common/DeleteModal"
import { axiosApi } from "helpers/api_helper"

const moduleName = "companies"

const Companies = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [currentId, setCurrentId] = useState()
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [deleteError, setDeleteError] = useState()

  //permissions
  const PermissionsSelector = createSelector(
    state => state.Permissions,
    perm => ({
      permissions: perm.permissions,
    })
  )
  const { permissions } = useSelector(PermissionsSelector)

  const [rows, setRows] = useState([])

  useEffect(() => {
    dispatch(fetchCompanies())
  }, [])

  const CompanyProperties = createSelector(
    state => state.Company,
    comp => ({
      loading: comp.loading,
      companies: comp.companies,
    })
  )

  const { loading, companies } = useSelector(CompanyProperties)

  useEffect(() => {
    setRows(
      companies.map(item => ({
        id: item.company_id,
        name: item.name,
        departments: item.departments?.map(el => el.type_name).join(", "),
        logo: item.logo,
      }))
    )
  }, [companies])

  const columns = [
    {
      headerName: "",
      width: "50px",
      sortable: false,
      field: "logo",
      cellRenderer: params => {
        return (
          <div className="d-flex align-items-center h-100">
            <div
              className="bg-primary text-white d-flex align-items-center justify-content-center"
              style={{
                height: "25px",
                width: "25px",
                minWidth: "25px",
                fontSize: "14px",
                borderRadius: "50%",
                overflow: "hidden",
              }}
            >
              {params.data.logo ? (
                <img src={params.data.logo} width={"100%"} height={"100%"} />
              ) : (
                params.data.name[0].toUpperCase()
              )}
            </div>
          </div>
        )
      },
    },
    { field: "name", filter: true, headerName: "Company Name" },
    { field: "departments", filter: true, headerName: "Departments" },
    {
      headerName: "",
      sortable: false,
      cellRenderer: params => {
        return (
          <div className="d-flex align-items-center h-100 gap-2">
            {actions(params.data.id)}
          </div>
        )
      },
    },
  ]

  const actions = id => {
    return (
      <>
        {/* <CategoryIcon /> */}
        {permissions?.[moduleName]?.EDIT && (
          <div
            onClick={() =>
              navigate("/companies/" + id, {
                state: companies?.find(item => item.company_id === id),
              })
            }
          >
            <EditIcon />
          </div>
        )}
        {permissions?.[moduleName]?.DELETE && (
          <div
            onClick={() => {
              setShowDeleteModal(true)
              setCurrentId(id)
            }}
          >
            <DeleteIcon />
          </div>
        )}
      </>
    )
  }

  const gridOptions = {
    autoSizeStrategy: {
      type: "fitGridWidth",
    },
    // other grid options ...
  }

  const handleDelete = async () => {
    setDeleteLoading(true)
    setDeleteError(null)
    try {
      await axiosApi.delete(POST_COMPANY + "/" + currentId)
      dispatch(fetchCompanies())
      setShowDeleteModal(false)
    } catch (err) {
      setDeleteError(err.response.data?.message)
    } finally {
      setDeleteLoading(false)
    }
  }

  return (
    <PageContainer>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setShowDeleteModal(false)
          setDeleteError(null)
        }}
        errorMsg={deleteError}
        loading={deleteLoading}
      />
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="page-title">Companies</h1>
        {permissions?.[moduleName]?.ADD && (
          <Button
            color="primary"
            style={{ height: "36px" }}
            onClick={() => navigate("/companies/new")}
          >
            Add Company
          </Button>
        )}
      </div>
      {loading ? (
        <div className="w-full d-flex justify-content-center align-items-center pt-4">
          <Spinner />
        </div>
      ) : (
        <>
          {permissions?.[moduleName]?.VIEW && (
            <AgGridTable
              rowState={[rows, setRows]}
              columns={columns}
              qiuckFilter={true}
            />
          )}
        </>
      )}
    </PageContainer>
  )
}

export default Companies
