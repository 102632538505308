import React from "react"

const Icon = () => {
  return (
    <div
      style={{
        backgroundColor: "rgba(235, 191, 192, 0.3)",
        width: "29px",
        height: "28px",
      }}
      className="d-flex align-items-center rounded"
    >
      <svg
        width="29"
        height="28"
        viewBox="0 0 29 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="29" height="28" rx="4" fill="#EBBFC0" fillOpacity="0.25" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0833 10.5H18.1667V11.3334H17.3333V16.75C17.3333 16.9802 17.1468 17.1667 16.9167 17.1667H11.0833C10.8532 17.1667 10.6667 16.9802 10.6667 16.75V11.3334H9.83333V10.5H11.9167V9.25004C11.9167 9.01992 12.1032 8.83337 12.3333 8.83337H15.6667C15.8968 8.83337 16.0833 9.01992 16.0833 9.25004V10.5ZM16.5 11.3334H11.5V16.3334H16.5V11.3334ZM12.75 9.66671V10.5H15.25V9.66671H12.75Z"
          fill="#C34040"
        />
      </svg>
    </div>
  )
}

export default Icon
