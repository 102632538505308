import {
  ADD_STORE,
  FETCH_STORES,
  STORE_ERROR,
  STORE_SUCCESS,
  SET_STORES,
  UPDATE_STORE,
} from "./actionTypes"

const initialState = {
  errorMsg: "",
  successMsg: "",
  loading: false,
  stores: [],
}

const store = (state = initialState, action) => {
  switch (action.type) {
    case ADD_STORE:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case UPDATE_STORE:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case FETCH_STORES:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        stores: [],
        loading: true,
      }
      break

    case SET_STORES:
      state = {
        ...state,
        loading: false,
        stores: action.payload.stores,
      }
      break
    case STORE_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload.msg,
        loading: false,
      }
      break
    case STORE_ERROR:
      state = { ...state, errorMsg: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default store
