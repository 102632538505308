import {
  ADD_EVENT,
  FETCH_EVENTS,
  UPDATE_EVENT,
  EVENT_SUCCESS,
  EVENT_ERROR,
  SET_EVENTS,
  DELETE_EVENT,
} from "./actionTypes"

export const addEvent = event => {
  return {
    type: ADD_EVENT,
    payload: { event },
  }
}

export const fetchEvents = (
  queryParams = {
    startDate: "",
    endDate: "",
  }
) => {
  return {
    type: FETCH_EVENTS,
    payload: { queryParams },
  }
}

export const updateEvent = (id, event) => {
  return {
    type: UPDATE_EVENT,
    payload: { id, event },
  }
}

export const deleteEvent = id => {
  return {
    type: DELETE_EVENT,
    payload: { id },
  }
}
export const eventSuccess = msg => {
  return {
    type: EVENT_SUCCESS,
    payload: { msg },
  }
}

export const eventError = error => {
  return {
    type: EVENT_ERROR,
    payload: error,
  }
}

export const resetEvents = () => {
  return {
    type: SET_EVENTS,
    payload: { events: [] },
  }
}
export const setEvents = events => {
  return {
    type: SET_EVENTS,
    payload: { events },
  }
}
