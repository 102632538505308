import {
  ADD_GOAL_ENTRY,
  FETCH_GOALS_ENTRIES,
  GOAL_ENTRY_ERROR,
  GOAL_ENTRY_SUCCESS,
  SET_GOALS_ENTRIES,
  UPDATE_GOAL_ENTRY,
} from "./actionTypes"

export const addGoalEntry = (goalEntry, history) => {
  return {
    type: ADD_GOAL_ENTRY,
    payload: { goalEntry, history },
  }
}

export const fetchGoalsEntries = () => {
  return {
    type: FETCH_GOALS_ENTRIES,
  }
}

export const updateGoalEntry = (id, goalEntry, history) => {
  return {
    type: UPDATE_GOAL_ENTRY,
    payload: { id, goalEntry, history },
  }
}

export const goalEntrySuccess = msg => {
  return {
    type: GOAL_ENTRY_SUCCESS,
    payload: { msg },
  }
}

export const goalEntryError = error => {
  return {
    type: GOAL_ENTRY_ERROR,
    payload: error,
  }
}

export const setGoalsEntries = goalsEntries => {
  return {
    type: SET_GOALS_ENTRIES,
    payload: { goalsEntries },
  }
}
