import PageContainer from "components/Common/PageContainer"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { Alert, Button, Form, Spinner } from "reactstrap"
import * as Yup from "yup"
import InputField from "components/Form/InputField"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import SelectField from "components/Form/SelectField"
import { addGoal, updateGoal } from "store/actions"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

const AddEditGoal = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { state: goalData } = useLocation()
  const [showConfirm, setShowConfirm] = useState(false)
  const dispatch = useDispatch()

  const GoalProperties = createSelector(
    state => state.Goal,
    goal => ({
      loading: goal.loading,
      errorMsg: goal.errorMsg,
      successMsg: goal.successMsg,
    })
  )

  const { loading, errorMsg, successMsg } = useSelector(GoalProperties)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: goalData
      ? {
          name: goalData.name,
          type: goalData.is_automated
            ? { value: "automatic", label: "Automatic" }
            : { value: "manual", label: "Manual" },
        }
      : {
          name: "",
          type: { value: "manual", label: "Manual" },
        },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter goal name"),
      type: Yup.object().required("Please choose type"),
    }),
    onSubmit: values => {
      window.scrollTo({ top: 0, behavior: "smooth" })
      const body = {
        name: values.name,
        is_automated: values.type.value === "automatic",
      }
      if (id && goalData) dispatch(updateGoal(id, body, navigate))
      else dispatch(addGoal(body, navigate))
    },
  })

  const handleCancel = () => {
    if (validation.dirty) {
      window.scrollTo({ top: 0, behavior: "smooth" })
      setShowConfirm(true)
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    if (successMsg) {
      toast.success(successMsg)
    }
  }, [successMsg])

  useEffect(() => {
    if (errorMsg) {
      toast.error(errorMsg)
    }
  }, [errorMsg])

  return (
    <PageContainer>
      <h1 className="page-title mb-4">{id ? "Edit" : "Add Manual"} KPI Goal</h1>
      <div className="content-container">
        {showConfirm && (
          <Alert color="danger" style={{ marginTop: "13px" }}>
            <div>
              <p>Are you sure you want to cancel? Your inputs will be lost.</p>
              <div className="d-flex">
                <Button
                  type="button"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() => navigate(-1)}
                >
                  Yes
                </Button>
                <Button type="button" onClick={() => setShowConfirm(false)}>
                  No
                </Button>
              </div>
            </div>
          </Alert>
        )}

        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-12 mb-4">
                <InputField
                  name="name"
                  validation={validation}
                  placeholder=""
                  label={"Goal Name"}
                  required
                />
              </div>
              {/* <div className="col-lg-7 col-12 mb-4">
                <SelectField
                  label={"Type"}
                  required
                  name="type"
                  validation={validation}
                  options={[{ value: "manual", label: "Manual" }]}
                />
              </div> */}
            </div>
          </div>

          <div className="mt-5 d-flex justify-content-center justify-content-lg-start">
            <Button
              color="primary"
              style={{
                marginRight: "20px",
                width: "110px",
              }}
              type="submit"
            >
              {loading ? <Spinner size="sm" /> : "Save"}
            </Button>
            <Button type="button" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </PageContainer>
  )
}

export default AddEditGoal
