import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { ADD_CALL, FETCH_CALLS, UPDATE_CALL } from "./actionTypes"
import { callSuccess, callError, setCalls } from "./actions"

//Include Both Helper File with needed methods
import { getCalls, patchCall, postCall } from "../../helpers/fakebackend_helper"

import { isArray } from "lodash"
function* addCall({ payload }) {
  try {
    console.log("new saga", payload.call)
    const response = yield call(postCall, payload.call)
    yield put(callSuccess("The call record is saved successfully"))
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(callError(errMsg))
  }
}

function* fetchCalls({ payload: { queryParams } }) {
  try {
    const response = yield call(getCalls, queryParams)
    yield put(setCalls(response))
  } catch (error) {
    console.log("error", error)
  }
}

function* updateCall({ payload }) {
  try {
    yield call(patchCall, payload.id, payload.call)
    yield put(callSuccess("Updated Successfully"))
    setTimeout(() => {
      payload.history("/calls")
    })
  } catch (error) {
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    console.log("error", error)
    yield put(callError(errMsg))
  }
}

function* callSaga() {
  yield takeEvery(ADD_CALL, addCall)
  yield takeEvery(FETCH_CALLS, fetchCalls)
  yield takeEvery(UPDATE_CALL, updateCall)
}

export default callSaga
