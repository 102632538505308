import { FETCH_ENTITY_REPORTS, SET_ENTITY_REPORTS } from "./actionTypes"

const initialState = {
  loading: false,
  entityReports: undefined,
}

const entityReport = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ENTITY_REPORTS:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        entityReports: undefined,
        loading: true,
      }
      break

    case SET_ENTITY_REPORTS:
      state = {
        ...state,
        loading: false,
        entityReports: action.payload.entityReports,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default entityReport
