import React, { useEffect, useRef, useCallback, useState } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link, NavLink } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import homeIcon from "assets/icons/home.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHouse,
  faPhoneVolume,
  faGear,
  faFileInvoice,
  faChartSimple,
  faRankingStar,
  faUsersViewfinder,
  faLayerGroup,
  faList,
  faBullseye,
  faChartBar,
  faBars,
  faClipboardUser,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons"
import { axiosApi } from "helpers/api_helper"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

const SidebarContent = props => {
  //permissions
  const PermissionsSelector = createSelector(
    state => state.Permissions,
    perm => ({
      permissions: perm.permissions,
    })
  )
  const { permissions } = useSelector(PermissionsSelector)

  const ref = useRef()
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()

  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName.includes(items[i].pathname)) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {permissions?.roomDashboard?.VIEW && (
              <>
                <li>
                  <Link to="/dashboard">
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{ marginRight: "10px" }}
                    />
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
              </>
            )}
            {permissions?.userDashboard?.VIEW &&
            permissions?.calls?.OWNINPUT &&
            permissions?.calls.ADD ? (
              <>
                <li>
                  <Link to="/agent-dashboard">
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{ marginRight: "10px" }}
                    />
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
              </>
            ) : permissions?.userDashboard?.VIEW && permissions?.calls?.VIEW ? (
              <>
                <li>
                  <Link to="/manager-dashboard">
                    <FontAwesomeIcon
                      icon={faHouse}
                      style={{ marginRight: "10px" }}
                    />
                    <span>{props.t("Manager Dashboard")}</span>
                  </Link>
                </li>
              </>
            ) : null}

            {(permissions?.calls?.VIEW || permissions?.calls?.ADD) && (
              <li>
                <Link to="/calls">
                  <FontAwesomeIcon
                    icon={faPhoneVolume}
                    style={{ marginRight: "10px" }}
                  />
                  <span>Call Records</span>
                </Link>
              </li>
            )}
            {(permissions?.goalsEntries?.VIEW ||
              permissions?.goalsEntries?.ADD) && (
              <li>
                <Link to="/goal-entries">
                  <FontAwesomeIcon
                    icon={faList}
                    style={{ marginRight: "10px" }}
                  />
                  <span>KPI Goal Entries</span>
                </Link>
              </li>
            )}
            {(permissions?.manualKpisEntries?.VIEW ||
              permissions?.manualKpisEntries?.ADD) && (
              <li>
                <Link to="/manual-kpi-entries">
                  <FontAwesomeIcon
                    icon={faBars}
                    style={{ marginRight: "10px" }}
                  />
                  <span>Manually Added KPI Entries</span>
                </Link>
              </li>
            )}
            {/* {isAdmin && (
              <li>
                <Link to="/agents">
                  <FontAwesomeIcon
                    icon={faList}
                    style={{ marginRight: "10px" }}
                  />
                  <span>Agent Matrics</span>
                </Link>
              </li>
            )} */}
            {
              <>
                {(permissions?.entityReport?.VIEW ||
                  permissions?.teamTotalReport?.VIEW ||
                  permissions?.stackRankingReport?.VIEW ||
                  permissions?.serviceRankingReport?.VIEW ||
                  permissions?.rollupReport?.VIEW ||
                  permissions?.serviceAdvisorReport?.VIEW ||
                  permissions?.individualSummaryReport?.VIEW) && (
                  <li className="menu-title">Reports</li>
                )}

                {permissions?.entityReport?.VIEW && (
                  <li>
                    <Link to="/entity-report">
                      <FontAwesomeIcon
                        icon={faFileInvoice}
                        style={{ marginRight: "10px" }}
                      />
                      <span>Entity Report </span>
                    </Link>
                  </li>
                )}
                {permissions?.individualSummaryReport?.VIEW && (
                  <li>
                    <Link to="/individual-summary">
                      <FontAwesomeIcon
                        icon={faClipboardUser}
                        style={{ marginRight: "10px" }}
                      />
                      <span>Individual Summary Report</span>
                    </Link>
                  </li>
                )}
                {permissions?.serviceAdvisorReport?.VIEW && (
                  <li>
                    <Link to="/service-advisors-report">
                      <FontAwesomeIcon
                        icon={faUsersViewfinder}
                        style={{ marginRight: "10px" }}
                      />
                      <span>Service Advisors Report</span>
                    </Link>
                  </li>
                )}
                {permissions?.rollupReport?.VIEW && (
                  <li>
                    <Link to="/rollup-report">
                      <FontAwesomeIcon
                        icon={faLayerGroup}
                        style={{ marginRight: "10px" }}
                      />
                      <span>Rollup Report </span>
                    </Link>
                  </li>
                )}
                {permissions?.stackRankingReport?.VIEW && (
                  <li>
                    <Link to="/stack-ranking-report">
                      <FontAwesomeIcon
                        icon={faRankingStar}
                        style={{ marginRight: "10px" }}
                      />
                      <span>Stack Ranking Report </span>
                    </Link>
                  </li>
                )}
                {permissions?.teamTotalReport?.VIEW && (
                  <li>
                    <Link to="/team-total-report">
                      <FontAwesomeIcon
                        icon={faChartSimple}
                        style={{ marginRight: "10px" }}
                      />
                      <span>Team Total Report </span>
                    </Link>
                  </li>
                )}
                {/* {permissions?.entityReport?.VIEW && (
                  <li>
                    <Link to="/ms-target-report">
                      <FontAwesomeIcon
                        icon={faFileInvoice}
                        style={{ marginRight: "10px" }}
                      />
                      <span>MS Target Report </span>
                    </Link>
                  </li>
                )} */}
                {/* {permissions?.entityReport?.VIEW && (
                  <li>
                    <Link to="/checklist-report">
                      <FontAwesomeIcon
                        icon={faFileInvoice}
                        style={{ marginRight: "10px" }}
                      />
                      <span>Daily Checklist Report </span>
                    </Link>
                  </li>
                )} */}
                {/* {permissions?.entityReport?.VIEW && (
                  <li>
                    <Link to="/month-over-month-report">
                      <FontAwesomeIcon
                        icon={faFileInvoice}
                        style={{ marginRight: "10px" }}
                      />
                      <span>Month Over Month Report</span>
                    </Link>
                  </li>
                )} */}
                {/* {(permissions?.teamTotalReport?.VIEW ||
                  permissions?.teamTotalReport?.ADD) && (
                  <li>
                    <Link to="/ss">
                      <FontAwesomeIcon
                        icon={faChartSimple}
                        style={{ marginRight: "10px" }}
                      />
                      <span>Team Total Report</span>
                    </Link>
                  </li>
                )}

                {(permissions?.stackRankingReport?.VIEW ||
                  permissions?.stackRankingReport?.ADD) && (
                  <li>
                    <Link to="/ss">
                      <FontAwesomeIcon
                        icon={faRankingStar}
                        style={{ marginRight: "10px" }}
                      />
                      <span>Stack Ranking Report</span>
                    </Link>
                  </li>
                )}
                {(permissions?.serviceRankingReport?.VIEW ||
                  permissions?.serviceRankingReport?.ADD) && (
                  <li>
                    <Link to="/ss">
                      <FontAwesomeIcon
                        icon={faUsersViewfinder}
                        style={{ marginRight: "10px" }}
                      />
                      <span>Service Advisors Report</span>
                    </Link>
                  </li>
                )}
                {(permissions?.rollupReport?.VIEW ||
                  permissions?.rollupReport?.ADD) && (
                  <li>
                    <Link to="/ss">
                      <FontAwesomeIcon
                        icon={faLayerGroup}
                        style={{ marginRight: "10px" }}
                      />
                      <span>Rollup Report</span>
                    </Link>
                  </li>
                )} */}
              </>
            }
            {(permissions?.companies?.VIEW ||
              permissions?.companies?.ADD ||
              permissions?.dispositions?.VIEW ||
              permissions?.dispositions?.ADD ||
              permissions?.groups?.VIEW ||
              permissions?.groups?.ADD ||
              permissions?.roles?.VIEW ||
              permissions?.roles?.ADD ||
              permissions?.stores?.VIEW ||
              permissions?.stores?.ADD ||
              permissions?.users?.VIEW ||
              permissions?.users?.ADD ||
              permissions?.goals?.VIEW ||
              permissions?.goals?.ADD ||
              permissions?.manualKpis?.VIEW ||
              permissions?.manualKpis?.ADD ||
              permissions?.saCalls?.ADD ||
              permissions?.saCalls?.VIEW) && (
              <li className="menu-title">System</li>
            )}
            {
              <li>
                {(permissions?.companies?.VIEW ||
                  permissions?.companies?.ADD ||
                  permissions?.departments?.VIEW ||
                  permissions?.departments?.ADD ||
                  permissions?.dispositions?.VIEW ||
                  permissions?.dispositions?.ADD ||
                  permissions?.groups?.VIEW ||
                  permissions?.groups?.ADD ||
                  permissions?.roles?.VIEW ||
                  permissions?.roles?.ADD ||
                  permissions?.users?.VIEW ||
                  permissions?.users?.ADD ||
                  permissions?.stores?.VIEW ||
                  permissions?.stores?.ADD ||
                  permissions?.goals?.VIEW ||
                  permissions?.goals?.ADD ||
                  permissions?.manualKpis?.VIEW ||
                  permissions?.manualKpis?.ADD ||
                  permissions?.saCalls?.ADD ||
                  permissions?.saCalls?.VIEW) && (
                  <Link to="/config" className="has-arrow">
                    <FontAwesomeIcon
                      icon={faGear}
                      style={{ marginRight: "10px" }}
                    />
                    <span>Configuration</span>
                  </Link>
                )}
                <ul className="sub-menu">
                  {(permissions?.departments?.VIEW ||
                    permissions?.departments?.ADD) && (
                    <li>
                      <Link to="/departments">Departments</Link>
                    </li>
                  )}
                  {(permissions?.companies?.VIEW ||
                    permissions?.companies?.ADD) && (
                    <li>
                      <Link to="/companies">Companies</Link>
                    </li>
                  )}
                  {(permissions?.stores?.VIEW || permissions?.stores?.ADD) && (
                    <li>
                      <Link to="/stores">Stores</Link>
                    </li>
                  )}
                  {(permissions?.groups?.VIEW || permissions?.groups?.ADD) && (
                    <li>
                      <Link to="/groups">Groups</Link>
                    </li>
                  )}
                  {(permissions?.roles?.VIEW || permissions?.roles?.ADD) && (
                    <li>
                      <Link to="/roles">Roles</Link>
                    </li>
                  )}
                  {(permissions?.users?.VIEW || permissions?.users?.ADD) && (
                    <li>
                      <Link to="/users">Users</Link>
                    </li>
                  )}
                  {(permissions?.dispositions?.VIEW ||
                    permissions?.dispositions?.ADD) && (
                    <li>
                      <Link to="/dispositions">Dispositions</Link>
                    </li>
                  )}

                  {(permissions?.saCalls?.VIEW ||
                    permissions?.saCalls?.ADD) && (
                    <li>
                      <Link to="/service-advisors">Service Advisors</Link>
                    </li>
                  )}

                  {(permissions?.goals?.VIEW || permissions?.goals?.ADD) && (
                    <li>
                      <Link to="/goals">
                        {/* <FontAwesomeIcon
                    icon={faBullseye}
                    style={{ marginRight: "10px" }}
                  /> */}
                        KPI Goals
                      </Link>
                    </li>
                  )}

                  {(permissions?.manualKpis?.VIEW ||
                    permissions?.manualKpis?.ADD) && (
                    <li>
                      <Link to="/manual-kpis">
                        {/* <FontAwesomeIcon
                    icon={faChartBar}
                    style={{ marginRight: "10px" }}
                  /> */}
                        Manually Added KPIs
                      </Link>
                    </li>
                  )}
                  {/* {(permissions?.manualKpisEntries?.VIEW ||
                    permissions?.manualKpisEntries?.ADD) && (
                    <li>
                      <Link to="/holidays-management">Holidays Management</Link>
                    </li>
                  )} */}

                  {/* <li>
                    <Link to="/targets">Targets</Link>
                  </li> */}
                </ul>
              </li>
            }
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
