import {
  FETCH_PERMISSIONS,
  PERMISSIONS_ERROR,
  SET_PERMISSIONS,
} from "./actionTypes"

export const fetchPermissions = () => {
  return {
    type: FETCH_PERMISSIONS,
  }
}

export const setPermissions = user => {
  return {
    type: SET_PERMISSIONS,
    payload: { permissions: user?.roles?.[0]?.permissions, user },
  }
}

export const permissionsError = error => {
  return {
    type: PERMISSIONS_ERROR,
    payload: { error },
  }
}
