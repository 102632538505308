// import "antd/dist/antd.css"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { Routes, Route, useNavigate } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import { useDispatch } from "react-redux"
import { logoutUser } from "store/actions"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./global.css"
import RouteWrapper from "components/RouteWrapper"
// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// import fakeBackend from "./helpers/AuthType/fakeBackend";

// Activating fake backend
// fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {
  const selectLayoutState = state => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, layout => ({
    layoutType: layout.layoutType,
  }))

  const { layoutType } = useSelector(LayoutProperties)
  const dispatch = useDispatch()
  const history = useNavigate()

  const Layout = getLayout(layoutType)

  //handle log out if all tabs closed
  useEffect(() => {
    const handleEndSession = () => {
      const lastClosedTime = localStorage.getItem("lastClosedTime")
      if (
        localStorage.getItem("tabCount") === "0" &&
        !localStorage.getItem("rememberMe") &&
        lastClosedTime
      ) {
        const currentTime = new Date().getTime()
        const timeDifference = currentTime - parseInt(lastClosedTime)
        const minutesDifference = timeDifference / (1000 * 60)
        if (minutesDifference >= 0.2) {
          dispatch(logoutUser(history))
        }
      }
    }

    const incrementTabCount = () => {
      const tabCount = localStorage.getItem("tabCount")
        ? parseInt(localStorage.getItem("tabCount"))
        : 0
      localStorage.setItem("tabCount", tabCount + 1)
    }

    const decrementTabCount = () => {
      const tabCount = parseInt(localStorage.getItem("tabCount")) || 0
      if (tabCount > 0) {
        localStorage.setItem("tabCount", tabCount - 1)
      }
      if (tabCount === 1) {
        // Store the datetime when the last tab is closed
        localStorage.setItem("lastClosedTime", new Date().getTime().toString())
      }
    }

    handleEndSession()
    incrementTabCount()

    window.addEventListener("beforeunload", decrementTabCount)

    return () => {
      window.removeEventListener("beforeunload", decrementTabCount)
    }
  }, [])

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ToastContainer autoClose={2000} />
        <Routes>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <RouteWrapper>
                  <NonAuthLayout>{route.component}</NonAuthLayout>
                </RouteWrapper>
              }
              key={idx}
              exact={true}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <RouteWrapper>
                  <Authmiddleware>
                    {route.withoutLayout ? (
                      route.component
                    ) : (
                      <Layout>{route.component}</Layout>
                    )}
                  </Authmiddleware>
                </RouteWrapper>
              }
              key={idx}
              exact={true}
            />
          ))}
        </Routes>
      </LocalizationProvider>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
