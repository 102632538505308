import { takeEvery, put, call } from "redux-saga/effects"
import { FETCH_ROLLUP_REPORT } from "./actionTypes"
import { setRollupReport } from "./actions"
import { getRollupReport } from "helpers/fakebackend_helper"

function* fetchRollupReport({ payload: { queryParams } }) {
  try {
    const response = yield call(getRollupReport, queryParams)
    yield put(setRollupReport(response))
  } catch (error) {
    console.log("error", error)
  }
}

function* rollupReportsSaga() {
  yield takeEvery(FETCH_ROLLUP_REPORT, fetchRollupReport)
}

export default rollupReportsSaga
