import {
  EDIT_AGENT,
  FETCH_AGENTS,
  AGENT_ERROR,
  EDIT_AGENT_SUCCESS,
  SET_AGENTS,
} from "./actionTypes"

const initialState = {
  errorMsg: "",
  successMsg: "",
  loading: false,
  agents: [],
}

const agent = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_AGENT:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        // loading: true,
      }
      break
    case FETCH_AGENTS:
      state = {
        ...state,
        agents: [],
        loading: true,
      }
      break

    case SET_AGENTS:
      state = {
        ...state,
        loading: false,
        agents: action.payload.agents,
      }
      break
    case EDIT_AGENT_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload.msg,
        loading: false,
        agents: state.agents.map(item =>
          item.id === action.payload.agent.id
            ? { ...item, ...action.payload.agent }
            : item
        ),
      }
      break
    case AGENT_ERROR:
      state = {
        ...state,
        errorMsg: action.payload,
        successMsg: "",
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default agent
