import React, { useEffect, useState } from "react"
import EditIcon from "assets/icons/EditIcon"
import DeleteIcon from "assets/icons/DeleteIcon"
import AgGridTable from "components/Common/AgGridTable"
import PageContainer from "components/Common/PageContainer"
import { Button, Form, Spinner } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons"

import {
  faCalendarCheck,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { fetchCalls, resetCalls } from "store/actions"
import { format } from "date-fns"
import DeleteModal from "components/Common/DeleteModal"
import { axiosApi } from "helpers/api_helper"
import { POST_CALL } from "helpers/url_helper"
import { Tooltip } from "react-tooltip"
import moment from "moment"
import { useFormik } from "formik"
import {
  customDateSelectFieldOptions,
  datesOptions,
  datesSelectFieldOptions,
  isCustomDateOption,
  tzMoment,
} from "constants/datesOptions"
import * as Yup from "yup"
import ScopeSelector from "components/Common/ScopeSelector"
import SelectField from "components/Form/SelectField"
import CustomRangePicker from "components/Form/CustomRangePicker"

const moduleName = "calls"

function comparator(filterLocalDateAtMidnight, cellValue) {
  if (!cellValue || !filterLocalDateAtMidnight) return -1

  //using moment js
  var dateAsString = moment(cellValue).format("DD/MM/YYYY")
  var dateParts = dateAsString.split("/")
  var cellDate = new Date(
    Number(dateParts[2]),
    Number(dateParts[1]) - 1,
    Number(dateParts[0])
  )

  if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
    return 0
  }

  if (cellDate < filterLocalDateAtMidnight) {
    return -1
  }

  if (cellDate > filterLocalDateAtMidnight) {
    return 1
  }
  return -1
}

const filterParams = {
  comparator,
}
const CallRecords = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [currentId, setCurrentId] = useState()
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [rangePickerKey, setRangePickerKey] = useState(0)

  //permissions
  const PermissionsSelector = createSelector(
    state => state.Permissions,
    perm => ({
      permissions: perm.permissions,
      user: perm.user,
    })
  )
  const { permissions, user } = useSelector(PermissionsSelector)
  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    // validateOnMount: true,
    // isInitialValid: false,
    initialValues: {
      scope: user?.scopes?.map(({ id }) => `${id}`),
      selectedDateOption: {
        value: datesOptions.today.value,
        label: datesOptions.today.title,
        customAbbreviation: datesOptions.today.secondaryTitle,
      },
      startDate: "",
      endDate: "",
    },
    validationSchema: Yup.object({
      startDate: Yup.string().when(
        "selectedDateOption",
        ([selectedDateOption], schema) =>
          selectedDateOption.value === datesOptions.custom.value
            ? schema.required("Please add start date")
            : schema.optional()
      ),
      endDate: Yup.string().when(
        "selectedDateOption",
        ([selectedDateOption], schema) =>
          selectedDateOption.value === datesOptions.custom.value
            ? schema.required("Please add end date")
            : schema.optional()
      ),
      scope: Yup.array().min(1, "Scope Is Required"),

      selectedDateOption: Yup.object().required("Please select a date option"),
    }),
    onSubmit: values => {},
  })

  const CallProperties = createSelector(
    state => state.Call,
    call => ({
      loading: call.loading,
      calls: call.calls,
    })
  )

  const { loading, calls } = useSelector(CallProperties)

  const isCustomDate = isCustomDateOption(
    validation.values.selectedDateOption?.value
  )

  function refreshDatePicker() {
    setRangePickerKey(oldVal => oldVal + 1)
  }
  function handleCustomRangeChange(dateStrings) {
    if (dateStrings.some(el => !el)) {
      return
    }

    const [startStr, endStr] = dateStrings || []
    validation.setFieldValue("startDate", startStr)
    validation.setFieldValue("endDate", endStr)

    const startStrTitle = startStr
      ? tzMoment(startStr).format("MM/DD/YYYY")
      : ""
    const endStrTitle = endStr ? tzMoment(endStr).format("MM/DD/YYYY") : ""
    const customAbbr =
      startStrTitle && endStrTitle ? `${startStrTitle} - ${endStrTitle}` : ""
    validation.setFieldValue("selectedDateOption", {
      ...validation.values.selectedDateOption,
      customAbbreviation: customAbbr,
    })
  }

  const columns = [
    { field: "id", width: "60px", headerName: "#" },
    {
      field: "date",
      filter: false,
      headerName: "Date",
      valueFormatter: params =>
        moment(params.data?.date).format("MM/DD/YYYY hh:mm A"),
      // filter: "agDateColumnFilter",
      // filterParams,
    },
    { field: "agent", filter: true, headerName: "Agent" },
    { field: "storeName", filter: true, headerName: "Store Name" },
    { field: "disposition", filter: true, headerName: "Disposition" },
    { field: "ibOb", filter: true, headerName: "Type" },
    { field: "clientName", filter: true, headerName: "Client Name" },
    { field: "clientPhone", filter: true, headerName: "Client Phone" },
    { field: "vin", filter: true, headerName: "VIN", width: "120px" },
    {
      field: "notes",
      filter: true,
      width: "100px",
      headerName: "Notes",
      cellRenderer: params => {
        return (
          <div className="d-flex align-items-center h-100">
            {params.data.notes && (
              <div
                data-tooltip-id="viewInfo"
                data-tooltip-content={params.data.notes}
              >
                <FontAwesomeIcon
                  fontSize={18}
                  color="grey"
                  icon={faCommentDots}
                />
              </div>
            )}
          </div>
        )
      },
    },
    { field: "serviceAdvisor", filter: true, headerName: "SA" },
    {
      field: "isConnected",
      filter: true,
      headerName: "Connected",
      width: "130px",
      cellRenderer: params => {
        const { is_ob, is_connected } = params.data || {}

        return is_ob && is_connected ? (
          <FontAwesomeIcon color="green" icon={faCheck} />
        ) : is_ob && !is_connected ? (
          <FontAwesomeIcon color="red" icon={faClose} />
        ) : (
          <div>-</div>
        )
      },
    },
    {
      field: "appointmentDate",
      filter: true,
      headerName: "Appt",
      width: "100px",
      cellRenderer: params => {
        return (
          <div className="d-flex align-items-center h-100">
            {params.data.appointmentDate && (
              <div
                data-tooltip-id="viewInfo"
                data-tooltip-content={moment(
                  params.data?.appointmentDate
                ).format("MM/DD/YYYY hh:mm A")}
              >
                <FontAwesomeIcon
                  fontSize={18}
                  color="grey"
                  icon={faCalendarCheck}
                />
              </div>
            )}
          </div>
        )
      },
    },
    {
      field: "am_pm",
      filter: true,
      headerName: "AM/PM",
      width: "100px",
    },
    {
      headerName: "",
      sortable: false,
      cellRenderer: params => {
        return (
          <div className="d-flex align-items-center h-100 gap-2">
            {actions(params.data.id)}
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    if (!calls?.length) {
      setRows([])
      return
    }

    const tableRows = calls.map(item => ({
      id: item.call_id,
      date: new Date(item.createdAt),
      agent: item.agent?.name,
      storeName: item.store?.name,
      disposition: item.disposition?.name,
      ibOb: item.is_ib
        ? "IB"
        : item.is_ob
        ? "OB"
        : item.is_internal
        ? "Internal"
        : "",
      clientName: item.client_name,
      clientPhone: item.client_phone,
      vin: item.vin,
      notes: item.notes,
      serviceAdvisor: item.sa?.name,
      am_pm: item.appt_date ? moment(item.appt_date).format("A") : "",
      appointmentDate: item.appt_date ? new Date(item.appt_date) : null,
      is_ob: item.is_ob,
      is_connected: item.is_connected,
    }))
    setRows(tableRows)
  }, [calls])

  useEffect(() => {
    const fetch = async () => {
      const errors = await validation.validateForm()
      if (Object.values(errors)?.length) {
        dispatch(resetCalls())
        return
      }

      const { values } = validation
      const currentDateOption = Object.values(datesOptions).find(
        ({ value }) => value === values.selectedDateOption.value
      )

      // if scope has "1" which is the whole system scope, then send empty scope as its optional in the api and will return the report for the whole system
      const updatedScope = values.scope.includes("1") ? [] : values.scope

      let startDate, endDate
      if (isCustomDateOption(currentDateOption.value)) {
        startDate = values.startDate
        endDate = values.endDate
      } else {
        startDate = currentDateOption.startDate
        endDate = currentDateOption.endDate
      }

      dispatch(
        fetchCalls({
          storesIds: updatedScope,
          startDate,
          endDate,
        })
      )
    }
    fetch()
  }, [validation.values])

  const actions = id => {
    return (
      <>
        {permissions?.[moduleName]?.EDIT && (
          <div onClick={() => navigate("/calls/" + id)}>
            <EditIcon />
          </div>
        )}
        {permissions?.[moduleName]?.DELETE && (
          <div
            onClick={() => {
              setShowDeleteModal(true)
              setCurrentId(id)
            }}
          >
            <DeleteIcon />
          </div>
        )}
      </>
    )
  }

  const handleDelete = async () => {
    setDeleteLoading(true)
    try {
      await axiosApi.delete(POST_CALL + "/" + currentId)
      dispatch(fetchCalls())
      setShowDeleteModal(false)
    } catch (err) {
      console.log("delete err", err)
    } finally {
      setDeleteLoading(false)
    }
  }

  return (
    <PageContainer>
      {/* MODALS */}
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => setShowDeleteModal(false)}
        loading={deleteLoading}
      />
      {/*  */}

      <div className="d-flex justify-content-between mb-4">
        <h1 className="page-title">Call Records</h1>
        {permissions?.[moduleName]?.ADD && (
          <Button
            color="primary"
            style={{ height: "36px" }}
            onClick={() => navigate("new")}
          >
            Add Call
          </Button>
        )}
      </div>

      <div>
        <Form
          className="content-container"
          style={{ marginBottom: "-65px" }}
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div
            className="row pb-4"
            style={{ background: "#fff", rowGap: "20px" }}
          >
            <div className="col-lg-3 col-12">
              <ScopeSelector
                validation={validation}
                onChange={ids => {
                  validation.setFieldValue("scope", ids)
                }}
                selectedScopes={validation.values.scope}
                hasInitialValue={true}
              />
            </div>
            <div className="col-lg-4 col-12">
              <SelectField
                label="Date"
                name="selectedDateOption"
                validation={validation}
                required
                options={customDateSelectFieldOptions}
                onChange={option => {
                  validation.setFieldValue("selectedDateOption", option)
                  if (isCustomDateOption(option.value)) {
                    validation.setFieldValue("startDate", "")
                    validation.setFieldValue("endDate", "")
                    refreshDatePicker()
                  }
                }}
              />
              {isCustomDate && !validation.values.startDate && (
                <div key={rangePickerKey} className="">
                  <CustomRangePicker
                    onChangeHandler={handleCustomRangeChange}
                  />
                </div>
              )}
            </div>
          </div>
        </Form>
        {loading ? (
          <div className="w-full d-flex justify-content-center align-items-center pt-4">
            <Spinner />
          </div>
        ) : (
          <>
            {!!permissions?.[moduleName]?.VIEW &&
              validation.isValid &&
              !validation.isValidating &&
              !!rows.length && (
                <AgGridTable
                  rowState={[rows, setRows]}
                  columns={columns}
                  qiuckFilter={true}
                />
              )}
          </>
        )}
      </div>
    </PageContainer>
  )
}

export default CallRecords
