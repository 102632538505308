import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import AddEditCompany from "pages/Companies/AddEditCompany"
import Departments from "pages/Departments"
import AddEditDepartment from "pages/Departments/AddEditDepartment"
import Stores from "pages/Stores"
import AddEditStore from "pages/Stores/AddEditStore"
import Companies from "pages/Companies"
import Groups from "pages/Groups"
import AddEditGroup from "pages/Groups/AddEditGroup"
import Users from "pages/Users"
import AddEditUser from "pages/Users/AddEditUser"
import Roles from "pages/Roles"
import AddEditRole from "pages/Roles/AddEditRole"
import Dispositions from "pages/Dispositions"
import AddEditDisposition from "pages/Dispositions/AddEditDisposition"
import CallRecords from "pages/CallRecords"
import ResetPassword from "pages/Authentication/ResetPassword"
import ServiceAdvisors from "pages/ServiceAdvisors"
import AddEditServiceAdvisor from "pages/ServiceAdvisors/AddEditServiceAdvisor"
import Agents from "pages/Agents"
import Targets from "pages/Targets"
import AddEditTarget from "pages/Targets/AddEditTarget"
import NewCallForm from "pages/CallRecords/NewCallForm"
import EditCall from "pages/CallRecords/EditCall"
import Dashboard2 from "pages/Dashboard/Dashboard2"
import NewCall from "pages/CallRecords/NewCall"
import Goals from "pages/Goals"
import AddEditGoal from "pages/Goals/AddEditGoal"
import AddEditGoalEntry from "pages/GoalsEntries/AddEditGoalEntry"
import GoalsEntries from "pages/GoalsEntries"
import Kpis from "pages/Kpis"
import AddEditKpi from "pages/Kpis/AddEditKpi"
import KpisEntries from "pages/KpisEntries"
import AddEditKpiEntry from "pages/KpisEntries/AddEditKpiEntry"
import ServiceAdvisorsReports from "pages/ServiceAdvisorsReports"
import IndividualSummary from "pages/IndividualSummary"
import EntityReports from "pages/EntityReports"
import RollupReport from "pages/RollupReport"
import StackRankingReport from "pages/StackRankingReport"
import TeamTotalReport from "pages/TeamTotalReport"
import MonthOverMonthReport from "pages/MonthOverMonthReport"
import MSTargetReport from "pages/MSTargetReport"
import ChecklistReport from "pages/ChecklistReport"
import Calendar from "pages/Calendar"
import HolidaysManagement from "pages/HolidaysManagement"

import AddEditHoliday from "pages/HolidaysManagement/AddEditHoliday"
import TVDashboard from "pages/TVDashboard"

const authProtectedRoutes = [
  {
    path: "/manager-dashboard",
    component: <Dashboard />,
    documentTitle: "Dashboard",
  },
  {
    path: "/agent-dashboard",
    component: <Dashboard />,
    documentTitle: "Dashboard",
  },
  {
    path: "/dashboard2",
    component: <Dashboard2 />,
    documentTitle: "Dashboard2",
  },
  // //profile
  { path: "/profile", component: <UserProfile />, documentTitle: "Profile" },
  //companies
  {
    path: "/companies",
    component: <Companies />,
    routes: [{ path: "new", component: <AddEditCompany /> }],
    documentTitle: "Companies",
  },
  {
    path: "/companies/new",
    component: <AddEditCompany />,
    documentTitle: "Add Company",
  },
  {
    path: "/companies/:id",
    component: <AddEditCompany />,
    documentTitle: "Edit Company",
  },
  //departments
  {
    path: "/departments",
    component: <Departments />,
    documentTitle: "Departments",
  },
  {
    path: "/departments/new",
    component: <AddEditDepartment />,
    documentTitle: "Add Department",
  },
  {
    path: "/departments/:id",
    component: <AddEditDepartment />,
    documentTitle: "Edit Department",
  },
  //stores
  { path: "/stores", component: <Stores />, documentTitle: "Stores" },
  {
    path: "/stores/new",
    component: <AddEditStore />,
    documentTitle: "Add Store",
  },
  {
    path: "/stores/:id",
    component: <AddEditStore />,
    documentTitle: "Edit Store",
  },
  //groups
  { path: "/groups", component: <Groups />, documentTitle: "Groups" },
  {
    path: "/groups/new",
    component: <AddEditGroup />,
    documentTitle: "Add Group",
  },
  {
    path: "/groups/:id",
    component: <AddEditGroup />,
    documentTitle: "Edit Group",
  },
  //users
  { path: "/users", component: <Users />, documentTitle: "Users" },
  {
    path: "/users/new",
    component: <AddEditUser />,
    documentTitle: "Add User",
  },
  {
    path: "/users/:id",
    component: <AddEditUser />,
    documentTitle: "Edit User",
  },
  //roles
  { path: "/roles", component: <Roles />, documentTitle: "Roles" },
  {
    path: "/roles/new",
    component: <AddEditRole />,
    documentTitle: "Add Role",
  },
  {
    path: "/roles/:id",
    component: <AddEditRole />,
    documentTitle: "Edit Role",
  },
  //dispositions
  {
    path: "/dispositions",
    component: <Dispositions />,
    documentTitle: "Dispositions",
  },
  {
    path: "/dispositions/new",
    component: <AddEditDisposition />,
    documentTitle: "Add Disposition",
  },
  {
    path: "/dispositions/:id",
    component: <AddEditDisposition />,
    documentTitle: "Edit Disposition",
  },
  //calls
  { path: "/calls", component: <CallRecords />, documentTitle: "Calls" },
  {
    path: "/calls/:callId",
    component: <EditCall />,
    documentTitle: "Edit Call",
  },
  { path: "/calls/new", component: <NewCall />, documentTitle: "New Call" },
  //serviceAdvisors
  {
    path: "/service-advisors",
    component: <ServiceAdvisors />,
    documentTitle: "Service Advisors",
  },
  {
    path: "/service-advisors/new",
    component: <AddEditServiceAdvisor />,
    documentTitle: "Add Service Advisor",
  },
  {
    path: "/service-advisors/:id",
    component: <AddEditServiceAdvisor />,
    documentTitle: "Edit Service Advisor",
  },
  //agents
  { path: "/agents", component: <Agents />, documentTitle: "Agents" },
  //targets
  { path: "/targets", component: <Targets />, documentTitle: "Targets" },
  {
    path: "/targets/new",
    component: <AddEditTarget />,
    documentTitle: "Add Target",
  },
  //Goals
  { path: "/goals", component: <Goals />, documentTitle: "Goals" },
  {
    path: "/goals/new",
    component: <AddEditGoal />,
    documentTitle: "Add Goal",
  },
  {
    path: "/goals/:id",
    component: <AddEditGoal />,
    documentTitle: "Edit Goal",
  },
  //Goals Entries
  {
    path: "/goal-entries",
    component: <GoalsEntries />,
    documentTitle: "Goals Entries",
  },
  {
    path: "/goal-entries/new",
    component: <AddEditGoalEntry />,
    documentTitle: "Add Goal Entry",
  },
  {
    path: "/goal-entries/:id",
    component: <AddEditGoalEntry />,
    documentTitle: "Edit Goal Entry",
  },
  //Manual KPIs
  { path: "/manual-kpis", component: <Kpis />, documentTitle: "Manual KPIs" },
  {
    path: "/manual-kpis/new",
    component: <AddEditKpi />,
    documentTitle: "Add Manual KPI",
  },
  {
    path: "/manual-kpis/:id",
    component: <AddEditKpi />,
    documentTitle: "Edit Manual KPI",
  },
  //Manual KPIs Entries
  {
    path: "/manual-kpi-entries",
    component: <KpisEntries />,
    documentTitle: "KPIs Entries",
  },
  {
    path: "/manual-kpi-entries/new",
    component: <AddEditKpiEntry />,
    documentTitle: "Add Manual KPI Entry",
  },
  {
    path: "/manual-kpi-entries/:id",
    component: <AddEditKpiEntry />,
    documentTitle: "Edit Manual KPI Entry",
  },
  {
    path: "/service-advisors-report",
    component: <ServiceAdvisorsReports />,
    documentTitle: "Service Advisors Reports",
  },
  // -- Reports --
  // Individual Summary
  {
    path: "/individual-summary",
    component: <IndividualSummary />,
    documentTitle: "Individual Summary Report",
  },
  // Entity Reports
  {
    path: "/entity-report",
    component: <EntityReports />,
    documentTitle: "Entity Report",
  },
  // Rollup Reports
  {
    path: "/rollup-report",
    component: <RollupReport />,
    documentTitle: "Rollup Report",
  },
  // Stack Ranking Reports
  {
    path: "/stack-ranking-report",
    component: <StackRankingReport />,
    documentTitle: "Stack Ranking Report",
  },
  // Team Total Reports
  {
    path: "/team-total-report",
    component: <TeamTotalReport />,
    documentTitle: "Team Total Report",
  },

  // MS Target Report
  {
    path: "/ms-target-report",
    component: <MSTargetReport />,
    documentTitle: "MS Target Report",
  },
  // Checklist Report
  {
    path: "/checklist-report",
    component: <ChecklistReport />,
    documentTitle: "Daily Checklist Report",
  },
  {
    path: "/holidays-management",
    component: <HolidaysManagement />,
    documentTitle: "Holidays Management",
  },
  {
    path: "/holidays-management/:id",
    component: <AddEditHoliday />,
    documentTitle: "Add/Edit Holiday",
  },

  // Checklist Report
  {
    path: "/dashboard",
    component: <TVDashboard />,
    documentTitle: "Dashboard",
    // withoutLayout: true,
  },

  // month over month report
  // {
  //   path: "/month-over-month-report",
  //   component: <MonthOverMonthReport />,
  //   documentTitle: "Month Over Month Report",
  // },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/manager-dashboard" />,
  },
  {
    path: "/config",
    exact: true,
    component: <Navigate to="/companies" />,
  },
]

const publicRoutes = [
  { path: "/login", component: <Login />, documentTitle: "Login" },
  { path: "/logout", component: <Logout />, documentTitle: "Logout" },
  {
    path: "/forgot-password",
    component: <ForgetPwd />,
    documentTitle: "Forgot Password",
  },
  {
    path: "/auth/forget-password/:token",
    component: <ResetPassword />,
    documentTitle: "Reset Password",
  },
  {
    path: "/auth/invitation/:token",
    component: <Register />,
    documentTitle: "Register",
  },
]

export { authProtectedRoutes, publicRoutes }
