import React, { useEffect, useState } from "react"
import EditIcon from "assets/icons/EditIcon"
import DeleteIcon from "assets/icons/DeleteIcon"
import AgGridTable from "components/Common/AgGridTable"
import PageContainer from "components/Common/PageContainer"
import { Badge, Button, Spinner } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { fetchServiceAdvisors, fetchUsers } from "store/actions"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { format } from "date-fns"
import DeleteModal from "components/Common/DeleteModal"
import { axiosApi } from "helpers/api_helper"
import { DELETE_USER, POST_SERRVICE_ADVISOR } from "helpers/url_helper"

const moduleName = "saCalls"

const ServiceAdvisors = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  //permissions
  const PermissionsSelector = createSelector(
    state => state.Permissions,
    perm => ({
      permissions: perm.permissions,
    })
  )
  const { permissions } = useSelector(PermissionsSelector)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [currentId, setCurrentId] = useState()
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [deleteError, setDeleteError] = useState()

  const [rows, setRows] = useState([])

  const columns = [
    { field: "name", filter: true, headerName: "Service Advisor" },
    { field: "email", filter: true, headerName: "Email" },
    { field: "department", filter: true, headerName: "Department" },
    { field: "store", filter: true, headerName: "Store" },
    {
      field: "status",
      filter: true,
      headerName: "Status",
      cellRenderer: params => {
        return (
          <Badge
            color={
              params.data.status === "Active"
                ? "success"
                : params.data.status === "Inactive"
                ? "danger"
                : "secondary"
            }
          >
            {params.data.status}
          </Badge>
        )
      },
    },
    {
      headerName: "",
      sortable: false,
      cellRenderer: params => {
        return (
          <div className="d-flex align-items-center h-100 gap-2">
            {actions(params.data.id)}
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    dispatch(fetchServiceAdvisors())
  }, [])

  const ServiceAdvisorProperties = createSelector(
    state => state.ServiceAdvisor,
    sa => ({
      loading: sa.loading,
      serviceAdvisors: sa.serviceAdvisors,
    })
  )

  const { loading, serviceAdvisors } = useSelector(ServiceAdvisorProperties)

  useEffect(() => {
    setRows(
      serviceAdvisors
        ?.sort((a, b) => a.name.localeCompare(b.name))
        ?.map(item => ({
          id: item.user_id,
          name: item.name,
          email: item.email,
          department: item.departments?.map(el => el.type_name).join(", "),
          status: item.status?.[0] + item.status?.substring(1)?.toLowerCase(),
          store: item.scopes?.[0]?.name,
        }))
    )
  }, [serviceAdvisors])

  const actions = id => {
    return (
      <>
        {permissions?.[moduleName]?.EDIT && (
          <div
            onClick={() =>
              navigate("/service-advisors/" + id, {
                state: serviceAdvisors?.find(item => item.user_id === id),
              })
            }
          >
            <EditIcon />
          </div>
        )}
        {permissions?.[moduleName]?.DELETE && (
          <div
            onClick={() => {
              setShowDeleteModal(true)
              setCurrentId(id)
            }}
          >
            <DeleteIcon />
          </div>
        )}
      </>
    )
  }
  const handleDelete = async () => {
    setDeleteLoading(true)
    setDeleteError(null)
    try {
      await axiosApi.delete(DELETE_USER + currentId)
      dispatch(fetchServiceAdvisors())
      setShowDeleteModal(false)
    } catch (err) {
      setDeleteError(err.response?.data?.message)
    } finally {
      setDeleteLoading(false)
    }
  }

  return (
    <PageContainer>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setShowDeleteModal(false)
          setDeleteError(null)
        }}
        errorMsg={deleteError}
        loading={deleteLoading}
      />
      <div className="d-flex justify-content-between mb-4">
        <h1 className="page-title">Service Advisors</h1>
        {permissions?.[moduleName]?.ADD && (
          <Button
            color="primary"
            style={{ height: "36px" }}
            onClick={() => navigate("new")}
          >
            Add Service Advisor
          </Button>
        )}
      </div>
      {loading ? (
        <div className="w-full d-flex justify-content-center align-items-center pt-4">
          <Spinner />
        </div>
      ) : (
        <>
          {permissions?.[moduleName]?.VIEW && (
            <AgGridTable
              rowState={[rows, setRows]}
              columns={columns}
              qiuckFilter={true}
            />
          )}
        </>
      )}
    </PageContainer>
  )
}

export default ServiceAdvisors
