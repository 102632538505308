import {
  ADD_COMPANY,
  FETCH_COMPANIES,
  COMPANY_ERROR,
  COMPANY_SUCCESS,
  SET_COMPANIES,
  UPDATE_COMPANY,
} from "./actionTypes"

const initialState = {
  errorMsg: "",
  successMsg: "",
  loading: false,
  companies: [],
}

const company = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COMPANY:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case FETCH_COMPANIES:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        companies: [],
        loading: true,
      }
      break
    case UPDATE_COMPANY:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case SET_COMPANIES:
      state = {
        ...state,
        loading: false,
        companies: action.payload.companies,
      }
      break
    case COMPANY_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload.msg,
        loading: false,
      }
      break
    case COMPANY_ERROR:
      state = { ...state, errorMsg: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default company
