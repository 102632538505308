import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"
import { POST_LOGIN } from "./url_helper"

//pass new generated access token here
const token = accessToken

//apply base url for axios
export const API_URL = "https://ppgkpi-dev-api.ppgauto.com" //dev
// export const API_URL = "https://ppg-kpi-api.ppgauto.com" //prod

export const axiosApi = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  // headers: {
  //   "Access-Control-Allow-Origin": "*",
  //   "Content-Type": "application/json",
  // },
})

// axiosApi.defaults.headers.common["Authorization"] = token

// axiosApi.defaults.headers.common["withCredentials"] = true

// axiosApi.defaults.headers.common["Cookie"] =
//   "Authorization=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQGFkbWluLmNvbSIsImlhdCI6MTcwOTQwMDI0OSwiZXhwIjoxNzEwMDA1MDQ5fQ.fnONwAngvsbViRy_46V9e6CFNS8wf2ZsvRO6Ei18bRY"

axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      const requestUrl = error.config.url
      if (requestUrl !== POST_LOGIN) {
        // Check if the request URL is not the login endpoint
        localStorage.removeItem("authUser")
        window.location.href = "/login"
      }
    }
    return Promise.reject(error)
  }
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
