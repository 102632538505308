import { takeEvery, put, call } from "redux-saga/effects"

// Login Redux States
import {
  ADD_KPI_ENTRY,
  FETCH_KPIS_ENTRIES,
  UPDATE_KPI_ENTRY,
} from "./actionTypes"
import { kpiEntrySuccess, kpiEntryError, setKpisEntries } from "./actions"

//Include Both Helper File with needed methods
import {
  getKpisEntries,
  postKpiEntry,
  patchKpiEntry,
} from "../../helpers/fakebackend_helper"

import { isArray } from "lodash"
function* addKpiEntry({ payload: { kpiEntry, history } }) {
  try {
    yield call(postKpiEntry, kpiEntry)
    yield put(kpiEntrySuccess("Created Successfully"))
    history("/manual-kpi-entries")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(kpiEntryError(errMsg))
  }
}

function* updateKpiEntry({ payload: { id, kpiEntry, history } }) {
  try {
    yield call(patchKpiEntry, id, kpiEntry)
    yield put(kpiEntrySuccess("Updated Successfully"))
    history("/manual-kpi-entries")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(kpiEntryError(errMsg))
  }
}

function* fetchKpisEntries() {
  try {
    const response = yield call(getKpisEntries)
    yield put(setKpisEntries(response))
  } catch (error) {
    console.log("error", error)
  }
}

function* kpiEntrySaga() {
  yield takeEvery(ADD_KPI_ENTRY, addKpiEntry)
  yield takeEvery(FETCH_KPIS_ENTRIES, fetchKpisEntries)
  yield takeEvery(UPDATE_KPI_ENTRY, updateKpiEntry)
}

export default kpiEntrySaga
