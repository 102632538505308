import { takeEvery, put, call } from "redux-saga/effects"
import { FETCH_ENTITY_REPORTS } from "./actionTypes"
import { setEntityReports } from "./actions"
import { getEntityReports } from "../../helpers/fakebackend_helper"

function* fetchEntityReports({ payload: { queryParams } }) {
  try {
    const response = yield call(getEntityReports, queryParams)
    yield put(setEntityReports(response))
  } catch (error) {
    console.log("error", error)
  }
}

function* entityReportsSaga() {
  yield takeEvery(FETCH_ENTITY_REPORTS, fetchEntityReports)
}

export default entityReportsSaga
