import React, { useEffect, useState } from "react"
import EditIcon from "assets/icons/EditIcon"
import DeleteIcon from "assets/icons/DeleteIcon"
import AgGridTable from "components/Common/AgGridTable"
import PageContainer from "components/Common/PageContainer"
import { Button, Spinner } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { fetchDepartments } from "store/actions"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import DeleteModal from "components/Common/DeleteModal"
import { POST_DEPARTMENT } from "helpers/url_helper"
import { axiosApi } from "helpers/api_helper"

const moduleName = "departments"

const Departments = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [currentId, setCurrentId] = useState()
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [deleteError, setDeleteError] = useState()

  //permissions
  const PermissionsSelector = createSelector(
    state => state.Permissions,
    perm => ({
      permissions: perm.permissions,
    })
  )
  const { permissions } = useSelector(PermissionsSelector)

  const DepartmentProperties = createSelector(
    state => state.Department,
    dep => ({
      departments: dep.departments,
      loading: dep.loading,
    })
  )

  const { loading, departments } = useSelector(DepartmentProperties)

  useEffect(() => {
    setRows(
      departments?.map(item => ({
        id: item.department_id,
        department: item.type_name,
        description: item.description,
      }))
    )
  }, [departments])

  const [rows, setRows] = useState([])

  useEffect(() => {
    dispatch(fetchDepartments())
  }, [])

  const columns = [
    { field: "department", filter: true, headerName: "Department" },
    { field: "description", filter: true, headerName: "Description" },
    {
      headerName: "",
      sortable: false,
      cellRenderer: params => {
        console.log(params.data)
        return (
          <div className="d-flex align-items-center h-100 gap-2">
            {actions(params.data.id)}
          </div>
        )
      },
    },
  ]

  const actions = id => {
    return (
      <>
        {permissions?.[moduleName]?.EDIT && (
          <div
            onClick={() =>
              navigate("/departments/" + id, {
                state: departments?.find(item => item.department_id === id),
              })
            }
          >
            <EditIcon />
          </div>
        )}
        {permissions?.[moduleName]?.DELETE && (
          <div
            onClick={() => {
              setShowDeleteModal(true)
              setCurrentId(id)
            }}
          >
            <DeleteIcon />
          </div>
        )}
      </>
    )
  }

  const handleDelete = async () => {
    setDeleteLoading(true)
    setDeleteError(null)
    try {
      await axiosApi.delete(POST_DEPARTMENT + "/" + currentId)
      dispatch(fetchDepartments())
      setShowDeleteModal(false)
    } catch (err) {
      setDeleteError(err.response.data?.message)
    } finally {
      setDeleteLoading(false)
    }
  }

  return (
    <PageContainer>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setShowDeleteModal(false)
          setDeleteError(null)
        }}
        errorMsg={deleteError}
        loading={deleteLoading}
      />
      <div className="d-flex justify-content-between mb-4">
        <h1 className="page-title">Departments</h1>
        {permissions?.[moduleName]?.ADD && (
          <Button
            color="primary"
            style={{ height: "36px" }}
            onClick={() => navigate("new")}
          >
            Add Department
          </Button>
        )}
      </div>
      {loading ? (
        <div className="w-full d-flex justify-content-center align-items-center pt-4">
          <Spinner />
        </div>
      ) : (
        <>
          {permissions?.[moduleName]?.VIEW && (
            <AgGridTable
              rowState={[rows, setRows]}
              columns={columns}
              qiuckFilter={true}
            />
          )}
        </>
      )}
    </PageContainer>
  )
}

export default Departments
