import { convertRatioToPercentage } from "helpers/helperFns"
import React from "react"
import { Badge } from "reactstrap"

const StatisticsSection = ({ statisticsData }) => {
  const { currentReportValues = {}, previousReportValues = {} } =
    statisticsData || {}

  const avgCallsChangePercent = calculateChangePercent(
    previousReportValues.avgCalls,
    currentReportValues.avgCalls
  )

  const avgIBCallsChangePercent = calculateChangePercent(
    previousReportValues.avgIBCalls,
    currentReportValues.avgIBCalls
  )
  const avgOBCallsChangePercent = calculateChangePercent(
    previousReportValues.avgOBCalls,
    currentReportValues.avgOBCalls
  )
  return (
    <div>
      <div
        className="p-2 d-flex rounded flex-wrap"
        style={{ border: "1px dashed #000", gap: 50 }}
      >
        <div className="d-flex gap-3">
          <div>
            <p className="mb-0 text-nowrap">AVG Calls</p>
            <p className="mb-0 text-nowrap">/Day</p>
          </div>
          <div>
            <h4 className="mb-0">{currentReportValues.avgCalls}</h4>
            <CustomBadge number={avgCallsChangePercent} />
          </div>
        </div>
        <div className="d-flex gap-3">
          <div>
            <p className="mb-0 text-nowrap">AVG IB Calls</p>
            <p className="mb-0 text-nowrap">/Day</p>
          </div>
          <div>
            <h4 className="mb-0">{currentReportValues.avgIBCalls}</h4>
            <CustomBadge number={avgIBCallsChangePercent} />
          </div>
        </div>
        <div className="d-flex gap-3">
          <div>
            <p className="mb-0 text-nowrap">AVG OB Calls</p>
            <p className="mb-0 text-nowrap">/Day</p>
          </div>
          <div>
            <h4 className="mb-0">{currentReportValues.avgOBCalls}</h4>
            <CustomBadge number={avgOBCallsChangePercent} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatisticsSection

const CustomBadge = ({ number }) => {
  const badgeColor =
    parseFloat(number) === 0
      ? "warning"
      : parseFloat(number) > 0
      ? "success"
      : "danger"
  return <Badge color={badgeColor}>{number}</Badge>
}
// function calculateChangePercent(a, b) {
//   let ratio

//   if (!a && !b) {
//     ratio = 0
//   } else if (b !== 0) {
//     if (a !== 0) {
//       // new - old / new * 100
//       ratio = (b - a) / a
//     } else {
//       ratio = b
//     }
//   } else {
//     ratio = -a
//   }
//   return convertRatioToPercentage(ratio)
// }
function calculateChangePercent(a, b) {
  let ratio

  if (!a && !b) {
    ratio = 0
  } else if (b !== 0) {
    if (a !== 0) {
      // new - old / new
      ratio = (b - a) / b
    } else {
      ratio = 1
    }
  } else {
    ratio = -1
  }
  return convertRatioToPercentage(ratio)
}
