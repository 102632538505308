import { tzMoment } from "constants/datesOptions"
import {
  approximate,
  convertTimeToDate,
  getAverage,
  getAverageOfDates,
  getPercentage,
  getSum,
  isHhMm,
} from "helpers/helperFns"
const NA_CHAR = "-"

const NAME = "name"
const IB_APPTS_PERCENT = "IB Appt %"
const OB_APPTS_PERCENT = "OB Appt %"
const OB_APPTS_PER_DAY = "OB Appts Per Day"
const OB_CALLS_PER_DAY = "OB Calls Per Day"
const AVG_APPTS_PER_DAY = "Avg Appts Created Per Day"
const AVG_CALLS_PER_DAY = "Avg Calls Per Day"
const AVG_HANDLE_TIME = "Avg Handle Time"
const SHOW_PERCENT = "Show %"
const TOTAL_MS = "Total MS"
const TOTAL_TARGET = "Total Target"

export function getAverageOfGoals(goalsArr) {
  if (!goalsArr?.length) return []

  const updatedGoalsArr = goalsArr.map(goalObj => {
    const { goalEntries = [] } = goalObj

    if (!goalEntries.length) return goalObj
    const isPercentage = goalEntries[0].minstandard?.endsWith?.("%")
    const isTime = isHhMm(goalEntries[0].minstandard)

    const uniqueStoresIds = [
      ...new Set(goalEntries.map(({ goalEntity }) => goalEntity?.id)),
    ]

    let goalsGroupedByStores = []
    for (const storeId of uniqueStoresIds) {
      const sameStoreGoalEntries = goalEntries.filter(
        ({ goalEntity }) => goalEntity?.id === storeId
      )
      const minStandardDates = isTime
        ? sameStoreGoalEntries.map(({ minstandard }) =>
            convertTimeToDate(minstandard)
          )
        : []
      const targetDates = isTime
        ? sameStoreGoalEntries.map(({ target }) => convertTimeToDate(target))
        : []
      const avgMS = isTime
        ? getAverageOfDates(minStandardDates, "HH:mm")
        : getAverage(sameStoreGoalEntries.map(({ minstandard }) => minstandard))
      const avgTarget = isTime
        ? getAverageOfDates(targetDates, "HH:mm")
        : getAverage(sameStoreGoalEntries.map(({ target }) => target))
      goalsGroupedByStores.push({
        storeId,
        minstandard: avgMS,
        target: avgTarget,
      })
    }

    const minStandardDates = isTime
      ? goalsGroupedByStores.map(({ minstandard }) =>
          convertTimeToDate(minstandard)
        )
      : []
    const targetDates = isTime
      ? goalsGroupedByStores.map(({ target }) => convertTimeToDate(target))
      : []
    let avgMinstandard = isTime
      ? getAverageOfDates(minStandardDates, "HH:mm")
      : getAverage(goalsGroupedByStores.map(({ minstandard }) => minstandard))
    let avgTarget = isTime
      ? getAverageOfDates(targetDates, "HH:mm")
      : getAverage(goalsGroupedByStores.map(({ target }) => target))

    if (isPercentage) {
      avgMinstandard = `${avgMinstandard}%`
      avgTarget = `${avgTarget}%`
    }

    const averageGoalObj = {
      minstandard: avgMinstandard,
      target: avgTarget,
    }

    return {
      ...goalObj,
      goalEntries: [averageGoalObj],
    }
  })

  return updatedGoalsArr
}
function isNACell(value) {
  return (
    value === undefined || value === null || value === "" || value === NA_CHAR
  )
}

function isGoalColumn(columnField) {
  return ![TOTAL_MS, TOTAL_TARGET, NAME].includes(columnField)
}
function valueFormatter(params) {
  // if (
  //   params.value === "" ||
  //   params.value === undefined ||
  //   params.value === null
  // ) {
  //   return "-"
  // }

  return params.value
}

function cellStyle(params, goals = []) {
  const { data, colDef, value } = params
  const isNA = isNACell(value)
  const isTime = isHhMm(value)

  const rowName = data?.[NAME]
  if (["Target", "Minimum Standards"].includes(rowName) || isNA) {
    return null
  }

  const columnGoalEntries =
    goals.find(goal => {
      return goal.name === colDef.field
    })?.goalEntries || []
  const goalObj = columnGoalEntries[columnGoalEntries.length - 1]

  const minstandard = goalObj?.minstandard || (isTime ? "23:59" : 0)
  const target = goalObj?.target || (isTime ? "23:59" : 0)

  let isBetterThanTarget, isBetterThanMS
  if (isTime) {
    isBetterThanTarget = tzMoment(value, "HH:mm").isSameOrBefore(
      tzMoment(target, "HH:mm")
    )
    isBetterThanMS = tzMoment(value, "HH:mm").isSameOrBefore(
      tzMoment(minstandard, "HH:mm")
    )
  } else {
    isBetterThanTarget = parseFloat(value) >= parseFloat(target)
    isBetterThanMS = parseFloat(value) >= parseFloat(minstandard)
  }

  let isAboveTarget = false
  let isAboveMin = false
  if (isBetterThanTarget) {
    isAboveTarget = true
    isAboveMin = true
  } else if (isBetterThanMS) {
    isAboveMin = true
  }

  if (isAboveTarget) {
    //mark police cells as red
    return { backgroundColor: "green" }
  } else if (isAboveMin) {
    return { backgroundColor: "yellow" }
  } else {
    return { backgroundColor: "red" }
  }
}

export function generateTableColumns(goals = [], formattedDate) {
  let tableColumns = [
    {
      field: NAME,
      headerName: formattedDate,
      filter: true,
      pinned: "left",
    },
    {
      field: IB_APPTS_PERCENT,
      headerName: IB_APPTS_PERCENT,
      filter: true,
      valueFormatter,
      cellStyle: params => cellStyle(params, goals),
    },
    {
      field: OB_APPTS_PERCENT,
      headerName: OB_APPTS_PERCENT,
      filter: true,
      valueFormatter,
      cellStyle: params => cellStyle(params, goals),
    },
    {
      field: OB_APPTS_PER_DAY,
      headerName: OB_APPTS_PER_DAY,
      filter: true,
      valueFormatter,
      cellStyle: params => cellStyle(params, goals),
    },
    {
      field: OB_CALLS_PER_DAY,
      headerName: OB_CALLS_PER_DAY,
      filter: true,
      valueFormatter,
      cellStyle: params => cellStyle(params, goals),
    },
    {
      field: AVG_APPTS_PER_DAY,
      headerName: AVG_APPTS_PER_DAY,
      filter: true,
      valueFormatter,
      cellStyle: params => cellStyle(params, goals),
    },
    {
      field: AVG_CALLS_PER_DAY,
      headerName: AVG_CALLS_PER_DAY,
      filter: true,
      valueFormatter,
      cellStyle: params => cellStyle(params, goals),
    },
    {
      field: AVG_HANDLE_TIME,
      headerName: AVG_HANDLE_TIME,
      filter: true,
      valueFormatter,
      cellStyle: params => cellStyle(params, goals),
    },
    {
      field: SHOW_PERCENT,
      headerName: SHOW_PERCENT,
      filter: true,
      valueFormatter,
      cellStyle: params => cellStyle(params, goals),
    },
    {
      field: TOTAL_MS,
      headerName: TOTAL_MS,
      filter: true,
      valueFormatter,
    },
    {
      field: TOTAL_TARGET,
      headerName: TOTAL_TARGET,
      filter: true,
      valueFormatter,
    },
  ]

  return tableColumns
}

export function generateTableRows(report, goals, columns) {
  const goalsColumns = columns.filter(({ field }) => isGoalColumn(field))
  const goalsFields = goalsColumns.map(({ field }) => field)

  let pinnedTopRows = [
    {
      [NAME]: "Target",
    },
    {
      [NAME]: "Minimum Standards",
    },
  ]

  for (const column of columns) {
    if (column.field === NAME) continue

    if ([TOTAL_MS, TOTAL_TARGET].includes(column.field)) {
      pinnedTopRows[0] = { ...pinnedTopRows[0], [column.field]: "-" }
      pinnedTopRows[1] = {
        ...pinnedTopRows[1],
        [column.field]:
          column.field === TOTAL_MS
            ? `# hit out of ${goalsColumns.length}`
            : column.field === TOTAL_TARGET
            ? `# hit out of ${goalsColumns.length}`
            : "",
      }

      continue
    }
    const columnGoalEntries =
      goals.find(goal => {
        return goal.name === column.field
      })?.goalEntries || []
    const goalObj = columnGoalEntries[columnGoalEntries.length - 1]
    const minstandard = goalObj?.minstandard
    const target = goalObj?.target
    pinnedTopRows[0] = { ...pinnedTopRows[0], [column.field]: target }
    pinnedTopRows[1] = { ...pinnedTopRows[1], [column.field]: minstandard }
  }

  let tableRows = {}
  for (let i = 0; i < report.length; i++) {
    const dispositionObj = report[i]
    const {
      dispositionName,
      name,
      count,
      ibCalls,
      obNotConnectedCalls,
      obConnectedCalls,
      ibApptsCreated,
      obNotConnectedApptsCreated,
      obConnectedApptsCreated,
      workedDays,
      obCallsPerDay,
      avgApptsPerDay,
      avgCallsPerDay,
    } = dispositionObj
    const isTime = isHhMm(count)
    if (tableRows[name]) {
      const updatedOBNotConnectedCalls =
        (parseFloat(tableRows[name].obNotConnectedCalls) || 0) +
        (parseFloat(obNotConnectedCalls) || 0)
      const updatedOBConnectedCalls =
        (parseFloat(tableRows[name].obConnectedCalls) || 0) +
        (parseFloat(obConnectedCalls) || 0)
      const updatedOBCalls = getSum([
        updatedOBNotConnectedCalls,
        updatedOBConnectedCalls,
      ])
      const updatedOBNotConnectedApptsCreated =
        (parseFloat(tableRows[name].obNotConnectedApptsCreated) || 0) +
        (parseFloat(obNotConnectedApptsCreated) || 0)
      const updatedOBConnectedApptsCreated =
        (parseFloat(tableRows[name].obConnectedApptsCreated) || 0) +
        (parseFloat(obConnectedApptsCreated) || 0)
      const updatedOBApptsCreated = getSum([
        updatedOBNotConnectedApptsCreated,
        updatedOBConnectedApptsCreated,
      ])
      const updatedIBCalls =
        (parseFloat(tableRows[name].ibCalls) || 0) + (parseFloat(ibCalls) || 0)
      const updatedIBApptsCreated =
        (parseFloat(tableRows[name].ibApptsCreated) || 0) +
        (parseFloat(ibApptsCreated) || 0)

      const updatedOBCallsPerDay = workedDays
        ? approximate(
            (parseFloat(tableRows[name][OB_CALLS_PER_DAY]) || 0) +
              getSum([obNotConnectedCalls, obConnectedCalls]) /
                parseFloat(workedDays)
          )
        : 0
      const updatedAvgApptsPerDay = workedDays
        ? approximate(
            (parseFloat(tableRows[name][AVG_APPTS_PER_DAY]) || 0) +
              getSum([
                obConnectedApptsCreated,
                obNotConnectedApptsCreated,
                ibApptsCreated,
              ]) /
                parseFloat(workedDays)
          )
        : 0
      const updatedCallsPerDay = workedDays
        ? approximate(
            (parseFloat(tableRows[name][AVG_CALLS_PER_DAY]) || 0) +
              getSum([obNotConnectedCalls, obConnectedCalls, ibCalls]) /
                parseFloat(workedDays)
          )
        : 0
      const updatedOBApptsPerDay = workedDays
        ? approximate(
            (parseFloat(tableRows[name][OB_APPTS_PER_DAY]) || 0) +
              getSum([obConnectedApptsCreated, obNotConnectedApptsCreated]) /
                parseFloat(workedDays)
          )
        : 0

      tableRows[name] = {
        ...tableRows[name],
        [dispositionName]: isTime ? count : parseFloat(count),
        ibCalls: updatedIBCalls,
        obCalls: updatedOBCalls,
        obNotConnectedCalls: updatedOBNotConnectedCalls,
        obConnectedCalls: updatedOBConnectedCalls,
        ibApptsCreated: updatedIBApptsCreated,
        obApptsCreated: updatedOBApptsCreated,
        obNotConnectedApptsCreated: updatedOBNotConnectedApptsCreated,
        obConnectedApptsCreated: updatedOBConnectedApptsCreated,

        [IB_APPTS_PERCENT]: getPercentage(
          updatedIBApptsCreated,
          updatedIBCalls
        ),
        [OB_APPTS_PERCENT]: getPercentage(
          updatedOBApptsCreated,
          updatedOBConnectedCalls
        ),

        // per day vals
        [OB_APPTS_PER_DAY]: updatedOBApptsPerDay,

        [OB_CALLS_PER_DAY]: updatedOBCallsPerDay,
        [AVG_APPTS_PER_DAY]: updatedAvgApptsPerDay,
        [AVG_CALLS_PER_DAY]: updatedCallsPerDay,
      }
    } else {
      tableRows[name] = {
        name,
        [dispositionName]: isTime ? count : parseFloat(count),
        ibCalls: parseFloat(ibCalls),
        obNotConnectedCalls: parseFloat(obNotConnectedCalls),
        obConnectedCalls: parseFloat(obConnectedCalls),
        obCalls: getSum([obNotConnectedCalls, obConnectedCalls]),
        ibApptsCreated: parseFloat(ibApptsCreated),
        obConnectedApptsCreated: parseFloat(obConnectedApptsCreated),
        obNotConnectedApptsCreated: parseFloat(obNotConnectedApptsCreated),
        obApptsCreated: getSum([
          obConnectedApptsCreated,
          obNotConnectedApptsCreated,
        ]),
        [IB_APPTS_PERCENT]: getPercentage(
          parseFloat(ibApptsCreated),
          parseFloat(ibCalls)
        ),
        [OB_APPTS_PERCENT]: getPercentage(
          getSum([obConnectedApptsCreated, obNotConnectedApptsCreated]),
          parseFloat(obConnectedCalls)
        ),
        daysWorked: parseFloat(workedDays),

        // per day vals
        [OB_APPTS_PER_DAY]: parseFloat(workedDays)
          ? approximate(
              getSum([obConnectedApptsCreated, obNotConnectedApptsCreated]) /
                parseFloat(workedDays)
            )
          : 0,
        [OB_CALLS_PER_DAY]: parseFloat(workedDays)
          ? approximate(
              getSum([obNotConnectedCalls, obConnectedCalls]) /
                parseFloat(workedDays)
            )
          : 0,
        [AVG_APPTS_PER_DAY]: parseFloat(workedDays)
          ? approximate(
              getSum([
                obConnectedApptsCreated,
                obNotConnectedApptsCreated,
                ibApptsCreated,
              ]) / parseFloat(workedDays)
            )
          : 0,
        [AVG_CALLS_PER_DAY]: parseFloat(workedDays)
          ? approximate(
              getSum([obNotConnectedCalls, obConnectedCalls, ibCalls]) /
                parseFloat(workedDays)
            )
          : 0,
      }
    }
  }
  tableRows = Object.values(tableRows)
  tableRows = tableRows.map(row => {
    let msHit = 0
    let targetHit = 0

    let updatedRow = {
      ...row,
    }

    for (const field of goalsFields) {
      const fieldValue = row[field]
      const isTime = isHhMm(fieldValue)

      const fieldMS = pinnedTopRows[1]?.[field] || 0
      const fieldTarget = pinnedTopRows[0]?.[field] || 0

      let isBetterThanTarget, isBetterThanMS
      if (isTime) {
        isBetterThanTarget = tzMoment(fieldValue, "HH:mm").isSameOrBefore(
          tzMoment(fieldTarget, "HH:mm")
        )
        isBetterThanMS = tzMoment(fieldValue, "HH:mm").isSameOrBefore(
          tzMoment(fieldMS, "HH:mm")
        )
      } else {
        isBetterThanTarget = parseFloat(fieldValue) >= parseFloat(fieldTarget)
        isBetterThanMS = parseFloat(fieldValue) >= parseFloat(fieldMS)
      }

      if (!fieldValue) {
        msHit = "-"
        targetHit = "-"
        updatedRow[field] = "-"
      } else if ((targetHit !== "-" || msHit !== "-") && isBetterThanTarget) {
        msHit += 1
        targetHit += 1
      } else if ((targetHit !== "-" || msHit !== "-") && isBetterThanMS) {
        msHit += 1
      }
    }
    return {
      ...updatedRow,
      [TOTAL_MS]: msHit,
      [TOTAL_TARGET]: targetHit,
    }
  })

  let pinnedBottomRows = [
    {
      [NAME]: "Team MS Average",
    },
  ]
  for (const column of columns) {
    if (column.field === NAME) continue

    if ([TOTAL_MS, TOTAL_TARGET].includes(column.field)) {
      const columnTotal = getSum(tableRows.map(row => row[column.field]))

      pinnedBottomRows[0] = {
        ...pinnedBottomRows[0],
        [column.field]: columnTotal,
      }
    } else {
      let columnAverage = getAverage(tableRows.map(row => row[column.field]))

      if (column.field.endsWith?.("%")) {
        columnAverage = columnAverage + "%"
      }
      pinnedBottomRows[0] = {
        ...pinnedBottomRows[0],
        [column.field]: columnAverage,
      }
    }
  }

  const bottomRow = pinnedBottomRows[0]
  const totalMS = bottomRow[TOTAL_MS]
  const totalTarget = bottomRow[TOTAL_TARGET]
  const totalActiveAgents = tableRows.filter(row => {
    for (const goal of goalsFields) {
      if (!row[goal]) {
        return false
      }
    }
    return true
  })
  const totalAgentsNumber = totalActiveAgents.length

  return {
    tableRows,
    pinnedTopRows,
    pinnedBottomRows,
    totalAgentsNumber,
    totalMS,
    totalTarget,
  }
}
