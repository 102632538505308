import {
  ADD_SERVICE_ADVISOR,
  FETCH_SERVICE_ADVISORS,
  SERVICE_ADVISOR_ERROR,
  SERVICE_ADVISOR_SUCCESS,
  SET_SERVICE_ADVISORS,
  UPDATE_SERVICE_ADVISOR,
} from "./actionTypes"

const initialState = {
  errorMsg: "",
  successMsg: "",
  loading: false,
  serviceAdvisors: [],
}

const serviceAdvisor = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SERVICE_ADVISOR:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case UPDATE_SERVICE_ADVISOR:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case FETCH_SERVICE_ADVISORS:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        serviceAdvisors: [],
        loading: true,
      }
      break

    case SET_SERVICE_ADVISORS:
      state = {
        ...state,
        loading: false,
        serviceAdvisors: action.payload.serviceAdvisors,
      }
      break
    case SERVICE_ADVISOR_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload.msg,
        loading: false,
      }
      break
    case SERVICE_ADVISOR_ERROR:
      state = { ...state, errorMsg: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default serviceAdvisor
