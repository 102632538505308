import { FETCH_ENTITY_REPORTS, SET_ENTITY_REPORTS } from "./actionTypes"

export const fetchEntityReports = (
  queryParams = {
    ids: [],
    agentIds: [],
    startDate: "",
    endDate: "",
    withAgentsNumber: false,
    withManuallyAddedKpisPerAgent: false,
    withManuallyAddedKpisPerStore: false,

    previousPeriodReportData: {
      isEnabled: false,
      startDate: "",
      endDate: "",
    },
  }
) => {
  return {
    type: FETCH_ENTITY_REPORTS,
    payload: { queryParams },
  }
}
export const resetEntityReports = () => {
  return {
    type: SET_ENTITY_REPORTS,
    payload: { entityReports: undefined },
  }
}

export const setEntityReports = entityReports => {
  return {
    type: SET_ENTITY_REPORTS,
    payload: { entityReports },
  }
}
