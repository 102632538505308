import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import listPlugin from "@fullcalendar/list"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import allLocales from "@fullcalendar/core/locales-all"

import classes from "./styles.module.css"

// import {
//   addNewEvent as onAddNewEvent,
//   deleteEvent as onDeleteEvent,
//   getCategories as onGetCategories,
//   getEvents as onGetEvents,
//   updateEvent as onUpdateEvent,
// } from "../../store/actions"

const categories = [
  { name: "Holiday", value: "holiday", className: "bg-warning" },
  { name: "Weekend", value: "weekend", className: "bg-success" },
]
import {
  eventError,
  eventSuccess,
  addEvent,
  setEvents,
  fetchEvents,
  updateEvent,
  resetEvents,
  deleteEvent,
} from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import DeleteModal from "components/Common/DeleteModal"
import InputField from "components/Form/InputField"
import moment from "moment"
import { mapHolidayToEvent } from "./util/helpers"
import ScopeSelector from "components/Common/ScopeSelector"
import { toast } from "react-toastify"
import PageContainer from "components/Common/PageContainer"

const Calender = props => {
  //meta title

  const dispatch = useDispatch()

  const [event, setEvent] = useState({})
  const [isEdit, setIsEdit] = useState(false)

  // category validation
  const eventValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: event?.name || "",
      start: event?.start || "",
      end: event?.end || "",
      description: event?.description || "",
      scope: event?.scope || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Event Name"),
      start: Yup.string().required("Please Enter Your Start Date"),
      end: Yup.string().required("Please Enter Your End Date"),
      scope: Yup.array().min(1, "Scope Is Required"),
    }),
    onSubmit: values => {
      const from = moment(values.start).format("YYYY-MM-DD")
      const to = moment(values.end).format("YYYY-MM-DD")

      const eventObj = {
        name: values.name,
        from,
        to,
        description: values.description,
        ids: values.scope,
      }
      if (isEdit) {
        // update event
        dispatch(updateEvent(event.id, eventObj))
      } else {
        // save new event
        dispatch(addEvent(eventObj))
      }
    },
  })

  const CalendarProperties = createSelector(
    state => state.Calendar,
    Calendar => ({
      events: Calendar.events?.map(event => mapHolidayToEvent(event)),
      loading: Calendar.loading,
      successMsg: Calendar.successMsg,
      errorMsg: Calendar.errorMsg,
    })
  )

  const { events, loading, successMsg, errorMsg } =
    useSelector(CalendarProperties)

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()
  const [modalCategory, setModalCategory] = useState(false)
  const [selectedDay, setSelectedDay] = useState(0)

  useEffect(() => {
    dispatch(fetchEvents({ startDate: "", endDate: "" }))
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    })
  }, [dispatch])

  useEffect(() => {
    if (!modalCategory && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({})
        setIsEdit(false)
      }, 500)
    }
  }, [modalCategory, event, isEdit])
  useEffect(() => {
    if (successMsg) {
      toast.success(successMsg)
      dispatch(fetchEvents({ startDate: "", endDate: "" }))
      closeInfoModal()
      eventValidation.resetForm()
    }
  }, [successMsg])

  useEffect(() => {
    if (errorMsg) {
      toast.error(errorMsg)
    }
  }, [errorMsg])
  /**
   * Handling the modal state
   */
  const toggle = () => {
    if (modalCategory) {
      setModalCategory(false)
      setEvent(null)
      setIsEdit(false)
    } else {
      setModalCategory(true)
    }
  }
  const closeInfoModal = () => {
    setModalCategory(false)
    setEvent(null)
    setIsEdit(false)
    setDeleteModal(false)
    setDeleteId()
  }

  /**
   * Handling date click on calendar
   */
  const handleDateClick = arg => {
    const date = arg["date"]
    // const day = date.getDate()
    // const month = date.getMonth()
    // const year = date.getFullYear()

    // const currectDate = new Date()
    // const currentHour = currectDate.getHours()
    // const currentMin = currectDate.getMinutes()
    // const currentSec = currectDate.getSeconds()
    // const modifiedDate = new Date(
    //   year,
    //   month,
    //   day,
    //   currentHour,
    //   currentMin,
    //   currentSec
    // )
    // const modifiedData = { ...arg, date: modifiedDate }

    // setSelectedDay(modifiedData)
    eventValidation.setFieldValue("start", moment(date).format("YYYY-MM-DD"))
    eventValidation.setFieldValue("end", moment(date).format("YYYY-MM-DD"))

    toggle()
  }

  /**
   * Handling click on event on calendar
   */

  const handleEventClick = arg => {
    let event = arg.event

    event = {
      id: event.id,
      start: moment(event.start).format("YYYY-MM-DD"),
      end: moment(event.end).format("YYYY-MM-DD"),
      name: event.extendedProps?.name,
      scope: event.extendedProps?.scope,
      description: event.extendedProps?.description,
    }

    setEvent(event)
    setDeleteId(event.id)
    setIsEdit(true)
    setModalCategory(true)
    toggle()
  }

  /**
   * On category darg event
   */
  const onDrag = event => {
    event.preventDefault()
  }

  /**
   * On calendar drop event
   */
  const onDrop = event => {
    const date = event["date"]
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    // const currectDate = new Date()
    // const currentHour = currectDate.getHours()
    // const currentMin = currectDate.getMinutes()
    // const currentSec = currectDate.getSeconds()
    // const modifiedDate = new Date(
    //   year,
    //   month,
    //   day,
    //   currentHour,
    //   currentMin,
    //   currentSec
    // )
    const formattedStart = moment()
      .set({ year, month, date: day })
      .format("YYYY-MM-DD")

    const formattedEnd = moment()
      .set({ year, month, date: day })
      .format("YYYY-MM-DD")

    const draggedEl = event.draggedEl

    const draggedElclass = draggedEl.className
    const categoryValue = draggedEl.classList[0]
    const categoryName = categories?.find(
      ({ value }) => value === categoryValue
    )?.name

    if (
      draggedEl.classList.contains("external-event") &&
      draggedElclass.indexOf("fc-event-draggable") == -1
    ) {
      const modifiedData = {
        name: categoryName || "",
        start: formattedStart,
        end: formattedEnd,
        description: "",
        scope: [],
      }
      setEvent(modifiedData)

      toggle()
    }
  }

  //set the local language
  const enLocal = {
    code: "en-nz",
    week: {
      dow: 1,
      doy: 4,
    },
    buttonHints: {
      prev: "Previous $0",
      next: "Next $0",
      today: "This $0",
    },
    viewHint: "$0 view",
    navLinkHint: "Go to $0",
  }

  return (
    <PageContainer>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => dispatch(deleteEvent(deleteId))}
        onCloseClick={() => setDeleteModal(false)}
        loading={loading}
        errorMsg={errorMsg}
      />
      <div className="d-flex justify-content-between mb-4">
        <h1 className="page-title">Holidays Management</h1>

        <Button color="primary" style={{ height: "36px" }} onClick={toggle}>
          Add Holiday
        </Button>
      </div>
      {/* Render Breadcrumb */}
      {/* <Breadcrumbs title="Calendar" breadcrumbItem="Full Calendar" /> */}
      <Row>
        <Col xs={12}>
          <Row>
            <>
              <Col sm={3} className={classes.stick}>
                <Card>
                  <CardBody>
                    <div id="external-events" className="mt-2">
                      <br />
                      <p className="text-muted">
                        Drag and drop your event or click in the calendar
                      </p>
                      {categories.map(category => (
                        <div
                          className={`${category.value} ${category.className} external-event fc-event text-white`}
                          key={"cat-" + category.value}
                          draggable
                          onDrag={event => onDrag(event, category)}
                        >
                          <i
                            className={
                              "mdi mdi-checkbox-blank-circle font-size-11 me-2"
                            }
                          />
                          {category.name}
                        </div>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col sm={9}>
                <Card>
                  <CardBody
                  // style={{ height: `${contentHeight}px`, overflow: "auto" }}
                  >
                    {/* fullcalendar control */}
                    <FullCalendar
                      height={"auto"}
                      plugins={[
                        BootstrapTheme,
                        dayGridPlugin,
                        listPlugin,
                        interactionPlugin,
                      ]}
                      displayEventTime={false}
                      slotDuration={"00:15:00"}
                      handleWindowResize={true}
                      // themeSystem="bootstrap"
                      headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,dayGridWeek,dayGridDay",
                        // right: "",
                      }}
                      // eventTextColor="#fff"
                      locale={enLocal}
                      events={events}
                      editable={true}
                      droppable={true}
                      selectable={true}
                      dateClick={handleDateClick}
                      eventClick={handleEventClick}
                      drop={onDrop}
                    />
                  </CardBody>
                </Card>

                <Modal
                  isOpen={modalCategory}
                  className={props.className}
                  centered
                >
                  <ModalHeader toggle={toggle} tag="h5">
                    {!!isEdit ? "Edit Event" : "Add Event"}
                  </ModalHeader>
                  <ModalBody className="p-4">
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        eventValidation.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <Col xs={12}>
                          <div className="mb-3">
                            <InputField
                              name="name"
                              type="text"
                              placeholder="Enter Event Name"
                              validation={eventValidation}
                              label={"Event Name"}
                              required
                            />
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div className="mb-3">
                            <InputField
                              name="start"
                              type="date"
                              placeholder="From Date"
                              validation={eventValidation}
                              label={"From"}
                              required
                            />
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div className="mb-3">
                            <InputField
                              name="end"
                              type="date"
                              placeholder="To Date"
                              validation={eventValidation}
                              label={"To"}
                              required
                            />
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div className="mb-3">
                            <ScopeSelector
                              validation={eventValidation}
                              onChange={ids => {
                                eventValidation.setFieldValue("scope", ids)
                              }}
                              selectedScopes={
                                isEdit
                                  ? eventValidation.values.scope
                                  : undefined
                              }
                              hasInitialValue={isEdit}
                            />
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div className="mb-3">
                            <InputField
                              name="description"
                              type="textarea"
                              placeholder="Description.."
                              validation={eventValidation}
                              label={"Description"}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col xs={6}>
                          {isEdit && (
                            <Button
                              type="button"
                              color="btn btn-danger"
                              id="btn-delete-event"
                              onClick={() => {
                                toggle()
                                setDeleteModal(true)
                              }}
                            >
                              Delete
                            </Button>
                          )}
                        </Col>

                        <Col xs={6} className="text-end">
                          <Button
                            disabled={loading}
                            color="light"
                            type="button"
                            className="me-1"
                            onClick={toggle}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            id="btn-save-event"
                            disabled={loading}
                          >
                            {loading ? <Spinner size="sm" /> : "Save"}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
              </Col>
            </>
          </Row>
        </Col>
      </Row>
    </PageContainer>
  )
}

export default Calender
