import React from "react"
// import CardItem from "./CardItem"
import classes from "./styles.module.css"
import {
  compareGenericValues,
  convertDurationToSeconds,
  isDuration,
} from "helpers/helperFns"

const RowItem = ({
  iconBgColor,
  iconTitle,
  title,
  value,
  icon,
  target,
  minStandard,
}) => {
  let color = "#000"

  const hasTarget =
    JSON.stringify(target) !== "null" && target !== undefined && target !== null
  const hasMinStandard =
    JSON.stringify(minStandard) !== "null" &&
    minStandard !== undefined &&
    minStandard !== null

  const reachedTarget = isDuration(value)
    ? [0, 1].includes(compareGenericValues(target, value))
    : [0, 1].includes(compareGenericValues(value, target))
  const reachedMinStandard = isDuration(value)
    ? [0, 1].includes(compareGenericValues(minStandard, value))
    : [0, 1].includes(compareGenericValues(value, minStandard))

  if (hasTarget && hasMinStandard) {
    color = reachedTarget
      ? "#209369"
      : reachedMinStandard
      ? "#F1B44C"
      : "#AF0001"
  } else if (hasTarget && !hasMinStandard) {
    color = reachedTarget ? "#209369" : "#AF0001"
  }

  return (
    <div className="d-flex justify-content-between align-items-center gap-2">
      <div className="d-flex align-items-center gap-2">
        <div className={` ${classes.icon}`}>
          {/* <CardItem bgColor={iconBgColor} title={iconTitle} /> */}
          {/* <CustomerServiceSvg /> */}
          {icon}
        </div>
        <p className={`flex-1 ${classes.text}`}>{title}</p>
      </div>
      <div>
        <p className={`${classes.title}`} style={{ color }}>
          {value}
        </p>
      </div>
    </div>
  )
}

export default RowItem
