import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { ADD_TARGET, FETCH_TARGETS } from "./actionTypes"
import { targetSuccess, targetError, setTargets } from "./actions"

//Include Both Helper File with needed methods
import { getTargets, postTarget } from "../../helpers/fakebackend_helper"

import { isArray } from "lodash"
function* addTarget({ payload: { target, history } }) {
  try {
    console.log("new saga", target)
    const response = yield call(postTarget, target)
    yield put(targetSuccess(response, "Created Successfully"))
    history("/targets")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(targetError(errMsg))
  }
}

function* fetchTargets() {
  try {
    console.log("saga get targets")
    const response = yield call(getTargets)
    yield put(setTargets(response.data))
  } catch (error) {
    console.log("error", error)
  }
}

function* targetSaga() {
  yield takeEvery(ADD_TARGET, addTarget)
  yield takeEvery(FETCH_TARGETS, fetchTargets)
}

export default targetSaga
