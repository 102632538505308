import {
  ADD_GOAL_ENTRY,
  FETCH_GOALS_ENTRIES,
  GOAL_ENTRY_ERROR,
  GOAL_ENTRY_SUCCESS,
  SET_GOALS_ENTRIES,
  UPDATE_GOAL_ENTRY,
} from "./actionTypes"

const initialState = {
  errorMsg: "",
  successMsg: "",
  loading: false,
  goalsEntries: [],
}

const goalEntry = (state = initialState, action) => {
  switch (action.type) {
    case ADD_GOAL_ENTRY:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case FETCH_GOALS_ENTRIES:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        goalsEntries: [],
        loading: true,
      }
      break
    case UPDATE_GOAL_ENTRY:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case SET_GOALS_ENTRIES:
      state = {
        ...state,
        loading: false,
        goalsEntries: action.payload.goalsEntries,
      }
      break
    case GOAL_ENTRY_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload.msg,
        loading: false,
      }
      break
    case GOAL_ENTRY_ERROR:
      state = { ...state, errorMsg: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default goalEntry
