import React from "react"
import { FormFeedback, Label } from "reactstrap"
import Switch from "react-switch"

const SwitchField = ({ label, name, validation, required, ...props }) => {
  console.log("v", validation)
  return (
    <div className="d-flex align-items-center switch-padding">
      <Label
        className="form-label"
        style={{ width: "100px", marginRight: "30px", marginBottom: 0 }}
      >{`${label} ${required ? "*" : ""}`}</Label>
      <Switch
        id={name}
        onChange={checked =>
          validation.handleChange({
            target: { value: checked, type: "change", id: name, name },
          })
        }
        onBlur={validation.handleBlur}
        checked={validation.values[name] || false}
        onColor="#AF0001"
        {...props}
      />
      {validation.touched[name] && validation.errors[name] ? (
        <FormFeedback type="invalid">{validation.errors[name]}</FormFeedback>
      ) : null}
    </div>
  )
}

export default SwitchField
