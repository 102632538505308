import {
  ADD_DISPOSITION,
  FETCH_DISPOSITIONS,
  DISPOSITION_ERROR,
  DISPOSITION_SUCCESS,
  SET_DISPOSITIONS,
  UPDATE_DISPOSITION,
} from "./actionTypes"

const initialState = {
  errorMsg: "",
  successMsg: "",
  loading: false,
  dispositions: [],
}

const disposition = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DISPOSITION:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case UPDATE_DISPOSITION:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case FETCH_DISPOSITIONS:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        dispositions: [],
        loading: true,
      }
      break

    case SET_DISPOSITIONS:
      state = {
        ...state,
        loading: false,
        dispositions: action.payload.dispositions,
      }
      break
    case DISPOSITION_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload.msg,
        loading: false,
      }
      break
    case DISPOSITION_ERROR:
      state = { ...state, errorMsg: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default disposition
