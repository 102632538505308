import {
  datesOptions,
  isCustomDateOption,
  tzMoment,
} from "constants/datesOptions"
import { axiosApi } from "helpers/api_helper"
import {
  approximate,
  convertDurationToSeconds,
  getAvgOfDurations,
  getDurationFromSeconds,
  getGenericAvg,
  getGenericSum,
  getPercentage,
  getSum,
  getSumOfDurations,
  isAdminRole,
  isAgentRole,
} from "helpers/helperFns"
import moment from "moment"
import React, { useEffect, useMemo, useState } from "react"
import crownImg from "assets/images/crown.png"

import userXImg from "assets/images/user-xmark.png"
import { getGoal } from "../helpers"
import { kpiTypesInitialValues, kpiValueTypes } from "../constants"

const SHOWS_KPI = "Shows"
const SCHEDULED_APPTS_KPI = "Scheduled Appts"
const AVG_HANDLING_TIME = "AVG Handling Time"
const CALL_PERFORMANCE_SCORE = "Call Performance Score"
function getWorkingDaysPerDateOption(startDate, endDate) {
  let totalWorkingDays = 0
  let i = 0
  let clonedStart = tzMoment(startDate)
  const clonedEnd = tzMoment(endDate)

  while (clonedStart.isBefore(clonedEnd, "minutes")) {
    const dayOfWeek = clonedStart.weekday()

    // Check if it's a weekend (Saturday or Sunday)
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      totalWorkingDays++
    }

    clonedStart = clonedStart.add(1, "days")
    i++
  }

  return totalWorkingDays
}

const useTVDashboard = ({ validation }) => {
  const [report, setReport] = useState({})
  // const [goal, setGoal] = useState({})
  const [callsGoal, setCallsGoal] = useState({})
  const [apptsGoal, setApptsGoal] = useState({})
  const [ibRatioGoal, setIbRatioGoal] = useState({})
  const [obRatioGoal, setObRatioGoal] = useState({})
  const [ibOtdbGoal, setIbOtdbGoal] = useState({})
  const [ibCallsGoal, setIbCallsGoal] = useState({})
  const [obOtdbGoal, setObOtdbGoal] = useState({})
  const [obCallsGoal, setObCallsGoal] = useState({})
  const [ibApptsGoal, setIbApptsGoal] = useState({})
  const [obApptsGoal, setObApptsGoal] = useState({})
  const [transferRatioGoal, setTransferRatioGoal] = useState({})
  const [showsGoal, setShowsGoal] = useState({})
  const [showsRatioGoal, setShowsRatioGoal] = useState({})
  const [avgHandleTimeGoal, setAvgHandleTimeGoal] = useState({})
  const [avgCallPerformanceGoal, setAvgCallPerformanceGoal] = useState({})

  const [loading, setLoading] = useState(true)

  const currentDateOption = Object.values(datesOptions).find(
    ({ value }) => value === validation.values.selectedDateOption?.value
  )
  let startDate, endDate
  if (isCustomDateOption(currentDateOption.value)) {
    startDate = validation.values.startDate
    endDate = validation.values.endDate
  } else {
    startDate = currentDateOption.startDate
    endDate = currentDateOption.endDate
  }

  const isSingleDay = [
    datesOptions.today.value,
    datesOptions.yesterday.value,
  ].includes(validation.values.selectedDateOption?.value)

  const workingDays = isSingleDay
    ? 1
    : getWorkingDaysPerDateOption(startDate, endDate)
  const fetchActualCalls = async (withLoading = true) => {
    withLoading && setLoading(true)

    const startDateParam = startDate ? `startDate=${startDate}` : ""
    const endDateParam = endDate ? `endDate=${endDate}` : ""

    const manualKpiStartDateParam = startDate
      ? `startDate=${tzMoment(startDate).format("YYYY-MM-DD")}`
      : ""
    const manualKpiEndDateParam = endDate
      ? `endDate=${tzMoment(endDate).format("YYYY-MM-DD")}`
      : ""

    let scopesIdsQuery = ""
    let idsQuery = ""
    if (validation.values.scope?.length > 0) {
      const queryArr = validation.values.scope.map(id => `scopeIds=${id}`)
      scopesIdsQuery = queryArr.join("&")

      const idsArr = validation.values.scope.map(id => `ids=${id}`)
      idsQuery = idsArr.join("&")
    }

    const [
      actualCallsResponse,
      manualKpiPerStoreResponse,
      manualKpiPerAgentResponse,
    ] = await Promise.all([
      axiosApi.get(
        `/api/v1/reports/dashboard?${startDateParam}&${endDateParam}&${scopesIdsQuery}`
      ),
      axiosApi.get(
        `/api/v1/reports/manual-kpi-per-store?${manualKpiStartDateParam}&${manualKpiEndDateParam}&${idsQuery}`
      ),
      axiosApi.get(
        `/api/v1/reports/manual-kpi-per-agent?${manualKpiStartDateParam}&${manualKpiEndDateParam}&${idsQuery}`
      ),
    ])

    const kpisPerStore = manualKpiPerStoreResponse.data?.stores || []
    const kpisPerAgent = manualKpiPerAgentResponse.data || []
    const totalKpis = [...kpisPerStore, ...kpisPerAgent]

    const scheduledApptsKpis = []
    const showsKpis = []
    const avgHandlingTimeKpis = []
    const callPerformanceScoreKpis = []

    totalKpis.forEach(kpi => {
      const { manualKpiName } = kpi

      const isShowsKpi = manualKpiName === SHOWS_KPI
      const isScheduledApptsKpi = manualKpiName === SCHEDULED_APPTS_KPI
      const isAvgHandlingTimeKpi = manualKpiName === AVG_HANDLING_TIME
      const isCallPerformanceScoreKpi = manualKpiName === CALL_PERFORMANCE_SCORE
      if (isShowsKpi) {
        showsKpis.push(kpi)
      } else if (isScheduledApptsKpi) {
        scheduledApptsKpis.push(kpi)
      } else if (isAvgHandlingTimeKpi) {
        avgHandlingTimeKpis.push(kpi)
      } else if (isCallPerformanceScoreKpi) {
        callPerformanceScoreKpis.push(kpi)
      }
    })
    // const actualCallsResponse = await axiosApi.get(
    //   `/api/v1/reports/dashboard?startDate=${startDate}${scopeQuery}`
    // )

    const totalShows = getSum(showsKpis.map(({ value }) => value))
    const totalScheduledAppts = getSum(
      scheduledApptsKpis.map(({ value }) => value)
    )
    const totalAvgHandlingTime = getAvgOfDurations(
      avgHandlingTimeKpis.map(({ value }) => value)
    )

    const totalCallPerformanceScore = getGenericAvg(
      callPerformanceScoreKpis.map(({ value }) => value)
    )

    // this is just for unifying the percentage format
    const parsedTotalCallPerformanceScore = totalCallPerformanceScore
      ? getPercentage(parseFloat(totalCallPerformanceScore), 100)
      : "0.0%"

    const newReport = {
      inbound: 0,
      obNotConnected: 0,
      obConnected: 0,
      connectedOtdb: 0,
      internal: 0,
      appointedInbound: 0,
      outboundAppointedNotConnected: 0,
      outboundAppointedConnected: 0,
      totalCalls: 0,
      activeAgents: actualCallsResponse.data?.calls?.activeUsers,
      inActiveAgents: actualCallsResponse.data?.calls?.inActiveUsers,
      transferOut: 0,
      ibOpportunity: 0,
      obOpportunity: 0,
      connected: 0,
      totalShows,
      totalScheduledAppts,
      totalAvgHandlingTime,
      totalCallPerformanceScore: parsedTotalCallPerformanceScore,
    }

    for (const call of actualCallsResponse.data?.calls?.formattedData || []) {
      let totalTransferOut = 0
      for (const disp of call.dispositions || []) {
        if (disp.isSaRequired) {
          totalTransferOut += getSum([
            disp.inbound,
            disp.internal,
            disp.outboundNotConnected,
            disp.outboundConnected,
          ])
        }
      }

      newReport.inbound += Number(call.ibCalls)
      newReport.obConnected += Number(call.obConnected)
      newReport.obNotConnected += Number(call.obNotConnected)
      newReport.connectedOtdb += Number(call.connectedOtdb)

      newReport.internal += Number(call.internal)
      newReport.appointedInbound += Number(call.appointedInbound)
      newReport.outboundAppointedNotConnected += Number(
        call.outboundAppointedNotConnected
      )
      newReport.outboundAppointedConnected += Number(
        call.outboundAppointedConnected
      )

      newReport.totalCalls += Number(call.totalCalls)
      newReport.ibOpportunity += Number(call.ibOpportunity)
      newReport.obOpportunity += Number(call.obOpportunity)

      newReport.connected += Number(call.connected)
      newReport.transferOut += totalTransferOut

      //   newReport.appointedInternal += Number(call.appointedInternal)
    }

    setReport(newReport)

    withLoading && setLoading(false)
  }

  const fetchCallsGoals = async (withLoading = true) => {
    withLoading && setLoading(true)

    const factor = (1 / 21) * workingDays

    const startDateParam = startDate ? `startDate=${startDate}` : ""
    const endDateParam = endDate ? `endDate=${endDate}` : ""

    let scopeQuery = ""
    if (validation.values.scope?.length > 0) {
      const queryArr = validation.values.scope.map(id => `scopeIds=${id}`)
      scopeQuery = queryArr.join("&")
    }

    const optionsObj = {
      startDateParam,
      endDateParam,
      scopeQuery,
      factor,
    }

    const [
      callsGoals,
      appointmentsGoal,
      ibClosingRatioGoal,
      obClosingRatioGoal,
      showsRatioGoal,
      avgHandleTimeGoal,
      obCallsPerDayGoal,
      obApptsPerDayGoal,
      ibCallsPerDayGoal,
      showsGoal,
      ibApptsPerDayGoal,
      callPerformanceScoreGoal,
    ] = await Promise.all([
      getGoal({
        name: "Calls",
        type: kpiValueTypes.NUMBER,
        onFinalize: setCallsGoal,
        ...optionsObj,
      }),
      getGoal({
        name: "Appointments",
        type: kpiValueTypes.NUMBER,
        onFinalize: setApptsGoal,
        ...optionsObj,
      }),
      getGoal({
        name: "IB Closing Ratio",
        type: kpiValueTypes.PERCENT,
        onFinalize: setIbRatioGoal,
        ...optionsObj,
      }),
      getGoal({
        name: "OB Closing Ratio",
        type: kpiValueTypes.PERCENT,
        onFinalize: setObRatioGoal,
        ...optionsObj,
      }),
      getGoal({
        name: "Show %",
        type: kpiValueTypes.PERCENT,
        onFinalize: setShowsRatioGoal,
        ...optionsObj,
      }),
      getGoal({
        name: "Avg Handle Time",
        type: kpiValueTypes.DURATION,
        onFinalize: setAvgHandleTimeGoal,
        ...optionsObj,
      }),
      getGoal({
        name: "OB Calls Per Day",
        type: kpiValueTypes.NUMBER,
        onFinalize: setObCallsGoal,
        ...optionsObj,
      }),
      getGoal({
        name: "OB Appts Per Day",
        type: kpiValueTypes.NUMBER,
        onFinalize: setObApptsGoal,
        ...optionsObj,
      }),
      // getGoal({
      //   name: "IB Calls Per Day",
      //   type: kpiValueTypes.NUMBER,
      //   onFinalize: setIbCallsGoal,
      //   ...optionsObj,
      // }),
      getGoal({
        name: "Shows",
        type: kpiValueTypes.NUMBER,
        onFinalize: setShowsGoal,
        ...optionsObj,
      }),
      getGoal({
        name: "IB Appts Per Day",
        type: kpiValueTypes.NUMBER,
        onFinalize: setIbApptsGoal,
        ...optionsObj,
      }),
      getGoal({
        name: "Call Performance Score",
        type: kpiValueTypes.NUMBER,
        onFinalize: setAvgCallPerformanceGoal,
        ...optionsObj,
      }),
      ,
    ])
    // setIbOtdbGoal
    // setObOtdbGoal
    // setTransferRatioGoal

    withLoading && setLoading(false)
    // setRefreshTable(old => !old)
  }

  useEffect(() => {
    const fetch = async () => {
      const errors = await validation.validateForm()

      if (Object.values(errors)?.length) {
        setReport({})
        // !IMP: check this after api is working
        // setCallsGoal({})
        // setApptsGoal({})
        // setIbRatioGoal({})
        // setObRatioGoal({})
        return
      }

      fetchActualCalls()
      fetchCallsGoals()
    }
    fetch()
  }, [validation.values])
  useEffect(() => {
    if (
      validation.values.selectedDateOption?.value !==
        datesOptions.today.value ||
      !validation.values.scope?.length
    ) {
      return
    }

    const interval = setInterval(() => {
      // toast.success("REFETCHING !")
      fetchActualCalls(false)
      fetchCallsGoals(false)
    }, 30000)

    return () => clearInterval(interval)
  }, [validation.values.selectedDateOption?.value, validation.values.scope])

  //

  const activeAgents = useMemo(() => {
    return report?.activeAgents
  }, [report])

  const ibCallsData = useMemo(() => {
    let count = kpiTypesInitialValues[kpiValueTypes.NUMBER]
    let target
    let minStandard
    const type = kpiValueTypes.NUMBER

    if (!loading) {
      count = report?.inbound
      target = ibCallsGoal.target
      minStandard = ibCallsGoal.minStandard
    }

    return {
      count,
      target,
      minStandard,
      type,
    }
  }, [loading, report, ibCallsGoal])
  const obCallsData = useMemo(() => {
    let count = kpiTypesInitialValues[kpiValueTypes.NUMBER]
    let target
    let minStandard
    const type = kpiValueTypes.NUMBER

    if (!loading) {
      count = getSum([report?.obNotConnected, report?.obConnected])
      target = obCallsGoal.target
      minStandard = obCallsGoal.minStandard
    }

    return {
      count,
      target,
      minStandard,
      type,
    }
  }, [loading, report, obCallsGoal])

  const ibApptsData = useMemo(() => {
    let count = kpiTypesInitialValues[kpiValueTypes.NUMBER]
    let target
    let minStandard
    const type = kpiValueTypes.NUMBER

    if (!loading) {
      count = report?.appointedInbound
      target = ibApptsGoal.target
      minStandard = ibApptsGoal.minStandard
    }

    return {
      count,
      target,
      minStandard,
      type,
    }
  }, [loading, report, ibApptsGoal])

  const obApptsData = useMemo(() => {
    let count = kpiTypesInitialValues[kpiValueTypes.NUMBER]
    let target
    let minStandard
    const type = kpiValueTypes.NUMBER
    if (!loading) {
      count = getSum([
        report?.outboundAppointedNotConnected,
        report?.outboundAppointedConnected,
      ])
      target = obApptsGoal.target
      minStandard = obApptsGoal.minStandard
    }

    return {
      count,
      target,
      minStandard,
      type,
    }
  }, [loading, report, obApptsGoal])

  const totalAvgHandlingTimeData = useMemo(() => {
    let count = kpiTypesInitialValues[kpiValueTypes.DURATION]
    let target
    let minStandard
    const type = kpiValueTypes.DURATION
    if (!loading) {
      count = report?.totalAvgHandlingTime

      target = avgHandleTimeGoal.target
      minStandard = avgHandleTimeGoal.minStandard
    }

    return {
      count,
      target,
      minStandard,
      type,
    }
  }, [loading, report, avgHandleTimeGoal])

  const callPerformanceScoreData = useMemo(() => {
    let percent = kpiTypesInitialValues[kpiValueTypes.PERCENT]
    let target
    let minStandard
    const type = kpiValueTypes.PERCENT

    if (!loading) {
      percent = report?.totalCallPerformanceScore
      target = avgCallPerformanceGoal.target
      minStandard = avgCallPerformanceGoal.minStandard
    }

    return {
      percent,
      target,
      minStandard,
      type,
    }
  }, [loading, report, avgCallPerformanceGoal])

  //

  const totalCallsData = useMemo(() => {
    let count = kpiTypesInitialValues[kpiValueTypes.NUMBER]
    let target
    let minStandard
    const type = kpiValueTypes.NUMBER

    if (!loading) {
      count = getSum([
        report?.inbound,
        report?.internal,
        report?.obNotConnected,
        report?.obConnected,
      ])
      target = callsGoal?.target
      minStandard = callsGoal?.minStandard
    }

    return {
      type,
      count,
      target,
      minStandard,
    }
  }, [loading, report, callsGoal])
  const totalApptsData = useMemo(() => {
    const type = kpiValueTypes.NUMBER

    let count = kpiTypesInitialValues[kpiValueTypes.NUMBER]
    let target
    let minStandard

    if (!loading) {
      count = getSum([
        report?.appointedInbound,
        report?.outboundAppointedNotConnected,
        report?.outboundAppointedConnected,
      ])
      target = apptsGoal?.target
      minStandard = apptsGoal?.minStandard
    }

    return {
      type,
      count,
      target,
      minStandard,
    }
  }, [loading, report, apptsGoal])

  const ibClosingRatioData = useMemo(() => {
    const type = kpiValueTypes.PERCENT

    let percent = kpiTypesInitialValues[kpiValueTypes.PERCENT]
    let target
    let minStandard
    if (!loading) {
      percent = getPercentage(report?.appointedInbound, report?.ibOpportunity)
      target = ibRatioGoal.target
      minStandard = ibRatioGoal.minStandard
    }

    return {
      type,
      percent,
      target,
      minStandard,
    }
  }, [loading, report, ibRatioGoal, workingDays])

  const obClosingRatioData = useMemo(() => {
    const type = kpiValueTypes.PERCENT
    let percent = kpiTypesInitialValues[kpiValueTypes.PERCENT]
    let target
    let minStandard
    let dailyAvg = kpiTypesInitialValues[kpiValueTypes.PERCENT]
    if (!loading) {
      percent = getPercentage(
        getSum([
          report.outboundAppointedNotConnected,
          report.outboundAppointedConnected,
        ]),
        report?.connectedOtdb
      )
      target = obRatioGoal.target
      minStandard = obRatioGoal.minStandard
    }

    return { type, percent, target, minStandard, dailyAvg }
  }, [loading, report, obRatioGoal])

  const ibOtdbCallsData = useMemo(() => {
    const type = kpiValueTypes.NUMBER
    let percent = kpiTypesInitialValues[kpiValueTypes.PERCENT]
    let count = kpiTypesInitialValues[kpiValueTypes.NUMBER]
    let minStandard
    let target
    if (!loading) {
      count = report?.ibOpportunity
      percent = getPercentage(report?.ibOpportunity, report?.inbound)
      target = ibOtdbGoal?.target
      minStandard = ibOtdbGoal?.minStandard
    }

    return { type, count, percent, target, minStandard }
  }, [loading, report, ibOtdbGoal])
  const obOtdbCallsData = useMemo(() => {
    const type = kpiValueTypes.NUMBER

    let percent = kpiTypesInitialValues[kpiValueTypes.PERCENT]
    let count = kpiTypesInitialValues[kpiValueTypes.NUMBER]
    let minStandard
    let target

    if (!loading) {
      count = report?.obOpportunity
      percent = getPercentage(report?.obOpportunity, report?.inbound)
      target = obOtdbGoal?.target
      minStandard = obOtdbGoal?.minStandard
    }

    return { type, count, percent, target, minStandard }
  }, [loading, report, obOtdbGoal])

  const transferredOutData = useMemo(() => {
    const type = kpiValueTypes.PERCENT
    let percent = kpiTypesInitialValues[kpiValueTypes.PERCENT]
    let barColor = "danger"

    if (!loading) {
      percent = getPercentage(report?.transferOut, report?.totalCalls)
      barColor =
        parseFloat(percent) > 15
          ? "danger"
          : percent >= 10
          ? "warning"
          : "success"
    }

    return { type, percent, barColor }
  }, [loading, report])

  const activeAgentsData = useMemo(() => {
    const type = kpiValueTypes.NUMBER
    let count = kpiTypesInitialValues[kpiValueTypes.NUMBER]
    let target
    if (!loading) {
      count = activeAgents
      // target = report?.activeAgents + report?.inActiveAgents
    }

    return { type, count, target }
  }, [loading, report, activeAgents])

  const totalShowsData = useMemo(() => {
    const type = kpiValueTypes.NUMBER
    let count = kpiTypesInitialValues[kpiValueTypes.NUMBER]
    let minStandard
    let target
    if (!loading) {
      count = report?.totalShows
      target = showsGoal?.target
      minStandard = showsGoal?.minStandard
    }

    return { type, count, target, minStandard }
  }, [loading, report, showsGoal])

  const totalShowsPercentData = useMemo(() => {
    const type = kpiValueTypes.PERCENT
    let percent = kpiTypesInitialValues[kpiValueTypes.PERCENT]
    let minStandard
    let target
    if (!loading) {
      percent = getPercentage(report?.totalShows, report?.totalScheduledAppts)
      target = showsRatioGoal?.target
      minStandard = showsRatioGoal?.minStandard
    }

    return { type, percent, target, minStandard }
  }, [loading, report, showsRatioGoal])

  return {
    loading,
    totalCallsData,
    totalApptsData,
    ibClosingRatioData,
    obClosingRatioData,
    ibOtdbCallsData,
    transferredOutData,
    activeAgentsData,
    totalShowsData,
    totalShowsPercentData,
    ibCallsData,
    obOtdbCallsData,
    obCallsData,
    ibApptsData,
    obApptsData,
    totalAvgHandlingTimeData,
    callPerformanceScoreData,
  }
}

export default useTVDashboard
