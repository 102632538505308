import PageContainer from "components/Common/PageContainer"
import DragAndDropImageInput from "components/Form/DragAndDropImageInput"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { Alert, Button, Form, Spinner } from "reactstrap"
import * as Yup from "yup"
import InputField from "components/Form/InputField"
import SelectField from "components/Form/SelectField"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { addCompany, updateCompany } from "store/company/actions"
import departments from "store/department/reducer"
import { fetchDepartments } from "store/actions"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { axiosApi } from "helpers/api_helper"
import { UPLOAD_LINK } from "helpers/url_helper"
import axios from "axios"
import { toast } from "react-toastify"

const AddEditCompany = () => {
  const dispatch = useDispatch()
  const history = useNavigate()

  const { id } = useParams()
  const { state: companyData } = useLocation()

  const [showConfirm, setShowConfirm] = useState(false)
  const [logo, setLogo] = useState()

  const axiosInstance = axios.create({
    withCredentials: true,
    headers: {
      "Content-Type": "image/jpeg",
    },
  })

  useEffect(() => {
    dispatch(fetchDepartments())
  }, [])

  const DepartmentProperties = createSelector(
    state => state.Department,
    dep => ({
      departments: dep.departments,
    })
  )

  const { departments } = useSelector(DepartmentProperties)

  const CompanyProperties = createSelector(
    state => state.Company,
    comp => ({
      loading: comp.loading,
      errorMsg: comp.errorMsg,
      successMsg: comp.successMsg,
    })
  )

  const { loading, errorMsg, successMsg } = useSelector(CompanyProperties)

  function uploadImage(file) {
    // const formData = new FormData()
    // formData.append("file", file)
    let url = ""
    axiosApi.get(UPLOAD_LINK).then(res => {
      axiosInstance
        .put(res.data, file)
        .then(response => {
          url = res.data
          console.log("URL:", response)
          // You can handle the URL response here
        })
        .catch(error => {
          console.error("Error:", error)
          // Handle errors here
        })
    })
    return url
  }

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: companyData
      ? {
          name: companyData.name,
          departments: companyData.departments?.map(item => ({
            value: item.department_id,
            label: item.type_name,
          })),
        }
      : {
          name: "",
          departments: [],
        },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter company name"),
      departments: Yup.array().min(1, "Please choose at least one department"),
    }),
    onSubmit: values => {
      window.scrollTo({ top: 0, behavior: "smooth" })

      // let logoUrl
      // if (logo) {
      //   logoUrl = uploadImage(logo)
      // }

      const body = {
        name: values.name,
        departmentIds: values.departments.map(item => item.value),
        logo: null,
      }
      console.log("submit", body)
      if (id && companyData) dispatch(updateCompany(id, body, history))
      else dispatch(addCompany(body, history))
    },
  })

  const handleCancel = () => {
    if (validation.values.name || validation.values.description) {
      window.scrollTo({ top: 0, behavior: "smooth" })
      setShowConfirm(true)
    } else {
      history(-1)
    }
  }

  useEffect(() => {
    if (successMsg) {
      toast.success(successMsg)
    }
  }, [successMsg])

  useEffect(() => {
    if (errorMsg) {
      toast.error(errorMsg)
    }
  }, [errorMsg])

  return (
    <PageContainer>
      <h1 className="page-title mb-4">{id ? "Edit" : "Add"} Company</h1>
      <div className="content-container">
        {showConfirm && (
          <Alert color="danger" style={{ marginTop: "13px" }}>
            <div>
              <p>Are you sure you want to cancel? Your inputs will be lost.</p>
              <div className="d-flex">
                <Button
                  type="button"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() => history(-1)}
                >
                  yes
                </Button>
                <Button type="button" onClick={() => setShowConfirm(false)}>
                  no
                </Button>
              </div>
            </div>
          </Alert>
        )}
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="container">
            <div className="row">
              {/* <div className="d-flex justify-content-center mb-lg-0 mb-5">
                <DragAndDropImageInput
                  placeholder="Drop logo here or click to upload"
                  onChange={img => setLogo(img)}
                />
              </div> */}

              <div className="col-lg-7 col-12 mb-4">
                <InputField
                  name="name"
                  validation={validation}
                  placeholder="Enter company name"
                  label={"Name"}
                  required
                />
              </div>
              <div className="col-lg-7 col-12 mb-4">
                <SelectField
                  label={"Department"}
                  required
                  name="departments"
                  validation={validation}
                  isMulti
                  options={departments?.map(item => ({
                    value: item.department_id,
                    label: item.type_name,
                  }))}
                />
              </div>
            </div>
            <div className="mt-5 d-flex justify-content-center justify-content-lg-start">
              <Button
                color="primary"
                style={{
                  marginRight: "20px",
                  width: "110px",
                }}
                type="submit"
              >
                {loading ? <Spinner size={"sm"}></Spinner> : "Save"}
              </Button>
              <Button type="button" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </PageContainer>
  )
}

export default AddEditCompany
