import moment from "moment-timezone"

// moment.tz.add('America/Los_Angeles|PST PDT|80 70|0101|1Lzm0 1zb0 Op0');
const timezone = "America/Los_Angeles"
const secondaryTextFormat = "MM/DD/YYYY"

// function isMonday(date) {
//   // 1 is the second weekday (monday) in America/Los_Angeles Timezone
//   return moment.tz(date, timezone).weekday() === 1
// }

export function tzMoment(date, format) {
  if (!date) return moment.tz(new Date(), timezone)

  if (format) {
    return moment.tz(date, format, timezone)
  }
  return moment.tz(date, timezone)
}

const nowDate = new Date()

// startOf("isoWeek") always return the past monday in this week (Or today if today is monday)
const prevMonday = tzMoment(nowDate).startOf("isoWeek")

const dayBeforePrevMonday = tzMoment(prevMonday)
  .subtract(1, "day")
  .endOf("day")
  .format()
const weekBeforePrevMonday = tzMoment(prevMonday).subtract(1, "week").format()

const todayStart = tzMoment(nowDate).startOf("day").format()
const todayEnd = tzMoment(nowDate).endOf("day").format()

const yesterday = tzMoment(nowDate).subtract(1, "days")
const yesterdayStart = tzMoment(yesterday).startOf("day").format()
const yesterdayEnd = tzMoment(yesterdayStart).endOf("day").format()

const firstOfCurrentMonth = tzMoment(nowDate).startOf("month").format()
const firstOfCurrentYear = tzMoment(nowDate).startOf("year").format()

const prevMonth = tzMoment(nowDate).subtract(1, "month")
const firstOfPrevMonth = tzMoment(prevMonth).startOf("month").format()
const lastDayOfPrevMonth = tzMoment(prevMonth).endOf("month").format()

// secondaryTitles
export const mtdSubText = `${tzMoment(firstOfCurrentMonth).format(
  secondaryTextFormat
)} - ${tzMoment(todayEnd).format(secondaryTextFormat)}`

export const datesOptions = {
  today: {
    value: "today",
    title: "Today",
    secondaryTitle: `${tzMoment(todayStart).format(secondaryTextFormat)}`,
    startDate: todayStart,
    endDate: todayEnd,
    previousPeriod: {
      startDate: yesterdayStart,
      endDate: yesterdayEnd,
    },
  },
  yesterday: {
    value: "yesterday",
    title: "Yesterday",
    secondaryTitle: `${tzMoment(yesterdayStart).format(secondaryTextFormat)}`,
    startDate: yesterdayStart,
    endDate: yesterdayEnd,
    previousPeriod: {
      startDate: tzMoment(yesterdayStart).subtract(1, "days").format(),
      endDate: tzMoment(yesterdayEnd).subtract(1, "days").format(),
    },
  },
  lastWeek: {
    value: "last_week",
    title: "Last Week",
    secondaryTitle: `${tzMoment(weekBeforePrevMonday).format(
      secondaryTextFormat
    )} - ${tzMoment(dayBeforePrevMonday).format(secondaryTextFormat)}`,
    startDate: weekBeforePrevMonday,
    endDate: dayBeforePrevMonday,
    previousPeriod: {
      startDate: tzMoment(weekBeforePrevMonday).subtract(1, "weeks").format(),
      endDate: tzMoment(dayBeforePrevMonday).subtract(1, "weeks").format(),
    },
  },
  monthToDate: {
    value: "month_to_date",
    title: "Month to Date",
    secondaryTitle: mtdSubText,
    startDate: firstOfCurrentMonth,
    endDate: todayEnd,
    previousPeriod: {
      startDate: tzMoment(firstOfCurrentMonth).subtract(1, "months").format(),
      endDate: tzMoment(todayEnd).subtract(1, "months").format(),
    },
  },
  lastMonth: {
    value: "last_month",
    title: "Last Month",
    secondaryTitle: `${tzMoment(firstOfPrevMonth).format(
      secondaryTextFormat
    )} - ${tzMoment(lastDayOfPrevMonth).format(secondaryTextFormat)}`,
    startDate: firstOfPrevMonth,
    endDate: lastDayOfPrevMonth,
    previousPeriod: {
      startDate: tzMoment(firstOfPrevMonth).subtract(1, "months").format(),
      endDate: tzMoment(lastDayOfPrevMonth).subtract(1, "months").format(),
    },
  },
  yearToDate: {
    value: "year_to_date",
    title: "Year To Date",
    secondaryTitle: `${tzMoment(firstOfCurrentYear).format(
      secondaryTextFormat
    )} - ${tzMoment(todayEnd).format(secondaryTextFormat)}`,
    startDate: firstOfCurrentYear,
    endDate: todayEnd,
    previousPeriod: {
      startDate: tzMoment(firstOfCurrentYear).subtract(1, "years").format(),
      endDate: tzMoment(todayEnd).subtract(1, "years").format(),
    },
  },
  custom: {
    value: "custom",
    title: "Custom",
    secondaryTitle: "",
  },
}

export const customDateSelectFieldOptions = Object.values(datesOptions)
.map(option => ({
  value: option.value,
  label: option.title,
  customAbbreviation: option.secondaryTitle,
}))

export const datesSelectFieldOptions = customDateSelectFieldOptions.filter(
  ({ value }) => value !== datesOptions.custom.value
)

export function isCustomDateOption(value) {
  return value === datesOptions.custom.value
}

export const entityReportTypes = {
  ENTITY_REPORT: "entity_report",
  INDIVIDUAL_SUMMARY: "individual_summary",
}
