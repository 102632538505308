import {
  ADD_GOAL,
  FETCH_GOALS,
  GOAL_ERROR,
  GOAL_SUCCESS,
  SET_GOALS,
  UPDATE_GOAL,
} from "./actionTypes"

const initialState = {
  errorMsg: "",
  successMsg: "",
  loading: false,
  goals: [],
}

const goal = (state = initialState, action) => {
  switch (action.type) {
    case ADD_GOAL:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break

    case UPDATE_GOAL:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case FETCH_GOALS:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        goals: [],
        loading: true,
      }
      break

    case SET_GOALS:
      state = {
        ...state,
        loading: false,
        goals: action.payload.goals,
      }
      break
    case GOAL_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload.msg,
        loading: false,
      }
      break
    case GOAL_ERROR:
      state = { ...state, errorMsg: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default goal
