import PageContainer from "components/Common/PageContainer"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { Alert, Button, Form, Spinner } from "reactstrap"
import * as Yup from "yup"
import InputField from "components/Form/InputField"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import SelectField from "components/Form/SelectField"
import {
  addGroup,
  fetchCompanies,
  fetchDepartments,
  fetchStores,
  updateGroup,
} from "store/actions"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

const AddEditGroup = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { state: groupData } = useLocation()
  const [showConfirm, setShowConfirm] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchStores())
    dispatch(fetchCompanies())
  }, [])

  const StoresProperties = createSelector(
    state => state.Store,
    store => ({
      stores: store.stores,
    })
  )

  const { stores } = useSelector(StoresProperties)

  const CompanyProperties = createSelector(
    state => state.Company,
    comp => ({
      companies: comp.companies,
    })
  )

  const { companies } = useSelector(CompanyProperties)

  const GroupProperties = createSelector(
    state => state.Group,
    group => ({
      loading: group.loading,
      errorMsg: group.errorMsg,
      successMsg: group.successMsg,
    })
  )

  const { loading, errorMsg, successMsg } = useSelector(GroupProperties)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: groupData
      ? {
          name: groupData.name,
          company: {
            value: groupData.company?.company_id,
            label: groupData.company?.name,
          },
          stores: groupData.stores?.map(item => ({
            value: item.store_id,
            label: item.name,
          })),
        }
      : {
          name: "",
          company: null,
          stores: [],
        },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter store name"),
      company: Yup.object().required("Please choose company"),
      stores: Yup.array().min(1, "Please choose at least one store"),
    }),
    onSubmit: values => {
      window.scrollTo({ top: 0, behavior: "smooth" })
      const body = {
        name: values.name,
        companyId: values.company.value,
        storesIds: values.stores.map(el => el.value),
      }
      if (id && groupData) dispatch(updateGroup(id, body, navigate))
      else dispatch(addGroup(body, navigate))
    },
  })

  const handleCancel = () => {
    if (validation.dirty) {
      window.scrollTo({ top: 0, behavior: "smooth" })
      setShowConfirm(true)
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    if (successMsg) {
      toast.success(successMsg)
    }
  }, [successMsg])

  useEffect(() => {
    if (errorMsg) {
      toast.error(errorMsg)
    }
  }, [errorMsg])

  return (
    <PageContainer>
      <h1 className="page-title mb-4">{id ? "Edit" : "Add"} Group</h1>
      <div className="content-container">
        {showConfirm && (
          <Alert color="danger" style={{ marginTop: "13px" }}>
            <div>
              <p>Are you sure you want to cancel? Your inputs will be lost.</p>
              <div className="d-flex">
                <Button
                  type="button"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() => navigate(-1)}
                >
                  Yes
                </Button>
                <Button type="button" onClick={() => setShowConfirm(false)}>
                  No
                </Button>
              </div>
            </div>
          </Alert>
        )}

        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-12 mb-4">
                <InputField
                  name="name"
                  validation={validation}
                  placeholder="Enter group name"
                  label={"Name"}
                  required
                />
              </div>
              <div className="col-lg-7 col-12 mb-4">
                <SelectField
                  label={"Company"}
                  required
                  name="company"
                  validation={validation}
                  options={companies?.map(item => ({
                    value: item.company_id,
                    label: item.name,
                  }))}
                />
              </div>
              <div className="col-lg-7 col-12 mb-4">
                <SelectField
                  label={"Stores"}
                  required
                  name="stores"
                  validation={validation}
                  isMulti
                  options={stores
                    ?.filter(
                      item =>
                        item.company?.company_id ===
                        validation.values.company?.value
                    )
                    ?.map(item => ({
                      value: item.store_id,
                      label: item.name,
                    }))}
                />
              </div>
            </div>
          </div>

          <div className="mt-5 d-flex justify-content-center justify-content-lg-start">
            <Button
              color="primary"
              style={{
                marginRight: "20px",
                width: "110px",
              }}
              type="submit"
            >
              {loading ? <Spinner size="sm" /> : "Save"}
            </Button>
            <Button type="button" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </PageContainer>
  )
}

export default AddEditGroup
