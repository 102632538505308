import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { ADD_USER, FETCH_USERS, UPDATE_USER } from "./actionTypes"
import { userSuccess, userError, setUsers } from "./actions"

//Include Both Helper File with needed methods
import { getUsers, postUser, putUser } from "../../helpers/fakebackend_helper"
import { isArray } from "lodash"

function* addUser({ payload: { user, history } }) {
  try {
    yield call(postUser, user)
    yield put(userSuccess("Created Successfully"))
    history("/users")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(userError(errMsg))
  }
}

function* fetchUsers({ payload: { searchValue } }) {
  try {
    const response = yield call(getUsers, searchValue)
    yield put(setUsers(response))
  } catch (error) {
    console.log("error", error)
  }
}

function* updateUser({ payload: { id, user, history } }) {
  try {
    yield call(putUser, id, user)
    yield put(userSuccess("Updated Successfully"))
    history("/users")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(userError(errMsg))
  }
}

function* userSaga() {
  yield takeEvery(ADD_USER, addUser)
  yield takeEvery(FETCH_USERS, fetchUsers)
  yield takeEvery(UPDATE_USER, updateUser)
}

export default userSaga
