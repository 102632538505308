import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Col, Container, Row, Spinner } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import Companies from "pages/Companies"
import PageContainer from "components/Common/PageContainer"
import NewCallForm from "pages/CallRecords/NewCallForm"
import DashboardCountCard from "components/DashboardCountCard"
import {
  faCalendar,
  faCalendarCheck,
  faCaretLeft,
  faCaretRight,
  faHandshake,
  faPhone,
} from "@fortawesome/free-solid-svg-icons"
import DashboardPercentCard from "components/DashboardPercentCard"
import { axiosApi } from "helpers/api_helper"
import { format } from "date-fns"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import NewCallCountCard from "components/NewCallCountCard"
import NewCallPercentCard from "components/NewCallPercentCard"
import { getPercentage, getSum } from "helpers/helperFns"
import { datesOptions } from "constants/datesOptions"

const NewCall = props => {
  //meta title
  // document.title = "Dashboard PPG"

  const CallProperties = createSelector(
    state => state.Call,
    call => ({
      successMsg: call.successMsg,
    })
  )

  const { successMsg } = useSelector(CallProperties)

  const [report, setReport] = useState({})
  // const [goal, setGoal] = useState({})
  const [callsGoal, setCallsGoal] = useState({})
  const [apptsGoal, setApptsGoal] = useState({})
  const [ibRatioGoal, setIbRatioGoal] = useState({})
  const [obRatioGoal, setObRatioGoal] = useState({})
  const [loading, setLoading] = useState(true)

  const fetchActualCalls = async () => {
    setLoading(true)

    const actualCallsResponse = await axiosApi.get(
      `/api/v1/reports/dashboard?startDate=${datesOptions.today.startDate}&detailed=true`
    )

    const newReport = {
      ...actualCallsResponse.data?.calls,
      transferOut: 0,
    }

    for (const disp of actualCallsResponse.data?.calls?.dispositions) {
      if (disp.isSaRequired) {
        newReport.transferOut +=
          Number(disp.inbound) +
          Number(disp.outboundNotConnected) +
          Number(disp.outboundConnected) +
          Number(disp.internal)
      }
    }
    setReport(newReport)

    setLoading(false)
  }

  const fetchCallsGoals = async () => {
    setLoading(true)

    //calls goal
    const callsGoalResponse = await axiosApi.get(
      `/api/v1/reports/dashboard-goal/Calls?startDate=${datesOptions.today.startDate}`
    )
    const newCallsGoal = {}
    for (const goal of callsGoalResponse.data) {
      newCallsGoal.target = Math.ceil(Number(goal.target) / 21)
      newCallsGoal.minStandard = Number(goal.minstandard) / 21
      if (goal.entity_type === "agent") break
    }
    setCallsGoal(newCallsGoal)

    //appts goal
    const apptGoalResponse = await axiosApi.get(
      `/api/v1/reports/dashboard-goal/Appointments?startDate=${datesOptions.today.startDate}`
    )
    const newApptsGoal = {}
    for (const goal of apptGoalResponse.data) {
      newApptsGoal.target = Math.ceil(Number(goal.target) / 21)
      newApptsGoal.minStandard = Number(goal.minstandard) / 21
      if (goal.entity_type === "agent") break
    }
    setApptsGoal(newApptsGoal)

    //Ib ratio goal
    const ibRatioResponse = await axiosApi.get(
      `/api/v1/reports/dashboard-goal/IB Closing Ratio?startDate=${datesOptions.today.startDate}`
    )
    const newIbRatioGoal = { target: 0, minStandard: 0 }
    for (const goal of ibRatioResponse.data) {
      newIbRatioGoal.target = Number(
        goal.target?.substring(0, goal.target?.length - 1)
      )

      newIbRatioGoal.minStandard = Number(
        goal.minstandard?.substring(0, goal.minstandard?.length - 1)
      )

      if (goal.entity_type === "agent") break
    }
    setIbRatioGoal(newIbRatioGoal)

    //Ob ratio goal
    const obRatioResponse = await axiosApi.get(
      `/api/v1/reports/dashboard-goal/OB Closing Ratio?startDate=${datesOptions.today.startDate}`
    )
    const newObRatioGoal = { target: 0, minStandard: 0 }
    for (const goal of obRatioResponse.data) {
      newObRatioGoal.target = Number(
        goal.target?.substring(0, goal.target?.length - 1)
      )

      newObRatioGoal.minStandard = Number(
        goal.minstandard?.substring(0, goal.minstandard?.length - 1)
      )
      if (goal.entity_type === "agent") break
    }
    setObRatioGoal(newObRatioGoal)

    setLoading(false)
  }

  useEffect(() => {
    fetchCallsGoals()
    fetchActualCalls()
  }, [successMsg])
  const getPercent = (num1, num2) => {
    if (!num1 || !num2) return 0

    return Math.ceil((num1 / (num1 + num2)) * 100)
  }

  return (
    <React.Fragment>
      <PageContainer>
        <h1 className="page-title">New Call</h1>

        <Row>
          <Col lg={9} className="mb-4">
            <NewCallForm />
          </Col>
          <Col lg={3} className="d-flex flex-column gap-2">
            <NewCallCountCard
              count={
                loading
                  ? 0
                  : (report?.inbound || 0) +
                    (report?.outboundNotConnected || 0) +
                    (report?.outboundConnected || 0) +
                    (report?.internal || 0)
              }
              title="Total Calls"
              icon={{
                icon: faPhone,
                value: getPercentage(report?.transferOut, report?.totalCalls),
                label: "Transfer Out",
              }}
              target={callsGoal?.target}
              minStandard={callsGoal?.minStandard}
              multiProgress={[
                {
                  percent: getPercent(
                    Number(report.inbound || 0),
                    Number(report.outboundNotConnected || 0) +
                      Number(report.outboundConnected || 0)
                  ),
                  value: Math.ceil(report.inbound || 0),
                },
                {
                  percent: getPercent(
                    Number(report.outboundNotConnected || 0) +
                      Number(report.outboundConnected || 0),
                    Number(report.inbound || 0)
                  ),
                  value: Math.ceil(
                    Number(report.outboundNotConnected || 0) +
                      Number(report.outboundConnected || 0)
                  ),
                },
              ]}
              isLoading={loading}
            />
            <NewCallCountCard
              count={
                loading
                  ? 0
                  : (report.appointedInbound || 0) +
                    (report.appointedInternal || 0) +
                    (report.outboundAppointedNotConnected || 0) +
                    (report.outboundAppointedConnected || 0)
              }
              title="Total Appts"
              // icon={faCalendarCheck}
              icon={{
                icon: faCalendarCheck,
                value: `${
                  Number(report?.outboundAppointedNotConnected || 0) +
                  Number(report?.outboundAppointedConnected || 0)
                }`,
                label: "OB Appt",
              }}
              target={apptsGoal?.target}
              minStandard={apptsGoal?.minStandard}
              multiProgress={[
                {
                  percent: getPercent(
                    Number(report?.appointedInbound || 0),
                    Number(report?.outboundAppointedNotConnected || 0) +
                      Number(report?.outboundAppointedConnected || 0)
                  ),
                  value: Math.ceil(report?.appointedInbound || 0),
                },
                {
                  percent: getPercent(
                    Number(report?.outboundAppointedNotConnected || 0) +
                      Number(report?.outboundAppointedConnected || 0),
                    Number(report?.appointedInbound || 0)
                  ),
                  value: Math.ceil(
                    Number(report?.outboundAppointedNotConnected || 0) +
                      Number(report?.outboundAppointedConnected || 0)
                  ),
                },
              ]}
              isLoading={loading}
            />
            <NewCallPercentCard
              percent={
                loading || Number(report?.inbound || 0) === 0
                  ? 0
                  : (Number(report?.appointedInbound || 0) /
                      Number(report?.inbound)) *
                    100
              }
              title="IB Closing Ratio"
              icon={
                <div className="d-flex align-items-start">
                  <FontAwesomeIcon
                    fontSize={"50px"}
                    color="#D9D9D9"
                    icon={faPhone}
                  />
                  <FontAwesomeIcon
                    icon={faCaretLeft}
                    color="#D9D9D9"
                    fontSize={"30px"}
                  />
                </div>
              }
              info="IB Closing Ratio"
              minStandard={ibRatioGoal?.minStandard}
              percentColor={"#F1426D"}
              isLoading={loading}
              type="inbound"
              target={ibRatioGoal?.target}
            />
            <NewCallPercentCard
              percent={
                loading || Number(report?.outboundConnected || 0) === 0
                  ? 0
                  : getPercentage(
                      getSum([
                        report.outboundAppointedNotConnected,
                        report.outboundAppointedConnected,
                      ]),
                      report.outboundConnected
                    )
              }
              title="OB Closing Ratio"
              icon={
                <div className="d-flex align-items-start">
                  <FontAwesomeIcon
                    fontSize={"50px"}
                    color="#D9D9D9"
                    icon={faPhone}
                  />
                  <FontAwesomeIcon
                    icon={faCaretRight}
                    color="#D9D9D9"
                    fontSize={"30px"}
                  />
                </div>
              }
              info="OB Closing Ratio"
              minStandard={obRatioGoal?.minStandard}
              percentColor={"#7239EA"}
              isLoading={loading}
              type="outbound"
              target={obRatioGoal?.target}
            />
          </Col>
        </Row>
        <div className="row">
          <div className="col-span-8"></div>
          <div className="col-span-4"></div>
        </div>
      </PageContainer>
    </React.Fragment>
  )
}

export default NewCall
