import { FETCH_MS_TARGET_REPORT, SET_MS_TARGET_REPORT } from "./actionTypes"

export const fetchMSTargetReport = (
  queryParams = {
    storesIds: [],
    startDate: "",
    endDate: "",
  }
) => {
  return {
    type: FETCH_MS_TARGET_REPORT,
    payload: { queryParams },
  }
}
export const resetMSTargetReportReports = () => {
  return {
    type: SET_MS_TARGET_REPORT,
    payload: { msTargetReport: undefined },
  }
}

export const setMSTargetReportReports = msTargetReport => {
  return {
    type: SET_MS_TARGET_REPORT,
    payload: { msTargetReport },
  }
}
