import PageContainer from "components/Common/PageContainer"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { Alert, Button, Form, Spinner } from "reactstrap"
import * as Yup from "yup"
import InputField from "components/Form/InputField"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { addDepartment, updateDepartment } from "store/actions"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

const AddEditDepartment = () => {
  const navigate = useNavigate()

  const { id } = useParams()
  const { state: departmentData } = useLocation()

  const [showConfirm, setShowConfirm] = useState(false)
  const dispatch = useDispatch()

  const DepartmentProperties = createSelector(
    state => state.Department,
    dep => ({
      loading: dep.loading,
      errorMsg: dep.errorMsg,
      successMsg: dep.successMsg,
    })
  )

  const { loading, errorMsg, successMsg } = useSelector(DepartmentProperties)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: departmentData
      ? {
          name: departmentData.type_name,
          description: departmentData.description,
        }
      : {
          name: "",
          description: "",
        },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter department name"),
      description: Yup.string().required("Please enter department description"),
    }),
    onSubmit: values => {
      window.scrollTo({ top: 0, behavior: "smooth" })
      const body = {
        type_name: values.name,
        description: values.description,
      }
      if (id && departmentData) dispatch(updateDepartment(id, body, navigate))
      else dispatch(addDepartment(body, navigate))
    },
  })

  const handleCancel = () => {
    if (validation.dirty) {
      window.scrollTo({ top: 0, behavior: "smooth" })
      setShowConfirm(true)
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    if (successMsg) {
      toast.success(successMsg)
    }
  }, [successMsg])

  useEffect(() => {
    if (errorMsg) {
      toast.error(errorMsg)
    }
  }, [errorMsg])

  return (
    <PageContainer>
      <h1 className="page-title mb-4">{id ? "Edit" : "Add"} Department</h1>
      <div className="content-container">
        {showConfirm && (
          <Alert color="danger" style={{ marginTop: "13px" }}>
            <div>
              <p>Are you sure you want to cancel? Your inputs will be lost.</p>
              <div className="d-flex">
                <Button
                  type="button"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() => navigate(-1)}
                >
                  Yes
                </Button>
                <Button type="button" onClick={() => setShowConfirm(false)}>
                  No
                </Button>
              </div>
            </div>
          </Alert>
        )}

        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-12 mb-4">
                <InputField
                  name="name"
                  validation={validation}
                  placeholder="Enter department name"
                  label={"Name"}
                  required
                />
              </div>
              <div className="col-lg-7 col-12 mb-4">
                <InputField
                  name="description"
                  type="textarea"
                  validation={validation}
                  placeholder="Enter department description"
                  label={"Description"}
                  style={{ height: "130px" }}
                  required
                />
              </div>
            </div>
          </div>

          <div className="mt-5 d-flex justify-content-center justify-content-lg-start">
            <Button
              color="primary"
              style={{
                marginRight: "20px",
                width: "110px",
              }}
              type="submit"
            >
              {loading ? <Spinner size="sm" /> : "Save"}
            </Button>
            <Button type="button" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </PageContainer>
  )
}

export default AddEditDepartment
