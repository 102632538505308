import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import AgGridTable from "components/Common/AgGridTable"
import { Button, Form, Spinner } from "reactstrap"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import {
  fetchEntityReports,
  resetEntityReports,
} from "store/entityReport/actions"
import {
  approximate,
  convertRatioToPercentage,
  getAverage,
  getPercentage,
  getPreviousPeriodFromRange,
  getSum,
} from "helpers/helperFns"
import ScopeSelector from "components/Common/ScopeSelector"
import SelectField from "components/Form/SelectField"
import { useFormik } from "formik"
import * as Yup from "yup"
import { entityReportTypes } from "./constants"
import { fetchUsers } from "store/actions"
import { format } from "date-fns"
import {
  customDateSelectFieldOptions,
  datesOptions,
  isCustomDateOption,
  tzMoment,
} from "constants/datesOptions"
import StatisticsSection from "./StatisticsSection"
import useEntityReports from "./hooks/useEntityReports"
import CsvIcon from "assets/icons/CsvIcon"
import CustomRangePicker from "components/Form/CustomRangePicker"

const setPrinterFriendly = api => {
  const eGridDiv = document.querySelector("#myGrid")
  eGridDiv.style.width = ""
  eGridDiv.style.height = ""
  api.sizeColumnsToFit()
  api.updateGridOptions({
    pagination: false,
  })
  api.setGridOption("domLayout", "print")
}
const setNormal = api => {
  const eGridDiv = document.querySelector("#myGrid")
  // eGridDiv.style.width = "700px"
  // eGridDiv.style.height = "200px"
  api.updateGridOptions({
    pagination: true,
  })
  api.setGridOption("domLayout", undefined)
}

const CommonEntityReports = ({ type = entityReportTypes.ENTITY_REPORT }) => {
  const dispatch = useDispatch()
  const gridRef = useRef()
  const [rangePickerKey, setRangePickerKey] = useState(0)

  const moduleName =
    type === entityReportTypes.INDIVIDUAL_SUMMARY
      ? "individualSummaryReport"
      : type === entityReportTypes.TEAM_TOTAL_REPORT
      ? "teamTotalReport"
      : type === entityReportTypes.ENTITY_REPORT
      ? "entityReport"
      : ""

  const localStorageUser = localStorage.getItem("authUser")
  const user = localStorageUser
    ? JSON.parse(localStorage.getItem("authUser"))
    : undefined

  //permissions
  const PermissionsSelector = createSelector(
    state => state.Permissions,
    perm => ({
      permissions: perm.permissions,
    })
  )
  const { permissions } = useSelector(PermissionsSelector)

  const isAdmin = permissions?.[moduleName]?.ADD
  const isIndividualSummary = type === entityReportTypes.INDIVIDUAL_SUMMARY
  const isTeamTotalReport = type === entityReportTypes.TEAM_TOTAL_REPORT
  const isEntityReport = type === entityReportTypes.ENTITY_REPORT

  const minimumRequiredSelectedUsers = isIndividualSummary ? 1 : 0

  // const { dailyData = [], summary = {} } = entityReport || {}

  const UserProperties = createSelector(
    state => state.User,
    user => ({
      loading: user.loading,
      users: user.users,
    })
  )

  const { loading: loadingUsers, users } = useSelector(UserProperties)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    // validateOnMount: true,
    // isInitialValid: false,
    initialValues: {
      user:
        isIndividualSummary || isAdmin
          ? undefined
          : {
              value: user?.user_id,
              label: user?.name,
            },

      scope: [],
      selectedDateOption: {
        value: datesOptions.monthToDate.value,
        label: datesOptions.monthToDate.title,
        customAbbreviation: datesOptions.monthToDate.secondaryTitle,
      },
      startDate: "",
      endDate: "",
    },
    validationSchema: Yup.object({
      startDate: Yup.string().when(
        "selectedDateOption",
        ([selectedDateOption], schema) =>
          selectedDateOption.value === datesOptions.custom.value
            ? schema.required("Please add start date")
            : schema.optional()
      ),
      endDate: Yup.string().when(
        "selectedDateOption",
        ([selectedDateOption], schema) =>
          selectedDateOption.value === datesOptions.custom.value
            ? schema.required("Please add start date")
            : schema.optional()
      ),
      scope: Yup.array().min(1, "Scope Is Required"),
      // users: Yup.array().min(
      //   minimumRequiredSelectedUsers,
      //   "Please choose at least one user"
      // ),
      user: isIndividualSummary
        ? Yup.object().required("Please select a user")
        : Yup.object(),
      selectedDateOption: Yup.object().required("Please select a date option"),
    }),
    onSubmit: values => {
      const currentDateOption = Object.values(datesOptions).find(
        ({ value }) => value === values.selectedDateOption.value
      )

      // if scope has "1" which is the whole system scope, then send empty scope as its optional in the api and will return the report for the whole system
      const updatedScope = values.scope.includes("1") ? [] : values.scope

      let startDate, endDate, previousPeriodStart, previousPeriodEnd
      if (isCustomDateOption(currentDateOption.value)) {
        startDate = values.startDate
        endDate = values.endDate

        const [prevStart, prevEnd] = getPreviousPeriodFromRange(
          startDate,
          endDate
        )
        previousPeriodStart = prevStart
        previousPeriodEnd = prevEnd
      } else {
        startDate = currentDateOption.startDate
        endDate = currentDateOption.endDate
        previousPeriodStart = currentDateOption.previousPeriod.startDate
        previousPeriodEnd = currentDateOption.previousPeriod.endDate
      }
      dispatch(
        fetchEntityReports({
          agentIds: values.user ? [values.user.value] : [],
          ids: updatedScope,
          startDate: startDate,
          endDate: endDate,
          withAgentsNumber: isTeamTotalReport,
          withManuallyAddedKpisPerAgent: true,
          withManuallyAddedKpisPerStore: isTeamTotalReport || isEntityReport,
          ...(isTeamTotalReport && {
            previousPeriodReportData: {
              isEnabled: true,
              startDate: previousPeriodStart,
              endDate: previousPeriodEnd,
            },
          }),
        })
      )
    },
  })

  function refreshDatePicker() {
    setRangePickerKey(oldVal => oldVal + 1)
  }

  const isCustomDate = isCustomDateOption(
    validation.values.selectedDateOption?.value
  )

  function handleCustomRangeChange(dateStrings) {
    if (dateStrings.some(el => !el)) {
      return
    }

    const [startStr, endStr] = dateStrings || []
    validation.setFieldValue("startDate", startStr)
    validation.setFieldValue("endDate", endStr)

    const startStrTitle = startStr
      ? tzMoment(startStr).format("MM/DD/YYYY")
      : ""
    const endStrTitle = endStr ? tzMoment(endStr).format("MM/DD/YYYY") : ""
    const customAbbr =
      startStrTitle && endStrTitle ? `${startStrTitle} - ${endStrTitle}` : ""
    validation.setFieldValue("selectedDateOption", {
      ...validation.values.selectedDateOption,
      customAbbreviation: customAbbr,
    })
  }

  const {
    columns,
    loadingEntityReports,
    pinnedTopRows,
    rows,
    setRows,
    statisticsData,
  } = useEntityReports({
    validation,
    isTeamTotalReport,
    isIndividualSummary,
    isEntityReport,
  })

  useEffect(() => {
    if (isIndividualSummary) {
      dispatch(fetchUsers("Agent"))
    }
  }, [isIndividualSummary])
  useEffect(() => {
    if (validation.values) {
      dispatch(resetEntityReports())
    }
  }, [validation.values])

  const selectedUsersIds = useMemo(() => {
    return validation.values.user?.value ? [validation.values.user?.value] : []
  }, [validation.values.user?.value])

  const onBtnExport = useCallback(() => {
    const fileName = isEntityReport
      ? "Entity Report"
      : isTeamTotalReport
      ? "Team Total Report"
      : isIndividualSummary
      ? "Individual Summary Report"
      : ""
    const csvParams = {
      fileName,
    }
    gridRef.current.api.exportDataAsCsv(csvParams)
  }, [gridRef, isEntityReport, isTeamTotalReport, isIndividualSummary])

  // const onBtPrint = useCallback(() => {
  //   setPrinterFriendly(gridRef.current.api)
  //   setTimeout(() => {
  //     print()
  //     setNormal(gridRef.current.api)
  //   }, 2000)
  // }, [print, gridRef])

  return (
    <div>
      {/* -15px margin to blend in the 2 sections */}
      <Form
        className="content-container"
        style={{ marginBottom: "-15px" }}
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <div
          className="row pb-2 flex-wrap"
          style={{ background: "#fff", rowGap: "20px" }}
        >
          {isIndividualSummary && (
            <div className="col-lg-3 col-12 flex-wrap">
              <SelectField
                label={"User"}
                required
                name="user"
                validation={validation}
                // isMulti
                options={users?.map(user => ({
                  value: user.user_id,
                  label: user.name,
                }))}
              />
            </div>
          )}
          <div className="col-lg-3 col-12">
            <ScopeSelector
              isSelfScope={!isIndividualSummary}
              usersIds={selectedUsersIds}
              validation={validation}
              onChange={ids => {
                validation.setFieldValue("scope", ids)
              }}
            />
          </div>
          <div className="col-lg-4 col-12">
            <SelectField
              label="Date"
              name="selectedDateOption"
              validation={validation}
              required
              options={customDateSelectFieldOptions}
              onChange={option => {
                validation.setFieldValue("selectedDateOption", option)
                if (isCustomDateOption(option.value)) {
                  validation.setFieldValue("startDate", "")
                  validation.setFieldValue("endDate", "")
                  refreshDatePicker()
                }
              }}
            />
            {isCustomDate && !validation.values.startDate && (
              <div key={rangePickerKey} className="">
                <CustomRangePicker onChangeHandler={handleCustomRangeChange} />
              </div>
            )}
          </div>
          {/* <div className="col-lg-1 col-12" style={{ marginTop: "28px" }}></div> */}
          <div
            // className="col-lg-1 col-12 d-flex gap-2"
            className="col d-flex align-items-start justify-content-between gap-2"
            style={{ marginTop: "28px" }}
          >
            <Button
              color="primary"
              style={{ height: "36px", marginRight: 20 }}
              type="submit"
            >
              Generate
            </Button>
            {!!columns.length && (
              <>
                <div>
                  <CsvIcon onClick={onBtnExport} />
                </div>
                {/* <div>
                  <i
                    className="fa-xl text-primary fa fa-fw fa-print"
                    style={{ cursor: "pointer" }}
                    onClick={onBtPrint}
                  />
                </div> */}
              </>
            )}
          </div>
        </div>
      </Form>

      {loadingEntityReports ? (
        <div className="w-full d-flex justify-content-center align-items-center pt-4">
          <Spinner />
        </div>
      ) : (
        <>
          {permissions?.[moduleName]?.VIEW &&
          validation.isValid &&
          !validation.isValidating &&
          columns.length ? (
            <>
              {isTeamTotalReport && (
                <div
                  className="content-container"
                  style={{ marginBottom: "-25px" }}
                >
                  <div
                    style={{
                      margin: "auto",
                      marginTop: "25px",

                      width: "fit-content",
                    }}
                  >
                    <StatisticsSection statisticsData={statisticsData} />
                  </div>
                </div>
              )}

              <AgGridTable
                rowState={[rows, setRows]}
                columns={columns}
                // qiuckFilter={true}
                pinnedTopRowData={pinnedTopRows}
                gridRef={gridRef}
              />
            </>
          ) : null}
        </>
      )}
    </div>
  )
}

export default CommonEntityReports
