import {
  ADD_GOAL,
  FETCH_GOALS,
  GOAL_ERROR,
  GOAL_SUCCESS,
  SET_GOALS,
  UPDATE_GOAL,
} from "./actionTypes"

export const addGoal = (goal, history) => {
  return {
    type: ADD_GOAL,
    payload: { goal, history },
  }
}

export const updateGoal = (id, goal, history) => {
  return {
    type: UPDATE_GOAL,
    payload: { id, goal, history },
  }
}

export const fetchGoals = () => {
  return {
    type: FETCH_GOALS,
  }
}

export const goalSuccess = msg => {
  return {
    type: GOAL_SUCCESS,
    payload: { msg },
  }
}

export const goalError = error => {
  return {
    type: GOAL_ERROR,
    payload: error,
  }
}

export const setGoals = goals => {
  return {
    type: SET_GOALS,
    payload: { goals },
  }
}
