import React, { useEffect, useState } from "react"
import EditIcon from "assets/icons/EditIcon"
import DeleteIcon from "assets/icons/DeleteIcon"
import AgGridTable from "components/Common/AgGridTable"
import PageContainer from "components/Common/PageContainer"
import { Button, Spinner } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { fetchGoalsEntries, fetchGroups } from "store/actions"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { axiosApi } from "helpers/api_helper"
import DeleteModal from "components/Common/DeleteModal"
import { POST_GOAL_ENTRY, POST_GROUP } from "helpers/url_helper"
import { format } from "date-fns"
import moment from "moment"

const moduleName = "goalsEntries"

const GoalsEntries = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  //permissions
  const PermissionsSelector = createSelector(
    state => state.Permissions,
    perm => ({
      permissions: perm.permissions,
    })
  )
  const { permissions } = useSelector(PermissionsSelector)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [currentId, setCurrentId] = useState()
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [deleteError, setDeleteError] = useState()

  const [rows, setRows] = useState([])

  useEffect(() => {
    dispatch(fetchGoalsEntries())
  }, [])

  const GoalEntryProperties = createSelector(
    state => state.GoalEntry,
    goalEntry => ({
      loading: goalEntry.loading,
      goalsEntries: goalEntry.goalsEntries,
    })
  )

  const { loading, goalsEntries } = useSelector(GoalEntryProperties)

  useEffect(() => {
    setRows(
      goalsEntries?.map(item => ({
        id: item.goalentry_id,
        month: item.month,
        goal: item.goal?.name,
        minStandard: item.minstandard,
        target: item.target,
        entityType:
          item.entity_type?.[0]?.toUpperCase() + item.entity_type?.substr(1),
        entity:
          item.entity_type === "store"
            ? item.goalEntity?.store?.name
            : item.goalEntity?.user?.name,
      }))
    )
  }, [goalsEntries])

  const columns = [
    {
      field: "month",
      filter: false,
      headerName: "Month",
      valueFormatter: params =>
        moment(params.data.month, "YYYY-MM").format("MMMM YYYY"),
    },
    { field: "goal", filter: true, headerName: "Goal" },
    { field: "entityType", filter: true, headerName: "Entity Type" },
    { field: "entity", filter: true, headerName: "Entity" },
    { field: "minStandard", filter: true, headerName: "Minimum Standard" },
    { field: "target", filter: true, headerName: "Target" },
    {
      headerName: "",
      sortable: false,
      cellRenderer: params => {
        return (
          <div className="d-flex align-items-center h-100 gap-2">
            {actions(params.data.id)}
          </div>
        )
      },
    },
  ]

  const actions = id => {
    return (
      <>
        {permissions?.[moduleName]?.EDIT && (
          <div
            onClick={() =>
              navigate("/goal-entries/" + id, {
                state: goalsEntries?.find(item => item.goalentry_id === id),
              })
            }
          >
            <EditIcon />
          </div>
        )}
        {permissions?.[moduleName]?.DELETE && (
          <div
            onClick={() => {
              setShowDeleteModal(true)
              setCurrentId(id)
            }}
          >
            <DeleteIcon />
          </div>
        )}
      </>
    )
  }

  const handleDelete = async () => {
    setDeleteLoading(true)
    setDeleteError(null)
    try {
      await axiosApi.delete(POST_GOAL_ENTRY + "/" + currentId)
      dispatch(fetchGoalsEntries())
      setShowDeleteModal(false)
    } catch (err) {
      setDeleteError(err.response.data?.message)
    } finally {
      setDeleteLoading(false)
    }
  }

  return (
    <PageContainer>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setShowDeleteModal(false)
          setDeleteError(null)
        }}
        errorMsg={deleteError}
        loading={deleteLoading}
      />
      <div className="d-flex justify-content-between mb-4">
        <h1 className="page-title">KPI Goal Entries</h1>
        {permissions?.[moduleName]?.ADD && (
          <Button
            color="primary"
            style={{ height: "36px" }}
            onClick={() => navigate("new")}
          >
            Set KPI Goal
          </Button>
        )}
      </div>
      {loading ? (
        <div className="w-full d-flex justify-content-center align-items-center pt-4">
          <Spinner />
        </div>
      ) : (
        <>
          {permissions?.[moduleName]?.VIEW && (
            <AgGridTable
              rowState={[rows, setRows]}
              columns={columns}
              qiuckFilter={true}
            />
          )}
        </>
      )}
    </PageContainer>
  )
}

export default GoalsEntries
