import { AgGridReact } from "ag-grid-react" // AG Grid Component
import "ag-grid-community/styles/ag-grid.css" // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css" // Optional Theme applied to the grid
import React, { useEffect, useRef, useState } from "react"
import "../../assets/scss/custom/components/_agGrid.scss"
import { Input } from "reactstrap"
import { Tooltip } from "react-tooltip"

const AgGridTable = ({
  rowState,
  columns,
  gridOptions,
  qiuckFilter,
  minWidth = "600px",
  // height = 550,
  field = false,
  // refresh,
  pinnedTopRowData,
  pinnedBottomRowData,
  gridRef,
  ...restProps
}) => {
  // Row Data: The data to be displayed.
  const [rowData, setRowData] = rowState

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState(columns)

  const [quickFilterText, setQuickFilterText] = useState("")

  const getRowStyle = params => {
    if (params.node.isRowPinned()) {
      return { background: "rgba(230, 230, 230, 0.4)" }
    } else if (params.node.rowIndex % 2 !== 0) {
      return { background: "#fbfbfb" }
    }
  }

  // useEffect(() => {
  //   setRowData([...rowData])
  // }, [refresh])

  return (
    <div
      className={`ag-theme-quartz ${field ? "" : "content-container"}`}
      style={{ height: "fit-content", overflowX: "auto" }}
    >
      {qiuckFilter && (
        <div className="col-md-1 col-lg-3">
          <Input
            type="text"
            placeholder="Search..."
            value={quickFilterText}
            onChange={e => setQuickFilterText(e.target.value)}
            className="mb-4 w-100 p-2"
          />
        </div>
      )}
      <div
        // id="myGrid"
        style={
          {
            // height,
            // width: "100%",
            // minWidth,
          }
        }
      >
        <AgGridReact
          ref={gridRef}
          domLayout={"autoHeight"}
          rowData={rowData}
          pinnedTopRowData={pinnedTopRowData}
          pinnedBottomRowData={pinnedBottomRowData}
          columnDefs={colDefs}
          defaultColDef={{ suppressMovable: true, resizable: false }}
          getRowStyle={getRowStyle}
          gridOptions={
            gridOptions || {
              autoSizeStrategy: {
                type: "fitCellContents",
              },
              pagination: true,
              paginationPageSize: 10,
              paginationPageSizeSelector: [10, 20, 30],
            }
          }
          // onGridSizeChanged={onGridSizeChanged}
          {...(qiuckFilter && { quickFilterText })}
          suppressFieldDotNotation={true}
          {...restProps}
        />
        <Tooltip id="viewInfo" place="bottom" />
      </div>
    </div>
  )
}

export default AgGridTable
