import CsvIcon from "assets/icons/CsvIcon"
import AgGridTable from "components/Common/AgGridTable"
import React, { useCallback, useEffect, useRef, useState } from "react"

const ServiceAdvisorTable = ({ tableData }) => {
  const [rows, setRows] = useState([])
  const gridRef = useRef()

  useEffect(() => {
    setRows(tableData.tableRows)
  }, [tableData])

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv()
  }, [gridRef])
  return (
    <div>
      <div
        className="content-container text-center"
        style={{ marginBottom: "-25px" }}
      >
        <CsvIcon onClick={onBtnExport} />
      </div>
      <div>
        <AgGridTable
          gridRef={gridRef}
          gridOptions={{
            autoSizeStrategy: {
              type: "fitCellContents",
            },
          }}
          height={"300px"}
          rowState={[rows, setRows]}
          columns={tableData.tableColumns}
        />
      </div>
    </div>
  )
}

export default ServiceAdvisorTable
