import React from "react"

import RowItem from "./components/RowItem"
import CustomerServiceIcon from "assets/images/tvDashboard/CustomerServiceIcon"
import ShowIcon from "assets/images/tvDashboard/ShowIcon"
import PercentageIcon from "assets/images/tvDashboard/PercentageIcon"
import PerformanceIcon from "assets/images/tvDashboard/PerformanceIcon"
import ChronometerIcon from "assets/images/tvDashboard/ChronometerIcon"

const SideSection = ({
  activeAgentsData,
  totalShowsData,
  totalShowsPercentData,
  totalAvgHandlingTimeData,
  callPerformanceScoreData,
}) => {
  return (
    <div className="bg-white p-3 d-flex gap-2 flex-column h-100 justify-content-between">
      <RowItem
        iconBgColor="#c93f62"
        iconTitle="A"
        title="Active Agents"
        value={activeAgentsData.count}
        icon={<CustomerServiceIcon />}
        target={activeAgentsData.target}
        minStandard={activeAgentsData.minStandard}
      />
      <RowItem
        iconBgColor="#80cf72"
        iconTitle="S"
        title="Shows"
        value={totalShowsData.count}
        icon={<ShowIcon />}
        target={totalShowsData.target}
        minStandard={totalShowsData.minStandard}
      />
      <RowItem
        iconBgColor="#6032d5"
        iconTitle="%"
        title="Show %"
        value={totalShowsPercentData.percent}
        icon={<PercentageIcon />}
        target={totalShowsPercentData.target}
        minStandard={totalShowsPercentData.minStandard}
      />
      <RowItem
        iconBgColor="#527fe9"
        iconTitle="P"
        title="Avg Call Performance Score"
        value={callPerformanceScoreData.percent}
        icon={<PerformanceIcon />}
        target={callPerformanceScoreData.target}
        minStandard={callPerformanceScoreData.minStandard}
      />
      <RowItem
        iconBgColor="#e3c35e"
        iconTitle="H"
        title="Avg Call Handle Time"
        value={totalAvgHandlingTimeData.count}
        icon={<ChronometerIcon />}
        target={totalAvgHandlingTimeData.target}
        minStandard={totalAvgHandlingTimeData.minStandard}
      />
    </div>
  )
}

export default SideSection
