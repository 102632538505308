import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import Companies from "pages/Companies"
import PageContainer from "components/Common/PageContainer"
import NewCallForm from "pages/CallRecords/NewCallForm"
import DashboardCountCard from "components/DashboardCountCard"
import {
  faCalendar,
  faCalendarCheck,
  faCaretLeft,
  faCaretRight,
  faPhone,
} from "@fortawesome/free-solid-svg-icons"
import DashboardPercentCard from "components/DashboardPercentCard"
import { axiosApi } from "helpers/api_helper"
import { format } from "date-fns"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import NewCallCountCard from "components/NewCallCountCard"
import NewCallPercentCard from "components/NewCallPercentCard"

const Dashboard2 = props => {
  //meta title
  // document.title = "Dashboard PPG"
  const [report, setReport] = useState({})
  const [loading, setLoading] = useState(true)

  const CallProperties = createSelector(
    state => state.Call,
    call => ({
      successMsg: call.successMsg,
    })
  )

  const { successMsg } = useSelector(CallProperties)

  const getDashboard = () => {
    setLoading(true)
    const nowDate = new Date()
    nowDate.setHours(0)
    nowDate.setMinutes(0)
    nowDate.setSeconds(0)

    const currentDate = nowDate.toISOString().substring(0, 19)
    axiosApi
      .get(`/api/v1/reports/dashboard?startDate=${currentDate}`)
      .then(res => setReport(res.data))
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getDashboard()
  }, [])

  useEffect(() => {
    getDashboard()
  }, [successMsg])

  const getPercent = (num1, num2) => {
    if (!num1 || !num2) return 0
    return Math.ceil((num1 / (num1 + num2)) * 100)
  }

  return (
    <React.Fragment>
      <PageContainer>
        <h1 className="page-title">Dashboard</h1>
        <Row className="mb-2">
          <Col lg={3}>
            <NewCallCountCard
              count={
                loading
                  ? 0
                  : Number(report?.calls?.inbound || 0) +
                    Number(report?.calls?.outbound || 0) +
                    Number(report?.calls?.internal || 0)
              }
              title="Total Calls"
              icon={{ icon: faPhone, value: "86%", label: "Transfer Out" }}
              progress={70}
              target={report?.targets?.[0]?.dailyCallsTarget || 0}
              color="success"
              info="On target to meet daily goal"
              multiProgress={[
                {
                  percent: getPercent(
                    Number(report?.calls?.inbound || 0),
                    Number(report?.calls?.outbound || 0)
                  ),
                  value: Math.ceil(report?.calls?.inbound || 0),
                },
                {
                  percent: getPercent(
                    Number(report?.calls?.outbound || 0),
                    Number(report?.calls?.inbound || 0)
                  ),
                  value: Math.ceil(report?.calls?.outbound || 0),
                },
              ]}
              isLoading={loading}
            />
          </Col>
          <Col lg={3}>
            <NewCallCountCard
              count={
                loading
                  ? 0
                  : Number(report?.calls?.appointedInbound || 0) +
                    Number(report?.calls?.appointedInternal || 0) +
                    Number(report?.calls?.appointedOutbound || 0)
              }
              title="Total Appts"
              icon={{
                icon: faCalendarCheck,
                value: `${Math.ceil(
                  Number(report?.calls?.appointedOutbound || 0)
                )}/80`,
                label: "OB Appt",
              }}
              target={report?.targets?.[0]?.dailyAppointmentsTarget || 0}
              color="warning"
              info="Behind daily goal"
              multiProgress={[
                {
                  percent: getPercent(
                    Number(report?.calls?.appointedInbound || 0),
                    Number(report?.calls?.appointedOutbound || 0)
                  ),
                  value: Math.ceil(report?.calls?.appointedInbound || 0),
                },
                {
                  percent: getPercent(
                    Number(report?.calls?.appointedOutbound || 0),
                    Number(report?.calls?.appointedInbound || 0)
                  ),
                  value: Math.ceil(report?.calls?.appointedOutbound || 0),
                },
              ]}
              isLoading={loading}
            />
          </Col>
          <Col lg={3}>
            <NewCallPercentCard
              percent={
                loading || Number(report?.calls?.inbound || 0) === 0
                  ? 0
                  : (Number(report?.calls?.appointedInbound || 0) /
                      Number(report?.calls?.inbound)) *
                    100
              }
              title="IB Closing Ratio"
              icon={
                <div className="d-flex align-items-start">
                  <FontAwesomeIcon
                    fontSize={"50px"}
                    color="#D9D9D9"
                    icon={faPhone}
                  />
                  <FontAwesomeIcon
                    icon={faCaretLeft}
                    color="#D9D9D9"
                    fontSize={"30px"}
                  />
                </div>
              }
              info="IB Closing Ratio"
              minPercent={72}
              percentColor={"#F1426D"}
              isLoading={loading}
              color={"success"}
              type="inbound"
              target={75}
            />
          </Col>
          <Col lg={3}>
            <NewCallPercentCard
              percent={
                loading || Number(report?.calls?.outbound || 0) === 0
                  ? 0
                  : (Number(report?.calls?.appointedOutbound || 0) /
                      Number(report?.calls?.outbound)) *
                    100
              }
              title="OB Closing Ratio"
              icon={
                <div className="d-flex align-items-start">
                  <FontAwesomeIcon
                    fontSize={"50px"}
                    color="#D9D9D9"
                    icon={faPhone}
                  />
                  <FontAwesomeIcon
                    icon={faCaretRight}
                    color="#D9D9D9"
                    fontSize={"30px"}
                  />
                </div>
              }
              progress={"100%"}
              color="success"
              info="OB Closing Ratio"
              minPercent={55}
              percentColor={"#7239EA"}
              isLoading={loading}
              type="outbound"
              target={85}
            />
          </Col>
        </Row>
        <Row>
          <NewCallForm dashboard2={true} />
        </Row>

        <div className="row">
          <div className="col-span-8"></div>
          <div className="col-span-4"></div>
        </div>
      </PageContainer>
    </React.Fragment>
  )
}

Dashboard2.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard2)
