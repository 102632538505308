import React, { useEffect, useState } from "react"
import classes from "./styles.module.css"
import { kpiValueTypes } from "pages/TVDashboard/constants"
import { compareGenericValues } from "helpers/helperFns"

const TVDashboardCard = ({
  type,
  count,
  title,
  isLoading,
  additionalValueData = undefined,
  isTextCentered = false,
  target,
  minStandard,
}) => {
  let color = "#000"
  let additionalDataColor = "#000"
  // if (target > 0 && minStandard) {
  //   color =
  //     count / target > 0.9
  //       ? "#209369"
  //       : count > minStandard
  //       ? "#F1B44C"
  //       : "#AF0001"
  // } else if (target > 0 && !minStandard) {
  //   color =
  //     count / target > 0.75
  //       ? "#209369"
  //       : count / target >= 0.5
  //       ? "#F1B44C"
  //       : "#AF0001"
  // }

  const hasTarget = !isNaN(target) && target !== undefined && target !== null
  const hasMinStandard =
    !isNaN(minStandard) && minStandard !== undefined && minStandard !== null

  const reachedTarget = [0, 1].includes(compareGenericValues(count, target))
  const reachedMinStandard = [0, 1].includes(
    compareGenericValues(count, minStandard)
  )

  if (hasTarget && hasMinStandard) {
    color = reachedTarget
      ? "#209369"
      : reachedMinStandard
      ? "#F1B44C"
      : "#AF0001"
  } else if (hasTarget && !hasMinStandard) {
    color = reachedTarget ? "#209369" : "#AF0001"
  }

  if (additionalValueData) {
    const additionalValueHasTarget =
      !isNaN(additionalValueData.target) &&
      additionalValueData.target !== undefined &&
      additionalValueData.target !== null

    const additionalValueHasMinStandard =
      !isNaN(additionalValueData.minStandard) &&
      additionalValueData.minStandard !== undefined &&
      additionalValueData.minStandard !== null

    const additionalValueReachedTarget = [0, 1].includes(
      compareGenericValues(
        additionalValueData?.count,
        additionalValueData.target
      )
    )
    const additionalValueReachedMinStandard = [0, 1].includes(
      compareGenericValues(
        additionalValueData.count,
        additionalValueData.minStandard
      )
    )
    if (additionalValueHasTarget && additionalValueHasMinStandard) {
      additionalDataColor = additionalValueReachedTarget
        ? "#209369"
        : additionalValueReachedMinStandard
        ? "#F1B44C"
        : "#AF0001"
    } else if (additionalValueHasTarget && !additionalValueHasMinStandard) {
      additionalDataColor = additionalValueReachedTarget ? "#209369" : "#AF0001"
    }
  }

  return (
    <div className="w-100">
      <div
        style={{
          height: "100%",
          filter: "drop-shadow(-1px 6px 10px rgba(192, 192, 192, 0.6))",
        }}
      >
        <div
          className={`bg-white p-3 px-4 w-full m-0 h-100 ${
            !!additionalValueData ? classes.trapezoid : ""
          }`}
          style={{
            borderRadius: !!additionalValueData ? "9px 32px 32px 9px" : "12px",
          }}
        >
          {!!additionalValueData ? (
            <div
              className={``}
              style={{
                //   position: "absolute",
                display: "flex",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                //   gridTemplateColumns: "1fr 1fr",
              }}
            >
              <div style={{ flex: 1, textAlign: "center" }}>
                <p
                  className={`${classes.title}`}
                  style={{
                    color,
                  }}
                >
                  {count?.toLocaleString()}
                </p>
                <p style={{}} className={`mb-1 ${classes.text}`}>
                  {title}
                </p>
              </div>
              <div className={classes.separator}></div>
              <div style={{ flex: 1, textAlign: "center" }}>
                <p
                  className={`${classes.secondaryTitle}`}
                  style={{
                    color: additionalDataColor,
                  }}
                >
                  {additionalValueData.count?.toLocaleString()}
                </p>
                <p style={{}} className={`mb-1 ${classes.secondaryText}`}>
                  {additionalValueData.title}
                </p>
              </div>
            </div>
          ) : (
            <div
              // style={{ height: 233 }}
              className="d-flex align-items-center h-100"
            >
              <div style={{ width: "100%", textAlign: "center" }}>
                <p
                  className={`${classes.title}`}
                  style={{
                    color,

                    ...(isTextCentered && { textAlign: "center" }),
                  }}
                >
                  {count?.toLocaleString()}
                </p>
                <p
                  className={`mb-1 ${classes.text}`}
                  style={{
                    ...(isTextCentered ? { textAlign: "center" } : {}),
                  }}
                >
                  {title}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TVDashboardCard
