import { takeEvery, put, call } from "redux-saga/effects"
import { FETCH_MS_TARGET_REPORT } from "./actionTypes"
import { setMSTargetReportReports } from "./actions"
import {
  getGoalsByScope,
  getStackRankingReport,
} from "../../helpers/fakebackend_helper"

function* fetchMSTargetReport({ payload: { queryParams } }) {
  try {
    const goals = yield call(getGoalsByScope, queryParams)
    const stackRankingReport = yield call(getStackRankingReport, queryParams)

    yield put(setMSTargetReportReports({ goals, stackRankingReport }))
  } catch (error) {
    console.log("error", error)
  }
}

function* msTargetReportSaga() {
  yield takeEvery(FETCH_MS_TARGET_REPORT, fetchMSTargetReport)
}

export default msTargetReportSaga
