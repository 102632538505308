export const kpiValueTypes = {
  NUMBER: "number",
  PERCENT: "percent",
  DURATION: "duration",
}
export const kpiTypesInitialValues = {
  [kpiValueTypes.NUMBER]: 0,
  [kpiValueTypes.PERCENT]: "0%",
  [kpiValueTypes.DURATION]: "00:00",
}
