import PageContainer from "components/Common/PageContainer"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { Alert, Button, Form, Spinner } from "reactstrap"
import * as Yup from "yup"
import InputField from "components/Form/InputField"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import SelectField from "components/Form/SelectField"
import { addGoalEntry, fetchGoals, updateGoalEntry } from "store/actions"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import months from "data/months"
import { axiosApi } from "helpers/api_helper"

const allEntitiesSelectOption = {
  label: "All",
  value: "all",
}

const AddEditGoalEntry = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { state: goalEntryData } = useLocation()
  const [showConfirm, setShowConfirm] = useState(false)
  const dispatch = useDispatch()
  const [entities, setEntities] = useState([])
  const [lastEntityType, setLastEntityType] = useState(
    goalEntryData?.entity_type || null
  )

  useEffect(() => {
    dispatch(fetchGoals())
  }, [])

  const GoalsProperties = createSelector(
    state => state.Goal,
    goal => ({
      goals: goal.goals,
    })
  )

  const { goals } = useSelector(GoalsProperties)

  const GoalEntryProperties = createSelector(
    state => state.GoalEntry,
    goalEntry => ({
      loading: goalEntry.loading,
      errorMsg: goalEntry.errorMsg,
      successMsg: goalEntry.successMsg,
    })
  )

  const { loading, errorMsg, successMsg } = useSelector(GoalEntryProperties)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: goalEntryData
      ? {
          goal: {
            value: goalEntryData.goal?.goal_id,
            label: goalEntryData.goal?.name,
          },
          entities: {
            value: goalEntryData.goalEntity?.id,
            label: goalEntryData.goalEntity?.store
              ? goalEntryData.goalEntity?.store?.name
              : goalEntryData.goalEntity?.user?.name,
          },
          entityType: {
            value: goalEntryData.entity_type,
            label:
              goalEntryData.entity_type?.[0]?.toUpperCase() +
              goalEntryData.entity_type?.substr(1),
          },
          target: goalEntryData.target,
          minStandard: goalEntryData.minstandard,
          month: {
            value: goalEntryData.month,
            label: months.find(item => item.value.includes(goalEntryData.month))
              ?.label,
          },
        }
      : {
          goal: null,
          entities: [],
          entityType: null,
          target: 0,
          minStandard: 0,
          month: null,
        },
    validationSchema: Yup.object({
      goal: Yup.object().required("Please choose goal"),
      entityType: Yup.object().required("Please choose entity type"),
      entities: goalEntryData
        ? Yup.object().required("Please choose entity type")
        : Yup.array().min(1, "Please choose at least one entity"),
      target: Yup.string()
        .required("Required")
        .matches(
          /^(\d+|0[0-9]|1[0-9]|2[0-3]+):[0-5][0-9]$|^\d+%$|^\d+$/,
          "Invalid input. Please provide either time in MM:SS format, percentage, or a number."
        ),
      minStandard: Yup.string().matches(
        /^(\d+|0[0-9]|1[0-9]|2[0-3]+):[0-5][0-9]$|^\d+%$|^\d+$/,
        "Invalid input. Please provide either time in MM:SS format, percentage, or a number."
      ),
      month: Yup.object().required("Please choose month"),
    }),
    onSubmit: values => {
      window.scrollTo({ top: 0, behavior: "smooth" })
      const body = {
        goal_id: values.goal.value,
        entity_type: values.entityType?.value,
        target: `${values.target}`,
        minstandard: `${values.minStandard}`,
        month: values.month.value,
      }
      if (goalEntryData) body.entity_id = values.entities.value
      else body.entity_ids = values.entities?.map(item => item.value)
      if (id && goalEntryData) dispatch(updateGoalEntry(id, body, navigate))
      else dispatch(addGoalEntry(body, navigate))
    },
  })

  useEffect(() => {
    const type = validation.values.entityType?.value
    if (type) {
      axiosApi.get(`/api/v1/goal/entities?type=${type}`).then(res => {
        if (type === "store") {
          setEntities(
            res.data?.map(item => ({
              value: item.id,
              type: item.type,
              label: item.store?.name,
            }))
          )
        } else {
          setEntities(
            res.data?.map(item => ({
              value: item.id,
              type: item.type,
              label: item.user?.name,
            }))
          )
        }
      })
    }
    if (type !== lastEntityType) {
      validation.setFieldValue("entities", goalEntryData ? null : [])
      setLastEntityType(type)
    }
  }, [validation.values.entityType, goalEntryData])

  useEffect(() => {}, [])

  const handleCancel = () => {
    if (validation.dirty) {
      window.scrollTo({ top: 0, behavior: "smooth" })
      setShowConfirm(true)
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    if (successMsg) {
      toast.success(successMsg)
    }
  }, [successMsg])

  useEffect(() => {
    if (errorMsg) {
      toast.error(errorMsg)
    }
  }, [errorMsg])
  function handleEntitiesChange(options) {
    if (options?.some(({ value }) => value === allEntitiesSelectOption.value)) {
      validation.setFieldValue("entities", entities)
    } else {
      validation.setFieldValue("entities", options)
    }
  }

  return (
    <PageContainer>
      <h1 className="page-title mb-4">{id ? "Edit" : "Add"} KPI Goal Entry</h1>
      <div className="content-container">
        {showConfirm && (
          <Alert color="danger" style={{ marginTop: "13px" }}>
            <div>
              <p>Are you sure you want to cancel? Your inputs will be lost.</p>
              <div className="d-flex">
                <Button
                  type="button"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() => navigate(-1)}
                >
                  Yes
                </Button>
                <Button type="button" onClick={() => setShowConfirm(false)}>
                  No
                </Button>
              </div>
            </div>
          </Alert>
        )}

        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12 mb-4">
                <SelectField
                  label={"Month"}
                  required
                  name="month"
                  validation={validation}
                  options={months}
                />
              </div>
              <div className="col-lg-6 col-12 mb-4">
                <SelectField
                  label={"Goal"}
                  required
                  name="goal"
                  validation={validation}
                  options={goals?.map(item => ({
                    value: item.goal_id,
                    label: item.name,
                  }))}
                />
              </div>
              <div className="col-lg-6 col-12 mb-4">
                <SelectField
                  label={"Entity Type"}
                  required
                  name="entityType"
                  validation={validation}
                  options={[
                    { value: "store", label: "Store" },
                    { value: "agent", label: "Agent" },
                  ]}
                />
              </div>
              <div className="col-lg-6 col-12 mb-4">
                <SelectField
                  label={goalEntryData ? "Entity" : "Entities"}
                  required
                  isMulti={!goalEntryData}
                  name="entities"
                  validation={validation}
                  options={[allEntitiesSelectOption, ...entities]}
                  onChange={handleEntitiesChange}
                />
              </div>
              <div className="col-lg-6 col-12 mb-4">
                <InputField
                  name="minStandard"
                  validation={validation}
                  placeholder=""
                  label={"Minimum Standard"}
                  required
                />
              </div>
              <div className="col-lg-6 col-12 mb-4">
                <InputField
                  name="target"
                  validation={validation}
                  placeholder=""
                  label={"Target"}
                  required
                />
              </div>
            </div>
          </div>

          <div className="mt-5 d-flex justify-content-center justify-content-lg-start">
            <Button
              color="primary"
              style={{
                marginRight: "20px",
                width: "110px",
              }}
              type="submit"
            >
              {loading ? <Spinner size="sm" /> : "Save"}
            </Button>
            <Button type="button" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </PageContainer>
  )
}

export default AddEditGoalEntry
