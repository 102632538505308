import { FETCH_ROLLUP_REPORT, SET_ROLLUP_REPORT } from "./actionTypes"

export const fetchRollupReport = (
  queryParams = {
    storesIds: [],
    startDate: "",
    endDate: "",
    withManuallyAddedKpis: false,
  }
) => {
  return {
    type: FETCH_ROLLUP_REPORT,
    payload: { queryParams },
  }
}
export const resetRollupReport = () => {
  return {
    type: SET_ROLLUP_REPORT,
    payload: { rollupReports: undefined },
  }
}

export const setRollupReport = rollupReports => {
  return {
    type: SET_ROLLUP_REPORT,
    payload: { rollupReports },
  }
}
