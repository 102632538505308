import { FETCH_MS_TARGET_REPORT, SET_MS_TARGET_REPORT } from "./actionTypes"

const initialState = {
  loading: false,
  msTargetReport: undefined,
}

const msTargetReport = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MS_TARGET_REPORT:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        msTargetReport: undefined,
        loading: true,
      }
      break

    case SET_MS_TARGET_REPORT:
      state = {
        ...state,
        loading: false,
        msTargetReport: action.payload.msTargetReport,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default msTargetReport
