import PageContainer from "components/Common/PageContainer"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { Alert, Button, Form, Input, Spinner, Table } from "reactstrap"
import * as Yup from "yup"
import InputField from "components/Form/InputField"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import AgGridTable from "components/Common/AgGridTable"
import ReactSwitch from "react-switch"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { addRole, updateRole } from "store/actions"
import { toast } from "react-toastify"

const AddEditRole = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { id } = useParams()
  const { state: roleData } = useLocation()

  const [showConfirm, setShowConfirm] = useState(false)
  const [permissions, setPermissions] = useState({})
  const [ownInputs, setOwnInputs] = useState(true)

  const RoleProperties = createSelector(
    state => state.Role,
    role => ({
      loading: role.loading,
      errorMsg: role.errorMsg,
      successMsg: role.successMsg,
    })
  )

  const { loading, errorMsg, successMsg } = useSelector(RoleProperties)

  useEffect(() => {
    if (roleData) {
      const permissionsData = {}
      for (const key in roleData.permissions) {
        permissionsData[key] = {
          add: roleData.permissions[key]?.ADD,
          view: roleData.permissions[key]?.VIEW,
          edit: roleData.permissions[key]?.EDIT,
          delete: roleData.permissions[key]?.DELETE,
        }
      }
      // console.log("roleData", roleData.permissions)
      setPermissions(permissionsData)
      setOwnInputs(roleData.permissions?.calls?.OWNINPUT)
    }
  }, [roleData])

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: roleData
      ? { name: roleData.name, description: roleData.description }
      : {
          name: "",
          description: "",
        },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter role name"),
      description: Yup.string().required("Please enter role description"),
    }),
    onSubmit: values => {
      window.scrollTo({ top: 0, behavior: "smooth" })
      const permissionsObj = {
        calls: { OWNINPUT: ownInputs, SCOPEINPUT: !ownInputs },
      }
      for (const key in permissions) {
        if (key === "calls")
          permissionsObj["calls"] = {
            ...permissionsObj["calls"],
            ADD: permissions[key].add ? true : false,
            EDIT: permissions[key].edit ? true : false,
            VIEW: permissions[key].view ? true : false,
            DELETE: permissions[key].delete ? true : false,
          }
        else
          permissionsObj[key] = {
            ADD: permissions[key].add ? true : false,
            EDIT: permissions[key].edit ? true : false,
            VIEW: permissions[key].view ? true : false,
            DELETE: permissions[key].delete ? true : false,
          }
      }
      const body = {
        name: values.name,
        description: values.description,
        permissions: permissionsObj,
      }

      console.log("body", body)
      if (id && roleData) dispatch(updateRole(id, body, navigate))
      else dispatch(addRole(body, navigate))
    },
  })

  const handleCancel = () => {
    if (validation.dirty) {
      window.scrollTo({ top: 0, behavior: "smooth" })
      setShowConfirm(true)
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    if (successMsg) {
      toast.success(successMsg)
    }
  }, [successMsg])

  useEffect(() => {
    if (errorMsg) {
      toast.error(errorMsg)
    }
  }, [errorMsg])

  // const columns =
  return (
    <PageContainer>
      <h1 className="page-title mb-4">{id ? "Edit" : "Add"} Role</h1>
      <div className="content-container">
        {showConfirm && (
          <Alert color="danger" style={{ marginTop: "13px" }}>
            <div>
              <p>Are you sure you want to cancel? Your inputs will be lost.</p>
              <div className="d-flex">
                <Button
                  type="button"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() => navigate(-1)}
                >
                  Yes
                </Button>
                <Button type="button" onClick={() => setShowConfirm(false)}>
                  No
                </Button>
              </div>
            </div>
          </Alert>
        )}

        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-12 mb-4">
                <InputField
                  name="name"
                  validation={validation}
                  placeholder="Enter role name"
                  label={"Role Name"}
                  required
                />
              </div>
              <div className="col-lg-7 col-12 mb-4">
                <InputField
                  name="description"
                  validation={validation}
                  placeholder="Enter role description"
                  label={"Description"}
                  required
                />
              </div>
            </div>
          </div>

          <RoleTable
            permissions={permissions}
            setPermissions={setPermissions}
            ownInputs={ownInputs}
            setOwnInputs={setOwnInputs}
          />
          <div className="mt-5 d-flex justify-content-center justify-content-lg-start">
            <Button
              color="primary"
              style={{
                marginRight: "20px",
                width: "110px",
              }}
              type="submit"
            >
              {loading ? <Spinner size="sm" /> : "Save"}
            </Button>
            <Button type="button" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </PageContainer>
  )
}

export default AddEditRole

const RoleTable = ({
  permissions,
  setPermissions,
  ownInputs,
  setOwnInputs,
}) => {
  const [rows, setRows] = useState([])
  const [selectAll, setSelectAll] = useState(false)

  const initialRows = [
    { id: "companies", role: "Companies" },
    { id: "departments", role: "Departments" },
    { id: "stores", role: "Stores" },
    { id: "groups", role: "Groups" },
    { id: "dispositions", role: "Dispositions" },
    { id: "roles", role: "Roles" },
    { id: "users", role: "Users" },
    { id: "calls", role: "Calls" },
    {
      id: "saCalls",
      role: "Service Advisor",
    },
    { id: "goals", role: "Goals" },
    { id: "goalsEntries", role: "Goals Entries" },
    { id: "manualKpis", role: "Manual KPIs" },
    { id: "manualKpisEntries", role: "Manual KPIs Entries" },
    { id: "entityReport", role: "Entity Report" },
    { id: "individualSummaryReport", role: "Individual Summary Report" },
    { id: "serviceAdvisorReport", role: "Service Advisors Report" },
    { id: "teamTotalReport", role: "Team Total Report" },
    { id: "stackRankingReport", role: "Stack Ranking Report" },
    { id: "rollupReport", role: "Rollup Report" },
    { id: "roomDashboard", role: "Main Dashboard" },
    { id: "userDashboard", role: "Manager Dashboard" },

    //! REMOVED
    // { id: "serviceRankingReport", role: "Service Advisors Report" },
  ]

  const readonlyPermsIds = [
    "entityReport",
    "individualSummaryReport",
    "serviceAdvisorReport",
    "teamTotalReport",
    "stackRankingReport",
    "rollupReport",
    "roomDashboard",
    "userDashboard",
  ]
  function isReadonlyPermission(permId) {
    return readonlyPermsIds.includes(permId)
  }

  useEffect(() => {
    const updatedRows = initialRows.map(row => ({
      ...row,
      add: permissions[row.id]?.add || false,
      edit: permissions[row.id]?.edit || false,
      view: permissions[row.id]?.view || false,
      delete: permissions[row.id]?.delete || false,
    }))

    setRows(updatedRows)
    if (
      updatedRows.filter(
        item =>
          item.add === true &&
          item.view === true &&
          item.edit === true &&
          item.delete === true
      )?.length === updatedRows.length
    ) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }, [permissions])

  const handleCheckboxChange = (id, type) => {
    setPermissions(old => ({
      ...old,
      [id]: old[id]
        ? { ...old[id], [type]: old[id][type] ? false : true }
        : { [type]: true },
    }))
  }

  const handleSelectRow = id => {
    console.log("rows", rows)
    setPermissions(old => ({
      ...old,
      [id]:
        old[id]?.view && old[id]?.add && old[id]?.delete && old[id]?.edit
          ? { add: false, edit: false, view: false, delete: false }
          : { add: true, edit: true, view: true, delete: true },
    }))
  }

  const handleSelectAll = () => {
    const newPermissions = {}
    for (const row of initialRows) {
      newPermissions[row.id] = {
        add: !selectAll,
        edit: !selectAll,
        view: !selectAll,
        delete: !selectAll,
      }
    }
    setPermissions(newPermissions)
  }

  const handleChangeScopeInputs = () => {
    setOwnInputs(old => !old)
  }

  return (
    <Table className="rounded">
      <thead>
        <tr style={{ backgroundColor: "rgba(235, 191, 192, 0.4)" }}>
          <th className="bg-transparent">
            <div className="d-flex align-items-center gap-2">
              <input
                type="checkbox"
                checked={selectAll}
                onClick={handleSelectAll}
              />
              <span>Select All</span>
            </div>
          </th>
          <th className="bg-transparent">Add</th>
          <th className="bg-transparent">Edit</th>
          <th className="bg-transparent">View</th>
          <th className="bg-transparent">Delete</th>
          <th className="bg-transparent">Inputs</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr
            key={row.id}
            style={{
              backgroundColor:
                index % 2 !== 0 ? "rgba(235, 191, 192, 0.2)" : "white",
            }}
          >
            <td className="bg-transparent">
              <div className="d-flex align-items-center gap-2">
                <input
                  type="checkbox"
                  checked={row.add && row.edit && row.view && row.delete}
                  onClick={() => handleSelectRow(row.id)}
                />
                <span>{row.role}</span>
              </div>
            </td>
            <td className="bg-transparent">
              {!isReadonlyPermission(row.id) && (
                <input
                  type="checkbox"
                  checked={row.add}
                  onClick={() => handleCheckboxChange(row.id, "add")}
                />
              )}
            </td>
            <td className="bg-transparent">
              {!isReadonlyPermission(row.id) && (
                <input
                  type="checkbox"
                  checked={row.edit}
                  onClick={() => handleCheckboxChange(row.id, "edit")}
                />
              )}
            </td>
            <td className="bg-transparent">
              <input
                type="checkbox"
                checked={row.view}
                onClick={() => handleCheckboxChange(row.id, "view")}
              />
            </td>
            <td className="bg-transparent">
              {!isReadonlyPermission(row.id) && (
                <input
                  type="checkbox"
                  checked={row.delete}
                  onClick={() => handleCheckboxChange(row.id, "delete")}
                />
              )}
            </td>
            <td className="bg-transparent">
              {row.id === "calls" && (
                <div
                  className="d-flex flex-column align-items-center gap-1"
                  style={{ width: "100px" }}
                >
                  <ReactSwitch
                    checked={ownInputs}
                    value={ownInputs}
                    onChange={handleChangeScopeInputs}
                    onColor="#AF0001"
                  />
                  <span style={{ fontSize: "12px", fontWeight: 600 }}>
                    {ownInputs ? "Own Input" : "Scope Input"}
                  </span>
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
