import {
  FETCH_STACK_RANKING_REPORT,
  SET_STACK_RANKING_REPORT,
} from "./actionTypes"

const initialState = {
  loading: false,
  stackRankingReport: undefined,
}

const stackRankingReport = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STACK_RANKING_REPORT:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        stackRankingReport: undefined,
        loading: true,
      }
      break

    case SET_STACK_RANKING_REPORT:
      state = {
        ...state,
        loading: false,
        stackRankingReport: action.payload.stackRankingReport,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default stackRankingReport
