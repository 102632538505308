import {
  FETCH_SERVICE_ADVISOR_REPORTS,
  SET_SERVICE_ADVISOR_REPORTS,
} from "./actionTypes"

const initialState = {
  loading: false,
  serviceAdvisorsReports: [],
}

const serviceAdvisorReport = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SERVICE_ADVISOR_REPORTS:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        serviceAdvisorsReports: [],
        loading: true,
      }
      break

    case SET_SERVICE_ADVISOR_REPORTS:
      state = {
        ...state,
        loading: false,
        serviceAdvisorsReports: action.payload.serviceAdvisorsReports,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default serviceAdvisorReport
