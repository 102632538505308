import AgGridTable from "components/Common/AgGridTable"
import React, { useEffect, useState } from "react"
import Table from "./Table"
import { generateID } from "helpers/helperFns"
import { toast } from "react-toastify"

function filterNullValues(rows) {
  return rows.filter(
    ({ entityValue }) =>
      entityValue !== undefined && entityValue !== null && entityValue !== ""
  )
}
const EntityFormTable = ({ validation, entities = [], setEntities }) => {
  function onEntityValueChange({ specificId, entityId, name, value }) {
    const currentEntitiesValues = [...validation.values.entitiesValues]
    const currentEntityIndex = currentEntitiesValues.findIndex(
      val => val.specificId === specificId
    )

    let updatedEntitiesValues
    if (currentEntityIndex === -1) {
      updatedEntitiesValues = [
        ...currentEntitiesValues,
        {
          specificId,
          entityId,
          storeId: null,
          entityValue: null,
          [name]: value || null,
        },
      ]
    } else {
      updatedEntitiesValues = currentEntitiesValues.map(val => {
        if (val.specificId === specificId) {
          return {
            ...val,
            [name]: value || null,
          }
        } else {
          return val
        }
      })
    }
    console.log({ updatedEntitiesValues })
    validation.setFieldValue("entitiesValues", updatedEntitiesValues)
  }

  function onAddNewRow({ entity }) {
    const currentEntitiesValues = [...validation.values.entitiesValues]
    const currentEntities = [...entities]

    const storesLength = entity.stores?.length || 0
    const currentEntriesOfSameEntryId = currentEntities.filter(
      ({ id }) => id === entity.id
    ).length
    if (storesLength <= currentEntriesOfSameEntryId) {
      toast.error(
        `Can't add more than ${storesLength} entries for ${entity.user?.name}`
      )
      return
    }

    const currentEntityIndex = currentEntities.findIndex(
      val => val.specificData?.specificId === entity.specificData?.specificId
    )
    if (currentEntityIndex === -1) {
      return
    }

    const newID = generateID()

    const newEntity = {
      ...entity,
      specificData: {
        specificId: newID,
        specificStoreId: null,
        specificValue: null,
        isNew: true, // marks this object as new to diffrentiate between new and not new ones
      },
    }
    const newEntityValueObj = {
      specificId: newID,
      entityId: entity.id,
      storeId: null,
      entityValue: null,
    }

    const updatedEntitiesValues = [...currentEntitiesValues, newEntityValueObj]
    // [a,b,c,d]
    const updatedEntities = [
      ...currentEntities.slice(0, currentEntityIndex + 1),
      newEntity,
      ...currentEntities.slice(currentEntityIndex + 1),
    ]

    validation.setFieldValue("entitiesValues", updatedEntitiesValues)
    setEntities(updatedEntities)
  }

  function onDeleteRow({ entity }) {
    if (!entity.specificData?.isNew) {
      toast.warn(
        "You can't delete already existing entries, to remove the record please remove its value"
      )
    }
    const specificId = entity.specificData?.specificId

    const currentEntitiesValues = [...validation.values.entitiesValues]
    const updatedEntitiesValues = currentEntitiesValues.filter(
      obj => obj.specificId !== specificId
    )
    const updatedEntities = [...entities].filter(
      entity => entity.specificData?.specificId !== specificId
    )
    validation.setFieldValue("entitiesValues", updatedEntitiesValues)
    setEntities(updatedEntities)
  }

  if (!entities.length) {
    return <div></div>
  }
  return (
    <div>
      {validation.errors["entitiesValues"] ? (
        <div>
          <h4 className="text-center text-danger mb-0">
            {
              validation.errors["entitiesValues"]?.filter(v => !!v)?.[0]
                ?.entityValue
            }
          </h4>
        </div>
      ) : null}
      <div>
        <Table
          entities={entities}
          entityType={validation.values.entityType}
          onEntityValueChange={onEntityValueChange}
          entitiesValues={validation.values.entitiesValues}
          onAddNewRow={onAddNewRow}
          onDeleteRow={onDeleteRow}
        />
      </div>
      {/* <AgGridTable
        rowState={[rows, setRows]}
        columns={columnsDef}
        onCellValueChanged={onCellValueChange}
      /> */}
    </div>
  )
}

export default EntityFormTable
