import React, { useEffect, useState } from "react"

import PageContainer from "components/Common/PageContainer"
import DashboardCountCard from "components/DashboardCountCard"
import {
  faCalendar,
  faCalendarCheck,
  faCaretLeft,
  faCaretRight,
  faHandshake,
  faPhone,
} from "@fortawesome/free-solid-svg-icons"
import DashboardPercentCard from "components/DashboardPercentCard"
import { axiosApi } from "helpers/api_helper"
import { format } from "date-fns"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ScopeSelector from "components/Common/ScopeSelector"
import { useFormik } from "formik"
import {
  customDateSelectFieldOptions,
  datesOptions,
  datesSelectFieldOptions,
  isCustomDateOption,
  tzMoment,
} from "constants/datesOptions"
import SelectField from "components/Form/SelectField"
import useAgentDashboard from "./hooks/useAgentDashboard"
import useAuth from "common/hooks/useAuth"
import { Spinner } from "reactstrap"
import * as Yup from "yup"
import CustomRangePicker from "components/Form/CustomRangePicker"

const AgentDashboard = props => {
  const { user } = useAuth()
  const [rangePickerKey, setRangePickerKey] = useState(0)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      scope: user?.scopes?.map(({ id }) => `${id}`),
      selectedDateOption: {
        value: datesOptions.today.value,
        label: datesOptions.today.title,
        customAbbreviation: datesOptions.today.secondaryTitle,
      },
      startDate: "",
      endDate: "",
    },
    validationSchema: Yup.object({
      startDate: Yup.string().when(
        "selectedDateOption",
        ([selectedDateOption], schema) =>
          selectedDateOption.value === datesOptions.custom.value
            ? schema.required("Please add start date")
            : schema.optional()
      ),
      endDate: Yup.string().when(
        "selectedDateOption",
        ([selectedDateOption], schema) =>
          selectedDateOption.value === datesOptions.custom.value
            ? schema.required("Please add end date")
            : schema.optional()
      ),
      scope: Yup.array().min(1, "Scope Is Required"),

      selectedDateOption: Yup.object().required("Please select a date option"),
    }),

    onSubmit: values => {
      console.log("scopes", values)
    },
  })

  const {
    loading,
    report,
    totalCallsData,
    totalApptsData,
    ibClosingRatioData,
    obClosingRatioData,
  } = useAgentDashboard({
    validation,
    // selectedDateOptionValue: validation.values.selectedDateOption?.value,
    // scopeIds: validation.values.scope,
    setFieldValue: validation.setFieldValue,
  })

  function refreshDatePicker() {
    setRangePickerKey(oldVal => oldVal + 1)
  }

  const isCustomDate = isCustomDateOption(
    validation.values.selectedDateOption?.value
  )
  function handleCustomRangeChange(dateStrings) {
    if (dateStrings.some(el => !el)) {
      return
    }

    const [startStr, endStr] = dateStrings || []
    validation.setFieldValue("startDate", startStr)
    validation.setFieldValue("endDate", endStr)

    const startStrTitle = startStr
      ? tzMoment(startStr).format("MM/DD/YYYY")
      : ""
    const endStrTitle = endStr ? tzMoment(endStr).format("MM/DD/YYYY") : ""
    const customAbbr =
      startStrTitle && endStrTitle ? `${startStrTitle} - ${endStrTitle}` : ""
    validation.setFieldValue("selectedDateOption", {
      ...validation.values.selectedDateOption,
      customAbbreviation: customAbbr,
    })
  }
  return (
    <PageContainer>
      <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap">
        <h1 className="page-title">Dashboard</h1>
        <div className="row flex-grow-1 gap-2 align-items-center flex-nowrap justify-content-end">
          <div className="col-5">
            <ScopeSelector
              minWidth={"100%"}
              validation={validation}
              onChange={ids => {
                validation.setFieldValue("scope", ids)
              }}
              selectedScopes={validation.values.scope}
              hideLabel
              hasInitialValue={true}
            />
          </div>
          <div className="col-6">
            <SelectField
              withLabel={false}
              name="selectedDateOption"
              validation={validation}
              required
              options={customDateSelectFieldOptions.filter(
                ({ value }) => value !== datesOptions.yearToDate.value
              )}
              onChange={option => {
                validation.setFieldValue("selectedDateOption", option)
                if (isCustomDateOption(option.value)) {
                  validation.setFieldValue("startDate", "")
                  validation.setFieldValue("endDate", "")
                  refreshDatePicker()
                }
              }}
            />{" "}
            {isCustomDate && !validation.values.startDate && (
              <div key={rangePickerKey} className="">
                <CustomRangePicker onChangeHandler={handleCustomRangeChange} />
              </div>
            )}
          </div>
        </div>
      </div>

      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "50vh" }}
        >
          <Spinner />
        </div>
      ) : (
        <div className="agent-dashboard-row">
          <DashboardCountCard
            count={totalCallsData.count}
            title="Total Calls"
            // icon={{ icon: faPhone, value: "86%", label: "Transfer Out" }}
            icon={faPhone}
            target={totalCallsData.target}
            multiProgress={totalCallsData.multiProgress}
            isLoading={loading}
            minStandard={totalCallsData.minStandard}
          />

          <DashboardCountCard
            count={totalApptsData.count}
            title="Total Appts"
            // icon={{
            //   icon: faCalendarCheck,
            //   value: `${Math.ceil(Number(report?.appointedOutbound))}/80`,
            //   label: "OB Appt",
            // }}
            icon={faCalendarCheck}
            target={totalApptsData.target}
            color="warning"
            info="Behind daily goal"
            multiProgress={totalApptsData.multiProgress}
            isLoading={loading}
            minStandard={totalApptsData.minStandard}
          />

          <DashboardPercentCard
            percent={ibClosingRatioData.percent}
            title="IB Closing Ratio"
            icon={
              <div className="d-flex align-items-start">
                <FontAwesomeIcon
                  fontSize={"30px"}
                  color="#D9D9D9"
                  icon={faPhone}
                />
                <FontAwesomeIcon
                  icon={faCaretLeft}
                  color="#D9D9D9"
                  fontSize={"20px"}
                />
              </div>
            }
            info="IB Closing Ratio"
            minStandard={ibClosingRatioData.minStandard}
            percentColor={"#F1426D"}
            isLoading={loading}
            color={"success"}
            type="inbound"
            target={ibClosingRatioData.target}
          />

          <DashboardPercentCard
            percent={obClosingRatioData.percent}
            title="OB Closing Ratio"
            icon={
              <div className="d-flex align-items-start">
                <FontAwesomeIcon
                  fontSize={"30px"}
                  color="#D9D9D9"
                  icon={faPhone}
                />
                <FontAwesomeIcon
                  icon={faCaretRight}
                  color="#D9D9D9"
                  fontSize={"20px"}
                />
              </div>
            }
            progress={"100%"}
            color="success"
            info="OB Closing Ratio"
            minStandard={obClosingRatioData.minStandard}
            percentColor={"#7239EA"}
            isLoading={loading}
            type="outbound"
            target={obClosingRatioData.target}
          />
        </div>
      )}
    </PageContainer>
  )
}

export default AgentDashboard
