import PageContainer from "components/Common/PageContainer"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { Alert, Button, Form, Input, Spinner } from "reactstrap"
import * as Yup from "yup"
import InputField from "components/Form/InputField"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Switch from "react-switch"
import SwitchField from "components/Form/SwitchField"
import SelectField from "components/Form/SelectField"
import { axiosApi } from "helpers/api_helper"
import { useDispatch } from "react-redux"
import {
  addDisposition,
  fetchDepartments,
  updateDisposition,
} from "store/actions"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import ScopeSelector from "components/Common/ScopeSelector"

const AddEditDisposition = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const { state: dispositionData } = useLocation()
  const [showConfirm, setShowConfirm] = useState(false)
  const [scopes, setScopes] = useState()

  useEffect(() => {
    dispatch(fetchDepartments())
  }, [])

  const DepartmentProperties = createSelector(
    state => state.Department,
    dep => ({
      departments: dep.departments,
    })
  )

  const { departments } = useSelector(DepartmentProperties)

  const DispositionProperties = createSelector(
    state => state.Disposition,
    dis => ({
      loading: dis.loading,
      errorMsg: dis.errorMsg,
      successMsg: dis.successMsg,
    })
  )

  const { loading, errorMsg, successMsg } = useSelector(DispositionProperties)

  useEffect(() => {
    axiosApi
      .get("/api/v1/scope/mine")
      .then(res =>
        setScopes(res.data.map(item => ({ value: item.id, label: item.name })))
      )
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: dispositionData
      ? {
          name: dispositionData.name,
          description: dispositionData.description,
          opportunity: dispositionData.is_opportunity,
          appointment: dispositionData.is_appointment,
          serviceAdvisor: dispositionData.is_sarequired,
          isConnected: dispositionData.is_connected,

          types: [
            dispositionData.is_ib && { value: "is_ib", label: "Inbound" },
            dispositionData.is_ob && { value: "is_ob", label: "Outbound" },
            dispositionData.is_internal && {
              value: "is_internal",
              label: "Internal",
            },
          ].filter(item => item),

          department: {
            value: dispositionData.departments?.[0]?.department_id,
            label: dispositionData.departments?.[0]?.type_name,
          },
          scope: dispositionData.scopes?.map(item => `${item.id}`),
          // scope: dispositionData.scopes?.[0]?.id,
          status: dispositionData.is_active
            ? { value: "active", label: "Active" }
            : { value: "inactive", label: "Inactive" },
        }
      : {
          name: "",
          description: "",
          opportunity: true,
          appointment: true,
          serviceAdvisor: true,
          isConnected: false,
          types: [],
          department: {},
          scope: [],
          status: { value: "active", label: "Active" },
        },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter disposition name"),

      types: Yup.array().min(1, "Please choose at least one type"),
      department: Yup.object().required("Required"),
      scope: Yup.array().min(1, "Scope Is Required"),
      // scope: Yup.string().required("Required"),
      types: Yup.array().min(1, "Please choose at least one type"),
      status: Yup.object().required("Required"),
    }),
    onSubmit: values => {
      window.scrollTo({ top: 0, behavior: "smooth" })
      const body = {
        name: values.name,
        description: values.description || null,
        is_active: values.status.value === "active",
        scopes_ids: values.scope,
        departments_ids: [values.department.value],
        is_ib: values.types.find(item => item.value === "is_ib") ? true : false,
        is_ob: values.types.find(item => item.value === "is_ob") ? true : false,
        is_internal: values.types.find(item => item.value === "is_internal")
          ? true
          : false,
        is_opportunity: values.opportunity,
        is_appointment: values.appointment,
        is_sarequired: values.serviceAdvisor,
        is_connected: values.isConnected,
      }
      if (id && dispositionData) dispatch(updateDisposition(id, body, navigate))
      else dispatch(addDisposition(body, navigate))
    },
  })

  const handleCancel = () => {
    if (validation.dirty) {
      window.scrollTo({ top: 0, behavior: "smooth" })
      setShowConfirm(true)
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    if (successMsg) {
      toast.success(successMsg)
    }
  }, [successMsg])

  useEffect(() => {
    if (errorMsg) {
      toast.error(errorMsg)
    }
  }, [errorMsg])

  const showConnectedField = validation.values.types
    .map(({ value }) => value)
    .includes("is_ob")

  return (
    <PageContainer>
      <h1 className="page-title mb-4">{id ? "Edit" : "Add"} Disposition</h1>
      <div className="content-container">
        {showConfirm && (
          <Alert color="danger" style={{ marginTop: "13px" }}>
            <div>
              <p>Are you sure you want to cancel? Your inputs will be lost.</p>
              <div className="d-flex">
                <Button
                  type="button"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() => navigate(-1)}
                >
                  Yes
                </Button>
                <Button type="button" onClick={() => setShowConfirm(false)}>
                  No
                </Button>
              </div>
            </div>
          </Alert>
        )}

        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="container">
            <div className="row align-items-start gap-lg-5 gap-0">
              <div className="col-lg-5 col-12 mb-4">
                <InputField
                  name="name"
                  validation={validation}
                  placeholder="Enter disposition name"
                  label={"Disposition Name"}
                  required
                />
              </div>
              <div className="col-lg-5 col-12 mb-4">
                <SwitchField
                  name="opportunity"
                  validation={validation}
                  label={"Opportunity to do business"}
                  required
                />
              </div>
            </div>

            <div className="row align-items-start gap-lg-5 gap-0">
              <div className="col-lg-5 col-12 mb-4">
                <SelectField
                  label={"Department"}
                  required
                  name="department"
                  validation={validation}
                  options={departments.map(item => ({
                    value: item.department_id,
                    label: item.type_name,
                  }))}
                />
              </div>
              <div className="col-lg-5 col-12 mb-4">
                <SwitchField
                  name="appointment"
                  validation={validation}
                  label={"Appointment"}
                  required
                />
              </div>
            </div>

            <div className="row align-items-start gap-lg-5 gap-0">
              <div className="col-lg-5 col-12 mb-4">
                <ScopeSelector
                  validation={validation}
                  onChange={ids => {
                    validation.setFieldValue("scope", ids)
                  }}
                  selectedScopes={validation.values.scope}
                  // selectedScope={dispositionData?.scopes?.[0]?.id}
                  hasInitialValue={true}
                />
              </div>
              <div className="col-lg-5 col-12 mb-4">
                <SwitchField
                  name="serviceAdvisor"
                  validation={validation}
                  label={"Service Advisor"}
                  required
                />
              </div>
            </div>

            <div className="row align-items-start gap-lg-5 gap-0">
              <div className="col-lg-5 col-12 mb-4">
                <SelectField
                  label={"Type/s"}
                  required
                  isMulti
                  name="types"
                  validation={validation}
                  options={[
                    { value: "is_ib", label: "Inbound" },
                    { value: "is_ob", label: "Outbound" },
                    { value: "is_internal", label: "Internal" },
                  ]}
                />
              </div>
              {showConnectedField && (
                <div className="col-lg-5 col-12 mb-4">
                  <SwitchField
                    name="isConnected"
                    validation={validation}
                    label={"Connected"}
                    required
                  />
                </div>
              )}
            </div>
            <div className="row align-items-start gap-lg-5 gap-0">
              <div className="col-lg-5 col-12 mb-4">
                <InputField
                  name="description"
                  type="textarea"
                  validation={validation}
                  placeholder="Enter disposition description"
                  label={"Description"}
                  style={{ height: "130px" }}
                />
              </div>
            </div>
            <div className="col-lg-5 col-12 mb-4">
              {id && (
                <SelectField
                  label={"Status"}
                  required
                  name="status"
                  validation={validation}
                  options={[
                    { value: "active", label: "Active" },
                    { value: "inactive", label: "Inactive" },
                  ]}
                  defaultValue={{ value: "active", label: "Active" }}
                />
              )}
            </div>
          </div>

          <div className="mt-5 d-flex justify-content-center justify-content-lg-start">
            <Button
              color="primary"
              style={{
                marginRight: "20px",
                width: "110px",
              }}
              type="submit"
            >
              {loading ? <Spinner size="sm" /> : "Save"}
            </Button>
            <Button type="button" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </PageContainer>
  )
}

export default AddEditDisposition
