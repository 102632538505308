import {
  faCircleCheck,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getPercentage, roundAndAddSignToPercentage } from "helpers/helperFns"
import React, { useEffect, useState } from "react"
import { Tooltip } from "react-tooltip"
import { Alert, Progress } from "reactstrap"
import target from "store/target/reducer"

const DashboardCountCard = ({
  count,
  title,
  icon,
  target,
  multiProgress,
  isLoading,
  minStandard,
  hideAlert,
  iconComponent,
  hideProgress = false,
  additionalValueData = undefined,
  isTextCentered = false,
}) => {
  // const [isLoading, setIsLoading] = useState(true)

  // useEffect(() => {
  //   // Simulating loading delay for demonstration purpose
  //   const timer = setTimeout(() => {
  //     setIsLoading(false)
  //   }, 500) // Adjust the delay time as needed

  //   // Cleanup function to clear the timeout
  //   return () => clearTimeout(timer)
  // }, [])
  let status = "danger"
  if (target > 0 && minStandard) {
    status =
      count / target > 0.9
        ? "success"
        : count > minStandard
        ? "warning"
        : "danger"
  } else if (target > 0 && !minStandard) {
    status =
      count / target > 0.75
        ? "success"
        : count / target >= 0.5
        ? "warning"
        : "danger"
  }

  return (
    <div className="w-100" style={{ minWidth: "240px" }}>
      <div className="bg-white p-3 px-4 w-full m-0">
        <div className="d-flex justify-content-between align-items-start ">
          {/* <div className='d-flex justify-content-center align-items-center gap-3'>

          </div> */}

          {!!additionalValueData ? (
            <div className="d-flex gap-4 m-auto">
              <div>
                <p
                  style={{
                    fontWeight: 800,
                    fontSize: "40px",
                    color: "black",
                    marginBottom: 0,
                    textAlign: "center",
                  }}
                >
                  {count?.toLocaleString()}
                </p>
                <p className="mb-1 text-center">{title}</p>
              </div>
              <div>
                <p
                  style={{
                    fontWeight: 800,
                    fontSize: "40px",
                    color: "black",
                    marginBottom: 0,
                    textAlign: "center",
                  }}
                >
                  {additionalValueData.count?.toLocaleString()}
                </p>
                <p className="mb-1 text-center">{additionalValueData.title}</p>
              </div>
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              <p
                style={{
                  fontWeight: 800,
                  fontSize: "40px",
                  color: "black",
                  marginBottom: 0,
                  ...(isTextCentered && { textAlign: "center" }),
                }}
              >
                {count?.toLocaleString()}
              </p>
              <p
                className="mb-1 "
                style={isTextCentered ? { textAlign: "center" } : {}}
              >
                {title}
              </p>

              {!hideProgress && (
                <div
                  className="d-flex flex-column gap-1 align-items-end"
                  style={{ width: "70%" }}
                >
                  <Progress
                    data-tooltip-id="progress"
                    data-tooltip-content={`${getPercentage(
                      count || 0,
                      target || 0
                    )}`}
                    className="w-100"
                    style={{
                      backgroundColor: "#d9d9d9",
                    }}
                    value={getPercentage(
                      count || 0,
                      target || 0,
                      false,
                      true,
                      true
                    )}
                    barClassName={`progress-bar-${status}`}
                  />
                  <Tooltip id="progress" />
                  <span>{target?.toLocaleString() || 0}</span>
                </div>
              )}
              {!isLoading && !hideAlert && (
                <div
                  className="px-3 py-2 mt-1"
                  style={{
                    position: "relative",
                    backgroundColor:
                      status === "success"
                        ? "#DCFFF3"
                        : status === "warning"
                        ? "#FFEFD3"
                        : "#FFDDDD",
                    color:
                      status === "success"
                        ? "#34C38F"
                        : status === "warning"
                        ? "#F1B44C"
                        : "#C34040",
                    fontSize: "12px",
                    borderRadius: "5px",
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      position: "absolute",
                      left: "-12.5px",
                      top: "-12.5px",
                      width: "25px",
                      height: "25px",
                      color:
                        status === "success"
                          ? "#34C38F"
                          : status === "warning"
                          ? "#F1B44C"
                          : "#C34040",
                      borderRadius: "50%",
                    }}
                    icon={
                      status === "success" ? faCircleCheck : faExclamationCircle
                    }
                  />

                  <span style={{ fontWeight: 500 }}>
                    {status === "success"
                      ? "On target to meet daily goal"
                      : status === "warning"
                      ? "Behind daily goal"
                      : "Not on Minimum Standard"}
                  </span>
                </div>
              )}
            </div>
          )}

          {icon && (
            <FontAwesomeIcon fontSize={"50px"} color="#D9D9D9" icon={icon} />
          )}
          {iconComponent && iconComponent}

          {/* {!isLoading && (
            <div className="d-flex flex-column">
              <div className="d-flex gap-2 align-items-center">
                <FontAwesomeIcon
                  fontSize={"20px"}
                  color="#AF0001"
                  icon={icon.icon}
                />
                <span
                  style={{
                    color: "#AF0001",
                    fontWeight: 600,
                    fontSize: "15px",
                  }}
                >
                  {icon.value}
                </span>
              </div>
              <span style={{ fontSize: "12px", color: "grey" }}>
                {icon.label}
              </span>
            </div>
          )} */}
        </div>
      </div>
      {multiProgress && (
        <Progress multi style={{ height: "5px", bottom: "-5px" }}>
          <Progress
            bar
            value={isLoading ? 0 : multiProgress[0]?.percent}
            style={{ backgroundColor: "#F1426D" }}
            data-tooltip-id="progress"
            data-tooltip-content={`${
              isLoading
                ? 0
                : "IB: " +
                  multiProgress[0]?.value +
                  "-" +
                  roundAndAddSignToPercentage(multiProgress[0]?.percent)
            }`}
          />
          <Progress
            bar
            value={isLoading ? 0 : multiProgress[1]?.percent}
            style={{ backgroundColor: "#7239EA" }}
            data-tooltip-id="progress"
            data-tooltip-content={`${
              isLoading
                ? 0
                : "OB: " +
                  multiProgress[1]?.value +
                  "-" +
                  roundAndAddSignToPercentage(multiProgress[1]?.percent)
            }`}
          />
        </Progress>
      )}
    </div>
  )
}

export default DashboardCountCard
