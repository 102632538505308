import {
  ADD_DEPARTMENT,
  GET_DEPARTMENTS,
  DEPARTMENT_ERROR,
  ADD_DEPARTMENT_SUCCESS,
  GET_DEPARTMENTS_SUCCESS,
  UPDATE_DEPARTMENT,
} from "./actionTypes"

export const addDepartment = (department, history) => {
  return {
    type: ADD_DEPARTMENT,
    payload: { department, history },
  }
}

export const updateDepartment = (id, department, history) => {
  return {
    type: UPDATE_DEPARTMENT,
    payload: { id, department, history },
  }
}

export const fetchDepartments = () => {
  return {
    type: GET_DEPARTMENTS,
  }
}

export const addDepartmentSuccess = msg => {
  return {
    type: ADD_DEPARTMENT_SUCCESS,
    payload: { msg },
  }
}

export const getDepartmentsSuccess = departments => {
  return {
    type: GET_DEPARTMENTS_SUCCESS,
    payload: { departments },
  }
}

export const departmentError = error => {
  return {
    type: DEPARTMENT_ERROR,
    payload: error,
  }
}
