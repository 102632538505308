import React, { useEffect, useState } from "react"
import EditIcon from "assets/icons/EditIcon"
import DeleteIcon from "assets/icons/DeleteIcon"
import AgGridTable from "components/Common/AgGridTable"
import PageContainer from "components/Common/PageContainer"
import { Badge, Button, Spinner } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { fetchUsers } from "store/actions"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { format } from "date-fns"
import { axiosApi } from "helpers/api_helper"
import { DELETE_USER, POST_USER } from "helpers/url_helper"
import DeleteModal from "components/Common/DeleteModal"

const moduleName = "users"

const Users = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  //permissions
  const PermissionsSelector = createSelector(
    state => state.Permissions,
    perm => ({
      permissions: perm.permissions,
    })
  )
  const { permissions } = useSelector(PermissionsSelector)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [currentId, setCurrentId] = useState()
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [deleteError, setDeleteError] = useState()

  const [rows, setRows] = useState([])

  const columns = [
    { field: "userName", filter: true, headerName: "User Name" },
    { field: "email", filter: true, headerName: "Email" },
    { field: "role", filter: true, headerName: "Role" },
    { field: "department", filter: true, headerName: "Department" },
    {
      field: "status",
      filter: true,
      headerName: "Status",
      cellRenderer: params => {
        return (
          <Badge
            color={
              params.data.status === "Active"
                ? "success"
                : params.data.status === "Inactive"
                ? "danger"
                : "secondary"
            }
          >
            {params.data.status}
          </Badge>
        )
      },
    },
    {
      field: "lastActivity",
      filter: true,
      headerName: "Last Activity",
      valueFormatter: params =>
        params.data.lastActivity
          ? format(params.data.lastActivity, "dd MMM yyyy")
          : "-",
    },
    {
      headerName: "",
      sortable: false,
      cellRenderer: params => {
        return (
          <div className="d-flex align-items-center h-100 gap-2">
            {actions(params.data.id)}
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    dispatch(fetchUsers())
  }, [])

  const UserProperties = createSelector(
    state => state.User,
    user => ({
      loading: user.loading,
      users: user.users,
    })
  )

  const { loading, users } = useSelector(UserProperties)

  useEffect(() => {
    setRows(
      users
        // ?.filter(item => item.roles?.[0]?.name !== "sa")
        ?.map(item => ({
          id: item.user_id,
          userName: item.name,
          email: item.email,
          role: item.roles?.map(el => el.name).join(", "),
          department: item.departments?.map(el => el.type_name).join(", "),
          status: item.status?.[0] + item.status?.substring(1)?.toLowerCase(),
          lastActivity: item.last_login ? new Date(item.last_login) : null,
        }))
    )
  }, [users])

  const actions = id => {
    return (
      <>
        {permissions?.[moduleName]?.EDIT && (
          <div
            onClick={() =>
              navigate("/users/" + id, {
                state: users?.find(item => item.user_id === id),
              })
            }
          >
            <EditIcon />
          </div>
        )}
        {permissions?.[moduleName]?.DELETE && (
          <div
            onClick={() => {
              setShowDeleteModal(true)
              setCurrentId(id)
            }}
          >
            <DeleteIcon />
          </div>
        )}
      </>
    )
  }

  const handleDelete = async () => {
    setDeleteLoading(true)
    setDeleteError(null)
    try {
      await axiosApi.delete(DELETE_USER + currentId)
      dispatch(fetchUsers())
      setShowDeleteModal(false)
    } catch (err) {
      setDeleteError(err.response?.data?.message)
    } finally {
      setDeleteLoading(false)
    }
  }

  return (
    <PageContainer>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setShowDeleteModal(false)
          setDeleteError(null)
        }}
        errorMsg={deleteError}
        loading={deleteLoading}
      />
      <div className="d-flex justify-content-between mb-4">
        <h1 className="page-title">Users</h1>
        {permissions?.[moduleName]?.ADD && (
          <Button
            color="primary"
            style={{ height: "36px" }}
            onClick={() => navigate("new")}
          >
            Add New User
          </Button>
        )}
      </div>
      {loading ? (
        <div className="w-full d-flex justify-content-center align-items-center pt-4">
          <Spinner />
        </div>
      ) : (
        <>
          {permissions?.[moduleName]?.VIEW && (
            <AgGridTable
              rowState={[rows, setRows]}
              columns={columns}
              qiuckFilter={true}
            />
          )}
        </>
      )}
    </PageContainer>
  )
}

export default Users
