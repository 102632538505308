import React, { useEffect, useState } from "react"
import PageContainer from "components/Common/PageContainer"
import CommonEntityReports from "components/CommonEntityReports"
import { entityReportTypes } from "components/CommonEntityReports/constants"

const moduleName = "entityReport"

const EntityReports = () => {
  return (
    <PageContainer>
      <div className="d-flex justify-content-between mb-4">
        <h1 className="page-title">Entity Report</h1>
      </div>
      <CommonEntityReports type={entityReportTypes.ENTITY_REPORT} />
    </PageContainer>
  )
}

export default EntityReports
