import React, { useEffect } from "react"
import { DatePicker, Space } from "antd"
import { tzMoment } from "constants/datesOptions"
import classes from "./styles.module.css"

const { RangePicker } = DatePicker

const CustomRangePicker = ({ onChangeHandler, ...rest }) => {
  useEffect(() => {
    onChangeHandler(["", ""])
  }, [])

  return (
    <div className={classes.container}>
      <RangePicker
        defaultOpen={true}
        size="small"
        onChange={(dates, dateStrings) => {
          const startStr = dateStrings[0]
          const endStr = dateStrings[1]

          const formattedStart = startStr
            ? tzMoment(startStr).startOf("day").format()
            : ""
          const formattedEnd = endStr
            ? tzMoment(endStr).endOf("day").format()
            : ""

          onChangeHandler([formattedStart, formattedEnd])
        }}
        //   value={}
        {...rest}
      />
    </div>
  )
}

export default CustomRangePicker
