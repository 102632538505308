import React, { useEffect, useState } from "react"
import PageContainer from "components/Common/PageContainer"
import CommonEntityReports from "components/CommonEntityReports"
import { entityReportTypes } from "components/CommonEntityReports/constants"

const TeamTotalReport = () => {
  return (
    <PageContainer>
      <div className="d-flex justify-content-between mb-4">
        <h1 className="page-title">Team Total Report</h1>
      </div>
      <CommonEntityReports type={entityReportTypes.TEAM_TOTAL_REPORT} />
    </PageContainer>
  )
}

export default TeamTotalReport
