import { takeEvery, put, call } from "redux-saga/effects"

// Login Redux States
import {
  ADD_GOAL_ENTRY,
  FETCH_GOALS_ENTRIES,
  UPDATE_GOAL_ENTRY,
} from "./actionTypes"
import { goalEntrySuccess, goalEntryError, setGoalsEntries } from "./actions"

//Include Both Helper File with needed methods
import {
  getGoalsEntries,
  postGoalEntry,
  putGoalEntry,
} from "../../helpers/fakebackend_helper"

import { isArray } from "lodash"
function* addGoalEntry({ payload: { goalEntry, history } }) {
  try {
    yield call(postGoalEntry, goalEntry)
    yield put(goalEntrySuccess("Created Successfully"))
    history("/goal-entries")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(goalEntryError(errMsg))
  }
}

function* updateGoalEntry({ payload: { id, goalEntry, history } }) {
  try {
    yield call(putGoalEntry, id, goalEntry)
    yield put(goalEntrySuccess("Updated Successfully"))
    history("/goal-entries")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(goalEntryError(errMsg))
  }
}

function* fetchGoalsEntries() {
  try {
    const response = yield call(getGoalsEntries)
    yield put(setGoalsEntries(response))
  } catch (error) {
    console.log("error", error)
  }
}

function* goalEntrySaga() {
  yield takeEvery(ADD_GOAL_ENTRY, addGoalEntry)
  yield takeEvery(FETCH_GOALS_ENTRIES, fetchGoalsEntries)
  yield takeEvery(UPDATE_GOAL_ENTRY, updateGoalEntry)
}

export default goalEntrySaga
