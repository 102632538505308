import {
  ADD_KPI,
  FETCH_KPIS,
  KPI_ERROR,
  KPI_SUCCESS,
  SET_KPIS,
  UPDATE_KPI,
} from "./actionTypes"

const initialState = {
  errorMsg: "",
  successMsg: "",
  loading: false,
  kpis: [],
}

const kpi = (state = initialState, action) => {
  switch (action.type) {
    case ADD_KPI:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break

    case UPDATE_KPI:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case FETCH_KPIS:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        kpis: [],
        loading: true,
      }
      break

    case SET_KPIS:
      state = {
        ...state,
        loading: false,
        kpis: action.payload.kpis,
      }
      break
    case KPI_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload.msg,
        loading: false,
      }
      break
    case KPI_ERROR:
      state = { ...state, errorMsg: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default kpi
