import {
  datesOptions,
  isCustomDateOption,
  tzMoment,
} from "constants/datesOptions"
import { axiosApi } from "helpers/api_helper"
import {
  approximate,
  getPercentage,
  getSum,
  isAdminRole,
  isAgentRole,
} from "helpers/helperFns"
import moment from "moment"
import React, { useEffect, useMemo, useState } from "react"
import crownImg from "assets/images/crown.png"

import userXImg from "assets/images/user-xmark.png"

const SHOWS_KPI = "Shows"
const SCHEDULED_APPTS_KPI = "Scheduled Appts"

function getWorkingDaysPerDateOption(startDate, endDate) {
  let totalWorkingDays = 0
  let i = 0
  let clonedStart = tzMoment(startDate)
  const clonedEnd = tzMoment(endDate)

  while (clonedStart.isBefore(clonedEnd, "minutes")) {
    const dayOfWeek = clonedStart.weekday()

    // Check if it's a weekend (Saturday or Sunday)
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      totalWorkingDays++
    }

    clonedStart = clonedStart.add(1, "days")
    i++
  }

  return totalWorkingDays
}

const useManagerDashboard = ({ validation, setFieldValue }) => {
  const [report, setReport] = useState({})
  // const [goal, setGoal] = useState({})
  const [callsGoal, setCallsGoal] = useState({})
  const [apptsGoal, setApptsGoal] = useState({})
  const [ibRatioGoal, setIbRatioGoal] = useState({})
  const [obRatioGoal, setObRatioGoal] = useState({})
  const [loading, setLoading] = useState(true)

  const [rows, setRows] = useState([])
  const [allRows, setAllRows] = useState([])
  const [agentTableMode, setAgentTableMode] = useState("top")
  // const [refreshTable, setRefreshTable] = useState(false)

  const currentDateOption = Object.values(datesOptions).find(
    ({ value }) => value === validation.values.selectedDateOption?.value
  )

  let startDate, endDate
  if (isCustomDateOption(currentDateOption.value)) {
    startDate = validation.values.startDate
    endDate = validation.values.endDate
  } else {
    startDate = currentDateOption.startDate
    endDate = currentDateOption.endDate
  }

  const isSingleDay = [
    datesOptions.today.value,
    datesOptions.yesterday.value,
  ].includes(validation.values.selectedDateOption?.value)

  const workingDays = isSingleDay
    ? 1
    : getWorkingDaysPerDateOption(startDate, endDate)
  const fetchActualCalls = async (withLoading = true) => {
    withLoading && setLoading(true)

    const startDateParam = startDate ? `startDate=${startDate}` : ""
    const endDateParam = endDate ? `endDate=${endDate}` : ""

    const manualKpiStartDateParam = startDate
      ? `startDate=${tzMoment(startDate).format("YYYY-MM-DD")}`
      : ""
    const manualKpiEndDateParam = endDate
      ? `endDate=${tzMoment(endDate).format("YYYY-MM-DD")}`
      : ""

    let scopesIdsQuery = ""
    let idsQuery = ""
    if (validation.values.scope?.length > 0) {
      const queryArr = validation.values.scope.map(id => `scopeIds=${id}`)
      scopesIdsQuery = queryArr.join("&")

      const idsArr = validation.values.scope.map(id => `ids=${id}`)
      idsQuery = idsArr.join("&")
    }

    const [actualCallsResponse, manualKpiEntriesResponse] = await Promise.all([
      axiosApi.get(
        `/api/v1/reports/dashboard?${startDateParam}&${endDateParam}&${scopesIdsQuery}`
      ),
      axiosApi.get(
        `/api/v1/reports/manual-kpi-per-store?${manualKpiStartDateParam}&${manualKpiEndDateParam}&${idsQuery}`
      ),
      // axiosApi.get(
      //   `/api/v1/manual-kpi-entry/all?orderBy=manual-kpi-entry.createdAt&${startDateParam}&${endDateParam}&${scopesIdsQuery}`
      // ),
    ])

    const manualKpiEntries = manualKpiEntriesResponse.data?.stores || []

    const scheduledApptsKpis = []
    const showsKpis = []
    manualKpiEntries.forEach(kpi => {
      const { manualKpiName } = kpi

      const isShowsKpi = manualKpiName === SHOWS_KPI
      const isScheduledApptsKpi = manualKpiName === SCHEDULED_APPTS_KPI
      if (isShowsKpi) {
        showsKpis.push(kpi)
      } else if (isScheduledApptsKpi) {
        scheduledApptsKpis.push(kpi)
      }
    })
    // const actualCallsResponse = await axiosApi.get(
    //   `/api/v1/reports/dashboard?startDate=${startDate}${scopeQuery}`
    // )

    const totalShows = getSum(showsKpis.map(({ value }) => value))
    const totalScheduledAppts = getSum(
      scheduledApptsKpis.map(({ value }) => value)
    )

    const newReport = {
      inbound: 0,
      obNotConnected: 0,
      obConnected: 0,
      connectedOtdb: 0,
      internal: 0,
      appointedInbound: 0,
      outboundAppointedNotConnected: 0,
      outboundAppointedConnected: 0,
      totalCalls: 0,
      activeAgents: actualCallsResponse.data?.calls?.activeUsers,
      inActiveAgents: actualCallsResponse.data?.calls?.inActiveUsers,
      transferOut: 0,
      ibOpportunity: 0,
      connected: 0,
      totalShows,
      totalScheduledAppts,
    }

    const tableRows = []
    for (const call of actualCallsResponse.data?.calls?.formattedData || []) {
      let totalTransferOut = 0
      for (const disp of call.dispositions || []) {
        if (disp.isSaRequired) {
          totalTransferOut += getSum([
            disp.inbound,
            disp.internal,
            disp.outboundNotConnected,
            disp.outboundConnected,
          ])
        }
      }
      if (isAgentRole(call.type)) {
        const newRow = {
          agent: call.name,
          totalCalls: Number(call.totalCalls),
          totalAppointments: getSum([
            call.appointedInbound,
            call.outboundAppointedNotConnected,
            call.outboundAppointedConnected,
          ]),
          inbound: Number(call.ibCalls),
          ibOpportunity: Number(call.ibOpportunity),
          outbound: getSum([call.obNotConnected, call.obConnected]),
          obConnected: call.obConnected,
          obOpportunity: Number(call.obOpportunity),
          appointedInbound: Number(call.appointedInbound),
          appointedOutbound: getSum([
            call.outboundAppointedNotConnected,
            call.outboundAppointedConnected,
          ]),
          ibClosing: getPercentage(call.appointedInbound, call.ibOpportunity),
          obClosing: getPercentage(
            getSum([
              call.outboundAppointedNotConnected,
              call.outboundAppointedConnected,
            ]),
            parseFloat(call.connectedOtdb)
          ),

          transferOut: totalTransferOut,
        }
        tableRows.push(newRow)
      }

      newReport.inbound += Number(call.ibCalls)
      newReport.obConnected += Number(call.obConnected)
      newReport.obNotConnected += Number(call.obNotConnected)
      newReport.connectedOtdb += Number(call.connectedOtdb)

      newReport.internal += Number(call.internal)
      newReport.appointedInbound += Number(call.appointedInbound)
      newReport.outboundAppointedNotConnected += Number(
        call.outboundAppointedNotConnected
      )
      newReport.outboundAppointedConnected += Number(
        call.outboundAppointedConnected
      )

      newReport.totalCalls += Number(call.totalCalls)
      newReport.ibOpportunity += Number(call.ibOpportunity)
      newReport.connected += Number(call.connected)
      newReport.transferOut += totalTransferOut

      //   newReport.appointedInternal += Number(call.appointedInternal)
    }
    const sortedRows = tableRows
      ?.sort((a, b) => b.totalCalls - a.totalCalls)
      ?.map((item, index) => ({ index: index + 1, ...item }))
    setRows(sortedRows)
    setAllRows(sortedRows)
    setReport(newReport)

    withLoading && setLoading(false)
  }

  const fetchCallsGoals = async (withLoading = true) => {
    withLoading && setLoading(true)

    let count = 0
    const factor = (1 / 21) * workingDays

    const startDateParam = startDate ? `startDate=${startDate}` : ""
    const endDateParam = endDate ? `endDate=${endDate}` : ""

    let scopeQuery = ""
    if (validation.values.scope?.length > 0) {
      const queryArr = validation.values.scope.map(id => `scopeIds=${id}`)
      scopeQuery = queryArr.join("&")
    }
    //calls goal
    const callsGoalResponse = await axiosApi.get(
      `/api/v1/reports/dashboard-goal/Calls?${startDateParam}&${endDateParam}&${scopeQuery}`
    )
    const newCallsGoal = { target: 0, minStandard: 0 }
    for (const goal of callsGoalResponse.data) {
      if (goal.entity_type === "agent") continue
      count++
      newCallsGoal.target += Number(goal.target)
      newCallsGoal.minStandard += Number(goal.minstandard)
    }

    setCallsGoal(
      count > 0
        ? {
            target: (newCallsGoal.target / count) * factor,
            minStandard: (newCallsGoal.minStandard / count) * factor,
          }
        : newCallsGoal
    )

    //appts goal
    const apptGoalResponse = await axiosApi.get(
      `/api/v1/reports/dashboard-goal/Appointments?${startDateParam}&${endDateParam}&${scopeQuery}`
    )
    const newApptsGoal = {
      target: 0,
      minStandard: 0,
    }

    count = 0
    for (const goal of apptGoalResponse.data) {
      if (goal.entity_type === "agent") continue
      count++
      newApptsGoal.target += Number(goal.target)
      newApptsGoal.minStandard += Number(goal.minstandard)
    }
    setApptsGoal(
      count > 0
        ? {
            target: (newApptsGoal.target / count) * factor,
            minStandard: (newApptsGoal.minStandard / count) * factor,
          }
        : newApptsGoal
    )

    //Ib ratio goal
    const ibRatioResponse = await axiosApi.get(
      `/api/v1/reports/dashboard-goal/IB Closing Ratio?${startDateParam}&${endDateParam}&${scopeQuery}`
    )

    const newIbRatioGoal = {
      target: 0,
      minStandard: 0,
    }

    count = 0
    for (const goal of ibRatioResponse.data) {
      if (goal.entity_type === "agent") continue
      count++
      newIbRatioGoal.target += Number(
        goal.target?.substring(0, goal.target?.length - 1)
      )

      newIbRatioGoal.minStandard += Number(
        goal.minstandard?.substring(0, goal.minstandard?.length - 1)
      )
    }
    setIbRatioGoal(
      count > 0
        ? {
            target: newIbRatioGoal.target / count,
            minStandard: newIbRatioGoal.minStandard / count,
          }
        : newIbRatioGoal
    )

    //Ob ratio goal
    const obRatioResponse = await axiosApi.get(
      `/api/v1/reports/dashboard-goal/OB Closing Ratio?${startDateParam}&${endDateParam}&${scopeQuery}`
    )

    const newObRatioGoal = { target: 0, minStandard: 0 }
    count = 0
    for (const goal of obRatioResponse.data) {
      if (goal.entity_type === "agent") continue
      count++
      newObRatioGoal.target += Number(
        goal.target?.substring(0, goal.target?.length - 1)
      )

      newObRatioGoal.minStandard += Number(
        goal.minstandard?.substring(0, goal.minstandard?.length - 1)
      )
    }
    setObRatioGoal(
      count > 0
        ? {
            target: newObRatioGoal.target / count,
            minStandard: newObRatioGoal.minStandard / count,
          }
        : newObRatioGoal
    )

    withLoading && setLoading(false)
    // setRefreshTable(old => !old)
  }

  const columns = [
    {
      field: "index",
      headerName: "",
      // width: "10px",
      cellRenderer: params => {
        return (
          <div className="d-flex align-items-center h-100 gap-2 justify-content-center">
            {params.data.totalCalls === 0 ? (
              <img src={userXImg} width={20} height={15} />
            ) : params.data.index === 1 ? (
              <img src={crownImg} width={20} height={15} />
            ) : (
              <span style={{ minWidthwidth: "20px", textAlign: "center" }}>
                {params.data.index}
              </span>
            )}
          </div>
        )
      },
    },
    { field: "agent", filter: true, headerName: "Agent" },
    { field: "totalCalls", filter: true, headerName: "Total Calls" },

    { field: "inbound", filter: true, headerName: "IB Calls" },
    { field: "ibOpportunity", filter: true, headerName: "IB OTDB Calls" },
    { field: "outbound", filter: true, headerName: "OB Calls" },
    { field: "obConnected", filter: true, headerName: "OB Connected Calls" },

    { field: "obOpportunity", filter: true, headerName: "OB OTDB Calls" },
    { field: "totalAppointments", filter: true, headerName: "Total Appts" },
    { field: "appointedInbound", filter: true, headerName: "IB Appts" },
    { field: "appointedOutbound", filter: true, headerName: "OB Appts" },
    { field: "ibClosing", filter: true, headerName: "IB Closing" },
    { field: "obClosing", filter: true, headerName: "OB Closing" },
    { field: "transferOut", filter: true, headerName: "Transfers Out" },
  ]

  const handleAgentTableMode = mode => {
    if (mode === "top") {
      setRows(allRows)
    } else if (mode === "bottom") {
      setRows([...allRows].reverse())
    } else {
      setRows(allRows.filter(item => item.totalCalls === 0))
    }
    setAgentTableMode(mode)
    // setRefreshTable(old => !old)
  }

  useEffect(() => {
    const fetch = async () => {
      const errors = await validation.validateForm()
      if (Object.values(errors)?.length) {
        setReport({})
        setCallsGoal({})
        setApptsGoal({})
        setIbRatioGoal({})
        setObRatioGoal({})
        setRows([])
        setAllRows([])
        return
      }

      fetchActualCalls()
      fetchCallsGoals()
    }
    fetch()
  }, [validation.values])
  useEffect(() => {
    if (
      validation.values.selectedDateOption?.value !==
        datesOptions.today.value ||
      !validation.values.scope?.length
    ) {
      return
    }

    const interval = setInterval(() => {
      // toast.success("REFETCHING !")
      fetchActualCalls(false)
      fetchCallsGoals(false)
    }, 30000)

    return () => clearInterval(interval)
  }, [validation.values])

  const totalCallsData = useMemo(() => {
    let count = 0
    let target = 0
    let minStandard = 0
    let avg = 0

    if (!loading) {
      count = getSum([
        report?.inbound,
        report?.internal,
        report?.obNotConnected,
        report?.obConnected,
      ])
      target = callsGoal?.target * report?.activeAgents
      minStandard = callsGoal?.minStandard * report?.activeAgents
      avg = approximate(
        report?.activeAgents > 0
          ? (report?.inbound +
              report?.obNotConnected +
              report?.obConnected +
              report?.internal) /
              report?.activeAgents
          : 0
      )
    }

    return {
      count,
      target,
      minStandard,
      avg,
      multiProgress: [
        {
          percent: getPercentage(
            report?.inbound,
            getSum([
              report?.inbound,
              report?.obNotConnected,
              report?.obConnected,
            ]),
            false,
            true,
            true
          ),

          value: parseFloat(report?.inbound),
        },
        {
          percent: getPercentage(
            getSum([report?.obNotConnected, report?.obConnected]),
            getSum([
              report?.inbound,
              report?.obNotConnected,
              report?.obConnected,
            ]),
            false,
            true,
            true
          ),

          value: getSum([report?.obNotConnected, report?.obConnected]),
        },
      ],
    }
  }, [loading, report, callsGoal])

  const totalApptsData = useMemo(() => {
    let count = 0
    let target = 0
    let minStandard = 0
    let avg = 0
    if (!loading) {
      count = getSum([
        report?.appointedInbound,
        report?.outboundAppointedNotConnected,
        report?.outboundAppointedConnected,
      ])
      target = apptsGoal?.target * report?.activeAgents
      minStandard = apptsGoal?.minStandard * report?.activeAgents
      avg = approximate(
        report?.activeAgents > 0
          ? (report?.appointedInbound +
              report?.outboundAppointedNotConnected +
              report?.outboundAppointedConnected) /
              report?.activeAgents
          : 0
      )
    }

    return {
      count,
      target,
      minStandard,
      avg,
      multiProgress: [
        {
          percent: getPercentage(
            report?.appointedInbound,
            getSum([
              report?.appointedInbound,
              report?.outboundAppointedNotConnected,
              report?.outboundAppointedConnected,
            ]),
            false,
            true,
            true
          ),
          value: parseFloat(report?.appointedInbound),
        },
        {
          percent: getPercentage(
            getSum([
              report?.outboundAppointedNotConnected,
              report?.outboundAppointedConnected,
            ]),
            getSum([
              report?.appointedInbound,
              report?.outboundAppointedNotConnected,
              report?.outboundAppointedConnected,
            ]),
            false,
            true,
            true
          ),
          value: getSum([
            report?.outboundAppointedNotConnected,
            report?.outboundAppointedConnected,
          ]),
        },
      ],
    }
  }, [loading, report, apptsGoal])

  const avgDailyCallsPerAgentData = useMemo(() => {
    let count = 0
    let target = 0
    let minStandard = 0
    if (!loading) {
      count =
        report?.activeAgents > 0
          ? approximate(
              (report?.inbound +
                report?.obNotConnected +
                report?.obConnected +
                report?.internal) /
                workingDays /
                report?.activeAgents
            )
          : 0
      target = callsGoal?.target / workingDays
      minStandard = callsGoal?.minStandard / workingDays
    }

    return {
      count,
      target,
      minStandard,
      multiProgress: [
        {
          percent: getPercentage(
            report?.inbound,
            getSum([
              report?.inbound,
              report?.obNotConnected,
              report?.obConnected,
            ]),
            false,
            true,
            true
          ),

          value: parseFloat(report?.inbound),
        },
        {
          percent: getPercentage(
            getSum([report?.obNotConnected, report?.obConnected]),
            getSum([
              report?.inbound,
              report?.obNotConnected,
              report?.obConnected,
            ]),
            false,
            true,
            true
          ),
          value: getSum([report?.obNotConnected, report?.obConnected]),
        },
      ],
    }
  }, [loading, report, workingDays])

  const ibClosingRatioData = useMemo(() => {
    let percent = 0
    let target = 0
    let minStandard = 0
    let dailyAvg = 0
    if (!loading) {
      percent = getPercentage(
        report?.appointedInbound,
        report?.ibOpportunity,
        false,
        true,
        true
      )
      target = ibRatioGoal?.target || 0
      minStandard = ibRatioGoal?.minStandard || 0
      dailyAvg = approximate(ibRatioGoal?.target / workingDays)
    }

    return {
      percent,
      target,
      minStandard,
      dailyAvg,
    }
  }, [loading, report, ibRatioGoal, workingDays])

  const obClosingRatioData = useMemo(() => {
    let percent = 0
    let target = 0
    let minStandard = 0
    let dailyAvg = 0
    if (!loading) {
      percent = getPercentage(
        getSum([
          report.outboundAppointedNotConnected,
          report.outboundAppointedConnected,
        ]),
        report?.connectedOtdb,
        false,
        true,
        true
      )
      target = obRatioGoal?.target || 0
      minStandard = obRatioGoal?.minStandard || 0
      dailyAvg = approximate(obRatioGoal?.target / workingDays)
    }

    return {
      percent,
      target,
      minStandard,
      dailyAvg,
    }
  }, [loading, report, obRatioGoal])

  const ibOtdbCallsData = useMemo(() => {
    let percent = 0

    if (!loading) {
      percent = getPercentage(
        report?.ibOpportunity,
        report?.inbound,
        false,
        true,
        true
      )
    }

    return {
      percent,
    }
  }, [loading, report])

  const obConnectedCallsData = useMemo(() => {
    let percent = 0
    let target = 0

    if (!loading) {
      percent = getPercentage(
        report?.connected,
        getSum([report?.obNotConnected, report?.obConnected]),
        false,
        true,
        true
      )

      target = obRatioGoal?.target
    }

    return {
      percent,
      target,
    }
  }, [loading, report, obRatioGoal])

  const transferredOutData = useMemo(() => {
    let percent = 0
    let barColor = "danger"

    if (!loading) {
      percent = getPercentage(
        report?.transferOut,
        report?.totalCalls,
        false,
        true,
        true
      )
      barColor = percent > 15 ? "danger" : percent >= 10 ? "warning" : "success"
    }

    return {
      percent,
      barColor,
    }
  }, [loading, report])

  const activeAgentsData = useMemo(() => {
    let count = 0
    let target = 0
    if (!loading) {
      count = report?.activeAgents
      target = report?.activeAgents + report?.inActiveAgents
    }

    return {
      count,
      target,
    }
  }, [loading, report])

  const totalShowsData = useMemo(() => {
    let count = 0
    if (!loading) {
      count = report?.totalShows
    }

    return {
      count,
    }
  }, [loading, report])

  const totalShowsPercentData = useMemo(() => {
    let percent = 0
    if (!loading) {
      percent = getPercentage(
        report?.totalShows,
        report?.totalScheduledAppts,
        false,
        true,
        true
      )
    }

    return {
      percent,
    }
  }, [loading, report])

  // console.log({
  //   report,
  //   totalCallsData,
  //   totalApptsData,
  //   ibClosingRatioData,
  //   obClosingRatioData,
  //   ibOtdbCallsData,
  //   obConnectedCallsData,
  //   transferredOutData,
  //   activeAgentsData,
  //   totalShowsData,
  //   totalShowsPercentData,
  // })
  return {
    loading,
    totalCallsData,
    totalApptsData,
    ibClosingRatioData,
    obClosingRatioData,
    ibOtdbCallsData,
    obConnectedCallsData,
    transferredOutData,
    activeAgentsData,
    avgDailyCallsPerAgentData,
    rows,
    setRows,
    agentTableMode,
    // refreshTable,
    columns,
    handleAgentTableMode,
    totalShowsData,
    totalShowsPercentData,
  }
}

export default useManagerDashboard
