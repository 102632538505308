import { takeEvery, put, call } from "redux-saga/effects"
import { setStackRankingReport } from "./actions"
import { FETCH_STACK_RANKING_REPORT } from "./actionTypes"
import { getStackRankingReport } from "helpers/fakebackend_helper"

function* fetchStackRankingReport({ payload: { queryParams } }) {
  try {
    const response = yield call(getStackRankingReport, queryParams)
    yield put(setStackRankingReport(response))
  } catch (error) {
    console.log("error", error)
  }
}

function* stackRankingReportSaga() {
  yield takeEvery(FETCH_STACK_RANKING_REPORT, fetchStackRankingReport)
}

export default stackRankingReportSaga
