import {
  approximate,
  getGenericSum,
  getPercentage,
  getSum,
  isPercentageString,
} from "helpers/helperFns"

const IB_CALLS = "ibCalls"
const IB_APPTS_CREATED = "ibApptsCreated"
const IB_CLOSING_RATIO = "ibClosingRatio"

const OB_CALLS = "obCalls"
const OB_APPTS_CREATED = "obApptsCreated"

const ABANDONED_CALLS = "Abandoned Calls"
const ABANDONED_PERCENT = "Abandoned %"
const NUMBER_OF_SC = "scNumber"
const AVG_OB_APPTS_PER_SC = "avgObApptsPerSc"
const STATUS_CALLS = "Status Calls"
const CALL_PERFORMANCE_PERCENT = "Call Performance %"
const columnHeadersNames = [
  IB_CALLS,
  IB_APPTS_CREATED,
  IB_CLOSING_RATIO,
  OB_CALLS,
  OB_APPTS_CREATED,
  ABANDONED_CALLS,
  ABANDONED_PERCENT,
  NUMBER_OF_SC,
  AVG_OB_APPTS_PER_SC,
  STATUS_CALLS,
  CALL_PERFORMANCE_PERCENT,
]

export function groupRollupReportByStore(
  rollupReport,
  agentsNumbers,
  manualKpisPerStore
) {
  let groupedStores = {}
  for (let i = 0; i < rollupReport?.stores?.length || 0; i++) {
    const dispositionObj = rollupReport.stores[i]
    const {
      dispositionName,
      storeName,
      count,
      ibCalls,
      obNotConnectedCalls,
      obConnectedCalls,
      ibApptsCreated,
      obNotConnectedApptsCreated,
      obConnectedApptsCreated,
      connectedOtdb,
    } = dispositionObj
    if (groupedStores[storeName]) {
      const updatedOBNotConnectedCalls =
        (parseFloat(groupedStores[storeName].obNotConnectedCalls) || 0) +
        (parseFloat(obNotConnectedCalls) || 0)
      const updatedOBConnectedCalls =
        (parseFloat(groupedStores[storeName].obConnectedCalls) || 0) +
        (parseFloat(obConnectedCalls) || 0)
      const updatedConnectedOtdb =
        (parseFloat(groupedStores[storeName].connectedOtdb) || 0) +
        (parseFloat(connectedOtdb) || 0)
      const updatedIBCalls =
        (parseFloat(groupedStores[storeName][IB_CALLS]) || 0) +
        (parseFloat(ibCalls) || 0)
      const updatedOBCalls = getSum([
        updatedOBNotConnectedCalls,
        updatedOBConnectedCalls,
      ])
      const updatedOBNotConnectedApptsCreated =
        (parseFloat(groupedStores[storeName].obNotConnectedApptsCreated) || 0) +
        (parseFloat(obNotConnectedApptsCreated) || 0)
      const updatedOBConnectedApptsCreated =
        (parseFloat(groupedStores[storeName].obConnectedApptsCreated) || 0) +
        (parseFloat(obConnectedApptsCreated) || 0)
      const updatedIBApptsCreated =
        (parseFloat(groupedStores[storeName][IB_APPTS_CREATED]) || 0) +
        (parseFloat(ibApptsCreated) || 0)
      const updatedOBApptsCreated = getSum([
        updatedOBNotConnectedApptsCreated,
        updatedOBConnectedApptsCreated,
      ])

      const updatedTotalCalls =
        (parseFloat(groupedStores[storeName].totalCalls) || 0) +
        getSum([ibCalls, obNotConnectedCalls, obConnectedCalls])
      const updatedTotalAppts =
        (parseFloat(groupedStores[storeName].totalApptsCreated) || 0) +
        getSum([
          ibApptsCreated,
          obConnectedApptsCreated,
          obNotConnectedApptsCreated,
        ])
      const updatedTotalClosingRatio = getPercentage(
        getSum([updatedIBApptsCreated, updatedOBApptsCreated]),
        getSum([updatedIBCalls, updatedConnectedOtdb])
      )

      const numberOfAgentsInStore = groupedStores[storeName][NUMBER_OF_SC]
      const avgObApptsPerSC = numberOfAgentsInStore
        ? approximate(updatedOBApptsCreated / parseFloat(numberOfAgentsInStore))
        : 0
      groupedStores[storeName] = {
        ...groupedStores[storeName],
        [dispositionName]: parseFloat(count),
        [IB_CALLS]: updatedIBCalls,
        [OB_CALLS]: updatedOBCalls,
        obNotConnectedCalls: updatedOBNotConnectedCalls,
        obConnectedCalls: updatedOBConnectedCalls,
        connectedOtdb: updatedConnectedOtdb,

        [IB_APPTS_CREATED]: updatedIBApptsCreated,
        [OB_APPTS_CREATED]: updatedOBApptsCreated,
        obNotConnectedApptsCreated: updatedOBNotConnectedApptsCreated,
        obConnectedApptsCreated: updatedOBConnectedApptsCreated,

        [IB_CLOSING_RATIO]: getPercentage(
          updatedIBApptsCreated,
          updatedIBCalls
        ),
        obClosingRatio: getPercentage(
          updatedOBApptsCreated,
          updatedConnectedOtdb
        ),

        totalCalls: updatedTotalCalls,
        totalApptsCreated: updatedTotalAppts,
        totalClosingRatio: updatedTotalClosingRatio,
        [AVG_OB_APPTS_PER_SC]: avgObApptsPerSC,
      }
    } else {
      const totalCalls = getSum([
        ibCalls,
        obNotConnectedCalls,
        obConnectedCalls,
      ])
      const totalObAppts = getSum([
        obConnectedApptsCreated,
        obNotConnectedApptsCreated,
      ])
      const totalApptsCreated = getSum([ibApptsCreated, totalObAppts])
      const totalClosingRatio = getPercentage(
        getSum([
          ibApptsCreated,
          obConnectedApptsCreated,
          obNotConnectedApptsCreated,
        ]),
        getSum([ibCalls, connectedOtdb])
      )
      const numberOfAgentsInStore =
        parseFloat(
          agentsNumbers?.stores?.find(({ name }) => name === storeName)?.count
        ) || 0

      const avgObApptsPerSC = numberOfAgentsInStore
        ? approximate(totalObAppts / parseFloat(numberOfAgentsInStore))
        : 0
      groupedStores[storeName] = {
        storeName,
        [dispositionName]: parseFloat(count),
        [IB_CALLS]: parseFloat(ibCalls),
        obNotConnectedCalls: parseFloat(obNotConnectedCalls),
        obConnectedCalls: parseFloat(obConnectedCalls),
        connectedOtdb: parseFloat(connectedOtdb),
        [OB_CALLS]: getSum([obNotConnectedCalls, obConnectedCalls]),
        [IB_APPTS_CREATED]: parseFloat(ibApptsCreated),
        obConnectedApptsCreated: parseFloat(obConnectedApptsCreated),
        obNotConnectedApptsCreated: parseFloat(obNotConnectedApptsCreated),
        [OB_APPTS_CREATED]: totalObAppts,
        [IB_CLOSING_RATIO]: getPercentage(
          parseFloat(ibApptsCreated),
          parseFloat(ibCalls)
        ),
        obClosingRatio: getPercentage(
          getSum([obConnectedApptsCreated, obNotConnectedApptsCreated]),
          parseFloat(connectedOtdb)
        ),
        totalCalls: totalCalls,
        totalApptsCreated: totalApptsCreated,
        totalClosingRatio: totalClosingRatio,
        [NUMBER_OF_SC]: numberOfAgentsInStore,
        [AVG_OB_APPTS_PER_SC]: avgObApptsPerSC,
      }
    }
  }

  let groupedGroups = {}
  const groupsKeys = Object.keys(rollupReport?.groups || {})
  for (let i = 0; i < (groupsKeys.length || 0); i++) {
    const key = groupsKeys[i]
    const groupObj = rollupReport.groups[key]

    const {
      ibCalls,
      obNotConnectedCalls,
      obConnectedCalls,
      ibApptsCreated,
      obNotConnectedApptsCreated,
      obConnectedApptsCreated,
      dispositions,
      connectedOtdb,
    } = groupObj

    const totalCalls = getSum([ibCalls, obNotConnectedCalls, obConnectedCalls])
    const totalObAppts = getSum([
      obConnectedApptsCreated,
      obNotConnectedApptsCreated,
    ])
    const totalApptsCreated = getSum([ibApptsCreated, totalObAppts])
    const totalClosingRatio = getPercentage(
      getSum([
        ibApptsCreated,
        obConnectedApptsCreated,
        obNotConnectedApptsCreated,
      ]),
      getSum([ibCalls, connectedOtdb])
    )

    const numberOfAgentsInGroup =
      parseFloat(agentsNumbers?.groups?.[key]?.count) || 0

    const avgObApptsPerSC = numberOfAgentsInGroup
      ? approximate(totalObAppts / parseFloat(numberOfAgentsInGroup))
      : 0

    groupedGroups[key] = {
      storeName: key,
      [IB_CALLS]: parseFloat(ibCalls),
      obNotConnectedCalls: parseFloat(obNotConnectedCalls),
      obConnectedCalls: parseFloat(obConnectedCalls),
      connectedOtdb: parseFloat(connectedOtdb),
      [OB_CALLS]: getSum([obNotConnectedCalls, obConnectedCalls]),
      [IB_APPTS_CREATED]: parseFloat(ibApptsCreated),
      obConnectedApptsCreated: parseFloat(obConnectedApptsCreated),
      obNotConnectedApptsCreated: parseFloat(obNotConnectedApptsCreated),
      [OB_APPTS_CREATED]: totalObAppts,
      [IB_CLOSING_RATIO]: getPercentage(
        parseFloat(ibApptsCreated),
        parseFloat(ibCalls)
      ),
      obClosingRatio: getPercentage(
        getSum([obConnectedApptsCreated, obNotConnectedApptsCreated]),
        parseFloat(connectedOtdb)
      ),
      totalCalls: totalCalls,
      totalApptsCreated: totalApptsCreated,
      totalClosingRatio: totalClosingRatio,
      [NUMBER_OF_SC]: numberOfAgentsInGroup,
      [AVG_OB_APPTS_PER_SC]: avgObApptsPerSC,
      ...dispositions,
    }
  }

  // per store kpis
  for (let i = 0; i < manualKpisPerStore?.stores?.length; i++) {
    const kpiObj = manualKpisPerStore.stores[i]
    const { manualKpiName, value, storeName, user_id, unit, creationDate } =
      kpiObj
    const isNumber = unit === "Number"
    const isTime = unit === "Minutes"
    const isPercent = unit === "Percentage"
    const parsedValue = isTime
      ? value
      : isPercent
      ? `${value}%`
      : parseFloat(value)

    if (groupedStores[storeName]) {
      let updatedValue
      if (!!groupedStores[storeName][manualKpiName]) {
        updatedValue = getGenericSum([
          value,
          groupedStores[storeName][manualKpiName],
        ])
      } else {
        updatedValue = parsedValue
      }
      groupedStores[storeName] = {
        ...groupedStores[storeName],
        [manualKpiName]: updatedValue,
      }
    } else {
      groupedStores[storeName] = {
        storeName,
        [manualKpiName]: parsedValue,
      }
    }
  }

  // per group kpis
  const kpiGroupsKeys = Object.keys(manualKpisPerStore?.groups || {})

  for (let i = 0; i < kpiGroupsKeys.length; i++) {
    const key = kpiGroupsKeys[i]
    const kpiObj = manualKpisPerStore.groups[key]

    const { manualKpis } = kpiObj

    const manualKpisNames = Object.keys(manualKpis || {})
    const aggregatedManualKpis = manualKpisNames.reduce((acc, kpiName) => {
      if (acc[kpiName] == null) {
        acc[kpiName] = getGenericSum(manualKpis[kpiName])
      }
      return acc
    }, {})

    groupedGroups[key] = {
      ...groupedGroups[key],
      ...aggregatedManualKpis,
    }
  }

  groupedStores = Object.values(groupedStores)
  groupedGroups = Object.values(groupedGroups)

  const combinedArr = [...groupedGroups, ...groupedStores]
  return combinedArr
}

// ----------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------

export function groupManualKpisByKpiName(manualKpis) {
  let groupedObj = {}
  for (let i = 0; i < manualKpis?.stores?.length || 0; i++) {
    const kpiObj = manualKpis.stores[i]
    const { manualKpiName, value, unit, storeName } = kpiObj

    const isNumber = unit === "Number"
    const isTime = unit === "Minutes"
    const isPercent = unit === "Percentage"
    const parsedValue = isTime
      ? value
      : isPercent
      ? `${value}%`
      : parseFloat(value)

    if (groupedObj[manualKpiName]) {
      let updatedStoresArr
      const existingStoreObj = groupedObj[manualKpiName].storesArr?.find(
        storeObj => storeObj.storeName === storeName
      )
      if (!!existingStoreObj) {
        updatedStoresArr = groupedObj[manualKpiName].storesArr.map(storeObj => {
          if (storeObj.storeName === storeName) {
            return {
              ...storeObj,
              value: getGenericSum([parsedValue, existingStoreObj.value]),
            }
          } else {
            return storeObj
          }
        })
      } else {
        updatedStoresArr = [
          ...groupedObj[manualKpiName].storesArr,
          { storeName, value: parsedValue },
        ]
      }

      groupedObj[manualKpiName] = {
        ...groupedObj[manualKpiName],
        storesArr: updatedStoresArr,
      }
    } else {
      groupedObj[manualKpiName] = {
        manualKpiName,
        storesArr: [
          {
            storeName,
            value: parsedValue,
          },
        ],
      }
    }
  }

  groupedObj = Object.values(groupedObj).filter(({ manualKpiName }) =>
    [
      ABANDONED_CALLS,
      ABANDONED_PERCENT,
      STATUS_CALLS,
      CALL_PERFORMANCE_PERCENT,
    ].includes(manualKpiName)
  )

  return groupedObj
}

// --------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------

function valueFormatter(params) {
  return params.value
}

export function getRollupByStoreTableData(rollupGroupedByStore) {
  if (!rollupGroupedByStore) return []

  let tableColumns = [
    {
      field: "storeName",
      headerName: "Name",
    },
    {
      field: IB_CALLS,
      headerName: "IB Calls",
      // valueFormatter,
    },
    {
      field: IB_APPTS_CREATED,
      headerName: "IB Appts",
      // valueFormatter,
    },
    {
      field: IB_CLOSING_RATIO,
      headerName: "IB Appt Ratio",
      // valueFormatter,
    },

    {
      field: ABANDONED_CALLS,
      headerName: ABANDONED_CALLS,
      // valueFormatter,
    },
    {
      field: ABANDONED_PERCENT,
      headerName: ABANDONED_PERCENT,
      // valueFormatter,
    },
    {
      field: NUMBER_OF_SC,
      headerName: "# of SCs",
      // valueFormatter,
    },
    {
      field: OB_CALLS,
      headerName: "OB Calls",
      // valueFormatter,
    },
    {
      field: OB_APPTS_CREATED,
      headerName: "OB Appts",
      // valueFormatter,
    },
    {
      field: AVG_OB_APPTS_PER_SC,
      headerName: "Avg OB Appts per SC",
      // valueFormatter,
    },
    {
      field: STATUS_CALLS,
      headerName: STATUS_CALLS,
      // valueFormatter,
    },
    {
      field: CALL_PERFORMANCE_PERCENT,
      headerName: CALL_PERFORMANCE_PERCENT,
      // valueFormatter,
    },
  ]
  // const storesWithTableData = rollupGroupedByStore
  //   .filter(storeReport => {
  //     if (
  //       !Object.keys(storeReport || {}).some(rowName =>
  //         columnHeadersNames.includes(rowName)
  //       )
  //     ) {
  //       return false
  //     }
  //     return true
  //   })
  //   .map(storeReport => {
  //     const tableRows = [storeReport]
  //     const storeName = storeReport.storeName

  //     return {
  //       tableColumns,
  //       tableRows,
  //       storeName,
  //     }
  //   })

  return {
    tableColumns,
    tableRows: rollupGroupedByStore,
    // storeName,
  }
}

export function getManualKpisTableData(manualKpisGroupedByKpiName) {
  if (!manualKpisGroupedByKpiName) return []

  const storesWithTableData = manualKpisGroupedByKpiName.map(kpiData => {
    const manualKpiName = kpiData.manualKpiName
    const kpiStores = kpiData.storesArr

    let tableColumns = [
      {
        field: manualKpiName,
        headerName: manualKpiName,
        // valueFormatter,
      },
      {
        field: "value",
        headerName: "#",
        // valueFormatter,
      },
    ]

    const tableRows = kpiStores.map(storeObj => ({
      [manualKpiName]: storeObj.storeName,
      value: storeObj.value,
    }))
    return {
      tableColumns,
      tableRows,
      manualKpiName,
    }
  })

  return storesWithTableData
}

export function groupSAReportByStore(serviceAdvisorsReport) {
  let groupedObj = {}
  for (let i = 0; i < serviceAdvisorsReport?.length || 0; i++) {
    const reportObj = serviceAdvisorsReport[i]
    const { dispositionName, count, storeName, serviceAdvisorName } = reportObj

    if (groupedObj[storeName]) {
      //   const isPercent = isPercentageString(count)

      const updatedSaArr = [
        ...groupedObj[storeName].saArr,
        { serviceAdvisorName, count: parseFloat(count) },
      ]

      groupedObj[storeName] = {
        ...groupedObj[storeName],
        saArr: updatedSaArr,
      }
    } else {
      groupedObj[storeName] = {
        storeName,
        saArr: [
          {
            serviceAdvisorName,
            count: parseFloat(count),
          },
        ],
      }
    }
  }
  groupedObj = Object.values(groupedObj)

  return groupedObj
}

export function getSaByStoreTableData(serviceAdvisorsGroupedByStore) {
  if (!serviceAdvisorsGroupedByStore) return []

  const storesWithTableData = serviceAdvisorsGroupedByStore.map(storeObj => {
    const storeName = storeObj.storeName
    const storeSAs = storeObj.saArr

    let tableColumns = [
      {
        field: storeName,
        headerName: storeName,
        // valueFormatter,
      },
      {
        field: "count",
        headerName: "#",
        // valueFormatter,
      },
    ]

    const tableRows = storeSAs.map(saObj => ({
      [storeName]: saObj.serviceAdvisorName,
      count: saObj.count,
    }))
    return {
      tableColumns,
      tableRows,
      storeName,
    }
  })

  return storesWithTableData
}
