import React from "react"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

const useAuth = () => {
  const PermissionsSelector = createSelector(
    state => state.Permissions,
    perm => ({
      user: perm.user,
      loading: perm.loading,
      permissions: perm.permissions,
    })
  )

  return useSelector(PermissionsSelector)
}

export default useAuth
