import {
  datesOptions,
  isCustomDateOption,
  tzMoment,
} from "constants/datesOptions"
import { axiosApi } from "helpers/api_helper"
import { approximate, getPercentage, getSum } from "helpers/helperFns"
import moment from "moment"
import React, { useEffect, useMemo, useState } from "react"

function getWorkingDaysPerDateOption(startDate, endDate) {
  let totalWorkingDays = 0
  let i = 0
  let clonedStart = tzMoment(startDate)
  const clonedEnd = tzMoment(endDate)

  while (clonedStart.isBefore(clonedEnd, "minutes")) {
    const dayOfWeek = clonedStart.weekday()

    // Check if it's a weekend (Saturday or Sunday)
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      totalWorkingDays++
    }

    clonedStart = clonedStart.add(1, "days")
    i++
  }

  return totalWorkingDays
}

const useAgentDashboard = ({
  validation,

  // selectedDateOptionValue,
  // scopeIds,
  setFieldValue,
}) => {
  const [report, setReport] = useState({})
  // const [goal, setGoal] = useState({})
  const [callsGoal, setCallsGoal] = useState({})
  const [apptsGoal, setApptsGoal] = useState({})
  const [ibRatioGoal, setIbRatioGoal] = useState({})
  const [obRatioGoal, setObRatioGoal] = useState({})
  const [loading, setLoading] = useState(true)

  const currentDateOption = Object.values(datesOptions).find(
    ({ value }) => value === validation.values.selectedDateOption?.value
  )

  let startDate, endDate
  if (isCustomDateOption(currentDateOption.value)) {
    startDate = validation.values.startDate
    endDate = validation.values.endDate
  } else {
    startDate = currentDateOption.startDate
    endDate = currentDateOption.endDate
  }

  const isSingleDay = [
    datesOptions.today.value,
    datesOptions.yesterday.value,
  ].includes(validation.values.selectedDateOption?.value)

  const workingDays = isSingleDay
    ? 1
    : getWorkingDaysPerDateOption(startDate, endDate)

  const fetchActualCalls = async () => {
    setLoading(true)

    const startDateParam = startDate ? `startDate=${startDate}` : ""
    const endDateParam = endDate ? `endDate=${endDate}` : ""

    let scopeQuery = ""
    if (validation.values.scope?.length > 0) {
      const queryArr = validation.values.scope.map(id => `scopeIds=${id}`)
      scopeQuery = queryArr.join("&")
    }

    const actualCallsResponse = await axiosApi.get(
      `/api/v1/reports/dashboard?${startDateParam}&${endDateParam}&${scopeQuery}`
    )
    // const actualCallsResponse = await axiosApi.get(
    //   `/api/v1/reports/dashboard?startDate=${startDate}${scopeQuery}`
    // )

    const newReport = {
      inbound: 0,
      outboundConnected: 0,
      outboundNotConnected: 0,
      connectedOtdb: 0,
      internal: 0,

      appointedInbound: 0,
      outboundAppointedConnected: 0,
      outboundAppointedNotConnected: 0,
      appointedInternal: 0,
    }
    for (const call of actualCallsResponse.data?.calls) {
      newReport.inbound += Number(call.inbound)
      newReport.outboundConnected += Number(call.outboundConnected)
      newReport.outboundNotConnected += Number(call.outboundNotConnected)
      newReport.connectedOtdb += Number(call.connectedOtdb)
      newReport.internal += Number(call.internal)
      newReport.appointedInbound += Number(call.appointedInbound)
      newReport.outboundAppointedConnected += Number(
        call.outboundAppointedConnected
      )
      newReport.outboundAppointedNotConnected += Number(
        call.outboundAppointedNotConnected
      )

      newReport.appointedInternal += Number(call.appointedInternal)
    }
    setReport(newReport)

    setLoading(false)
  }

  const fetchCallsGoals = async () => {
    setLoading(true)

    const startDateParam = startDate ? `startDate=${startDate}` : ""
    const endDateParam = endDate ? `endDate=${endDate}` : ""

    let scopeQuery = ""
    if (validation.values.scope?.length > 0) {
      const queryArr = validation.values.scope.map(id => `scopeIds=${id}`)
      scopeQuery = queryArr.join("&")
    }
    //calls goal
    const callsGoalResponse = await axiosApi.get(
      `/api/v1/reports/dashboard-goal/Calls?${startDateParam}&${endDateParam}&${scopeQuery}`
    )
    const newCallsGoal = {}
    for (const goal of callsGoalResponse.data) {
      newCallsGoal.target = approximate(Number(goal.target) / 21)
      newCallsGoal.minStandard = Number(goal.minstandard) / 21
      if (goal.entity_type === "agent") break
    }
    setCallsGoal(newCallsGoal)

    //appts goal
    const apptGoalResponse = await axiosApi.get(
      `/api/v1/reports/dashboard-goal/Appointments?${startDateParam}&${endDateParam}&${scopeQuery}`
    )
    const newApptsGoal = {}
    for (const goal of apptGoalResponse.data) {
      newApptsGoal.target = approximate(Number(goal.target) / 21)
      newApptsGoal.minStandard = Number(goal.minstandard) / 21
      if (goal.entity_type === "agent") break
    }
    setApptsGoal(newApptsGoal)

    //Ib ratio goal
    const ibRatioResponse = await axiosApi.get(
      `/api/v1/reports/dashboard-goal/IB Closing Ratio?${startDateParam}&${endDateParam}&${scopeQuery}`
    )
    const newIbRatioGoal = {}
    for (const goal of ibRatioResponse.data) {
      newIbRatioGoal.target = Number(
        goal.target?.substring(0, goal.target?.length - 1)
      )

      newIbRatioGoal.minStandard = Number(
        goal.minstandard?.substring(0, goal.minstandard?.length - 1)
      )

      if (goal.entity_type === "agent") break
    }
    setIbRatioGoal(newIbRatioGoal)

    //Ob ratio goal
    const obRatioResponse = await axiosApi.get(
      `/api/v1/reports/dashboard-goal/OB Closing Ratio?${startDateParam}&${endDateParam}&${scopeQuery}`
    )
    const newObRatioGoal = {}
    for (const goal of obRatioResponse.data) {
      newObRatioGoal.target = Number(
        goal.target?.substring(0, goal.target?.length - 1)
      )

      newObRatioGoal.minStandard = Number(
        goal.minstandard?.substring(0, goal.minstandard?.length - 1)
      )
      if (goal.entity_type === "agent") break
    }
    setObRatioGoal(newObRatioGoal)

    setLoading(false)
  }

  useEffect(() => {
    const fetch = async () => {
      const errors = await validation.validateForm()
      if (Object.values(errors)?.length) {
        setReport({})
        setCallsGoal({})
        setApptsGoal({})
        setIbRatioGoal({})
        setObRatioGoal({})
        return
      }

      fetchActualCalls()
      fetchCallsGoals()
    }
    fetch()
  }, [validation.values])

  const totalCallsData = useMemo(() => {
    let count = 0
    let target = 0
    let minStandard = 0

    if (!loading) {
      count = getSum([
        report?.inbound,
        report?.outboundConnected,
        report.outboundNotConnected,
        report?.internal,
      ])
      target = parseFloat(callsGoal?.target || 0) * workingDays
      minStandard = parseFloat(callsGoal?.minStandard || 0) * workingDays
    }

    return {
      count,
      target,
      minStandard,
      multiProgress: [
        {
          percent: getPercentage(
            report?.inbound,
            getSum([
              report?.inbound,
              report?.outboundConnected,
              report.outboundNotConnected,
            ]),
            false,
            true,
            true
          ),
          value: parseFloat(report?.inbound),
        },
        {
          percent: getPercentage(
            getSum([report?.outboundConnected, report.outboundNotConnected]),
            getSum([
              report?.inbound,
              report?.outboundConnected,
              report.outboundNotConnected,
            ]),
            false,
            true,
            true
          ),
          value: getSum([
            report?.outboundConnected,
            report.outboundNotConnected,
          ]),
        },
      ],
    }
  }, [loading, report, callsGoal])

  const totalApptsData = useMemo(() => {
    let count = 0
    let target = 0
    let minStandard = 0

    if (!loading) {
      count = getSum([
        report?.appointedInbound,
        report?.outboundAppointedConnected,
        report?.outboundAppointedNotConnected,
        report?.appointedInternal,
      ])
      target = parseFloat(apptsGoal?.target || 0) * workingDays
      minStandard = parseFloat(apptsGoal?.minStandard || 0) * workingDays
    }

    return {
      count,
      target,
      minStandard,
      multiProgress: [
        {
          percent: getPercentage(
            report?.appointedInbound,
            getSum([
              report?.appointedInbound,
              report?.outboundAppointedConnected,
              report?.outboundAppointedNotConnected,
            ]),
            false,
            true,
            true
          ),
          value: parseFloat(report?.appointedInbound),
        },
        {
          percent: getPercentage(
            getSum([
              report?.outboundAppointedConnected,
              report?.outboundAppointedNotConnected,
            ]),
            getSum([
              report?.appointedInbound,
              report?.outboundAppointedConnected,
              report?.outboundAppointedNotConnected,
            ]),
            false,
            true,
            true
          ),
          value: getSum([
            report?.outboundAppointedConnected,
            report?.outboundAppointedNotConnected,
          ]),
        },
      ],
    }
  }, [loading, report, apptsGoal])

  const ibClosingRatioData = useMemo(() => {
    let percent = 0
    let target = 0
    let minStandard = 0

    if (!loading) {
      percent = getPercentage(
        report?.appointedInbound,
        report?.inbound,
        false,
        true,
        true
      )
      target = ibRatioGoal?.target || 0
      minStandard = ibRatioGoal?.minStandard || 0
    }

    return {
      percent,
      target,
      minStandard,
    }
  }, [loading, report, ibRatioGoal])

  const obClosingRatioData = useMemo(() => {
    let percent = 0
    let target = 0
    let minStandard = 0

    if (!loading) {
      percent = getPercentage(
        getSum([
          report.outboundAppointedNotConnected,
          report.outboundAppointedConnected,
        ]),
        report?.connectedOtdb,
        false,
        true,
        true
      )
      target = obRatioGoal?.target || 0
      minStandard = obRatioGoal?.minStandard || 0
    }

    return {
      percent,
      target,
      minStandard,
    }
  }, [loading, report, obRatioGoal])

  return {
    loading,
    totalCallsData,
    totalApptsData,
    ibClosingRatioData,
    obClosingRatioData,
  }
}

export default useAgentDashboard
