import {
  FETCH_STACK_RANKING_REPORT,
  SET_STACK_RANKING_REPORT,
} from "./actionTypes"

export const fetchStackRankingReport = (
  queryParams = {
    storesIds: [],
    startDate: "",
    endDate: "",
    withManuallyAddedKpis: false,
  }
) => {
  return {
    type: FETCH_STACK_RANKING_REPORT,
    payload: { queryParams },
  }
}
export const resetStackRankingReport = () => {
  return {
    type: SET_STACK_RANKING_REPORT,
    payload: { stackRankingReport: undefined },
  }
}

export const setStackRankingReport = stackRankingReport => {
  return {
    type: SET_STACK_RANKING_REPORT,
    payload: { stackRankingReport },
  }
}
