import React from "react"
import classes from "./styles.module.css"

const CustomTable = ({ children, ...props }) => {
  return (
    <table className={classes.table} {...props}>
      {children}
    </table>
  )
}

export default CustomTable
