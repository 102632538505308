import {
  FETCH_SERVICE_ADVISOR_REPORTS,
  SET_SERVICE_ADVISOR_REPORTS,
} from "./actionTypes"

export const fetchServiceAdvisorReports = (
  queryParams = { storesIds: [], startDate: "", endDate: "" }
) => {
  return {
    type: FETCH_SERVICE_ADVISOR_REPORTS,
    payload: { queryParams },
  }
}
export const resetServiceAdvisorReports = () => {
  return {
    type: SET_SERVICE_ADVISOR_REPORTS,
    payload: { serviceAdvisorsReports: [] },
  }
}

export const setServiceAdvisorReports = serviceAdvisorsReports => {
  return {
    type: SET_SERVICE_ADVISOR_REPORTS,
    payload: { serviceAdvisorsReports },
  }
}
