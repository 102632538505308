import { useFormik } from "formik"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Alert, Button, Form, Input, Spinner } from "reactstrap"
import * as Yup from "yup"
import InputField from "components/Form/InputField"
import { useNavigate } from "react-router-dom"
import SelectField from "components/Form/SelectField"
import {
  addCall,
  fetchStores,
  setServiceAdvisors,
  updateCall,
} from "store/actions"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { axiosApi } from "helpers/api_helper"
import { format } from "date-fns"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons"
import { Tooltip } from "react-tooltip"

import DateTimeField from "components/Form/DateTimeField"
import { isDate } from "lodash"
import { toast } from "react-toastify"

const NewCallForm = ({ call, dashboard2 }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showConfirm, setShowConfirm] = useState(false)
  const [dispositions, setDispositions] = useState([])
  const [showSa, setShowSa] = useState(false)
  const [showAppt, setShowAppt] = useState(false)
  const [saList, setSaList] = useState([])
  const [storeFocus, setStoreFocus] = useState(false)

  useEffect(() => {
    dispatch(fetchStores())
  }, [])

  const StoresProperties = createSelector(
    state => state.Store,
    store => ({
      stores: store.stores,
    })
  )

  const { stores } = useSelector(StoresProperties)

  const CallProperties = createSelector(
    state => state.Call,
    call => ({
      loading: call.loading,
      errorMsg: call.errorMsg,
      successMsg: call.successMsg,
      calls: call.calls,
    })
  )

  const { loading, errorMsg, successMsg, calls } = useSelector(CallProperties)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: call
      ? {
          store: { value: call.store?.store_id, label: call.store?.name },
          type: call.is_ib
            ? { value: "is_ib", label: "Inbound (IB)" }
            : call.is_ob
            ? { value: "is_ob", label: "Outbound (OB)" }
            : { value: "internal", label: "Internal" },
          disposition: {
            value: call.disposition?.disposition_id,
            label: call.disposition?.name,
          },
          serviceAdvisor: { value: call.sa?.user_id, label: call.sa?.name },
          apptDateTime: call.appt_date ? new Date(call.appt_date) : null,
          // isAm: call.is_am
          //   ? { value: "is_am", label: "AM" }
          //   : { value: "is_pm", label: "PM" },
          clientName: call.client_name || "",
          clientPhone: call.client_phone || "",
          vin: call.vin || "",
          notes: call.notes || "",
          is_connected: call.is_connected,
        }
      : {
          store: null,
          type: null,
          disposition: null,
          serviceAdvisor: null,
          apptDateTime: null,
          // isAm: null,
          clientName: "",
          clientPhone: "",
          vin: "",
          notes: "",
          is_connected: false,
        },
    validationSchema: Yup.object({
      clientName: Yup.string().optional(),
      store: Yup.object().required("Required"),
      type: Yup.object().required("Required"),
      disposition: Yup.object().required("Required"),
      clientPhone: Yup.string().test("Invalid mobile number", v => {
        // if (!v || !v?.match(/\d/g)?.length) return true
        return v?.match(/\d/g)?.length === 10
      }),
    }),
    onSubmit: values => {
      // if (showAppt && !isDate(values.apptDateTime)) {
      //   validation.setErrors({ apptDateTime: "Date and time are required." })
      //   return
      // }
      // if (showSa && !values.serviceAdvisor) {
      //   validation.setErrors({
      //     serviceAdvisor: "Please choose service advisor",
      //   })
      //   return
      // }
      window.scrollTo({ top: 0, behavior: "smooth" })

      const body = {
        dispositionId: values.disposition.value,
        storeId: values.store.value,
        client_name: values.clientName,
        is_ib: values.type.value === "is_ib",
        is_ob: values.type.value === "is_ob",
        is_internal: values.type.value === "is_internal",
        is_connected:
          values.type.value === "is_ob" ? values.is_connected : false,
      }
      if (values.notes) body.notes = values.notes
      if (values.vin) body.vin = values.vin
      if (values.clientPhone) {
        body.client_phone = values.clientPhone
      }
      if (showSa && values.serviceAdvisor.value) {
        body.saId = values.serviceAdvisor.value
      }
      if (showAppt && values.apptDateTime) {
        const dateTimeString = format(
          values.apptDateTime,
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        )
        body.appt_date = dateTimeString
      }
      console.log(body)
      if (call) dispatch(updateCall(call.call_id, body, navigate))
      else dispatch(addCall(body, navigate))
    },
  })

  const storeId = useMemo(() => {
    return validation.values?.store?.value
  }, [validation.values?.store?.value])
  const scopeId = useMemo(() => {
    return stores?.find(item => item.store_id == storeId)?.entityType?.id
  }, [stores, storeId])

  useEffect(() => {
    const type =
      validation.values?.type?.value === "is_ib"
        ? "Inbound"
        : validation.values?.type?.value === "is_internal"
        ? "Internal"
        : validation.values?.type?.value === "is_ob" &&
          validation.values.is_connected
        ? "ConnectedOutBound"
        : validation.values?.type?.value === "is_ob" &&
          !validation.values.is_connected
        ? "NotConnectedOutBound"
        : null
    if (type && storeId > 0) {
      // validation.setFieldValue("disposition", null)

      if (scopeId) {
        axiosApi
          .get(
            `/api/v1/dispositions/scope?scope_id=${scopeId}&disposition_type=${type}`
          )
          .then(res => {
            setDispositions(res.data)
            if (
              !res.data.find(
                item =>
                  item.disposition_id == validation.values?.disposition?.value
              )
            )
              validation.setFieldValue("disposition", null)
          })
      }
    }
  }, [scopeId, validation.values?.type?.value, validation.values.is_connected])
  useEffect(() => {
    if (scopeId) {
      axiosApi.get(`/api/v1/service-advisor/scope/${scopeId}`).then(res => {
        setSaList(res.data)
      })
    }
  }, [scopeId])

  useEffect(() => {
    const dispId = validation.values?.disposition?.value
    if (dispId && dispositions?.length > 0) {
      const disp = dispositions.find(item => item.disposition_id === dispId)
      setShowAppt(disp.is_appointment)
      setShowSa(disp.is_sarequired)
    } else {
      setShowAppt(false)
      setShowSa(false)
    }
  }, [validation.values?.disposition?.value, dispositions])

  useEffect(() => {
    if (successMsg) {
      resetForm()
      toast.success(successMsg)
    }
  }, [successMsg])

  useEffect(() => {
    if (errorMsg) {
      toast.error(errorMsg)
    }
  }, [errorMsg])

  const resetForm = () => {
    validation.resetForm()
    setDispositions([])
    setServiceAdvisors([])
    setStoreFocus(old => !old)
  }

  const handleCancel = () => {
    if (validation.dirty) {
      window.scrollTo({ top: 0, behavior: "smooth" })
      setShowConfirm(true)
    } else {
      navigate(-1)
    }
  }
  return (
    <>
      <div className="bg-white px-2 py-3">
        {showConfirm && (
          <Alert color="danger" style={{ marginTop: "13px" }}>
            <div>
              <p>Are you sure you want to cancel? Your inputs will be lost.</p>
              <div className="d-flex">
                <Button
                  type="button"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() => navigate(-1)}
                >
                  Yes
                </Button>
                <Button type="button" onClick={() => setShowConfirm(false)}>
                  No
                </Button>
              </div>
            </div>
          </Alert>
        )}

        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
          onKeyDown={e => {
            // Handle key press events
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault()
            }

            if ((e.ctrlKey || e.metaKey) && e.key === "s") {
              e.preventDefault()
              validation.handleSubmit() // Submit the form if Enter key is pressed
            }
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-12">
                <SelectField
                  id="store-field"
                  label={"Store"}
                  required
                  name="store"
                  validation={validation}
                  options={stores?.map(item => ({
                    value: item.store_id,
                    label: item.name,
                  }))}
                  wrapperClassName="mb-4"
                  autoFocus={true}
                  preSelected={true}
                  setFocus={storeFocus}
                />
              </div>

              <div className="col-lg-3 col-8">
                <SelectField
                  label={"Type"}
                  required
                  name="type"
                  validation={validation}
                  options={[
                    { value: "is_ib", label: "Inbound (IB)" },
                    { value: "is_ob", label: "Outbound (OB)" },
                    { value: "is_internal", label: "Internal" },
                  ]}
                  wrapperClassName="mb-4"
                />
              </div>
              {validation.values?.type?.value === "is_ob" && (
                <div className="col-lg-2 col-4 d-flex justify-content-center align-items-start">
                  <div
                    className="d-flex align-items-center gap-2"
                    style={{ paddingTop: "35px" }}
                  >
                    <Input
                      type="checkbox"
                      style={{ width: "20px", height: "20px" }}
                      value={validation.values["is_connected"]}
                      defaultChecked={call ? call.is_connected : false}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      name="is_connected"
                      onKeyDown={e => {
                        if (e.key === "Enter") {
                          e.preventDefault()
                          validation.setFieldValue(
                            "is_connected",
                            !validation.values?.is_connected
                          )
                        }
                      }}
                      // ref={checkboxRef}
                    />
                    <span>Connected</span>
                  </div>
                </div>
              )}
              <div className="col-lg-3 col-12">
                <SelectField
                  label={"Disposition"}
                  required
                  name="disposition"
                  validation={validation}
                  options={dispositions?.map(item => ({
                    value: item.disposition_id,
                    label: item.name,
                  }))}
                  wrapperClassName="mb-4"
                  disabled={dispositions?.length === 0}
                  preSelected={true}
                />
              </div>
            </div>
            <div className="row">
              {showSa && (
                <div className="col-lg-6 col-12">
                  <SelectField
                    label={"Service Advisor"}
                    // required
                    name="serviceAdvisor"
                    validation={validation}
                    options={saList?.map(item => ({
                      value: item.user_id,
                      label: item.name,
                    }))}
                    wrapperClassName="mb-4"
                    preSelected={true}
                  />
                </div>
              )}
              <div className="col-lg-6 col-12">
                {showAppt && (
                  // <div className="d-flex gap-2">
                  //   <InputField
                  //     name="apptDate"
                  //     validation={validation}
                  //     placeholder=""
                  //     label={"Appointment Date"}
                  //     type="date"
                  //     required
                  //     wrapperClassName="mb-4"
                  //   />

                  //   <InputField
                  //     name="apptTime"
                  //     validation={validation}
                  //     placeholder=""
                  //     label={"Time"}
                  //     type="time"
                  //     required
                  //     wrapperClassName="mb-4"
                  //   />
                  // </div>
                  <DateTimeField
                    label="Appointment Time"
                    name="apptDateTime"
                    validation={validation}
                  />
                  // <div style={{ maxWidth: "200px" }}>
                  //   <SelectField
                  //     label={"AM/PM"}
                  //     required
                  //     name="isAm"
                  //     validation={validation}
                  //     options={[
                  //       { value: "is_am", label: "AM" },
                  //       { value: "is_pm", label: "PM" },
                  //     ]}
                  //     wrapperClassName="mb-4"
                  //   />
                  // </div>
                )}
              </div>
            </div>
            {!dashboard2 && (
              <div className="row">
                <div className="col-lg-4 col-12">
                  <InputField
                    name="clientName"
                    validation={validation}
                    placeholder=""
                    label={"Client Name"}
                    wrapperClassName="mb-4"
                  />
                </div>
                <div className="col-lg-4 col-12">
                  <InputField
                    name="clientPhone"
                    validation={validation}
                    placeholder=""
                    label={"Client Phone"}
                    wrapperClassName="mb-4"
                    mask={"(999) 999-9999"}
                    required
                  />
                </div>
                <div className="col-lg-4 col-12">
                  <InputField
                    name="vin"
                    validation={validation}
                    placeholder=""
                    label={"VIN"}
                    wrapperClassName="mb-4"
                  />
                </div>
              </div>
            )}

            {dashboard2 && (
              <div className="row">
                <div className="col-lg-3 col-12">
                  <InputField
                    name="clientName"
                    validation={validation}
                    placeholder=""
                    label={"Client Name"}
                    required
                    wrapperClassName="mb-4"
                  />
                </div>
                <div className="col-lg-3 col-12">
                  <InputField
                    name="clientPhone"
                    validation={validation}
                    placeholder=""
                    label={"Client Phone"}
                    wrapperClassName="mb-4"
                    mask={"(999) 999-9999"}
                  />
                </div>
                <div className="col-lg-3 col-12">
                  <InputField
                    name="vin"
                    validation={validation}
                    placeholder=""
                    label={"VIN"}
                    wrapperClassName="mb-4"
                    maxLength="500"
                  />
                </div>
                <div className="col-lg-3 col-12">
                  <InputField
                    name="notes"
                    type="textarea"
                    validation={validation}
                    placeholder=""
                    label={"Notes"}
                    style={{ height: "50px" }}
                    wrapperClassName="mb-4"
                    maxLength="500"
                  />
                </div>
              </div>
            )}

            {!dashboard2 && (
              <div className="row">
                <InputField
                  name="notes"
                  type="textarea"
                  validation={validation}
                  placeholder=""
                  label={"Notes"}
                  style={{ height: "50px" }}
                  wrapperClassName="mb-4"
                  maxLength="500"
                />
              </div>
            )}
          </div>

          <div className="mt-2 mx-2 d-flex justify-content-center justify-content-lg-start">
            <Button
              color="primary"
              style={{
                marginRight: "20px",
                width: "110px",
              }}
              type="submit"
              data-tooltip-id="saveCallBtn"
              data-tooltip-content={`${
                navigator.platform.toUpperCase().indexOf("MAC") >= 0
                  ? "Command+S"
                  : "Ctrl+S"
              }`}
            >
              {loading ? (
                <Spinner size="sm" />
              ) : (
                <>
                  <FontAwesomeIcon icon={faFloppyDisk} />
                  <span className="mx-2">Save</span>
                </>
              )}
            </Button>
            <Button type="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Tooltip id="saveCallBtn" />
          </div>
        </Form>
      </div>
    </>
  )
}

export default NewCallForm
