import {
  ADD_GROUP,
  FETCH_GROUPS,
  GROUP_ERROR,
  GROUP_SUCCESS,
  SET_GROUPS,
  UPDATE_GROUP,
} from "./actionTypes"

const initialState = {
  errorMsg: "",
  successMsg: "",
  loading: false,
  groups: [],
}

const group = (state = initialState, action) => {
  switch (action.type) {
    case ADD_GROUP:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break

    case UPDATE_GROUP:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case FETCH_GROUPS:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        groups: [],
        loading: true,
      }
      break

    case SET_GROUPS:
      state = {
        ...state,
        loading: false,
        groups: action.payload.groups,
      }
      break
    case GROUP_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload.msg,
        loading: false,
      }
      break
    case GROUP_ERROR:
      state = { ...state, errorMsg: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default group
