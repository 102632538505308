import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import {
  ADD_ROLE,
  FETCH_ADDABLE_ROLES,
  FETCH_ROLES,
  UPDATE_ROLE,
} from "./actionTypes"
import { roleSuccess, roleError, setRoles } from "./actions"

//Include Both Helper File with needed methods
import {
  getAddableRoles,
  getRoles,
  postRole,
  putRole,
} from "../../helpers/fakebackend_helper"

import { isArray } from "lodash"
function* addRole({ payload: { role, history } }) {
  try {
    console.log("new saga", role)
    yield call(postRole, role)
    yield put(roleSuccess("Created Successfully"))
    history("/roles")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(roleError(errMsg))
  }
}

function* updateRole({ payload: { id, role, history } }) {
  try {
    yield call(putRole, id, role)
    yield put(roleSuccess("Updated Successfully"))
    history("/roles")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(roleError(errMsg))
  }
}

function* fetchRoles() {
  try {
    console.log("saga get roles")
    const response = yield call(getRoles)
    yield put(setRoles(response))
  } catch (error) {
    console.log("error", error)
  }
}

function* fetchAddableRoles() {
  try {
    const response = yield call(getAddableRoles)
    yield put(setRoles(response))
  } catch (error) {
    console.log("error", error)
  }
}

function* roleSaga() {
  yield takeEvery(ADD_ROLE, addRole)
  yield takeEvery(FETCH_ROLES, fetchRoles)
  yield takeEvery(FETCH_ADDABLE_ROLES, fetchAddableRoles)
  yield takeEvery(UPDATE_ROLE, updateRole)
}

export default roleSaga
