import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { ADD_GROUP, FETCH_GROUPS, UPDATE_GROUP } from "./actionTypes"
import { groupSuccess, groupError, setGroups } from "./actions"

//Include Both Helper File with needed methods
import {
  getGroups,
  postGroup,
  putGroup,
} from "../../helpers/fakebackend_helper"

import { isArray } from "lodash"
function* addGroup({ payload: { group, history } }) {
  try {
    yield call(postGroup, group)
    yield put(groupSuccess("Created Successfully"))
    setTimeout(() => {
      history("/groups")
    }, 1500)
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(groupError(errMsg))
  }
}

function* updateGroup({ payload: { id, group, history } }) {
  try {
    yield call(putGroup, id, group)
    yield put(groupSuccess("Updated Successfully"))
    history("/groups")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(groupError(errMsg))
  }
}

function* fetchGroups() {
  try {
    console.log("saga get groups")
    const response = yield call(getGroups)
    yield put(setGroups(response))
  } catch (error) {
    console.log("error", error)
  }
}

function* groupSaga() {
  yield takeEvery(ADD_GROUP, addGroup)
  yield takeEvery(FETCH_GROUPS, fetchGroups)
  yield takeEvery(UPDATE_GROUP, updateGroup)
}

export default groupSaga
