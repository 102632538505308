import {
  ADD_DISPOSITION,
  FETCH_DISPOSITIONS,
  DISPOSITION_ERROR,
  DISPOSITION_SUCCESS,
  SET_DISPOSITIONS,
  UPDATE_DISPOSITION,
} from "./actionTypes"

export const addDisposition = (disposition, history) => {
  return {
    type: ADD_DISPOSITION,
    payload: { disposition, history },
  }
}

export const updateDisposition = (id, disposition, history) => {
  return {
    type: UPDATE_DISPOSITION,
    payload: { id, disposition, history },
  }
}

export const fetchDispositions = () => {
  return {
    type: FETCH_DISPOSITIONS,
  }
}

export const dispositionSuccess = msg => {
  return {
    type: DISPOSITION_SUCCESS,
    payload: { msg },
  }
}

export const dispositionError = error => {
  return {
    type: DISPOSITION_ERROR,
    payload: error,
  }
}

export const setDispositions = dispositions => {
  return {
    type: SET_DISPOSITIONS,
    payload: { dispositions },
  }
}
