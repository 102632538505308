import { datesOptions } from "constants/datesOptions"
import { useFormik } from "formik"
import React, { useState } from "react"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  getNumberOfAgentsPerStore,
  getRollupReport,
  getServiceAdvisorsReports,
} from "helpers/fakebackend_helper"
import {
  getManualKpisTableData,
  getRollupByStoreTableData,
  getSaByStoreTableData,
  groupManualKpisByKpiName,
  groupRollupReportByStore,
  groupSAReportByStore,
} from "../helpers"

const dummyAgents = {
  total_count: "18",
  stores: [
    {
      count: "14",
      name: "Claremont Toyota",
      id: 46,
    },
    {
      count: "14",
      name: "Toyota of Huntington Beach",
      id: 47,
    },
    {
      count: "15",
      name: "Gardena Honda",
      id: 48,
    },
    {
      count: "13",
      name: "Toyota of Las Vegas",
      id: 49,
    },
    {
      count: "2",
      name: "Mercedes-Benz of Temecula",
      id: 52,
    },
    {
      count: "1",
      name: "CBS of Southern California",
      id: 55,
    },
    {
      count: "1",
      name: "AutoNation 3",
      id: 59,
    },
  ],
  groups: {
    "Dave Wilson Automotive ": {
      count: 56,
    },
    "AutoNation Group - West Coast ": {
      count: 0,
    },
  },
}

const useChecklistReport = () => {
  const dispatch = useDispatch()

  const [checklistReport, setChecklistReport] = useState(undefined)
  const [loadingChecklistReport, setLoadingChecklistReport] =
    useState(undefined)

  async function getChecklistReport({ startDate, endDate, storesIds }) {
    try {
      setLoadingChecklistReport(true)

      const [rollupReportObj, serviceAdvisorsReport, agentsNumbers] =
        await Promise.all([
          getRollupReport({
            startDate,
            endDate,
            storesIds,
            withManuallyAddedKpis: true,
          }),
          getServiceAdvisorsReports({ startDate, endDate, storesIds }),
          getNumberOfAgentsPerStore({ startDate, endDate, storesIds }),
        ])

      const { rollupReport, manualKpisPerStore } = rollupReportObj
      // const rollupReport = dummyReports
      // const serviceAdvisorsReport = dummySas
      // const agentsNumbers = dummyAgents

      //   const rollupGroupedByStore = groupRollupReportByStore(rollupReport)
      const rollupGroupedByStore = groupRollupReportByStore(
        rollupReport,
        agentsNumbers,
        manualKpisPerStore
      )
      const rollupByStoreTableData =
        getRollupByStoreTableData(rollupGroupedByStore)

      const manualKpisGroupedByKpiName =
        groupManualKpisByKpiName(manualKpisPerStore)
      const manualKpisTableData = getManualKpisTableData(
        manualKpisGroupedByKpiName
      )

      // service advisors
      const serviceAdvisorsGroupedByStore = groupSAReportByStore(
        serviceAdvisorsReport
      )
      const saByStoreTableData = getSaByStoreTableData(
        serviceAdvisorsGroupedByStore
      )

      // console.log({
      //   manualKpisPerStore,
      //   rollupGroupedByStore,
      //   rollupReport,
      //   rollupByStoreTableData,
      //   manualKpisTableData,
      //   manualKpisGroupedByKpiName,
      //   serviceAdvisorsReport,
      //   serviceAdvisorsGroupedByStore,
      //   saByStoreTableData,
      //   agentsNumbers,
      // })

      const reportObj = {
        rollupByStoreTableData,
        manualKpisTableData,
        saByStoreTableData,
      }
      setChecklistReport(reportObj)
    } catch (err) {
      console.log(err)
    } finally {
      setLoadingChecklistReport(false)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    // validateOnMount: true,
    // isInitialValid: false,
    initialValues: {
      scope: [],
      selectedDateOption: {
        value: datesOptions.monthToDate.value,
        label: datesOptions.monthToDate.title,
        customAbbreviation: datesOptions.monthToDate.secondaryTitle,
      },
    },
    validationSchema: Yup.object({
      scope: Yup.array().min(1, "Scope Is Required"),

      selectedDateOption: Yup.object().required("Please select a date option"),
    }),
    onSubmit: values => {
      const currentDateOption = Object.values(datesOptions).find(
        ({ value }) => value === values.selectedDateOption.value
      )

      // if scope has "1" which is the whole system scope, then send empty scope as its optional in the api and will return the report for the whole system
      const updatedScope = values.scope.includes("1") ? [] : values.scope

      const startDate = currentDateOption.startDate
      const endDate = currentDateOption.endDate

      getChecklistReport({
        storesIds: updatedScope,
        startDate,
        endDate,
      })
    },
  })
  return { validation, loadingChecklistReport, checklistReport }
}

export default useChecklistReport
