// import React from "react"
// import { FormFeedback, Input, Label } from "reactstrap"

// const InputField = ({
//   label,
//   name,
//   placeholder = "",
//   type = "text",
//   validation,
//   required,
//   wrapperClassName,
//   ...props
// }) => {
//   return (
//     <div className={wrapperClassName}>
//       <Label className="form-label">{`${label} ${required ? "*" : ""}`}</Label>
//       <Input
//         name={name}
//         placeholder={placeholder}
//         type={type}
//         onChange={validation.handleChange}
//         onBlur={validation.handleBlur}
//         value={validation.values[name] || ""}
//         style={{ height: "40px" }}
//         invalid={
//           validation.touched[name] && validation.errors[name] ? true : false
//         }
//         {...props}
//       />
//       {validation.touched[name] && validation.errors[name] ? (
//         <FormFeedback type="invalid">{validation.errors[name]}</FormFeedback>
//       ) : null}
//     </div>
//   )
// }

// export default InputField
import React from "react"
import { FormFeedback, Input, Label } from "reactstrap"
import InputMask from "react-input-mask"

const InputField = ({
  label,
  name,
  placeholder = "",
  type = "text",
  validation,
  required,
  wrapperClassName,
  mask, // New prop for mask
  ...props
}) => {
  return (
    <div className={wrapperClassName}>
      <Label className="form-label">{`${label} ${required ? "*" : ""}`}</Label>
      {mask ? ( // Render input with mask if mask prop is provided
        <InputMask
          mask={mask}
          name={name}
          placeholder={placeholder}
          type={type}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values[name] || ""}
          style={{ height: "40px", width: "100%" }}
          className={
            validation.touched[name] && validation.errors[name]
              ? "input-mask-error"
              : "input-mask"
          }
          invalid={
            validation.touched[name] && validation.errors[name] ? true : false
          }
          {...props}
        />
      ) : (
        <Input
          name={name}
          placeholder={placeholder}
          type={type}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values[name] || ""}
          style={{ height: "40px" }}
          invalid={
            validation.touched[name] && validation.errors[name] ? true : false
          }
          {...props}
        />
      )}
      {validation.touched[name] && validation.errors[name] ? (
        <>
          {mask ? (
            <span
              style={{ color: "#C34040", fontSize: "10.4px", marginTop: "2px" }}
            >
              {"Invalid mobile number"}
            </span>
          ) : (
            <FormFeedback type="invalid">
              {validation.errors[name]}
            </FormFeedback>
          )}
        </>
      ) : null}
    </div>
  )
}

export default InputField
