// import { TreeSelect } from "antd"
// import { axiosApi } from "helpers/api_helper"
// import { combineArrayToString } from "helpers/helperFns"
// import React, { useEffect, useState } from "react"
// import { FormFeedback, Label } from "reactstrap"

// function mapArray(inputArray) {
//   return inputArray.map(item => ({
//     value: item.id.toString(),
//     title: item.name === "system" ? "All" : item.name,
//     children: item.children.length > 0 ? mapArray(item.children) : [],
//   }))
// }

// const ScopeSelector = ({
//   selectedScopes,
//   onChange,
//   usersIds,
//   validation,
//   isSelfScope = true,
// }) => {
//   const [treeData, setTreeData] = useState([])
//   const [selectedOptionsIds, setSelectedOptionsIds] = useState(
//     selectedScopes || []
//   )

//   const handleChange = newValue => {
//     setSelectedOptionsIds(newValue)
//     onChange(newValue)
//   }

//   useEffect(() => {
//     handleChange([])
//     if (isSelfScope) {
//       axiosApi
//         .get("/api/v1/scope/hierarchy/mine")
//         .then(res => setTreeData(mapArray(res.data)))
//     } else {
//       if (!usersIds?.length) {
//         setTreeData([])
//         return
//       }
//       const usersIdsStr = combineArrayToString(usersIds)
//       axiosApi
//         .get(`/api/v1/user/${usersIdsStr}/scope`)
//         .then(res => setTreeData(mapArray(res.data)))
//     }
//   }, [usersIds, isSelfScope])

//   console.log("value", selectedOptionsIds)

//   return (
//     <div>
//       <Label className="form-label">{`Scope *`}</Label>
//       <TreeSelect
//         className="tree-select"
//         showSearch
//         style={{ width: "100%", minHeight: "40px" }}
//         value={selectedOptionsIds}
//         dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
//         placeholder="Please select"
//         allowClear
//         multiple
//         treeDefaultExpandAll
//         onChange={handleChange}
//         treeData={treeData}
//         treeNodeFilterProp="title"
//       />
//       {validation.touched["scope"] && validation.errors["scope"] ? (
//         <FormFeedback type="invalid" style={{ display: "block" }}>
//           {validation.errors["scope"]}
//         </FormFeedback>
//       ) : null}
//     </div>
//   )
// }

// export default ScopeSelector

import { TreeSelect } from "antd"
import { axiosApi } from "helpers/api_helper"
import { combineArrayToString } from "helpers/helperFns"
import { GET_GROUPS_STORES } from "helpers/url_helper"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { FormFeedback, Label } from "reactstrap"
import { createSelector } from "reselect"

function mapArray(inputArray) {
  return inputArray.map(item => ({
    value: item.id.toString(),
    title: item.name === "system" ? "All" : item.name,
    children: item.children.length > 0 ? mapArray(item.children) : [],
  }))
}

const aggregateStores = inputArray => {
  const result = inputArray.reduce((r, { children, ...rest }) => {
    if (rest.type === "store") r.push(rest)
    if (children) r.push(...aggregateStores(children))
    return r
  }, [])

  return result
}
const mapStoresArray = inputArray => {
  const aggregatedStores = aggregateStores(inputArray)
  return aggregatedStores.map(item => ({
    value: item.id.toString(),
    title: item.name,
    children: [],
  }))
}

const ScopeSelector = ({
  selectedScopes,
  onChange,
  usersIds,
  validation,
  isSelfScope = true,
  hideLabel,
  minWidth,
  hasInitialValue = false,
  isStoresOnly = false,
}) => {
  const [treeData, setTreeData] = useState([])
  const [selectedOptionsIds, setSelectedOptionsIds] = useState([])
  const [groups, setGroups] = useState([])
  const [selectedGroupsIds, setSelectedGroupsIds] = useState([])

  const handleChange = newValue => {
    setSelectedOptionsIds(newValue)
    onChange(newValue)
  }

  useEffect(() => {
    if (isStoresOnly) return
    axiosApi.get(GET_GROUPS_STORES).then(res => setGroups(res.data))
  }, [isStoresOnly])

  useEffect(() => {
    if (!hasInitialValue) {
      setSelectedOptionsIds([]) // Reset selected options
    }

    const fetchData = async () => {
      try {
        if (isSelfScope) {
          const res = await axiosApi.get("/api/v1/scope/hierarchy/mine")
          setTreeData(
            isStoresOnly ? mapStoresArray(res.data) : mapArray(res.data)
          )
        } else {
          if (!usersIds?.length) {
            setTreeData([])
            return
          }
          const usersIdsStr = combineArrayToString(usersIds)
          const res = await axiosApi.get(`/api/v1/user/${usersIdsStr}/scope`)
          setTreeData(mapArray(res.data))
        }

        // setting initial value for options
        setSelectedOptionsIds(selectedScopes || [])
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, [usersIds, isSelfScope, selectedScopes, isStoresOnly])

  const handleSelectGroup = groupId => {
    const group = groups.find(item => item.group_id === groupId)
    const groupStores = group?.stores?.map(item => item.id)
    if (selectedGroupsIds.includes(groupId)) {
      //deselect stores
      const newOptions = selectedOptionsIds?.filter(
        item => !groupStores.includes(item)
      )
      handleChange(newOptions)
      setSelectedGroupsIds(old => old.filter(item => item !== groupId))
    } else {
      //select store
      const storesSet = new Set([...selectedOptionsIds, ...groupStores])
      handleChange([...storesSet])

      setSelectedGroupsIds(old => [...old, groupId])
    }
  }

  return (
    <div style={{ minWidth: minWidth || "250px" }}>
      {!hideLabel && <Label className="form-label">{`Scope *`}</Label>}
      <TreeSelect
        className="tree-select"
        showSearch
        style={{ width: "100%", minHeight: "40px" }}
        value={selectedOptionsIds}
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        placeholder="Select scope"
        allowClear
        multiple
        treeDefaultExpandAll
        onChange={handleChange}
        treeData={treeData}
        treeNodeFilterProp="title"
        treeCheckable={true}
        treeCheckStrictly={false}
        showCheckedStrategy="SHOW_PARENT"
        dropdownRender={menu => {
          return (
            <div>
              <div
                style={{
                  width: "100%",
                  borderBottom: "0.5px solid",
                  padding: "4px",
                  marginBottom: "10px",
                  display: "flex",
                  overflow: "scroll",
                  cursor: "pointer",
                }}
              >
                {groups?.map(item => (
                  <div
                    key={item.group_id}
                    style={{
                      padding: "4px 5px",
                      borderRadius: "10px",
                      border: "0.5px solid green",
                      margin: "0 5px",
                      fontSize: "12px",
                      minWidth: "fit-content",
                      color: selectedGroupsIds.find(el => el === item.group_id)
                        ? "white"
                        : "green",
                      backgroundColor: selectedGroupsIds.find(
                        el => el === item.group_id
                      )
                        ? "green"
                        : "white",
                    }}
                    onClick={() => handleSelectGroup(item.group_id)}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
              {menu}
            </div>
          )
        }}
      />
      {validation.touched["scope"] && validation.errors["scope"] ? (
        <FormFeedback type="invalid" style={{ display: "block" }}>
          {validation.errors["scope"]}
        </FormFeedback>
      ) : null}
    </div>
  )
}

export default ScopeSelector
