import {
  ADD_DEPARTMENT,
  GET_DEPARTMENTS,
  DEPARTMENT_ERROR,
  ADD_DEPARTMENT_SUCCESS,
  GET_DEPARTMENTS_SUCCESS,
  UPDATE_DEPARTMENT,
} from "./actionTypes"

const initialState = {
  errorMsg: "",
  successMsg: "",
  loading: false,
  departments: [],
}

const departments = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DEPARTMENT:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case UPDATE_DEPARTMENT:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case GET_DEPARTMENTS:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        departments: [],
        loading: true,
      }
      break
    case ADD_DEPARTMENT_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload.msg,
        loading: false,
      }
      break

    case GET_DEPARTMENTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        departments: action.payload.departments,
      }
      break
    case DEPARTMENT_ERROR:
      state = { ...state, errorMsg: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default departments
