import React from "react"
import styles from "./styles.module.css"
const LiveIcon = () => {
  return (
    <span className={`ms-3 text-danger ${styles.blink}`}>
      LIVE
      {/* <i className={`fa fa-circle text-danger ${styles.blink}`}></i>&nbsp; Live */}
    </span>
  )
}

export default LiveIcon
