export const weekDays = {
  SUNDAY: {
    name: "Sunday",
    value: 0,
  },
  MONDAY: {
    name: "Monday",
    value: 1,
  },
  TUESDAY: {
    name: "Tuesday",
    value: 2,
  },
  WEDNESDAY: {
    name: "Wednesday",
    value: 3,
  },
  THURSDAY: {
    name: "Thursday",
    value: 4,
  },
  FRIDAY: {
    name: "Friday",
    value: 5,
  },

  SATURDAY: {
    name: "Saturday",
    value: 6,
  },
}
