const year = new Date().getFullYear()

export default [
  { value: `${year}-01-25`, label: `January ${year}` },
  { value: `${year}-02-25`, label: `February ${year}` },
  { value: `${year}-03-25`, label: `March ${year}` },
  { value: `${year}-04-25`, label: `April ${year}` },
  { value: `${year}-05-25`, label: `May ${year}` },
  { value: `${year}-06-25`, label: `June ${year}` },
  { value: `${year}-07-25`, label: `July ${year}` },
  { value: `${year}-08-25`, label: `August ${year}` },
  { value: `${year}-09-25`, label: `September ${year}` },
  { value: `${year}-10-25`, label: `October ${year}` },
  { value: `${year}-11-25`, label: `November ${year}` },
  { value: `${year}-12-25`, label: `December ${year}` },
]
