import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import {
  ADD_SERVICE_ADVISOR,
  FETCH_SERVICE_ADVISORS,
  UPDATE_SERVICE_ADVISOR,
} from "./actionTypes"
import {
  serviceAdvisorSuccess,
  serviceAdvisorError,
  setServiceAdvisors,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getServiceAdvisors,
  postServiceAdvisor,
  putServiceAdvisor,
} from "../../helpers/fakebackend_helper"

import { isArray } from "lodash"
function* addServiceAdvisor({ payload: { serviceAdvisor, history } }) {
  try {
    yield call(postServiceAdvisor, serviceAdvisor)
    yield put(serviceAdvisorSuccess("Created Successfully"))
    history("/service-advisors")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(serviceAdvisorError(errMsg))
  }
}

function* fetchServiceAdvisors() {
  try {
    console.log("saga get serviceAdvisors")
    const response = yield call(getServiceAdvisors)
    yield put(setServiceAdvisors(response))
  } catch (error) {
    console.log("error", error)
  }
}

function* updateServiceAdvisor({ payload: { id, serviceAdvisor, history } }) {
  try {
    yield call(putServiceAdvisor, id, serviceAdvisor)
    yield put(serviceAdvisorSuccess("Updated Successfully"))
    history("/service-advisors")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(serviceAdvisorError(errMsg))
  }
}

function* serviceAdvisorSaga() {
  yield takeEvery(ADD_SERVICE_ADVISOR, addServiceAdvisor)
  yield takeEvery(FETCH_SERVICE_ADVISORS, fetchServiceAdvisors)
  yield takeEvery(UPDATE_SERVICE_ADVISOR, updateServiceAdvisor)
}

export default serviceAdvisorSaga
