import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import {
  ADD_DISPOSITION,
  FETCH_DISPOSITIONS,
  UPDATE_DISPOSITION,
} from "./actionTypes"
import {
  dispositionSuccess,
  dispositionError,
  setDispositions,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDispositions,
  patchDisposition,
  postDisposition,
} from "../../helpers/fakebackend_helper"

import { isArray } from "lodash"
function* addDisposition({ payload: { disposition, history } }) {
  try {
    yield call(postDisposition, disposition)
    yield put(dispositionSuccess("Created Successfully"))
    history("/dispositions")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(dispositionError(errMsg))
  }
}

function* fetchDispositions() {
  try {
    const response = yield call(getDispositions)
    yield put(setDispositions(response))
  } catch (error) {
    console.log("error", error)
  }
}

function* updateDisposition({ payload: { id, disposition, history } }) {
  try {
    yield call(patchDisposition, id, disposition)
    yield put(dispositionSuccess("Updated Successfully"))
    history("/dispositions")
  } catch (error) {
    console.log("error", error)
    const errMsg = isArray(error.response?.data?.message)
      ? error.response?.data?.message?.join(", ")
      : error.response?.data?.message
    yield put(dispositionError(errMsg))
  }
}

function* dispositionSaga() {
  yield takeEvery(ADD_DISPOSITION, addDisposition)
  yield takeEvery(FETCH_DISPOSITIONS, fetchDispositions)
  yield takeEvery(UPDATE_DISPOSITION, updateDisposition)
}

export default dispositionSaga
