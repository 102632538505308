import React, { useEffect, useState } from "react"
import EditIcon from "assets/icons/EditIcon"
import DeleteIcon from "assets/icons/DeleteIcon"
import AgGridTable from "components/Common/AgGridTable"
import PageContainer from "components/Common/PageContainer"
import { Button, Spinner } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import { fetchStores } from "store/actions"
import DeleteModal from "components/Common/DeleteModal"
import { axiosApi } from "helpers/api_helper"
import { POST_STORE } from "helpers/url_helper"

const moduleName = "stores"

const Stores = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  //permissions
  const PermissionsSelector = createSelector(
    state => state.Permissions,
    perm => ({
      permissions: perm.permissions,
    })
  )
  const { permissions } = useSelector(PermissionsSelector)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [currentId, setCurrentId] = useState()
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [deleteError, setDeleteError] = useState()

  const [rows, setRows] = useState([])

  useEffect(() => {
    dispatch(fetchStores())
  }, [])

  const StoreProperties = createSelector(
    state => state.Store,
    store => ({
      loading: store.loading,
      stores: store.stores,
    })
  )

  const { loading, stores } = useSelector(StoreProperties)

  useEffect(() => {
    setRows(
      stores.map(item => ({
        id: item.store_id,
        name: item.name,
        company: item.company?.name,
        groups: item.groups?.map(el => el.name)?.join(", "),
      }))
    )
  }, [stores])

  const columns = [
    { field: "name", filter: true, headerName: "Store Name" },
    { field: "company", filter: true, headerName: "Company" },
    { field: "groups", filter: true, headerName: "Groups" },
    {
      headerName: "",
      sortable: false,
      cellRenderer: params => {
        return (
          <div className="d-flex align-items-center h-100 gap-2">
            {actions(params.data.id)}
          </div>
        )
      },
    },
  ]

  const actions = id => {
    return (
      <>
        {permissions?.[moduleName]?.EDIT && (
          <div
            onClick={() =>
              navigate("/stores/" + id, {
                state: stores?.find(item => item.store_id === id),
              })
            }
          >
            <EditIcon />
          </div>
        )}
        {permissions?.[moduleName]?.DELETE && (
          <div
            onClick={() => {
              setShowDeleteModal(true)
              setCurrentId(id)
            }}
          >
            <DeleteIcon />
          </div>
        )}
      </>
    )
  }

  const handleDelete = async () => {
    setDeleteLoading(true)
    setDeleteError(null)
    try {
      await axiosApi.delete(POST_STORE + "/" + currentId)
      dispatch(fetchStores())
      setShowDeleteModal(false)
    } catch (err) {
      setDeleteError(err.response.data?.message)
    } finally {
      setDeleteLoading(false)
    }
  }

  return (
    <PageContainer>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setShowDeleteModal(false)
          setDeleteError(null)
        }}
        errorMsg={deleteError}
        loading={deleteLoading}
      />
      <div className="d-flex justify-content-between mb-4">
        <h1 className="page-title">Stores</h1>
        {permissions?.[moduleName]?.ADD && (
          <Button
            color="primary"
            style={{ height: "36px" }}
            onClick={() => navigate("new")}
          >
            Add Store
          </Button>
        )}
      </div>
      {loading ? (
        <div className="w-full d-flex justify-content-center align-items-center pt-4">
          <Spinner />
        </div>
      ) : (
        <>
          {permissions?.[moduleName]?.VIEW && (
            <AgGridTable
              rowState={[rows, setRows]}
              columns={columns}
              qiuckFilter={true}
            />
          )}
        </>
      )}
    </PageContainer>
  )
}

export default Stores
