import {
  ADD_CALL,
  FETCH_CALLS,
  CALL_ERROR,
  CALL_SUCCESS,
  SET_CALLS,
  UPDATE_CALL,
} from "./actionTypes"

const initialState = {
  errorMsg: "",
  successMsg: "",
  loading: false,
  calls: [],
}

const call = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CALL:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case FETCH_CALLS:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        calls: [],
        loading: true,
      }
      break

    case UPDATE_CALL:
      state = {
        ...state,
        successMsg: "",
        errorMsg: "",
        loading: true,
      }
      break

    case SET_CALLS:
      state = {
        ...state,
        loading: false,
        calls: action.payload.calls,
      }
      break
    case CALL_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload.msg,
        loading: false,
      }
      break
    case CALL_ERROR:
      state = { ...state, errorMsg: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default call
