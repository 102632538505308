import {
  ADD_KPI_ENTRY,
  FETCH_KPIS_ENTRIES,
  KPI_ENTRY_ERROR,
  KPI_ENTRY_SUCCESS,
  SET_KPIS_ENTRIES,
  UPDATE_KPI_ENTRY,
} from "./actionTypes"

const initialState = {
  errorMsg: "",
  successMsg: "",
  loading: false,
  kpisEntries: [],
}

const kpiEntry = (state = initialState, action) => {
  switch (action.type) {
    case ADD_KPI_ENTRY:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case FETCH_KPIS_ENTRIES:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        kpisEntries: [],
        loading: true,
      }
      break
    case UPDATE_KPI_ENTRY:
      state = {
        ...state,
        errorMsg: "",
        successMsg: "",
        loading: true,
      }
      break
    case SET_KPIS_ENTRIES:
      state = {
        ...state,
        loading: false,
        kpisEntries: action.payload.kpisEntries,
      }
      break
    case KPI_ENTRY_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload.msg,
        loading: false,
      }
      break
    case KPI_ENTRY_ERROR:
      state = { ...state, errorMsg: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default kpiEntry
