import {
  ADD_TARGET,
  FETCH_TARGETS,
  TARGET_ERROR,
  TARGET_SUCCESS,
  SET_TARGETS,
} from "./actionTypes"

export const addTarget = (target, history) => {
  return {
    type: ADD_TARGET,
    payload: { target, history },
  }
}

export const fetchTargets = () => {
  return {
    type: FETCH_TARGETS,
  }
}

export const targetSuccess = (target, msg) => {
  return {
    type: TARGET_SUCCESS,
    payload: { target, msg },
  }
}

export const targetError = error => {
  return {
    type: TARGET_ERROR,
    payload: error,
  }
}

export const setTargets = targets => {
  return {
    type: SET_TARGETS,
    payload: { targets },
  }
}
