import React, { useEffect, useState } from "react"
import EditIcon from "assets/icons/EditIcon"
import DeleteIcon from "assets/icons/DeleteIcon"
import AgGridTable from "components/Common/AgGridTable"
import PageContainer from "components/Common/PageContainer"
import { Button, Spinner } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { axiosApi } from "helpers/api_helper"
import DeleteModal from "components/Common/DeleteModal"
import { POST_GOAL } from "helpers/url_helper"
import {
  deleteEvent,
  fetchEvents,
  fetchGoals,
  fetchKpisEntries,
} from "store/actions"
import { format } from "date-fns"
import moment from "moment"
import { truncateString } from "helpers/helperFns"
import { deleteHoliday } from "helpers/fakebackend_helper"

const moduleName = "manualKpisEntries"

const HolidaysSection = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [currentId, setCurrentId] = useState()
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [deleteError, setDeleteError] = useState()

  const [rows, setRows] = useState([])

  //permissions
  const PermissionsSelector = createSelector(
    state => state.Permissions,
    perm => ({
      permissions: perm.permissions,
    })
  )
  const { permissions } = useSelector(PermissionsSelector)

  useEffect(() => {
    dispatch(fetchEvents({ startDate: "", endDate: "" }))
  }, [])

  const CalendarProperties = createSelector(
    state => state.Calendar,
    Calendar => ({
      events: Calendar.events,
      loading: Calendar.loading,
      successMsg: Calendar.successMsg,
      errorMsg: Calendar.errorMsg,
    })
  )

  const { events, loading, successMsg, errorMsg } =
    useSelector(CalendarProperties)

  useEffect(() => {
    setRows(
      events.map(item => ({
        id: item.id,
        name: item.name,
        from: item.from
          ? moment(item.from, "YYYY-MM-DD").format("MM/DD/YYYY")
          : "",
        to: item.to ? moment(item.to, "YYYY-MM-DD").format("MM/DD/YYYY") : "",
        description: item.description,
      }))
    )
  }, [events])

  const columns = [
    { field: "name", headerName: "Name" },
    { field: "from", headerName: "From" },
    { field: "to", headerName: "To" },

    {
      field: "description",
      filter: true,
      headerName: "Description",
      cellRenderer: params => {
        const description = params.data.description
        const isLong = !!description && description.length > 30

        const updatedDescription = isLong
          ? `${truncateString(description, 30)}...`
          : description
        return (
          <div className="d-flex align-items-center h-100">
            {description &&
              (isLong ? (
                <div
                  data-tooltip-id="viewInfo"
                  data-tooltip-content={description}
                >
                  <p>{updatedDescription}</p>
                </div>
              ) : (
                <div>{updatedDescription}</div>
              ))}
          </div>
        )
      },
    },
    {
      headerName: "",
      sortable: false,
      cellRenderer: params => {
        return (
          <div className="d-flex align-items-center h-100 gap-2">
            {actions(params.data.id)}
          </div>
        )
      },
    },
  ]

  const actions = id => {
    return (
      <>
        {permissions?.[moduleName]?.EDIT && (
          <div
            onClick={() =>
              navigate("/holidays-management/" + id, {
                state: events?.find(item => item.id === id),
              })
            }
          >
            <EditIcon />
          </div>
        )}
        {permissions?.[moduleName]?.DELETE && (
          <div
            onClick={() => {
              setShowDeleteModal(true)
              setCurrentId(id)
            }}
          >
            <DeleteIcon />
          </div>
        )}
      </>
    )
  }

  const handleDelete = async () => {
    setDeleteLoading(true)
    setDeleteError(null)
    try {
      await deleteHoliday(currentId)

      dispatch(fetchEvents())
      setShowDeleteModal(false)
    } catch (err) {
      setDeleteError(err.response?.data?.message)
    } finally {
      setDeleteLoading(false)
    }
  }

  return (
    <div>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setShowDeleteModal(false)
          setDeleteError(null)
        }}
        errorMsg={deleteError}
        loading={deleteLoading}
      />

      <div className="content-container" style={{ marginBottom: "-45px" }}>
        <h4 className="">Holidays</h4>
      </div>
      {loading ? (
        <div
          className="w-full d-flex justify-content-center align-items-center pt-4"
          style={{ height: 200 }}
        >
          <Spinner />
        </div>
      ) : (
        <>
          {permissions?.[moduleName]?.VIEW && (
            <AgGridTable rowState={[rows, setRows]} columns={columns} />
          )}
        </>
      )}
    </div>
  )
}

export default HolidaysSection
