import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  Spinner,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link, useNavigate, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "assets/images/logo.png"
import logoSmall from "../../assets/images/logo-small.png"
import axios from "axios"
import { API_URL } from "helpers/api_helper"

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: API_URL,
})

const ResetPassword = props => {
  const dispatch = useDispatch()
  const { token } = useParams()

  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [successMsg, setSuccessMsg] = useState("")
  const navigate = useNavigate()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Password is required")
        .matches(
          // eslint-disable-next-line
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*\-_+=`~§±/():;'|?<>{}\\[\]",.])(?=.{8,})/,
          "Must Contain:<br/> At least 8 Characters <br/>One Uppercase <br/>One Lowercase <br/>One Number<br/> One Symbol"
        ),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Your passwords do not match"
      ),
    }),
    onSubmit: values => {
      setLoading(true)
      setSuccessMsg("")
      setErrorMsg("")
      axiosInstance
        .post(
          "/api/v1/auth/change-forgotten-password",
          { password: values.password },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then(response => {
          setSuccessMsg(
            "Your Password has been changed successfully, redirecting to login..."
          )
          setTimeout(() => {
            navigate("/login")
          }, 4000)
        })
        .catch(error => {
          setErrorMsg(error.response?.data?.message)
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })

  return (
    <React.Fragment>
      <div className="account-pages my-2 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="d-flex flex-row  justify-content-center mb-4">
                <img src={logo} width={180} />
              </div>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div
                        className="text-black py-4"
                        style={{ paddingLeft: "20px" }}
                      >
                        <h5>Reset Password</h5>
                        <p style={{ fontSize: "12px" }}>
                          Hurry up! Don't lose your momentum.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logoSmall} width={72} alt="" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {errorMsg && (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {errorMsg}
                      </Alert>
                    )}
                    {successMsg && (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {successMsg}
                      </Alert>
                    )}

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: validation.errors.password,
                              }}
                            />
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Confirm Password</Label>
                        <Input
                          name="confirmPassword"
                          value={validation.values.confirmPassword || ""}
                          type="password"
                          placeholder="Re-enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.confirmPassword &&
                            validation.errors.confirmPassword
                              ? true
                              : false
                          }
                        />
                        {validation.touched.confirmPassword &&
                        validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.confirmPassword}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            {loading ? (
                              <Spinner size="sm"></Spinner>
                            ) : (
                              "Confirm"
                            )}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember it ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Sign In here
                  </Link>
                </p>
                <p>© {new Date().getFullYear()} PPG Tracker</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ResetPassword.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ResetPassword)
