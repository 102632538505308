import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Col, Container, Row, Spinner } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import Companies from "pages/Companies"
import PageContainer from "components/Common/PageContainer"
import NewCallForm from "pages/CallRecords/NewCallForm"
import DashboardCountCard from "components/DashboardCountCard"
import {
  faCalendar,
  faCalendarCheck,
  faCaretLeft,
  faCaretRight,
  faHandshake,
  faPhone,
} from "@fortawesome/free-solid-svg-icons"
import DashboardPercentCard from "components/DashboardPercentCard"
import { axiosApi } from "helpers/api_helper"
import { format } from "date-fns"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import NewCall from "pages/CallRecords/NewCall"
import AgentDashboard from "./AgentDashboard"
import ManagerDashboard from "./ManagerDashboard"

const Dashboard = props => {
  //meta title
  // document.title = "Dashboard PPG"
  const [report, setReport] = useState({})
  const [goal, setGoal] = useState({})
  const [loading, setLoading] = useState(true)

  //permissions
  const PermissionsSelector = createSelector(
    state => state.Permissions,
    perm => ({
      permissions: perm.permissions,
    })
  )
  const { permissions } = useSelector(PermissionsSelector)

  const CallProperties = createSelector(
    state => state.Call,
    call => ({
      successMsg: call.successMsg,
    })
  )

  const { successMsg } = useSelector(CallProperties)

  // const getDashboard = () => {
  //   setLoading(true)

  //   const nowDate = new Date()
  //   nowDate.setHours(0)
  //   nowDate.setMinutes(0)
  //   nowDate.setSeconds(0)

  //   const currentDate = nowDate.toISOString().substring(0, 19)

  //   axiosApi
  //     .get(`/api/v1/reports/dashboard?startDate=${currentDate}`)
  //     .then(res => {
  //       console.log("report", res)
  //       const newReport = {
  //         inbound: 0,
  //         outbound: 0,
  //         internal: 0,
  //         appointedInbound: 0,
  //         appointedOutbound: 0,
  //         appointedInternal: 0,
  //       }
  //       for (const call of res.data?.calls) {
  //         newReport.inbound += Number(call.inbound)
  //         newReport.outbound += Number(call.outbound)
  //         newReport.internal += Number(call.internal)
  //         newReport.appointedInbound += Number(call.appointedInbound)
  //         newReport.appointedOutbound += Number(call.appointedOutbound)
  //         newReport.appointedInternal += Number(call.appointedInternal)
  //       }
  //       console.log("report actual", newReport)
  //     })
  //     .catch(err => console.log(err))
  //     .finally(() => setLoading(false))

  //   axiosApi
  //     .get(
  //       `/api/v1/reports/dashboard-goal/Daily Goals?startDate=${currentDate}`
  //     )
  //     .then(res => {
  //       const newGoal = {}
  //       for (const goal of res.data) {
  //         newGoal.target = Number(goal.target)
  //         newGoal.minStandard = Number(goal.minstandard)
  //         if (goal.entity_type === "agent") break
  //       }
  //       console.log("report goal", newGoal)
  //     })
  // }

  // useEffect(() => {
  //   getDashboard()
  // }, [])

  // useEffect(() => {
  //   getDashboard()
  // }, [successMsg])

  const getPercent = (num1, num2) => {
    return Math.ceil((num1 / (num1 + num2)) * 100)
  }

  return (
    <React.Fragment>
      {/* Agent view */}
      {permissions?.calls?.OWNINPUT && permissions?.calls.ADD ? (
        <>
          {/* <NewCall /> */}
          <AgentDashboard />
        </>
      ) : (
        // Admin view
        // <PageContainer>
        //   <h1 className="page-title">Dashboard</h1>
        // </PageContainer>
        <ManagerDashboard />
      )}
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
