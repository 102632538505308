import React, { useEffect, useState } from "react"
import EditIcon from "assets/icons/EditIcon"
import DeleteIcon from "assets/icons/DeleteIcon"
import AgGridTable from "components/Common/AgGridTable"
import PageContainer from "components/Common/PageContainer"
import { Button, Spinner } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { axiosApi } from "helpers/api_helper"
import DeleteModal from "components/Common/DeleteModal"

import {
  deleteEvent,
  fetchEvents,
  fetchGoals,
  fetchKpisEntries,
} from "store/actions"
import { format } from "date-fns"
import moment from "moment"
import { truncateString } from "helpers/helperFns"
import { deleteHoliday } from "helpers/fakebackend_helper"
import HolidaysSection from "./components/HolidaysSection"
import WeekendsSection from "./components/WeekendsSection"

const moduleName = "manualKpisEntries"

const HolidaysManagement = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  //permissions
  const PermissionsSelector = createSelector(
    state => state.Permissions,
    perm => ({
      permissions: perm.permissions,
    })
  )
  const { permissions } = useSelector(PermissionsSelector)

  return (
    <PageContainer>
      <div className="d-flex justify-content-between mb-4">
        <h1 className="page-title">Holidays Management</h1>
        {permissions?.[moduleName]?.ADD && (
          <div className="d-flex gap-4">
            <Button
              color="primary"
              style={{ height: "36px" }}
              onClick={() => navigate("new")}
            >
              Add New Holiday/Weekend
            </Button>
          </div>
        )}
      </div>
      <div className="d-flex gap-4">
        <div style={{ flex: 1 }}>
          <HolidaysSection />
        </div>
        <div style={{ flex: 1 }}>
          <WeekendsSection />
        </div>
      </div>
    </PageContainer>
  )
}

export default HolidaysManagement
