import React, { useCallback, useEffect, useRef, useState } from "react"
import AgGridTable from "components/Common/AgGridTable"
import PageContainer from "components/Common/PageContainer"
import { Button, Form, Spinner } from "reactstrap"
import { useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { useFormik } from "formik"
import {
  fetchServiceAdvisorReports,
  resetServiceAdvisorReports,
} from "store/actions"
import * as Yup from "yup"
import ScopeSelector from "components/Common/ScopeSelector"
import SelectField from "components/Form/SelectField"
import { dummyReports, dummyReports2 } from "./dummy"
import {
  customDateSelectFieldOptions,
  datesOptions,
  datesSelectFieldOptions,
  isCustomDateOption,
  tzMoment,
} from "constants/datesOptions"
import CsvIcon from "assets/icons/CsvIcon"
import CustomRangePicker from "components/Form/CustomRangePicker"

const moduleName = "serviceAdvisorReport"

const ServiceAdvisorsReports = () => {
  const dispatch = useDispatch()
  const gridRef = useRef()

  const [rangePickerKey, setRangePickerKey] = useState(0)

  const localStorageUser = localStorage.getItem("authUser")
  const user = localStorageUser
    ? JSON.parse(localStorage.getItem("authUser"))
    : undefined

  //permissions
  const PermissionsSelector = createSelector(
    state => state.Permissions,
    perm => ({
      permissions: perm.permissions,
    })
  )
  const { permissions } = useSelector(PermissionsSelector)
  const ServiceAdvisoresReportsProperties = createSelector(
    state => state.ServiceAdvisorReport,
    serviceAdvisorReport => ({
      loading: serviceAdvisorReport.loading,
      serviceAdvisorsReports: serviceAdvisorReport.serviceAdvisorsReports,
    })
  )

  const { loading: loadingServiceAdvisorsReports, serviceAdvisorsReports } =
    useSelector(ServiceAdvisoresReportsProperties)
  // const serviceAdvisorsReports = dummyReports2

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    // validateOnMount: true,
    // isInitialValid: false,
    initialValues: {
      scope: [],
      selectedDateOption: {
        value: datesOptions.monthToDate.value,
        label: datesOptions.monthToDate.title,
        customAbbreviation: datesOptions.monthToDate.secondaryTitle,
      },
      startDate: "",
      endDate: "",
    },
    validationSchema: Yup.object({
      startDate: Yup.string().when(
        "selectedDateOption",
        ([selectedDateOption], schema) =>
          selectedDateOption.value === datesOptions.custom.value
            ? schema.required("Please add start date")
            : schema.optional()
      ),
      endDate: Yup.string().when(
        "selectedDateOption",
        ([selectedDateOption], schema) =>
          selectedDateOption.value === datesOptions.custom.value
            ? schema.required("Please add start date")
            : schema.optional()
      ),
      scope: Yup.array().min(1, "Scope Is Required"),

      selectedDateOption: Yup.object().required("Please select a date option"),
    }),
    onSubmit: values => {
      const currentDateOption = Object.values(datesOptions).find(
        ({ value }) => value === values.selectedDateOption.value
      )

      // if scope has "1" which is the whole system scope, then send empty scope as its optional in the api and will return the report for the whole system
      const updatedScope = values.scope.includes("1") ? [] : values.scope

      let startDate, endDate
      if (currentDateOption.value === datesOptions.custom.value) {
        startDate = values.startDate
        endDate = values.endDate
      } else {
        startDate = currentDateOption.startDate
        endDate = currentDateOption.endDate
      }
      console.log(startDate, endDate)
      dispatch(
        fetchServiceAdvisorReports({
          storesIds: updatedScope,
          startDate,
          endDate,
        })
      )
    },
  })

  function refreshDatePicker() {
    setRangePickerKey(oldVal => oldVal + 1)
  }

  const isCustomDate = isCustomDateOption(
    validation.values.selectedDateOption?.value
  )
  function handleCustomRangeChange(dateStrings) {
    if (dateStrings.some(el => !el)) {
      return
    }

    const [startStr, endStr] = dateStrings || []
    validation.setFieldValue("startDate", startStr)
    validation.setFieldValue("endDate", endStr)

    const startStrTitle = startStr
      ? tzMoment(startStr).format("MM/DD/YYYY")
      : ""
    const endStrTitle = endStr ? tzMoment(endStr).format("MM/DD/YYYY") : ""
    const customAbbr =
      startStrTitle && endStrTitle ? `${startStrTitle} - ${endStrTitle}` : ""
    validation.setFieldValue("selectedDateOption", {
      ...validation.values.selectedDateOption,
      customAbbreviation: customAbbr,
    })
  }

  function valueFormatter(params) {
    if (
      params.value === "" ||
      params.value === undefined ||
      params.value === null
    ) {
      return "0"
    }
  }

  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])

  useEffect(() => {
    if (validation.values) {
      dispatch(resetServiceAdvisorReports())
    }
  }, [validation.values])

  useEffect(() => {
    if (!serviceAdvisorsReports?.length) {
      setRows([])
      setColumns([])
      return
    }

    const uniqueDispositionNames = [
      ...new Set(
        serviceAdvisorsReports.map(reportObj => reportObj["dispositionName"])
      ),
    ]
    const cols = [
      {
        field: "storeName",
        headerName: "Store Name ",
        filter: true,
        valueFormatter,
        pinned: "left",
      },
      {
        field: "advisorName",
        headerName: "Service Advisor Name",
        filter: true,
        valueFormatter,
        pinned: "left",
      },
      ...uniqueDispositionNames.map(dispositionName => {
        return {
          field: dispositionName,
          headerName: dispositionName,
          filter: true,
          valueFormatter,
        }
      }),
    ]
    setColumns(cols)

    const reportsFilteredByName = Object.values(
      serviceAdvisorsReports.reduce(
        (acc, obj) => ({
          ...acc,
          [`${obj["serviceAdvisorName"]} - ${obj["storeName"]}`]: {
            ...obj,
          },
        }),
        {}
      )
    )

    const tableRows = reportsFilteredByName.map(reportObj => {
      const advisorName = reportObj["serviceAdvisorName"]
      const storeName = reportObj["storeName"]

      const dispositionsObj = Object.assign(
        {},
        ...serviceAdvisorsReports
          .filter(
            r =>
              r["serviceAdvisorName"] === advisorName &&
              r["storeName"] === storeName
          )
          .map(r => ({
            [r["dispositionName"]]: r["count"],
          }))
      )
      return {
        storeName,
        advisorName,
        ...dispositionsObj,
      }
    })

    setRows(tableRows)
  }, [serviceAdvisorsReports])

  const onBtnExport = useCallback(() => {
    const csvParams = {
      fileName: "Service Advisors Report",
    }

    gridRef.current.api.exportDataAsCsv(csvParams)
  }, [gridRef])

  return (
    <PageContainer>
      <div className="d-flex justify-content-between mb-4">
        <h1 className="page-title">Service Advisors Report</h1>
      </div>
      <div>
        {/* -15px margin to blend in the 2 sections */}
        <Form
          className="content-container"
          style={{ marginBottom: "-15px" }}
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div
            className="row pb-4"
            style={{ background: "#fff", rowGap: "20px" }}
          >
            <div className="col-lg-3 col-12">
              <ScopeSelector
                validation={validation}
                onChange={ids => {
                  validation.setFieldValue("scope", ids)
                }}
              />
            </div>
            <div className="col-lg-4 col-12">
              <SelectField
                label="Date"
                name="selectedDateOption"
                validation={validation}
                required
                options={customDateSelectFieldOptions}
                onChange={option => {
                  validation.setFieldValue("selectedDateOption", option)
                  if (isCustomDateOption(option.value)) {
                    validation.setFieldValue("startDate", "")
                    validation.setFieldValue("endDate", "")
                    refreshDatePicker()
                  }
                }}
              />
              {isCustomDate && !validation.values.startDate && (
                <div key={rangePickerKey} className="">
                  <CustomRangePicker
                    onChangeHandler={handleCustomRangeChange}
                  />
                </div>
              )}
            </div>
            {/* <div className="col-lg-2 col-12" style={{ marginTop: "28px" }}>
              <Button color="primary" style={{ height: "36px" }} type="submit">
                Generate
              </Button>
            </div> */}
            <div
              // className="col-lg-1 col-12 d-flex gap-2"
              className="col d-flex align-items-start justify-content-between gap-2"
              style={{ marginTop: "28px" }}
            >
              <Button
                color="primary"
                style={{ height: "36px", marginRight: 20 }}
                type="submit"
              >
                Generate
              </Button>
              {!!columns.length && (
                <>
                  <div>
                    <CsvIcon onClick={onBtnExport} />
                  </div>
                </>
              )}
            </div>
          </div>
        </Form>
        {loadingServiceAdvisorsReports ? (
          <div className="w-full d-flex justify-content-center align-items-center pt-4">
            <Spinner />
          </div>
        ) : (
          <>
            {permissions?.[moduleName]?.VIEW &&
            validation.isValid &&
            !validation.isValidating &&
            columns.length ? (
              <AgGridTable
                gridRef={gridRef}
                rowState={[rows, setRows]}
                columns={columns}
              />
            ) : null}
          </>
        )}
      </div>
    </PageContainer>
  )
}

export default ServiceAdvisorsReports
