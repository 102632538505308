import {
  ADD_COMPANY,
  FETCH_COMPANIES,
  COMPANY_ERROR,
  COMPANY_SUCCESS,
  SET_COMPANIES,
  UPDATE_COMPANY,
} from "./actionTypes"

export const addCompany = (company, history) => {
  return {
    type: ADD_COMPANY,
    payload: { company, history },
  }
}

export const fetchCompanies = () => {
  return {
    type: FETCH_COMPANIES,
  }
}

export const updateCompany = (id, company, history) => {
  return {
    type: UPDATE_COMPANY,
    payload: { id, company, history },
  }
}

export const companySuccess = msg => {
  return {
    type: COMPANY_SUCCESS,
    payload: { msg },
  }
}

export const companyError = error => {
  return {
    type: COMPANY_ERROR,
    payload: error,
  }
}

export const setCompanies = companies => {
  return {
    type: SET_COMPANIES,
    payload: { companies },
  }
}
