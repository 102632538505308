import React, { useEffect } from "react"
import { useLocation, useParams } from "react-router-dom"
import { authProtectedRoutes, publicRoutes } from "routes"

const RouteWrapper = ({ children }) => {
  const location = useLocation()
  const params = useParams()

  useEffect(() => {
    let originalPathDefinition = location.pathname
    for (const [param, value] of Object.entries(params || {})) {
      originalPathDefinition = originalPathDefinition.replace(
        value,
        `:${param}`
      )
    }

    const routeObj = [...authProtectedRoutes, ...publicRoutes].find(
      ({ path }) => {
        return path === originalPathDefinition
      }
    )
    const docTitle = routeObj?.documentTitle || "Peak Performance Group"
    document.title = docTitle
  }, [location.pathname])

  return <div>{children}</div>
}

export default RouteWrapper
